import { type QueryClient } from '@tanstack/react-query'

import { getLoaderContext } from './context'

import { type ApiTypes, type LooseApiDefinition } from '../../core'
import {
  createQueryOptions,
  type QueryOptionsContext,
} from '../hooks/use-query-options-factory'

export function createLoader<TApi extends LooseApiDefinition>(api: TApi) {
  type t = ApiTypes<TApi>
  return (client: QueryClient) => {
    const httpOptions = getLoaderContext(client)

    const ctx: QueryOptionsContext<TApi> = {
      token: httpOptions.token,
      headers: httpOptions.headers,
      client,
    }
    const factory = createQueryOptions<TApi>(api, ctx)
    return (args: t['args'], opts?: t['UseQueryOptions']) => {
      const query = factory(args, opts)
      return client.ensureQueryData(query)
    }
  }
}
