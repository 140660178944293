import { ORDERED_LIST, UNORDERED_LIST } from '@lexical/markdown'

export * from './editor'
export * from './plugins'
export * from './context'
export * from './utils'
export * from './title-editor'
export * from './base-editor'
export * from './ai-docs-editor'

export { TabIndentationPlugin as LexicalTabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
export { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
export { ListPlugin } from '@lexical/react/LexicalListPlugin'
export { ListItemNode, ListNode } from '@lexical/list'
export const LIST_TRANSFORMERS = [UNORDERED_LIST, ORDERED_LIST]

export { AttachmentLexicalWrapper } from './plugins/attachment-plugin/attachment-lexical-wrapper'

export {
  MentionNode,
  $createMentionNode,
  $isMentionNode,
  AttachmentNode,
  CollapsibleContainerNode,
  CollapsibleTitleNode,
  CollapsibleContentNode,
  ActionItemBlockNode,
} from '@motion/notes-shared'
