import { ListItemNode, type SerializedListItemNode } from '@lexical/list'
import { $findMatchingParent } from '@lexical/utils'
import {
  DOMConversionMap,
  DOMConversionOutput,
  EditorConfig,
  ElementNode,
  isHTMLElement,
  LexicalEditor,
  LexicalNode,
  type NodeKey,
  RangeSelection,
  type Spread,
} from 'lexical'
import { v4 } from 'uuid'

import {
  $createCollapsibleListNode,
  $isCollapsibleListNode,
} from './collapsible-list-node'
import {
  COLLAPSIBLE_ITEM_ATTR,
  EXPANDED_ITEM_ATTR,
  TIMESTAMP_ATTR,
} from './constants'
import { createNoteInfoElement } from './utils'

import { MotionId } from '../../../types'

export type SerializedCollapsibleListItemNode = Spread<
  {
    open: boolean
    noteInfo?: NoteInfo
  },
  SerializedListItemNode
>

export type NoteInfo = {
  /**
   * Duration in seconds of the meeting recording video
   */
  timestamp?: number
}

export class CollapsibleListItemNode extends ListItemNode {
  __motionId: MotionId

  __open: boolean
  __noteInfo?: NoteInfo

  static getType() {
    return 'collapsible-list-item'
  }

  constructor(
    value?: number,
    checked?: boolean,
    open?: boolean,
    noteInfo?: NoteInfo,
    motionId: MotionId = v4(),
    key?: NodeKey
  ) {
    super(value, checked, key)
    this.__open = open ?? true
    this.__noteInfo = noteInfo
    this.__motionId = motionId
  }

  static clone(node: CollapsibleListItemNode): CollapsibleListItemNode {
    return new CollapsibleListItemNode(
      node.__value,
      node.__checked,
      node.__open,
      node.__noteInfo,
      node.__motionId,
      node.__key
    )
  }

  static importDOM(): DOMConversionMap | null {
    return {
      li: () => ({
        conversion: $convertCollapsibleListItemElement,
        priority: 2,
      }),
    }
  }

  createDOM(config: EditorConfig, editor?: LexicalEditor): HTMLElement {
    const dom = super.createDOM(config)

    if ($isCollapsibleHeaderListItem(this)) {
      dom.setAttribute(COLLAPSIBLE_ITEM_ATTR, 'true')
      dom.setAttribute(EXPANDED_ITEM_ATTR, JSON.stringify(this.__open))
    }

    if (this.__noteInfo) {
      const { timestamp } = this.__noteInfo

      if (timestamp != null && timestamp > 0 && editor != null) {
        dom.setAttribute(TIMESTAMP_ATTR, timestamp.toString())

        const info = createNoteInfoElement(timestamp, editor, config)

        dom.appendChild(info)
      }
    }

    return dom
  }

  updateDOM(prevNode: this, dom: HTMLElement, config: EditorConfig): boolean {
    const update = super.updateDOM(prevNode, dom, config)
    let nextSibling = this.getNextSibling()

    const prevIsCollapsible = JSON.parse(
      dom.getAttribute(COLLAPSIBLE_ITEM_ATTR) || 'false'
    )
    const currIsCollapsible = $isCollapsibleHeaderListItem(this)

    // If the node is not collapsible, remove the collapsible attribute and the toggle button
    if (prevIsCollapsible !== currIsCollapsible) {
      dom.removeAttribute(COLLAPSIBLE_ITEM_ATTR)
    }

    if (!$isCollapsibleListItemNode(nextSibling)) {
      return update
    }

    const child = nextSibling.getFirstChild()

    if (!$isCollapsibleListNode(child)) {
      return update
    }

    const currentOpen = this.__open
    if (prevNode.__open !== currentOpen) {
      dom.setAttribute(EXPANDED_ITEM_ATTR, JSON.stringify(currentOpen))
    }

    return update
  }

  exportDOM(editor: LexicalEditor) {
    const ret = super.exportDOM(editor)
    const el = ret.element
    if (isHTMLElement(el) && el.dataset.collapsible === 'true') {
      el.dataset.expanded = this.__open ? 'true' : 'false'
    }

    return ret
  }

  static importJSON(
    serializedNode: SerializedCollapsibleListItemNode
  ): CollapsibleListItemNode {
    return new CollapsibleListItemNode(
      serializedNode.value,
      serializedNode.checked,
      serializedNode.open,
      serializedNode.noteInfo
    ).updateFromJSON(serializedNode)
  }

  exportJSON(): SerializedCollapsibleListItemNode {
    return {
      ...super.exportJSON(),
      open: this.__open,
      type: this.getType(),
      noteInfo: this.__noteInfo,
      version: 1,
    }
  }

  getMotionId() {
    const self = this.getLatest()
    return self.__motionId
  }

  setOpen(open: boolean): void {
    const writable = this.getWritable()
    writable.__open = open
  }

  getOpen(): boolean {
    return this.getLatest().__open
  }

  toggleOpen(): void {
    this.setOpen(!this.getOpen())
  }

  isShadowRoot(): boolean {
    return true
  }

  isParentRequired(): true {
    return true
  }

  createParentElementNode(): ElementNode {
    return $createCollapsibleListNode('bullet', 0)
  }

  canMergeWith(node: LexicalNode): boolean {
    return $isCollapsibleListItemNode(node)
  }

  excludeFromCopy(destination?: 'clone' | 'html'): boolean {
    return destination === 'html' && $isCollapsibleContainerListItem(this)
  }

  collapseAtStart(selection: RangeSelection): true {
    return true
  }
}

export function $createCollapsibleListItemNode(
  value?: number,
  checked?: boolean,
  open?: boolean,
  noteInfo?: NoteInfo
): CollapsibleListItemNode {
  return new CollapsibleListItemNode(value, checked, open, noteInfo)
}

export function $convertCollapsibleListItemElement(
  domNode: HTMLElement
): DOMConversionOutput {
  const noteInfo = getNoteInfo(domNode)

  const isGitHubCheckList = domNode.classList.contains('task-list-item')
  if (isGitHubCheckList) {
    for (const child of Array.from(domNode.children)) {
      if (child.tagName === 'INPUT') {
        return $convertCheckboxInput(child, noteInfo)
      }
    }
  }

  const ariaCheckedAttr = domNode.getAttribute('aria-checked')
  const checked =
    ariaCheckedAttr === 'true'
      ? true
      : ariaCheckedAttr === 'false'
        ? false
        : undefined

  return {
    node: $createCollapsibleListItemNode(
      undefined,
      checked,
      undefined,
      noteInfo
    ),
  }
}

function $convertCheckboxInput(
  domNode: Element,
  noteInfo?: NoteInfo
): DOMConversionOutput {
  const isCheckboxInput = domNode.getAttribute('type') === 'checkbox'
  if (!isCheckboxInput) {
    return { node: null }
  }
  const checked = domNode.hasAttribute('checked')

  return {
    node: $createCollapsibleListItemNode(
      undefined,
      checked,
      undefined,
      noteInfo
    ),
  }
}

export function $isCollapsibleListItemNode(
  node: LexicalNode | null | undefined
): node is CollapsibleListItemNode {
  return node instanceof CollapsibleListItemNode
}

function $getCollapsibleContainerListItem(
  node: LexicalNode
): CollapsibleListItemNode | null {
  const previousSibling = node.getPreviousSibling()

  if (!$isCollapsibleContainerListItem(node)) {
    return null
  }

  if (!$isCollapsibleListItemNode(previousSibling)) {
    return null
  }
  return previousSibling
}

// Checks if this list item node is a container for a sub list
export function $isCollapsibleContainerListItem(node: LexicalNode): boolean {
  if (!$isCollapsibleListItemNode(node)) {
    return false
  }

  const child = node.getFirstChild()

  return $isCollapsibleListNode(child)
}

// Checks if this list item node is a header for a sub list
export function $isCollapsibleHeaderListItem(
  node: CollapsibleListItemNode
): boolean {
  const nextSibling = node.getNextSibling()

  if (!$isCollapsibleListItemNode(nextSibling)) {
    return false
  }

  return $isCollapsibleContainerListItem(nextSibling)
}

export function $isInsideCollapsedListItem(node: LexicalNode): boolean {
  return (
    $findMatchingParent(node, (node) => {
      if (!$isCollapsibleListItemNode(node)) return false
      const collapsibleItem = $getCollapsibleContainerListItem(node)
      return collapsibleItem !== null && !collapsibleItem.getOpen()
    }) !== null
  )
}

function getNoteInfo(domNode: HTMLElement): NoteInfo {
  const timestampAttr = domNode.getAttribute(TIMESTAMP_ATTR)
  return {
    timestamp: domNode.getAttribute(TIMESTAMP_ATTR)
      ? parseInt(timestampAttr ?? '0')
      : undefined,
  }
}
