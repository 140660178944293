import { CollaborationPlugin as LexicalCollaborationPlugin } from '@lexical/react/LexicalCollaborationPlugin'
import { type ComponentProps, useMemo } from 'react'

import { useEditorContext } from '../../context'

export function CollaborationPlugin(
  props: Omit<
    ComponentProps<typeof LexicalCollaborationPlugin>,
    'cursorContainerRef'
  >
) {
  const { floatingAnchorElem } = useEditorContext()

  const cursorsContainerRef = useMemo(
    () => ({ current: floatingAnchorElem }),
    [floatingAnchorElem]
  )

  return (
    <LexicalCollaborationPlugin
      {...props}
      cursorsContainerRef={cursorsContainerRef}
    />
  )
}
