import { debounce } from '@motion/utils/core'
import { makeLog } from '@motion/web-base/logging'
import { Sentry } from '@motion/web-base/sentry'

import {
  type PersistedClient,
  type Persister,
} from '@tanstack/react-query-persist-client'

import { DB } from '../storage'
import {
  DB_VERSION,
  QUERY_PERSISTOR_MIN_DB_VERSION,
} from '../storage/db/constants'

const log = makeLog('query-persister')

const QUERY_CACHE_KEY = 'query'

const createThrottledPersist = (key: string) => {
  return debounce(
    async (client: PersistedClient) => {
      // We only want to persist if we are using `indexedDB`
      if (DB.provider !== 'indexedDB') return
      if (DB.version < 3) return

      await log
        .time(`store [${key}]`, async () => {
          await DB.open()
          return DB.queryCache.set(key, client as any)
        })
        .catch(reportError('persist', key, () => undefined))
    },
    2000,
    { leading: true, trailing: true }
  )
}

export function createGlobalQueryPersister(key: string = QUERY_CACHE_KEY) {
  log('create', key)
  if (DB_VERSION < QUERY_PERSISTOR_MIN_DB_VERSION) {
    return noopPersister
  }
  return {
    persistClient: createThrottledPersist(key),
    restoreClient: async () => {
      // We only want to restore if we are using `indexedDB`

      return await log.time(
        `restore [${key}]`,
        async () => {
          return DB.open()
            .then(() => {
              if (DB.provider !== 'indexedDB' || DB.version < 3) {
                log(
                  `persistor requires indexedDB >= 3. Found '${DB.provider}@${DB.version}`
                )
                return undefined
              }

              return DB.queryCache.get(key)
            })
            .catch(reportError('restore', key, () => undefined))
        },
        () => ({ mark: Math.trunc(performance.now()) })
      )
    },
    removeClient: async () => {
      log('clearing')
      return DB.open()
        .then(() => {
          if (DB.provider !== 'indexedDB' || DB.version < 3) return
          return DB.queryCache.delete(key)
        })
        .catch(reportError('removeClient', key, () => undefined))
    },
  } as Persister
}

export const noopPersister = {
  persistClient: () => {},
  restoreClient: () => {},
  removeClient: () => {},
} as Persister

/**
 * Reports an error to Sentry
 * @param method the method that was invoked
 * @param key the persistance key
 * @param handler optional handler for error. Defaults to re-throw
 * @returns the result of the handler
 */
function reportError<T>(
  method: string,
  key: string,
  handler: (ex: unknown) => T = (ex) => {
    throw ex
  }
) {
  return (ex: unknown) => {
    Sentry.captureException(ex, {
      tags: {
        position: 'query-persistor',
      },
      extra: {
        method,
        key,
      },
    })
    return handler(ex)
  }
}
