import { $isLinkNode, LinkNode } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getNodeByKey } from 'lexical'
import { useEffect } from 'react'

export function ExternalLinkPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerMutationListener(LinkNode, (mutations) => {
      for (const [key, mutation] of mutations) {
        if (mutation === 'created') {
          editor.update(() => {
            const node = $getNodeByKey(key)

            if ($isLinkNode(node)) {
              const isExternalLink = !node
                .getURL()
                .startsWith(window.location.origin)

              // Make external links open in a new tab
              if (isExternalLink) {
                node.setTarget('_blank')
              }
            }
          })
        }
      }
    })
  }, [editor])

  return null
}
