import {
  $createAttachmentNode,
  $isAttachmentNode,
  AttachmentNode,
} from '@motion/notes-shared'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { DRAG_DROP_PASTE, eventFiles } from '@lexical/rich-text'
import {
  $dfs,
  $getNearestBlockElementAncestorOrThrow,
  mergeRegister,
} from '@lexical/utils'
import {
  $createParagraphNode,
  $createRangeSelection,
  $getNearestNodeFromDOMNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootNode,
  $isTextNode,
  $nodesOfType,
  $normalizeSelection__EXPERIMENTAL,
  $setSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  DROP_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
  PASTE_COMMAND,
} from 'lexical'
import { useEffect, useRef } from 'react'
import { v4 as uuid } from 'uuid'

import {
  DELETE_ATTACHMENT_NODE,
  type DeleteAttachmentCommandPayload,
  INSERT_ATTACHMENT_NODES,
  type InsertAttachmentCommandPayload,
  SET_ATTACHMENT_PREVIEW_NODE,
  type SetAttachmentPreviewCommandPayload,
  TRIGGER_ATTACHMENT_UPLOAD,
  UPDATE_ATTACHMENT_NODE,
  type UpdateAttachmentCommandPayload,
} from './commands'

import { getCaretFromPoint } from '../../utils'

export type AttachmentsPluginProps = {
  uploadFile: (file: File) => Promise<string>
  fileIsPreviewable: (mimeType: string) => boolean
  supportedMimeTypes?: readonly string[]
}

export function AttachmentsPlugin({
  uploadFile,
  fileIsPreviewable,
  supportedMimeTypes = [],
}: AttachmentsPluginProps) {
  const [editor] = useLexicalComposerContext()

  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        PASTE_COMMAND,
        (event) => {
          const [, files] = eventFiles(event)
          if (files.length > 0) {
            // Lexical doesn't paste as a file if there's text content in the clipboard.
            // I assume this is to make the preview work without having to upload the file.
            // We want to upload the file as an attachment every time.
            editor.dispatchCommand(DRAG_DROP_PASTE, files)
            return true
          }

          return false
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        DRAG_DROP_PASTE,
        (files) => {
          // Create a temp ID for each file
          const filesWithTempIds = files.map((file): [string, File] => [
            uuid(),
            file,
          ])

          // Check if every file is previewable
          const isPreview = filesWithTempIds.every(([, file]) =>
            fileIsPreviewable?.(file.type)
          )

          // Dispatch command that inserts the attachment nodes in loading state
          editor.dispatchCommand(INSERT_ATTACHMENT_NODES, {
            attachments: filesWithTempIds,
            isUploading: true,
            isPreview,
          })

          // Upload the files
          ;(async () => {
            const filesResult = await Promise.allSettled(
              filesWithTempIds.map(async ([tempId, file]) => {
                const attachmentId = await uploadFile(file)
                return {
                  tempId,
                  attachmentId,
                  mimeType: file.type,
                  filename: file.name,
                  size: file.size,
                }
              })
            )

            // Dispatch command that updates the attachment nodes
            filesResult.forEach((result, index) => {
              if (result.status === 'fulfilled') {
                const { tempId, attachmentId, mimeType, filename, size } =
                  result.value

                editor.dispatchCommand(UPDATE_ATTACHMENT_NODE, {
                  attachmentId: tempId,
                  updates: {
                    attachmentId,
                    isUploading: false,
                    mimeType,
                    filename,
                    size,
                  },
                })
              } else {
                const [tempId] = filesWithTempIds[index]

                // Delete the failed upload attachment node
                editor.update(() => {
                  const attachmentNodes = $dfs()
                    .filter((dfsNode) => $isAttachmentNode(dfsNode.node))
                    .map(({ node }) => node as AttachmentNode)

                  const node =
                    attachmentNodes.find(
                      (node) => node.__attachmentId === tempId
                    ) ?? null

                  if ($isAttachmentNode(node)) {
                    node.remove()
                  }
                })
              }
            })
          })()

          return true
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<InsertAttachmentCommandPayload>(
        INSERT_ATTACHMENT_NODES,
        (payload) => {
          const selection = $getSelection()
          if (!selection || !$isRangeSelection(selection)) {
            return false
          }

          const nodes = payload.attachments.map(([attachmentId, file]) => {
            return $createAttachmentNode(
              attachmentId,
              file.name,
              file.type,
              file.size,
              payload.isUploading,
              payload.isPreview
            )
          })

          if (nodes.length === 0) {
            return false
          }

          let anchorNode = selection.anchor.getNode()

          // If the selected node is the root node,
          // create a paragraph node, append it to the root
          // and set the anchor node to the paragraph node instead
          if ($isRootNode(anchorNode)) {
            anchorNode = $createParagraphNode()
            $getRoot().append(anchorNode)
          }

          // If the anchor node is a block, replace it with the attachment node
          if (!$isTextNode(anchorNode) && !anchorNode.isInline()) {
            if (anchorNode.isEmpty()) {
              anchorNode.replace(nodes[0])
            } else {
              anchorNode.insertAfter(nodes[0])
            }

            nodes.slice(1).forEach((node) => {
              nodes[0].insertAfter(node)
            })
          } else {
            const blockNode = $getNearestBlockElementAncestorOrThrow(
              selection.anchor.getNode()
            )

            // Insert before or after the anchor node based on the selection anchor offset
            if (selection.anchor.offset === 0) {
              nodes.forEach((node) => {
                blockNode.insertBefore(node)
              })
            } else {
              nodes.forEach((node) => {
                blockNode.insertAfter(node)
              })
            }
          }

          return true
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand<UpdateAttachmentCommandPayload>(
        UPDATE_ATTACHMENT_NODE,
        (payload) => {
          const attachmentNodes = $nodesOfType(AttachmentNode)

          attachmentNodes.forEach((node) => {
            if (
              $isAttachmentNode(node) === false ||
              payload.updates.attachmentId == null ||
              payload.updates.isUploading == null
            ) {
              return false
            }

            if (node.__attachmentId === payload.attachmentId) {
              node.replace(
                $createAttachmentNode(
                  payload.updates.attachmentId,
                  node.getFilename(),
                  node.getMimeType(),
                  node.getSize(),
                  payload.updates.isUploading,
                  node.getIsPreview()
                )
              )

              return true
            }
          })

          return false
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand<SetAttachmentPreviewCommandPayload>(
        SET_ATTACHMENT_PREVIEW_NODE,
        ({ nodeKey, isPreview }) => {
          let result = false

          const node = $getNodeByKey(nodeKey)

          if ($isAttachmentNode(node)) {
            node.setIsPreview(isPreview)
            result = true
          }

          return result
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand<DeleteAttachmentCommandPayload>(
        DELETE_ATTACHMENT_NODE,
        (nodeKey) => {
          let result = false

          const attachmentNode = $getNodeByKey(nodeKey)

          if ($isAttachmentNode(attachmentNode)) {
            attachmentNode.remove()
            result = true
          }

          return result
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        DROP_COMMAND,
        (event) => {
          const [, files] = eventFiles(event)
          if (files.length > 0) {
            const x = event.clientX
            const y = event.clientY
            const eventRange = getCaretFromPoint(x, y)
            if (eventRange !== null) {
              const { offset: domOffset, node: domNode } = eventRange
              const node = $getNearestNodeFromDOMNode(domNode)
              if (node !== null) {
                const selection = $createRangeSelection()

                if ($isTextNode(node)) {
                  selection.anchor.set(node.getKey(), domOffset, 'text')
                  selection.focus.set(node.getKey(), domOffset, 'text')
                } else {
                  const parentKey = node.getParentOrThrow().getKey()
                  const offset = node.getIndexWithinParent()
                  selection.anchor.set(parentKey, offset, 'element')
                  selection.focus.set(parentKey, offset, 'element')
                }
                const normalizedSelection =
                  $normalizeSelection__EXPERIMENTAL(selection)
                $setSelection(normalizedSelection)
              }
              editor.dispatchCommand(DRAG_DROP_PASTE, files)
            }
            event.preventDefault()
            return true
          }

          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            return true
          }

          return false
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        TRIGGER_ATTACHMENT_UPLOAD,
        () => {
          fileInputRef.current?.click()
          return true
        },
        COMMAND_PRIORITY_HIGH
      ),
      // This fixes the issue when an attachment is the last node in a list / collapsible heading content
      // and the user presses the right arrow, the block cursor shows up and hitting enter freezes the
      // browser.
      editor.registerCommand(
        INSERT_PARAGRAPH_COMMAND,
        () => {
          const selection = $getSelection()

          if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
            return false
          }

          let node = selection.anchor.getNode()

          if (!$isElementNode(node)) {
            return false
          }

          if ($isRootNode(node)) {
            node = $createParagraphNode()
            $getRoot().append(node)
          }

          const lastNode = node.getLastChild()

          // ToDo: Once we unify the AttachmentNode into a single one in `@motion/notes-shared`,
          // we should use $isAttachmentNode instead of `getType() === 'attachment-id'`.
          if (lastNode?.getType() !== 'attachment-id') {
            return false
          }

          const newParagraph = $createParagraphNode()
          node.insertAfter(newParagraph)
          newParagraph.selectStart()
          return true
        },
        COMMAND_PRIORITY_CRITICAL
      )
    )
  }, [editor, uploadFile, fileIsPreviewable, supportedMimeTypes])

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = Array.from(event.target.files ?? [])

    editor.dispatchCommand(DRAG_DROP_PASTE, files)
  }

  return (
    <input
      ref={fileInputRef}
      type='file'
      onChange={handleFileInputChange}
      accept={supportedMimeTypes.join(',')}
      multiple
      hidden
    />
  )
}
