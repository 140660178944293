import {
  $createCollapsibleHeadingContentNode,
  $isCollapsibleHeadingContentNode,
  $isCollapsibleHeadingNode,
} from '@motion/notes-shared'

import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import { $getNearestBlockElementAncestorOrThrow } from '@lexical/utils'
import {
  $getSelection,
  $isDecoratorNode,
  $isElementNode,
  $isRangeSelection,
  $isRootNode,
} from 'lexical'

export function $handleIndentCommand() {
  const selection = $getSelection()

  if (!$isRangeSelection(selection)) {
    return false
  }

  const nodes = selection.getNodes()

  const topNodes = nodes
    .filter((node) => {
      const parentNode = node.getParent()

      const parentNodeInSelection = nodes.some(
        (node) => node.getKey() === parentNode?.getKey()
      )

      return !parentNodeInSelection
    })
    // Retrieve the nearest block element ancestor for each node
    .map((node) => {
      if (
        $isElementNode(node) ||
        $isDecoratorBlockNode(node) ||
        ($isDecoratorNode(node) && !node.isInline())
      ) {
        return node
      }

      if (node == null) return null

      if ($isRootNode(node)) return null

      return $getNearestBlockElementAncestorOrThrow(node)
    })
    .filter((node) => node != null)

  const topNode = topNodes[0]

  if (topNode == null) {
    return false
  }

  let prevNode = topNode.getPreviousSibling()

  if ($isCollapsibleHeadingNode(prevNode)) {
    const collapsibleContentNode = $createCollapsibleHeadingContentNode()
    prevNode.insertAfter(collapsibleContentNode)
    prevNode = collapsibleContentNode
  }

  if ($isCollapsibleHeadingContentNode(prevNode)) {
    prevNode.append(...topNodes)

    return true
  }

  return false
}
