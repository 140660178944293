import { isString } from '@motion/utils/guards'

import { type ReactNode, useContext, useEffect } from 'react'

import { HtmlContext } from './context'

export function useTitle(routeTitle: ReactNode, viewName?: string | null) {
  const { pushTitle } = useContext(HtmlContext)

  useEffect(() => {
    let cleanTitle = Array.isArray(routeTitle)
      ? routeTitle.filter(isString).join('').trim()
      : isString(routeTitle)
        ? routeTitle
        : null

    if (cleanTitle) {
      pushTitle(viewName ? `${cleanTitle} - ${viewName}` : cleanTitle)
    }
  }, [pushTitle, routeTitle, viewName])
}

export function useModalTitle(title: string | undefined) {
  const { pushModalTitle } = useContext(HtmlContext)

  useEffect(() => {
    if (!title) return

    return pushModalTitle(title)
  }, [pushModalTitle, title])
}
