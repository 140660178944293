import {
  type CalendarEventSchemaV2,
  type EventConferenceType,
  type MeetingBotStatusSchema,
  type MeetingInsightsSchema,
  type RecurringMeetingInsightsSchema,
} from '@motion/rpc-types'

export function isNotetakerSupportedConferenceLink(
  conferenceType: EventConferenceType | null | undefined
) {
  return (
    conferenceType === 'hangoutsMeet' ||
    conferenceType === 'meet' ||
    conferenceType === 'zoom' ||
    conferenceType === 'teamsForBusiness'
  )
}

/**
 * Whether the meeting insight's bot is in a state that can be changed.
 * @param meetingInsights The meeting insights to check.
 */
export function isBotMutable(
  meetingInsights: MeetingInsightsSchema | null | undefined
) {
  const unsupportedStatuses: MeetingBotStatusSchema[] = [
    'COMPLETED',
    'DONT_SCHEDULE',
    'FAILED_TO_PROCESS',
  ]

  return (
    meetingInsights != null &&
    !unsupportedStatuses.includes(meetingInsights.meetingBotStatus)
  )
}

/**
 * Whether the bot is ready to be used
 */
export function isBotReady(
  meetingInsights: MeetingInsightsSchema | null | undefined
) {
  return meetingInsights?.meetingBotStatus === 'SCHEDULED'
}

/**
 * Whether the bot is in a state that can be sent
 * */
export function isBotSendable(
  meetingInsights: MeetingInsightsSchema | null | undefined
) {
  return (
    meetingInsights?.conferenceLink != null &&
    meetingInsights?.meetingBotStatus != null &&
    ['NEEDS_SCHEDULING', 'SCHEDULED', 'FAILED_TO_SCHEDULE'].includes(
      meetingInsights?.meetingBotStatus
    )
  )
}

/**
 * Whether the bot is in a state that can be kicked
 */
export function isBotKickable(
  meetingInsights: MeetingInsightsSchema | null | undefined
) {
  return meetingInsights?.meetingBotStatus === 'IN_CALL'
}

/**
 * Whether the meeting insight is completed
 */
export function isNotetakerCompleted(
  meetingInsights: MeetingInsightsSchema | null | undefined
) {
  return meetingInsights?.meetingBotStatus === 'COMPLETED'
}

export function isNotetakerSectionHidden(
  meetingInsights: MeetingInsightsSchema | null | undefined,
  initialEvent: CalendarEventSchemaV2 | null | undefined,
  recurringMeetingInsights: RecurringMeetingInsightsSchema | null | undefined
) {
  // The event doesn't have a conference link, so we should not show the notetaker section.
  if (initialEvent != null && initialEvent.conferenceLink == null) {
    return true
  }

  const isRecurringDataInvalid =
    initialEvent?.type === 'RECURRING_EVENT' && recurringMeetingInsights == null

  const isNormalDataInvalid =
    initialEvent?.type === 'NORMAL' && meetingInsights == null

  return (
    isNotetakerCompleted(meetingInsights) ||
    isRecurringDataInvalid ||
    isNormalDataInvalid
  )
}
