import { type Editor } from '@tiptap/react'
import { forwardRef, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { StyledField, type StyledFieldProps } from './styled-field'

export type RichTextFieldProps = Omit<StyledFieldProps, 'sentiment'> & {
  prefix?: (({ active }: { active: boolean }) => ReactNode) | ReactNode
  suffix?: (({ active }: { active: boolean }) => ReactNode) | ReactNode
  editor: Editor | null | undefined
}

/**
 * A rich text field that can be used to edit rich text content.
 * Note that this component does not include the editor itself.
 *
 * Example Usage: <RichTextField editor={editor}><RichTextContent editor={editor} /></RichTextField>
 */
export const RichTextField = forwardRef<HTMLSpanElement, RichTextFieldProps>(
  function RichTextField({ children, prefix, suffix, editor, ...rest }, ref) {
    const onFocus = () => {
      editor?.chain().focus('all', {
        scrollIntoView: false,
      })
    }

    return (
      <StyledField
        {...rest}
        className={twMerge(
          'transition-colors p-1 flex-row gap-1',
          editor &&
            (!editor.isEmpty || editor.isFocused) &&
            'flex-col items-stretch'
        )}
        variant='default'
        sentiment={editor?.isFocused ? 'active' : 'default'}
        ref={ref}
        onFocus={onFocus}
        tabIndex={-1}
      >
        {prefix && (
          <div className='flex justify-end'>
            {typeof prefix === 'function'
              ? prefix({ active: editor?.isFocused ?? false })
              : prefix}
          </div>
        )}

        <div className='min-w-0 grow pt-0.5'>{children}</div>

        {suffix && (
          <div className='flex justify-end'>
            {typeof suffix === 'function'
              ? suffix({ active: editor?.isFocused ?? false })
              : suffix}
          </div>
        )}
      </StyledField>
    )
  }
)
