import { isMobileEditor } from '@motion/notes-shared'

const CARET_ICON_SVG = `
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="ToggleChevronIcon">
    <path id="Icon" d="M11 7.99999L5 12L5 4L11 7.99999Z" fill="currentColor" style="fill:currentColor;fill-opacity:1;"/>
  </g>
</svg>
`

export function createCollapsibleToggleElement({
  onToggle,
}: {
  onToggle: () => void
}): HTMLButtonElement {
  const toggle = document.createElement('button')

  toggle.innerHTML = CARET_ICON_SVG

  if (!isMobileEditor()) {
    toggle.setAttribute('contenteditable', 'false')
  }

  toggle.setAttribute('data-content-editable-void', 'true')
  toggle.setAttribute('data-text-edit-side', 'start')

  toggle.classList.add('collapsible-toggle')

  toggle.onclick = onToggle

  return toggle
}
