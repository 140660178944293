import {
  RTECheckListSolid,
  RTECodeblockOutline,
  RTEH1Solid,
  RTEH2Solid,
  RTEH3Solid,
  RTEOrderedListSolid,
  RTEQuoteSolid,
  RTETextSolid,
  RTEUnorderedListSolid,
} from '@motion/icons'
import {
  $isAttachmentNode,
  $isCollapsibleListItemNode,
  $isCollapsibleListNode,
  $isEmbedNode,
} from '@motion/notes-shared'

import { $isCodeNode } from '@lexical/code'
import { $isHeadingNode, $isQuoteNode } from '@lexical/rich-text'
import { $isTableNode } from '@lexical/table'
import { $isParagraphNode, type LexicalNode } from 'lexical'

import { type TurnIntoOption } from './types'

function genericValidations(node: LexicalNode) {
  if ($isTableNode(node)) {
    return false
  }

  if ($isAttachmentNode(node)) {
    return false
  }

  if ($isEmbedNode(node)) {
    return false
  }

  return true
}

const buildGetIsList =
  (listType: 'bullet' | 'number' | 'check') => (node: LexicalNode) => {
    if ($isCollapsibleListNode(node) && node.getListType() === listType) {
      return true
    }

    if ($isCollapsibleListItemNode(node)) {
      const parent = node.getParent()
      return $isCollapsibleListNode(parent) && parent.getListType() === listType
    }

    return false
  }

export const TURN_INTO_OPTIONS: Array<TurnIntoOption> = [
  {
    key: 'h1',
    label: 'Heading 1',
    icon: RTEH1Solid,
    validators: [genericValidations],
    getIsSelected: (node) => $isHeadingNode(node) && node.getTag() === 'h1',
  },
  {
    key: 'h2',
    label: 'Heading 2',
    icon: RTEH2Solid,
    validators: [genericValidations],
    getIsSelected: (node) => $isHeadingNode(node) && node.getTag() === 'h2',
  },
  {
    key: 'h3',
    label: 'Heading 3',
    icon: RTEH3Solid,
    validators: [genericValidations],
    getIsSelected: (node) => $isHeadingNode(node) && node.getTag() === 'h3',
  },
  {
    key: 'paragraph',
    label: 'Paragraph',
    icon: RTETextSolid,
    validators: [genericValidations],
    getIsSelected: (node) => $isParagraphNode(node),
  },
  {
    key: 'bulleted-list',
    label: 'Bulleted list',
    icon: RTEUnorderedListSolid,
    validators: [genericValidations],
    getIsSelected: buildGetIsList('bullet'),
  },
  {
    key: 'numbered-list',
    label: 'Numbered list',
    icon: RTEOrderedListSolid,
    validators: [genericValidations],
    getIsSelected: buildGetIsList('number'),
  },
  {
    key: 'check-list',
    label: 'Check list',
    icon: RTECheckListSolid,
    validators: [genericValidations],
    getIsSelected: buildGetIsList('check'),
  },
  {
    key: 'code-block',
    label: 'Code block',
    icon: RTECodeblockOutline,
    validators: [genericValidations],
    getIsSelected: (node) => $isCodeNode(node),
  },
  {
    key: 'block-quote',
    label: 'Block quote',
    icon: RTEQuoteSolid,
    validators: [genericValidations],
    getIsSelected: (node) => $isQuoteNode(node),
  },
]
