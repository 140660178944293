import {
  CollapsibleListItemNode,
  CollapsibleListNode,
} from '@motion/notes-shared'

import { ListItemNode, ListNode } from '@lexical/list'

export const ListNodeReplacement = {
  replace: ListNode,
  with: (node: CollapsibleListNode) => {
    return new CollapsibleListNode(node.__listType, node.__start)
  },
  withKlass: CollapsibleListNode,
}

export const ListItemNodeReplacement = {
  replace: ListItemNode,
  with: (node: ListItemNode) => {
    return new CollapsibleListItemNode()
  },
  withKlass: CollapsibleListItemNode,
}
