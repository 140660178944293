import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getNodeByKey, type NodeKey } from 'lexical'
import { useEffect, useState } from 'react'

import { TURN_INTO_OPTIONS } from './constants'
import { type TurnIntoOption } from './types'

export function useTurnIntoOptions(
  nodeKey: NodeKey | null | undefined
): [TurnIntoOption | undefined, TurnIntoOption[]] {
  const [editor] = useLexicalComposerContext()

  const [selected, setSelected] = useState<TurnIntoOption>()
  const [options, setOptions] = useState<Array<TurnIntoOption>>([])

  useEffect(() => {
    if (nodeKey == null) {
      setOptions([])
    } else {
      const selectedOption = editor.getEditorState().read(() => {
        const node = $getNodeByKey(nodeKey)

        if (node == null) {
          return
        }

        return TURN_INTO_OPTIONS.find(({ getIsSelected }) =>
          getIsSelected(node)
        )
      })

      const filteredOptions = editor.getEditorState().read(() => {
        const node = $getNodeByKey(nodeKey)

        if (node == null) {
          return []
        }

        return TURN_INTO_OPTIONS.filter((option) =>
          option.validators.every((validator) => validator(node))
        )
      })

      setSelected(selectedOption)
      setOptions(filteredOptions)
    }
  }, [editor, nodeKey])

  return [selected, options]
}
