import { ChevronDownOutline } from '@motion/icons'
import { ActionDropdown, Button } from '@motion/ui/base'

import {
  $isCodeNode,
  CODE_LANGUAGE_FRIENDLY_NAME_MAP,
  type CodeNode,
  getLanguageFriendlyName,
} from '@lexical/code'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getNodeByKey } from 'lexical'
import { useEffect, useMemo, useState } from 'react'

export type CodeLanguageDropdownProps = {
  codeNodeKey: CodeNode['__key']
  onDropdownOpen?: () => void
  onDropdownClose?: () => void
}

export function CodeLanguageDropdown({
  codeNodeKey,
  onDropdownOpen,
  onDropdownClose,
}: CodeLanguageDropdownProps) {
  const [editor] = useLexicalComposerContext()

  const [lang, setLang] = useState<string | null | undefined>()

  useEffect(() => {
    // eslint-disable-next-line no-restricted-syntax
    editor.read(() => {
      const codeNode = $getNodeByKey(codeNodeKey)

      if (!codeNode || !$isCodeNode(codeNode)) {
        return
      }

      setLang(codeNode.getLanguage())
    })
  }, [codeNodeKey, editor])

  const codeFriendlyName = lang ? getLanguageFriendlyName(lang) : 'Language'

  const items = useMemo(() => {
    return Object.entries(CODE_LANGUAGE_FRIENDLY_NAME_MAP).map(
      ([lang, friendlyName]) => ({
        content: friendlyName,
        onAction: () => {
          editor.update(() => {
            const codeNode = $getNodeByKey(codeNodeKey)

            if (!codeNode || !$isCodeNode(codeNode)) {
              return
            }

            codeNode.setLanguage(lang)

            setLang(lang)
          })
        },
      })
    )
  }, [codeNodeKey, editor])

  return (
    <ActionDropdown
      items={items}
      placement='bottom-end'
      onClose={onDropdownClose}
    >
      <Button
        sentiment='neutral'
        variant='muted'
        size='small'
        onClick={onDropdownOpen}
      >
        {codeFriendlyName}
        <ChevronDownOutline />
      </Button>
    </ActionDropdown>
  )
}
