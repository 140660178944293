import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { COMMAND_PRIORITY_NORMAL } from 'lexical'
import { useEffect, useRef } from 'react'

import { BROADCAST_UPDATE_COMMAND } from './commands'

const getBroadcastChannelName = (noteId: string) => `motion-note-${noteId}`

export type BroadcastPluginProps = {
  noteId: string
}

export function BroadcastPlugin({
  noteId,
}: BroadcastPluginProps): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  const channel = useRef<BroadcastChannel | null>(null)

  useEffect(() => {
    channel.current = new BroadcastChannel(getBroadcastChannelName(noteId))

    channel.current.onmessage = (event) => {
      editor.update(() =>
        editor.dispatchCommand(event.data.command, event.data.payload)
      )
    }

    return () => channel.current?.close()
  }, [editor, noteId])

  useEffect(() => {
    return editor.registerCommand(
      BROADCAST_UPDATE_COMMAND,
      (payload) => {
        if (channel.current == null) {
          throw new Error('Broadcast channel not initialized')
        }

        channel.current.postMessage(payload)

        return true
      },
      COMMAND_PRIORITY_NORMAL
    )
  }, [editor])

  return null
}
