import { ChevronDownOutline } from '@motion/icons'
import { ActionDropdown, Button } from '@motion/ui/base'

import { type NodeKey } from 'lexical'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { Divider } from './styled'

import { useTurnIntoActions, useTurnIntoOptions } from '../../turn-into-plugin'

export type TextSelectionTurnIntoDropdownProps = {
  blockNodeKey: NodeKey | null
}

export function TextSelectionTurnIntoDropdown({
  blockNodeKey,
}: TextSelectionTurnIntoDropdownProps) {
  const [open, setOpen] = useState(false)

  const [selectedOption, turnIntoOptions] = useTurnIntoOptions(blockNodeKey)

  const turnIntoActions = useTurnIntoActions({
    blockNodeKey,
    turnIntoOptions,
  })

  if (selectedOption == null) {
    return null
  }

  const SelectedIcon = selectedOption.icon
  const selectedLabel = selectedOption.label

  return turnIntoOptions.length > 0 ? (
    <>
      <ActionDropdown
        placement='bottom-start'
        offset={{
          crossAxis: -8,
          mainAxis: 12,
        }}
        items={turnIntoActions}
        onClose={() => setOpen(false)}
        enableOutsideInteractions
      >
        <Button
          sentiment='neutral'
          variant='muted'
          size='small'
          onClick={() => setOpen((prev) => !prev)}
        >
          <SelectedIcon className='!text-semantic-neutral-text-subtle' />
          <span className='font-normal'>{selectedLabel}</span>
          <ChevronDownOutline
            className={twMerge(
              '!size-2 transition duration-300 !text-semantic-neutral-text-subtle',
              open && '-rotate-180'
            )}
          />
        </Button>
      </ActionDropdown>
      <Divider />
    </>
  ) : null
}
