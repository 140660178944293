import { classed } from '@motion/theme'

import { type PropsWithChildren } from 'react'

export const FieldSlot = classed('div', {
  base: `
    order-2 flex items-center justify-between h-full gap-2

    [&_[data-icon]]:text-field-icon-default
    [&_[data-icon]]:shrink-0
  `,
  variants: {
    size: {
      xsmall: `[&_[data-icon]]:size-3 [&_[data-icon]]:mt-0.5`,
      small: `[&_[data-icon]]:size-3 [&_[data-icon]]:mt-0.5`,
      normal: `[&_[data-icon]]:size-[18px] [&_[data-icon]]:mt-px`,
      large: `[&_[data-icon]]:size-[18px] [&_[data-icon]]:mt-px`,
    },
  },
})

export const HeaderSlot = ({ children }: PropsWithChildren) => {
  return (
    <div
      className='order-1'
      onClick={(e) => {
        // Prevents the wrapping label component from focusing the input on click
        e.preventDefault()
      }}
    >
      {children}
    </div>
  )
}

export const FooterSlot = ({ children }: PropsWithChildren) => {
  return (
    <div
      className='order-3'
      onClick={(e) => {
        // Prevents the wrapping label component from focusing the input on click
        e.preventDefault()
      }}
    >
      {children}
    </div>
  )
}
