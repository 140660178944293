import { entries, keys } from '@motion/utils/object'
import { type AllModelsSchema } from '@motion/zod/client'

import {
  type QueryClient,
  type QueryKey,
  useQueryClient,
} from '@tanstack/react-query'
// NOTE: Do not import from v1 cache's barrel file, will cause circular dependency
// instead, import from the specific directory or file needed inside @motion/rpc-cache
import { useCallback } from 'react'

import {
  type EntryValue,
  type ModelCache,
  type ModelCacheCollection,
  type ModelCacheRoot,
  type ModelId,
  type TypedCacheEntry,
} from './types'

import { log } from '../utils'
import { setCacheInfinite } from '../utils/set-cache-infinite'

export const MODEL_CACHE_KEY = ['model-cache'] as const
export const HASHED_QUERY_KEY = '["model-cache"]'

export function isModelCacheKey(key: QueryKey): key is ['model-cache'] {
  return key.length === 1 && key[0] === MODEL_CACHE_KEY[0]
}

export const useTaskFromCacheFn = () => {
  const client = useQueryClient()
  return (id: string) => getLocalTaskById(client, id)
}

export function getLocalTaskById(client: QueryClient, id: string) {
  return getCacheEntry(client, 'tasks', id)?.value
}

export function useStoreModelsFn() {
  const client = useQueryClient()
  return useCallback(
    (models: Partial<AllModelsSchema>) => {
      appendModelsToCache(client, models)
    },
    [client]
  )
}

export function useReplaceModelInCachesFn() {
  const client = useQueryClient()
  return useCallback(
    (models: Partial<AllModelsSchema>) => {
      replaceModelsInCache(client, models)
    },
    [client]
  )
}

export function getCacheEntry<TModel extends keyof ModelCache>(
  client: QueryClient,
  model: TModel,
  id: ModelId
): TypedCacheEntry<TModel> | undefined {
  const cache = getModelCache(client)
  if (!cache[model]) return

  return cache[model][id] as TypedCacheEntry<TModel> | undefined
}

export function getCacheEntryValue<TModel extends keyof ModelCache>(
  client: QueryClient,
  model: TModel,
  id: ModelId
): EntryValue<TModel> | undefined {
  return getCacheEntry(client, model, id)?.value
}

export function setCacheEntry<T extends { id: string }>(
  client: QueryClient,
  model: keyof AllModelsSchema,
  value: T
) {
  const cache = getModelCache(client)

  cache[model] ??= {}
  cache[model][value.id] = { updatedAt: Date.now(), value }

  updateModelCache(client, cache)
}

export function updateModelCache(client: QueryClient, cache: ModelCache) {
  client.setQueryData(MODEL_CACHE_KEY, { models: cache })
  setCacheInfinite(client, MODEL_CACHE_KEY)
}

export function removeModelFromCache(
  client: QueryClient,
  model: keyof ModelCache,
  id: ModelId
) {
  const cache = getModelCache(client)
  if (!cache[model]) return

  delete cache[model][id]
  updateModelCache(client, cache)
}

export const buildEmptyCache = (): ModelCache => ({
  tasks: {},
  chunks: {},
  recurringTasks: {},
  labels: {},
  statuses: {},
  projects: {},
  users: {},
  userSettings: {},
  workspaces: {},
  views: {},
  customFields: {},
  customFieldCategories: {},
  teams: {},
  teamSettings: {},
  scheduledEntities: {},
  schedules: {},
  calendarEvents: {},
  feedEntries: {},
  projectDefinitions: {},
  stageDefinitions: {},
  legacyProjectTemplates: {},
  calendars: {},
  folders: {},
  notes: {},
  noteSnapshots: {},
  systemFolders: {},
  uploadedFiles: {},
  pageViewSettings: {},
  threads: {},
  userFeaturePermissions: {},
  userFeaturePermissionUsages: {},
  workspaceFeaturePermissionUsages: {},
  meetingInsights: {},
  meetingActionItems: {},
  recurringMeetingInsights: {},
  agentWorkflows: {},
  agentWorkflowsListing: {},
})

export function appendModelsToCache(
  client: QueryClient,
  models: Partial<AllModelsSchema>
) {
  const cache = getModelCache(client)

  const updatedAt = Date.now()

  log.time('storing models', () => {
    keys(models).forEach((key) => {
      const collection = models[key]
      if (!collection) return
      entries(collection).forEach(([id, model]) => {
        cache[key] ??= {}
        cache[key][id] = { updatedAt, value: model }
      })
    })
    updateModelCache(client, cache)
  })
}

export function replaceModelsInCache(
  client: QueryClient,
  models: Partial<AllModelsSchema>
) {
  const cache = getModelCache(client)

  const updatedAt = Date.now()

  log.time('replacing models', () => {
    keys(models).forEach((key) => {
      const collection = models[key]
      if (!collection) return

      cache[key] = entries(collection).reduce((acc, [id, model]) => {
        acc[id] = { updatedAt, value: model }
        return acc
      }, {} as ModelCacheCollection)
    })
    updateModelCache(client, cache)
  })
}

export function getModelCacheRoot(client: QueryClient): ModelCacheRoot {
  const cacheQuery = client.getQueryCache().get(HASHED_QUERY_KEY)
  const cacheRoot = cacheQuery?.state.data as ModelCacheRoot | undefined
  if (cacheRoot == null) {
    return { models: buildEmptyCache() }
  }

  return cacheRoot
}

export function getModelCache(client: QueryClient): ModelCache {
  const cacheRoot = getModelCacheRoot(client)

  return cacheRoot.models
}

export function initializeModelCache(client: QueryClient) {
  const cacheQuery = client.getQueryCache().get(HASHED_QUERY_KEY)
  const cache = cacheQuery?.state.data as ModelCacheRoot
  if (cache == null) {
    updateModelCache(client, buildEmptyCache())
  }
}
