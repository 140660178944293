import { CrownSolid, CrownSparkleSolid, type SvgIcon } from '@motion/icons'
import {
  businessAITier,
  enterpriseTier,
  proAITier,
  proPlusTier,
  proTier,
  type Tier,
} from '@motion/ui-logic/tiered-pricing'

export const getTierIcon = (tier: Tier): SvgIcon | null => {
  switch (tier) {
    case proTier:
    case enterpriseTier:
      return CrownSolid
    case proPlusTier:
    case proAITier:
    case businessAITier:
      return CrownSparkleSolid
    default:
      return null
  }
}

/*
 * On billing cards, we hide the icon for enterprise tier
 */
export const getTierIconForBilling = (
  tier: Tier,
  aiEnabled: boolean
): SvgIcon | null => {
  if (tier === enterpriseTier) return null

  // When we launch AI tiers, these will be the only ones we show and the
  // cards will not have icons
  if ((aiEnabled && tier === proAITier) || tier === businessAITier) return null

  return getTierIcon(tier)
}
