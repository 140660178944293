import { classed } from '@motion/theme'

import { Tag } from './tag'

export const UserTag = classed(Tag, {
  base: `
    [&[data-size="normal"]]:pl-0.5
    [&[data-size="small"]]:pl-0
  `,
})
