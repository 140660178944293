import { Term } from '@motion/ui-logic/billing'

import { type Tier, type TierPrices } from './types'

export const basicTier: Tier = 'BASIC'
export const proTier: Tier = 'PRO'
export const proPlusTier: Tier = 'PROPLUS'
export const proAITier: Tier = 'PROAI'
export const enterpriseTier: Tier = 'ENTERPRISE'
export const businessTier: Tier = 'BUSINESS'
export const businessPlusTier: Tier = 'BUSINESSPLUS'
export const businessAITier: Tier = 'BUSINESSAI'

export const BASE_RECOMMENDED_TIER = proTier

export const BASE_ORDERED_TIERS: Tier[] = [basicTier, proTier, enterpriseTier]

export const getTierPrice = (
  tier: Tier,
  bucket: number,
  term: Term
): number | undefined => {
  const priceConfig = getTierPrices(tier)

  if (!priceConfig) {
    return undefined
  }

  return bucket > 1
    ? term === Term.Annual || term === Term.LowCostTrial
      ? priceConfig.multiSeat.annual
      : priceConfig.multiSeat.monthly
    : term === Term.Annual || term === Term.LowCostTrial
      ? priceConfig.singleSeat.annual
      : priceConfig.singleSeat.monthly
}

/**
 * @deprecated only used for calculating savings %, use useGetTierPrices hook
 * for real price data
 */
export const getTierPrices = (tier: Tier): TierPrices | null => {
  switch (tier) {
    case basicTier:
      return {
        singleSeat: {
          monthly: 34,
          annual: 19,
        },
        multiSeat: {
          monthly: 20,
          annual: 12,
        },
      }
    case proTier:
    case proPlusTier:
      return {
        singleSeat: {
          monthly: 69,
          annual: 41,
        },
        multiSeat: {
          monthly: 42,
          annual: 25,
        },
      }
    case proAITier:
      return {
        singleSeat: {
          monthly: 135,
          annual: 81,
        },
        multiSeat: {
          monthly: 82,
          annual: 49,
        },
      }
    default:
      return null
  }
}

export const getTierAnnualPercentSavings = (
  tier: Tier,
  isTeam: boolean
): number => {
  const dollarPrices = getTierPrices(tier)

  if (!dollarPrices) {
    return 40
  }

  const { monthly, annual } = isTeam
    ? dollarPrices.multiSeat
    : dollarPrices.singleSeat

  return Math.max(Math.round((1 - annual / monthly) * 100), 0)
}

export const getTierTitle = (tier: Tier) => {
  switch (tier) {
    case basicTier:
      return 'Basic'
    case proTier:
      return 'Pro'
    case proPlusTier:
      return 'Pro Plus'
    case proAITier:
      return 'Pro AI'
    case businessTier:
      return 'Business'
    case businessPlusTier:
      return 'Business Plus'
    case businessAITier:
      return 'Business AI'
    case enterpriseTier:
      return 'Enterprise'
    default:
      return undefined
  }
}

export const getTierBulletHeader = (
  tier: Tier,
  orderedTiers: Tier[]
): string => {
  const currentTierIndex = orderedTiers.indexOf(tier)

  if (currentTierIndex <= 0) {
    return ''
  }

  const previousTier = orderedTiers[currentTierIndex - 1]
  const previousTierName = getTierTitle(previousTier)

  return previousTierName ? `Includes ${previousTierName}, plus:` : ''
}

const getTierBullets = (tier: Tier): string[] => {
  switch (tier) {
    case basicTier:
      return [
        'Automatically plan your day with A.I.',
        'Project and task management',
        'Notes and Docs',
        'List and Kanban views',
        'Advanced calendar management',
        'Time tracking',
        'Desktop, iOS, and Android apps',
        'Receive warnings when you might miss deadline',
        '2-week auto-scheduling window',
        '1 task template',
        '1 workflow automation',
        '1 GB attachments storage',
        'Customer Support',
      ]
    case proTier:
      return [
        'Automated team project planning & coordination with A.I.',
        'Predict project delivery date with A.I.',
        'Gantt and Timeline views',
        'Multi-layer pivot tables',
        'Charts and dashboards',
        'Team capacity view',
        'Sharing with external guests',
        'SOP templates and automations',
        'Meeting booking pages (Calendly)',
        'Zapier, API & Slack integration',
        'Activity & comments (90 day history)',
        'Unlimited workflow automations',
        'Unlimited task and project templates',
        'Unlimited custom fields',
        '50 GB attachments storage',
        '3-month auto-scheduling window',
        'Priority support and central billing',
      ]
    case proPlusTier:
      return [
        'Advanced Charts',
        'Advanced Team Schedule',
        'Unlimited history',
        'Manual data backups',
        'SOC2 and Security Reports',
        'Advanced customer support',
        'Early access to beta features',
      ]
    case proAITier:
      return [
        'AI Task Planner',
        'AI Project Manager',
        'AI Calendar and Scheduling',
        'AI Meeting Notetaker',
        'AI Docs and Notes',
        'AI Writer and Editor',
        'AI Workflows Generator',
        'AI Search and Ask',
        'AI Agent Assistants - in beta (waitlist & white-glove onboarding)',
        'Integrations',
        'Unlimited AI Usage',
        'Unlimited Storage',
        'iOS, Android, and Desktop apps',
        'Priority Support',
      ]
    case businessAITier:
      return [
        'Productivity Charts',
        'Team Capacity Planning',
        'Advanced Dashboards',
        'Reporting and Analytics',
        'Timeline and Gantt',
        'Time Tracking',
        'Central Billing',
        'Permissions',
        'Business Support',
      ]
    case enterpriseTier:
      return [
        'Enterprise-scale Integrations and API',
        'Data Backup',
        'Role Based Access Control (RBAC)',
        'Share with External Parties',
        'SOC 2 and Security Reports',
        'Complete IT and Security Questionnaires',
        'Single Sign On',
        'Engineering Support',
        'Ongoing dedicated project management expert for customer success',
      ]
    default:
      return []
  }
}

export const getExperimentalTierBullets = (
  tier: Tier,
  exp: string
): string[] => {
  switch (exp) {
    case 'exp-1':
      if (tier === basicTier) {
        return [
          'A.I. Planning Assistant',
          'A.I. Task and Calendar Assistant',
          'Project and task management',
          'Unlimited List and Kanban views',
          'Advanced calendar management',
          'Time-blocking',
          'Time-tracking',
          'Desktop, iOS, and Android apps',
          'Deadline ETA prediction and alerts',
          '2-week auto-scheduling window',
          'Limited task template',
          'Limited workflow automation',
          'Limited attachments storage',
          'Free customer support',
        ]
      }
    case 'exp-3':
      switch (tier) {
        case basicTier:
          return [
            'AI Calendar and Tasks',
            'Schedule Meetings',
            'Folders and Favorites',
            'Search across everything',
            'Personal Workspaces',
            'Personal Saved Views',
            'Filtering and Groupings',
            'Light and Dark modes',
            'Time-blocking for tasks',
            '5 concurrent projects',
            'Automated Customer Support',
          ]
        case proTier:
          return getTierBullets(basicTier)
        case businessTier:
          return getTierBullets(proTier)
        case businessPlusTier:
          return [
            'Advanced Charts',
            'Advanced Team Schedule',
            'Unlimited history',
            'Manual data backups',
            'SOC2 and Security Reports',
            'Advanced customer support',
            'Early access to beta features',
          ]
      }
    default:
      return getTierBullets(tier)
  }
}

const getTierDescription = (tier: Tier): string => {
  switch (tier) {
    case basicTier:
      return 'For individuals or small teams who need essential auto-scheduling and project management tools'
    case proTier:
      return 'For power users or teams with complex workflows that need advanced project management and scheduling features'
    case proPlusTier:
      return 'For ultra power users or teams who need advanced analytics and security reports for IT purposes. Not necessary for most users.'
    case proAITier:
      return 'For professionals and small teams'
    case businessAITier:
      return 'For power users and businesses with complex needs'
    case enterpriseTier:
      return 'Get exclusive features for your organization'
    default:
      return ''
  }
}

export const getExperimentalTierDescription = (
  tier: Tier,
  exp: string
): string => {
  switch (exp) {
    case 'exp-3':
      switch (tier) {
        case basicTier:
          return 'For individuals who need simple task management and calendaring with A.I.'
        case proTier:
          return 'For individuals or small teams who need project management and auto-scheduling.'
        case businessTier:
          return 'For sophisticated individuals, teams, or businesses with more complex workflows.'
        case businessPlusTier:
          return 'Includes advanced analytics and security reports for IT purposes. Not necessary for most users.'
        case enterpriseTier:
          return 'Tailored for larger teams that require additional security, compliance, and customization'
      }
    default:
      return getTierDescription(tier)
  }
}

export const featureToMotionProText = {
  aiDocs: 'use AI in docs',
  aiNotetaker: 'use AI notetaker',
  aiNotetakerLimit: 'use AI notetaker',
  aiProjectCreation: 'use AI project creation',
  api: 'use API',
  attachmentStorage: 'upgrade attachment storage from 1 GB to 50 GB',
  bookingLinks: 'use booking links',
  customFields: 'create unlimited custom fields',
  dashboards: 'use dashboards',
  gantt: 'use Gantt charts',
  projectDefinitions: 'create unlimited project templates',
  rbac: 'use Role Based Access Control (RBAC)',
  tasksInExternalCalendar: 'show tasks on external calendar',
  teamSchedule: 'use team schedule',
  templateTasks: 'create unlimited task templates',
  workspaces: 'create up to 20 workspaces',
  autoScheduleWindow: 'auto-schedule tasks up to 3 months in advance',
  projectAndTaskActivity: 'view project and task activity',
}

export const featureToMotionEnterpriseText = {
  ...featureToMotionProText,
  attachmentStorage: 'upgrade attachment storage from 50 GB to unlimited',
  workspaces: 'create unlimited workspaces',
}

// TODO: These hardcoded values should be removed once we have a backend endpoint for this
export const getAutoScheduleWindowLimits = (tier: Tier): number => {
  switch (tier) {
    case proTier:
    case proPlusTier:
    case proAITier:
    case businessAITier:
    case enterpriseTier:
      return 92
    case basicTier:
    default:
      return 14
  }
}
