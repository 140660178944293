import {
  $isCollapsibleHeadingContentNode,
  $isCollapsibleHeadingNode,
  LIST_UNCOLLAPSE_COMMAND,
} from '@motion/notes-shared'

import { $findMatchingParent } from '@lexical/utils'
import { $getEditor, $getSelection, $isRangeSelection } from 'lexical'

export function $handleKeyBackspaceCommand() {
  const editor = $getEditor()
  const selection = $getSelection()

  if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
    return false
  }

  const node = selection.anchor.getNode()

  const collapsibleContainerNode = $findMatchingParent(
    node,
    $isCollapsibleHeadingContentNode
  )

  if (collapsibleContainerNode != null) {
    const collapsibleHeadingNode = collapsibleContainerNode.getPreviousSibling()

    if ($isCollapsibleHeadingNode(collapsibleHeadingNode)) {
      // Because the collapsible container node is a shadow root,
      // we need to move the cursor manually to get out of it when
      // backspacing on the first character of the first child
      if (node.is(collapsibleContainerNode.getFirstChild())) {
        if (selection.anchor.offset === 0) {
          collapsibleHeadingNode.selectEnd()
          return true
        }
      }
      // If the heading is collapsed, we want to uncollapse it
      // when backspacing into it
      else if (!collapsibleHeadingNode.getOpen()) {
        editor.dispatchCommand(
          LIST_UNCOLLAPSE_COMMAND,
          collapsibleHeadingNode.getMotionId()
        )
        return true
      }
    }
  }

  return false
}
