import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import { type TextSelectionToolbarProps } from './components/text-selection-toolbar'
import { useFloatingTextFormatToolbar } from './use-floating-text-format-toolbar'

import { useEditorContext } from '../../context'

type TextSelectionToolbarPlugin = {
  onSelectOption?: TextSelectionToolbarProps['onSelectOption']
  onOpenCreateTaskWithAiModal?: TextSelectionToolbarProps['onOpenCreateTaskWithAiModal']
  hasDocsAiOnToolbar?: TextSelectionToolbarProps['hasDocsAiOnToolbar']
  onCreateTaskWithAI?: TextSelectionToolbarProps['onCreateTaskWithAI']
  onOpenSummarizeWithAiModal?: TextSelectionToolbarProps['onOpenSummarizeWithAiModal']
  onOpenRewriteModal?: TextSelectionToolbarProps['onOpenRewriteModal']
}

export function TextSelectionToolbarPlugin({
  onSelectOption,
  onOpenCreateTaskWithAiModal,
  onCreateTaskWithAI,
  hasDocsAiOnToolbar,
  onOpenSummarizeWithAiModal,
  onOpenRewriteModal,
}: TextSelectionToolbarPlugin): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  const { floatingAnchorElem } = useEditorContext()

  return useFloatingTextFormatToolbar({
    editor,
    anchorElem: floatingAnchorElem,
    onSelectOption,
    hasDocsAiOnToolbar,
    onCreateTaskWithAI,
    onOpenCreateTaskWithAiModal,
    onOpenSummarizeWithAiModal,
    onOpenRewriteModal,
  })
}
