import { classed } from '@motion/theme'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection'
import { mergeRegister } from '@lexical/utils'
import type { NodeKey } from 'lexical'
import {
  $createParagraphNode,
  $getSelection,
  $isNodeSelection,
  BLUR_COMMAND,
  CLICK_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_ENTER_COMMAND,
} from 'lexical'
import { type ReactNode, useCallback, useEffect, useRef } from 'react'

type SelectableNodeWrapperProps = {
  nodeKey: NodeKey
  children: ReactNode
  as?: 'div' | 'span'
}

export function SelectableNodeWrapper({
  nodeKey,
  children,
  as = 'div',
}: SelectableNodeWrapperProps) {
  const [editor] = useLexicalComposerContext()

  const [isSelected, setSelected, clearSelection] =
    useLexicalNodeSelection(nodeKey)

  const containerRef = useRef<HTMLDivElement | null>(null)

  const $onDelete = useCallback(
    (event: KeyboardEvent) => {
      const deleteSelection = $getSelection()
      if (isSelected && $isNodeSelection(deleteSelection)) {
        event.preventDefault()
        deleteSelection.getNodes().forEach((node) => {
          if (nodeKey === node.getKey()) {
            node.remove()
          }
        })
      }
      return false
    },
    [isSelected, nodeKey]
  )

  const $onClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement

      // Don't handle selection for form elements
      if (
        target instanceof HTMLInputElement ||
        target instanceof HTMLTextAreaElement ||
        target instanceof HTMLSelectElement ||
        target.getAttribute('contenteditable') === 'true'
      ) {
        return false
      }

      if (containerRef.current?.contains(target)) {
        event.preventDefault()
        if (!event.shiftKey) {
          clearSelection()
        }

        setSelected(true)
        return false
      }

      return false
    },
    [containerRef, clearSelection, setSelected]
  )

  const $onBlur = useCallback(() => {
    if (isSelected) {
      clearSelection()
    }

    return false
  }, [isSelected, clearSelection])

  const $onEnter = useCallback(
    (event: KeyboardEvent) => {
      const selection = $getSelection()
      if ($isNodeSelection(selection) && isSelected) {
        event?.preventDefault()

        selection.getNodes().forEach((node) => {
          // Don't allow new paragrapgh if selected node is inline
          if (!node.isInline()) {
            const insertedNode = node.insertAfter($createParagraphNode())
            insertedNode.selectStart()
          }
        })

        return true
      }

      return false
    },
    [isSelected]
  )

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand<MouseEvent>(
        CLICK_COMMAND,
        $onClick,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        $onDelete,
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        KEY_DELETE_COMMAND,
        $onDelete,
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(BLUR_COMMAND, $onBlur, COMMAND_PRIORITY_LOW),
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        $onEnter,
        COMMAND_PRIORITY_CRITICAL
      )
    )
  }, [editor, $onClick, $onDelete, $onBlur, $onEnter])

  return (
    <Wrapper as={as} ref={containerRef} selected={isSelected}>
      {children}
    </Wrapper>
  )
}

const Wrapper = classed('span', {
  base: '',
  variants: {
    selected: {
      true: '[&>*:first-child]:outline [&>*:first-child]:outline-2 [&>*:first-child]:outline-blue-200 [&>*:first-child]:outline-offset-[-1px]',
    },
  },
})
