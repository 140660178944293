/**
 * Auto Generated
 */
import { type ClientZod } from '../../../utils/client-transform'
import type * as Server from '../../../server/models/agents/workflow-steps'

// Generated Types

export type AgentWorkflowStepType = ClientZod<
  typeof Server.AgentWorkflowStepType
>
export const AgentWorkflowStepType = [
  'LLM_GENERATION',
  'WEB_RESEARCH',
  'CREATE_DOCUMENT',
] as const
export type AgentWorkflowLLMStepSchema = ClientZod<
  typeof Server.AgentWorkflowLLMStepSchema
>
export type AgentWorkflowResearchStepSchema = ClientZod<
  typeof Server.AgentWorkflowResearchStepSchema
>
export type AgentWorkflowCreateDocStepSchema = ClientZod<
  typeof Server.AgentWorkflowCreateDocStepSchema
>
export type AgentWorkflowStepSchema = ClientZod<
  typeof Server.AgentWorkflowStepSchema
>
