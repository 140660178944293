import { $generateNodesFromDOM } from '@lexical/html'
import { $getRoot, $insertNodes, type LexicalEditor } from 'lexical'

export const populateFromHtml = (editor: LexicalEditor, html: string) => {
  const parser = new DOMParser()
  const dom = parser.parseFromString(html, 'text/html')

  const nodes = $generateNodesFromDOM(editor, dom)

  const root = $getRoot()
  root.clear()
  root.select()

  $insertNodes(nodes)
}
