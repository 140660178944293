import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin'
import { type LexicalEditor } from 'lexical'
import { type ReactNode } from 'react'

import {
  ChangeFocusPlugin,
  MaxLengthPlugin,
  RemoveNewLinesPlugin,
} from './plugins'

import { EditorSizeContainer } from '../editor/components/styled'
import { ReadOnlyPlugin } from '../plugins'

function Placeholder() {
  return (
    <div className='absolute left-0 bottom-1 pointer-events-none opacity-20'>
      New doc
    </div>
  )
}

export type TitleEditorProps = {
  plugins?: ReactNode
  readonly?: boolean
  loading?: boolean
  onBlur?: (value: { text: string }) => void
}

const editorConfig = {
  namespace: 'title',
  nodes: [],

  onError(error: Error) {
    throw error
  },
  editorState: null,
  theme: {},
}

export const TitleEditor = (props: TitleEditorProps) => {
  const { plugins, readonly = false, onBlur } = props

  const [parentEditor] = useLexicalComposerContext()

  return (
    <div className='flex flex-row justify-center mt-3'>
      <EditorSizeContainer>
        <LexicalComposer initialConfig={editorConfig}>
          <TitleEditorContent
            plugins={plugins}
            readonly={readonly}
            onBlur={onBlur}
            parentEditor={parentEditor}
          />
        </LexicalComposer>
      </EditorSizeContainer>
    </div>
  )
}

export type TitleEditorContentProps = TitleEditorProps & {
  parentEditor: LexicalEditor
}

export const TitleEditorContent = ({
  plugins,
  readonly = false,
  onBlur,
  parentEditor,
}: TitleEditorContentProps) => {
  const [editor] = useLexicalComposerContext()

  const handleBlur = () => {
    if (onBlur == null) return

    const text = editor.getEditorState().read(() => {
      const rootNode = editor.getRootElement()
      return rootNode?.textContent || ''
    })

    onBlur({ text })
  }

  return (
    <>
      <div className='font-bold text-4xl leading-snug' onBlur={handleBlur}>
        <PlainTextPlugin
          contentEditable={
            <ContentEditable className='focus:outline-none w-full pb-1' />
          }
          placeholder={<Placeholder />}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </div>
      {plugins}
      <ChangeFocusPlugin parentEditor={parentEditor} />
      <RemoveNewLinesPlugin />
      <MaxLengthPlugin maxLength={512} label='Title' />
      <ReadOnlyPlugin readOnly={readonly} />
    </>
  )
}
