import { classed } from '@motion/theme'

import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  offset as floatingOffset,
  type OffsetOptions,
  type Placement,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import React, { type MouseEvent, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { PopoverEvent } from './popover-event'

import { mergeRefs } from '../../utils'
import { type Point } from '../context-menu/context-menu.types'

export type PopoverOptions = {
  placement?: Placement
  open?: boolean
  /**
   * When set to true, interaction with outside elements will be disabled.
   */
  enableOutsideInteractions?: boolean
  showArrow?: boolean
  arrowClassName?: string
  offset?: OffsetOptions
  onOpenChange?: (open: boolean) => void
  /**
   * For presentational popovers this can be useful since there's no interaction with elements inside the popover..
   */
  disableFocusManager?: boolean
}

export type PopoverProps = React.HTMLProps<HTMLDivElement> & {
  children: React.ReactNode
  /**
   * The reference of the element that you want to attach the popover too. It should be a button.
   * The reference could also be a point where we attach the popover.
   */
  triggerRef: React.RefObject<HTMLElement | null> | Point
  /**
   * Triggered when the popover is closed by clicking outside or pressing the Esc key.
   */
  onClose: (event: Event | undefined) => void
} & PopoverOptions

export const Popover = ({
  children,
  triggerRef,
  onClose,
  enableOutsideInteractions = false,
  showArrow = false,
  arrowClassName,
  offset = 5,
  placement = 'right-start',
  disableFocusManager = false,
}: PopoverProps) => {
  const transitionRef = React.useRef<
    { transitionDuration: string; transitionProperty: string } | undefined
  >(undefined)
  const arrowRef = React.useRef(null)
  const { context } = useFloating({
    placement,
    open: true,
    onOpenChange: (open, event) => !open && onClose(event),
    whileElementsMounted: autoUpdate,
    middleware: [
      floatingOffset(offset),
      flip({
        crossAxis: false,
        fallbackAxisSideDirection: 'end',
      }),
      shift({ padding: 5 }),
      arrow({
        element: arrowRef,
      }),
    ],
  })
  const dismiss = useDismiss(context)
  const role = useRole(context)
  const interactions = useInteractions([dismiss, role])

  React.useLayoutEffect(() => {
    // Set the reference to the trigger element the dropdown will be attached to.
    if ('current' in triggerRef && triggerRef.current) {
      context.refs.setReference(triggerRef.current)
    } else if ('x' in triggerRef && 'y' in triggerRef) {
      context.refs.setPositionReference({
        getBoundingClientRect() {
          return {
            width: 0,
            height: 0,
            x: triggerRef.x,
            y: triggerRef.y,
            top: triggerRef.y,
            right: triggerRef.x,
            bottom: triggerRef.y,
            left: triggerRef.x,
          }
        },
      })
    }
  }, [context.refs, triggerRef])

  React.useEffect(() => {
    if (context.isPositioned) {
      transitionRef.current = {
        transitionDuration: '180ms',
        transitionProperty: 'all',
      }
    }
  }, [context.isPositioned])

  const mainAxisOffset =
    (typeof offset === 'number'
      ? offset
      : typeof offset === 'object'
        ? offset.mainAxis
        : 0) ?? 0

  return (
    <FloatingPortal>
      <PopoverOverlay enableOutsideInteractions={enableOutsideInteractions}>
        <FloatingFocusManager
          context={context}
          visuallyHiddenDismiss
          returnFocus
          disabled={disableFocusManager}
        >
          <PopoverContainer
            ref={context.refs.setFloating}
            onPointerDown={(e) => e.stopPropagation()}
            style={{
              ...transitionRef.current,
              position: context.strategy,
              top: context.y || 0,
              left: context.x || 0,
            }}
            {...interactions.getFloatingProps()}
          >
            {children}
            {showArrow && (
              <FloatingArrow
                ref={arrowRef}
                context={context}
                width={20}
                height={Math.max(mainAxisOffset - 4, 0)}
                strokeWidth={0.75}
                className={twMerge(
                  'fill-dropdown-bg [&>path:first-of-type]:stroke-dropdown-border',
                  arrowClassName
                )}
              />
            )}
          </PopoverContainer>
        </FloatingFocusManager>
      </PopoverOverlay>
    </FloatingPortal>
  )
}

export const PopoverContainer = classed('div', {
  base: 'border-dropdown-border bg-dropdown-bg text-semantic-neutral-text-default rounded border shadow-lg z-[1]',
})

type PopoverOverlayProps = {
  children: React.ReactNode
  enableOutsideInteractions?: boolean
}
export const PopoverOverlay = (props: PopoverOverlayProps) => {
  const { enableOutsideInteractions = true } = props
  if (enableOutsideInteractions) return props.children
  return (
    <FloatingOverlay
      onClick={(e) => e.stopPropagation()}
      // stops propagation to drag and drop containers
      onMouseDown={(e) => e.stopPropagation()}
      className='z-[1]' // Same zindex as modals
    >
      {props.children}
    </FloatingOverlay>
  )
}

export type PopoverTriggerProps = Pick<
  PopoverOptions,
  'placement' | 'offset' | 'enableOutsideInteractions'
> & {
  /**
   * Should always be a single button.
   */
  children: ReactNode
  /**
   * Render the content of the popover (dropdown)
   */
  renderPopover: ({ close }: { close: () => void }) => React.ReactNode
  openOnMount?: boolean
  onClose?: () => void
}

export const PopoverTrigger = (props: PopoverTriggerProps) => {
  const {
    openOnMount = false,
    renderPopover,
    onClose,
    children,
    offset,
    placement,
    enableOutsideInteractions,
  } = props
  const [open, setOpen] = React.useState(openOnMount)

  const triggerRef = React.useRef<HTMLButtonElement>(null)

  const child = React.Children.only(children) as React.ReactElement<any>

  const handleClick = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    e.target.dispatchEvent(
      new PopoverEvent({
        state: 'open',
      })
    )
    setOpen((o) => !o)
  }

  const trigger = React.cloneElement(child, {
    // @ts-expect-error ref exists but isn't exposed
    ref: mergeRefs(child.ref, triggerRef),
    onClick: (e: React.MouseEvent) => {
      child.props.onClick?.(e)
      handleClick(e)
    },
    className: twMerge(child.props.className, open && 'visible'),
  })

  const handleClose = () => {
    onClose?.()
    setOpen(false)
    triggerRef.current?.dispatchEvent(
      new PopoverEvent({
        state: 'close',
      })
    )
    triggerRef.current?.focus()
  }

  return (
    <>
      {trigger}
      {open && (
        <Popover
          triggerRef={triggerRef}
          onClose={handleClose}
          open={open}
          offset={offset}
          placement={placement}
          enableOutsideInteractions={enableOutsideInteractions}
        >
          {renderPopover({
            close: handleClose,
          })}
        </Popover>
      )}
    </>
  )
}
