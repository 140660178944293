import {
  ChevronRightOutline,
  DuplicateOutline,
  RefreshOutline,
  TrashOutline,
} from '@motion/icons'
import { $isMotionNode, type MotionId } from '@motion/notes-shared'
import { useOnValueChange } from '@motion/react-core/hooks'
import { ActionList } from '@motion/ui/base'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getNodeByKey, $isElementNode, type NodeKey } from 'lexical'
import { useCallback, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { useTurnIntoActions, useTurnIntoOptions } from '../turn-into-plugin'

export type BlockActionMenuProps = {
  nodeKey: NodeKey | null
  onCopyLink: (motionId: MotionId, textContent: string | null) => void
  onDelete?: () => void
  close: () => void
}

export function BlockActionMenu({
  nodeKey,
  onCopyLink,
  onDelete,
  close,
}: BlockActionMenuProps) {
  const [editor] = useLexicalComposerContext()

  const [motionId, setMotionId] = useState<MotionId | null>(null)

  const [blockTextContent, setBlockTextContent] = useState<string | null>(null)

  const [turnIntoOpen, setTurnIntoOpen] = useState(false)

  const [_, turnIntoOptions] = useTurnIntoOptions(nodeKey)

  const turnIntoActions = useTurnIntoActions({
    blockNodeKey: nodeKey,
    turnIntoOptions,
  })

  useOnValueChange(
    nodeKey,
    (newNodeKey) => {
      if (newNodeKey == null) {
        return
      }

      // eslint-disable-next-line no-restricted-syntax
      const newMotionId = editor.read(() => {
        const node = $getNodeByKey(newNodeKey)

        return $isMotionNode(node) ? node.getMotionId() : null
      })

      setMotionId(newMotionId)
      setBlockTextContent(
        // eslint-disable-next-line no-restricted-syntax
        editor.read(() => {
          const node = $getNodeByKey(newNodeKey)
          return node?.getTextContent() ?? null
        })
      )
    },
    { triggerOnFirstRender: true }
  )

  const handleDeleteClick = useCallback(() => {
    if (nodeKey == null) return

    editor.update(() => {
      const node = $getNodeByKey(nodeKey)

      if (node != null) {
        node.remove()
      }
    })

    onDelete?.()
  }, [editor, nodeKey, onDelete])

  const handleCopyLinkClick = useCallback(() => {
    if (motionId == null) return

    onCopyLink(motionId, blockTextContent)
    close()
  }, [motionId, blockTextContent, onCopyLink, close])

  const handleTurnIntoClick = useCallback(() => {
    if (nodeKey == null) return

    editor.update(() => {
      const node = $getNodeByKey(nodeKey)

      if ($isElementNode(node)) {
        node.selectStart()
      }
    })

    close()
  }, [editor, nodeKey, close])

  return (
    <ActionList
      onActionAnyItem={close}
      sections={[
        {
          items: [
            motionId != null && {
              prefix: <DuplicateOutline />,
              content: 'Copy link to block',
              onAction: handleCopyLinkClick,
            },
            turnIntoOptions.length > 0 && {
              prefix: <RefreshOutline />,
              content: 'Turn into',
              suffix: (
                <ChevronRightOutline
                  className={twMerge(
                    '!size-3 transition duration-300',
                    turnIntoOpen && 'rotate-90'
                  )}
                />
              ),
              onAction: () => setTurnIntoOpen((prev) => !prev),
              renderPopover: () => (
                <ActionList
                  items={turnIntoActions}
                  onActionAnyItem={handleTurnIntoClick}
                />
              ),
            },
          ],
        },
        {
          items: [
            {
              prefix: <TrashOutline />,
              content: 'Delete block',
              destructive: true,
              onAction: handleDeleteClick,
            },
          ],
        },
      ]}
    />
  )
}
