import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementNode,
  LexicalEditor,
  LexicalNode,
  type SerializedElementNode,
  type Spread,
} from 'lexical'

export type SerializedCollapsibleContainerNode = Spread<
  {
    open: boolean
  },
  SerializedElementNode
>

export class CollapsibleContainerNode extends ElementNode {
  __open: boolean

  constructor(open: boolean, key?: string) {
    super(key)
    this.__open = open
  }

  createDOM(config: EditorConfig, editor: LexicalEditor): HTMLElement {
    const detailsDom = document.createElement('details')
    detailsDom.open = this.__open

    // Prevent the default behavior where clicking on the summary element will toggle the open state
    detailsDom.addEventListener('click', (e) => {
      e.preventDefault()
    })

    detailsDom.className = config.theme.collapsible.container

    return detailsDom
  }

  updateDOM(prevNode: this, dom: HTMLDetailsElement): boolean {
    if (prevNode.__open !== this.__open) {
      dom.open = this.__open
    }
    return false
  }

  static getType(): string {
    return 'collapsible-container'
  }

  static clone(node: CollapsibleContainerNode): CollapsibleContainerNode {
    return new CollapsibleContainerNode(node.__open, node.__key)
  }

  static importDOM(): DOMConversionMap<HTMLDetailsElement> | null {
    return {
      details: (dom) => {
        return {
          conversion: $convertDetailsElement,
          priority: 1,
        }
      },
    }
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('details')
    element.setAttribute('open', this.__open.toString())
    return {
      element,
    }
  }

  static importJSON(
    serializedNode: SerializedCollapsibleContainerNode
  ): CollapsibleContainerNode {
    return $createCollapsibleContainerNode(serializedNode.open).updateFromJSON(
      serializedNode
    )
  }

  exportJSON(): SerializedCollapsibleContainerNode {
    return {
      ...super.exportJSON(),
      open: this.__open,
    }
  }

  $setOpen(open: boolean): void {
    const writable = this.getWritable()
    writable.__open = open
  }

  getOpen(): boolean {
    return this.getLatest().__open
  }

  $toggleOpen(): void {
    this.$setOpen(!this.getOpen())
  }
}

export function $createCollapsibleContainerNode(
  open: boolean
): CollapsibleContainerNode {
  return new CollapsibleContainerNode(open)
}

export function $isCollapsibleContainerNode(
  node: LexicalNode | null | undefined
): node is CollapsibleContainerNode {
  return node instanceof CollapsibleContainerNode
}

function $convertDetailsElement(
  domNode: HTMLDetailsElement
): DOMConversionOutput | null {
  const isOpen = domNode.open !== undefined ? domNode.open : true
  const node = $createCollapsibleContainerNode(isOpen)
  return {
    node,
  }
}
