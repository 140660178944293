import { useEffect } from 'react'

import { focusFirstFocusableNode } from '../../utils'

export interface FocusProps {
  children?: React.ReactNode
  contentRef: React.RefObject<HTMLElement | null>
}

/**
 * Allow focusing the first focusable element in its children,
 * unless there's one of the child with the autofocus attribute
 */
export const Focus = ({ children, contentRef }: FocusProps) => {
  useFocusWithinContent({ contentRef })

  return children
}

function useFocusWithinContent({ contentRef }: Omit<FocusProps, 'children'>) {
  useEffect(() => {
    const node = contentRef.current

    if (
      !node ||
      node.querySelector('[autofocus]') ||
      node.contains(document.activeElement)
    ) {
      return
    }

    // Waiting a frame before setting the focus element,
    // otherwise this conflicts with the FocusManager for popovers
    // which thinks the focused element here is the trigger
    setTimeout(() => {
      focusFirstFocusableNode(node)
    }, 0)
  }, [contentRef])
}
