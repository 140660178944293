import {
  $isCollapsibleHeadingContentNode,
  $isCollapsibleHeadingNode,
  CollapsibleHeadingContentNode,
  CollapsibleHeadingNode,
} from '@motion/notes-shared'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import {
  $getNodeByKey,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_NORMAL,
  createCommand,
  INDENT_CONTENT_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
  KEY_BACKSPACE_COMMAND,
  type NodeKey,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical'
import { useEffect } from 'react'

import {
  $handleIndentCommand,
  $handleInsertCollapsibleHeadingCommand,
  $handleInsertParagraphCommand,
  $handleKeyBackspaceCommand,
  $handleOutdentCommand,
} from './handlers'

export type InsertCollapsibleHeadingCommandPayload = {
  heading: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

type HeadingNodeKey = NodeKey
type ContentNodeKey = NodeKey

export const INSERT_COLLAPSIBLE_HEADING_COMMAND =
  createCommand<InsertCollapsibleHeadingCommandPayload>()

export function CollapsibleHeadingPlugin() {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (
      !editor.hasNodes([CollapsibleHeadingNode, CollapsibleHeadingContentNode])
    ) {
      throw new Error(
        'CollapsiblePlugin: CollapsibleHeadingNode and/or CollapsibleHeadingContentNode not registered on editor'
      )
    }

    const collapsibleHeadingNodes = new Map<HeadingNodeKey, ContentNodeKey>()

    return mergeRegister(
      editor.registerMutationListener(CollapsibleHeadingNode, (mutations) => {
        for (const [nodeKey, type] of mutations) {
          if (type === 'destroyed') {
            editor.update(() => {
              const collapsibleContentNodeKey =
                collapsibleHeadingNodes.get(nodeKey)

              if (collapsibleContentNodeKey == null) {
                return
              }

              const collapsibleContentNode = $getNodeByKey(
                collapsibleContentNodeKey
              )

              if (!$isCollapsibleHeadingContentNode(collapsibleContentNode)) {
                return
              }

              // If we removed the heading node, we should move the content node to the parent
              const parentNode = collapsibleContentNode.getParent()

              if (parentNode == null) {
                return
              }

              const childNodes = collapsibleContentNode.getChildren()
              const firstChild = childNodes[0]

              if (firstChild == null) {
                return
              }

              collapsibleContentNode.replace(firstChild)

              childNodes
                .slice(1)
                .toReversed()
                .forEach((childNode) => {
                  firstChild.insertAfter(childNode)
                })

              collapsibleHeadingNodes.delete(nodeKey)
            })
          }
        }
      }),
      editor.registerMutationListener(
        CollapsibleHeadingContentNode,
        (mutations) => {
          for (const [nodeKey, type] of mutations) {
            if (type === 'created') {
              editor.update(() => {
                const collapsibleContentNode = $getNodeByKey(nodeKey)

                if (!$isCollapsibleHeadingContentNode(collapsibleContentNode)) {
                  return
                }

                const collapsibleHeadingNode =
                  collapsibleContentNode.getPreviousSibling()

                if (!$isCollapsibleHeadingNode(collapsibleHeadingNode)) {
                  // Remove the content node if it's not preceded by a heading node
                  const parentNode = collapsibleContentNode.getParent()

                  if (parentNode == null) {
                    return
                  }

                  parentNode.append(...collapsibleContentNode.getChildren())

                  collapsibleContentNode.remove()
                } else {
                  collapsibleHeadingNodes.set(
                    collapsibleHeadingNode.getKey(),
                    nodeKey
                  )
                }
              })
            }
          }
        }
      ),
      editor.registerNodeTransform(CollapsibleHeadingContentNode, (node) => {
        const collapsibleHeadingNode = node.getPreviousSibling()

        if (!$isCollapsibleHeadingNode(collapsibleHeadingNode)) {
          const childNodes = node.getChildren()
          const firstChild = childNodes[0]

          if (firstChild == null) {
            return
          }

          node.replace(firstChild)

          childNodes
            .slice(1)
            .toReversed()
            .forEach((childNode) => {
              firstChild.insertAfter(childNode)
            })
        }
      }),
      editor.registerCommand(
        INSERT_COLLAPSIBLE_HEADING_COMMAND,
        $handleInsertCollapsibleHeadingCommand,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        INSERT_PARAGRAPH_COMMAND,
        $handleInsertParagraphCommand,
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        $handleKeyBackspaceCommand,
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerCommand(
        OUTDENT_CONTENT_COMMAND,
        $handleOutdentCommand,
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        INDENT_CONTENT_COMMAND,
        $handleIndentCommand,
        COMMAND_PRIORITY_NORMAL
      )
    )
  }, [editor])

  return null
}
