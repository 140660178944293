import { mergeRegister } from '@lexical/utils'
import type { LexicalEditor } from 'lexical'
import { useEffect, useRef } from 'react'

/**
 * Hook to track the last editor update timestamp
 * @param editor The Lexical editor instance
 * @param anchorElem The anchor element to observe for resize events
 * @returns The ref containing the last update timestamp
 */
export function useEditorLastUpdate(
  editor: LexicalEditor,
  anchorElem: HTMLElement
) {
  // Initialize with current timestamp
  const lastEditorUpdateRef = useRef<number>(new Date().getTime())

  // Track editor updates
  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        lastEditorUpdateRef.current = new Date().getTime()
      })
    )
  }, [editor])

  // Track resize events
  useEffect(() => {
    const element = anchorElem
    if (!element) return

    const resizeObserver = new ResizeObserver(() => {
      lastEditorUpdateRef.current = new Date().getTime()
    })

    resizeObserver.observe(element)

    return () => {
      resizeObserver.disconnect()
    }
  }, [anchorElem])

  return lastEditorUpdateRef
}
