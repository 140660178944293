import { XSolid } from '@motion/icons'
import { classed, type ComponentProps } from '@motion/theme'

import { forwardRef, type ReactNode } from 'react'

import { FieldSlot, FooterSlot, HeaderSlot } from './slots'
import { StyledField, type StyledFieldProps } from './styled-field'

export type TextInputProps = Pick<
  ComponentProps<typeof Input>,
  | 'autoComplete'
  | 'autoCorrect'
  | 'autoFocus'
  | 'defaultValue'
  | 'disabled'
  | 'max'
  | 'maxLength'
  | 'min'
  | 'name'
  | 'onBlur'
  | 'onClick'
  | 'onFocus'
  | 'onKeyDown'
  | 'onKeyUp'
  | 'onPaste'
  | 'placeholder'
  | 'readOnly'
  | 'required'
  | 'role'
  | 'spellCheck'
  | 'step'
  | 'type'
  | 'value'
> &
  StyledFieldProps & {
    onChange?: (value: string) => void
    prefix?: ReactNode
    suffix?: ReactNode
    showClearButton?: boolean
    enableAutofill?: boolean
  }

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(props, ref) {
    const {
      sentiment,
      variant,
      disabled,
      readOnly,
      prefix,
      suffix,
      showClearButton,
      onChange,
      size,
      name,
      enableAutofill,
      rounded,
      headerContent,
      footerContent,
      raised,
      ...rest
    } = props

    return (
      <StyledField
        variant={variant}
        disabled={disabled}
        readOnly={readOnly}
        sentiment={sentiment}
        size={size}
        rounded={rounded}
        raised={raised}
      >
        <FieldSlot size={size}>
          {prefix}
          <Input
            ref={ref}
            disabled={disabled}
            readOnly={readOnly}
            onChange={(e) => {
              onChange?.(e.currentTarget.value)
            }}
            name={enableAutofill ? name : (name || 'textinput') + '_search'}
            autoComplete={enableAutofill ? undefined : 'off'}
            variant={variant}
            {...rest}
          />
          {suffix}
          {showClearButton && !!rest.value && (
            <button
              aria-label='Clear'
              type='button'
              onClick={() => {
                onChange?.('')
              }}
            >
              <XSolid />
            </button>
          )}
        </FieldSlot>

        {headerContent && <HeaderSlot>{headerContent}</HeaderSlot>}

        {footerContent && <FooterSlot>{footerContent}</FooterSlot>}
      </StyledField>
    )
  }
)

const Input = classed('input', {
  base: `
    outline-none
    bg-transparent
    w-full
    h-full

    text-field-text-default

    disabled:text-field-text-disabled
    disabled:placeholder:text-field-text-disabled
    disabled:cursor-not-allowed
  `,
  variants: {
    variant: {
      default: `enabled:placeholder:text-field-text-placeholder`,
      muted: ``,
      minimal: `
        placeholder:text-semantic-neutral-text-default
        disabled:placeholder:text-semantic-neutral-text-disabled
      `,
      title: 'enabled:placeholder:text-field-text-placeholder',
    },
  },
})
