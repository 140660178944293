import {
  $createCollapsibleListNode,
  $isCollapsibleContainerListItem,
  $isCollapsibleHeaderListItem,
  $isCollapsibleListItemNode,
  $isCollapsibleListNode,
} from '@motion/notes-shared'

import { $findMatchingParent } from '@lexical/utils'
import {
  $getNodeByKey,
  $isDecoratorNode,
  $isElementNode,
  type LexicalNode,
} from 'lexical'

export const $getDraggable = (nodeKey: string | null) => {
  if (!nodeKey) {
    return null
  }

  const node = $getNodeByKey(nodeKey)
  if (!node) {
    return null
  }

  const topLevelElement = node.getTopLevelElement()
  if (topLevelElement) {
    const nearestRoot = $findMatchingParent(node, $isCollapsibleListItemNode)
    if (nearestRoot && nearestRoot.getFirstChild() === topLevelElement) {
      return nearestRoot
    }
    return topLevelElement
  }
  return node
}

export const $getDropTarget = (
  targetNode: LexicalNode,
  draggedNode: LexicalNode
): LexicalNode => {
  let dropTarget = targetNode

  if (!$isElementNode(draggedNode) && !$isDecoratorNode(draggedNode)) {
    return dropTarget
  }

  // Handle collapsible lists
  if ($isCollapsibleListItemNode(draggedNode)) {
    // If target is inside a list item node, set that as the drop target
    const targetCollapsibleListItem = $findMatchingParent(dropTarget, (node) =>
      $isCollapsibleListItemNode(node)
    )

    const nextSibling = draggedNode.getNextSibling()

    if (targetCollapsibleListItem) {
      const targetIsInsideDraggable = $findMatchingParent(
        targetNode,
        (node) => node === nextSibling
      )

      dropTarget = targetCollapsibleListItem

      // Dropped into itself
      if (dropTarget === draggedNode) {
        return dropTarget
      }

      if (targetIsInsideDraggable) {
        // Dragged into its collapsible container
        return dropTarget
      }

      if (!$isCollapsibleHeaderListItem(draggedNode)) {
        if (!targetCollapsibleListItem.getOpen()) {
          const targetNextSibling = targetCollapsibleListItem.getNextSibling()
          if ($isCollapsibleListItemNode(targetNextSibling)) {
            dropTarget = targetNextSibling
          }
        }
      }
    }
  }

  return dropTarget
}

export const $getNodesToDrag = (draggedNode: LexicalNode) => {
  let nodesToInsert: LexicalNode[] = [draggedNode]

  if (!$isElementNode(draggedNode) && !$isDecoratorNode(draggedNode)) {
    return []
  }
  if ($isCollapsibleListItemNode(draggedNode)) {
    const nextSibling = draggedNode.getNextSibling()
    if ($isCollapsibleListItemNode(nextSibling)) {
      nodesToInsert.push(nextSibling)
    }
  }
  return nodesToInsert
}

export const $getNodesToInsert = (
  dropTarget: LexicalNode,
  draggedNode: LexicalNode
): LexicalNode[] => {
  let nodesToInsert: LexicalNode[] = [draggedNode]

  if (!$isElementNode(draggedNode) && !$isDecoratorNode(draggedNode)) {
    return []
  }

  // Handle collapsible lists
  if ($isCollapsibleListItemNode(draggedNode)) {
    const nextSibling = draggedNode.getNextSibling()
    const nextSiblingIsListContainer =
      $isCollapsibleListItemNode(nextSibling) &&
      $isCollapsibleContainerListItem(nextSibling)

    const wrapInListNode = () => {
      const draggedListNodeType =
        $findMatchingParent(draggedNode, (node) =>
          $isCollapsibleListNode(node)
        )?.getListType() ?? 'bullet'
      const container = $createCollapsibleListNode(draggedListNodeType, 1)

      container.append(draggedNode)
      if (nextSiblingIsListContainer) {
        container.append(nextSibling)
      }

      return container
    }

    const targetCollapsibleListItem = $isCollapsibleListItemNode(dropTarget)

    if (targetCollapsibleListItem) {
      const targetIsInsideDraggable = $findMatchingParent(
        dropTarget,
        (node) => node === nextSibling
      )

      // Dropped into itself
      if (dropTarget === draggedNode) {
        return []
      }

      if (targetIsInsideDraggable) {
        // Dragged into its collapsible container
        return []
      }

      if ($isCollapsibleHeaderListItem(draggedNode)) {
        // Dragged into a header list item
        const container = wrapInListNode()
        nodesToInsert = [container]
      }
    } else {
      const container = wrapInListNode()
      nodesToInsert = [container]
    }
  }

  return nodesToInsert
}

// For backward compatibility
export const $handleDrop = (
  targetNode: LexicalNode,
  draggedNode: LexicalNode
): { dropTarget: LexicalNode; nodesToInsert: LexicalNode[] } => {
  const dropTarget = $getDropTarget(targetNode, draggedNode)
  const nodesToInsert = $getNodesToInsert(dropTarget, draggedNode)
  return { dropTarget, nodesToInsert }
}
