import { HocuspocusProviderWebsocket } from '@hocuspocus/provider'
import { useEffect, useMemo, useRef } from 'react'

import { EditorContext } from './editor-context'

import { useStatefulRef } from '../editor/hooks'
import { DOCUMENT_COMPAT_VERSION } from '../version'

export type EditorProviderProps = {
  children: React.ReactNode
  websocketUrl?: string
}

export function EditorProvider({
  children,
  websocketUrl,
}: EditorProviderProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [floatingAnchorElem, setFloatingAnchorRef] =
    useStatefulRef<HTMLDivElement | null>(null)

  const websocketProvider = useMemo(() => {
    if (websocketUrl) {
      return new HocuspocusProviderWebsocket({
        url: websocketUrl,
        parameters: {
          version: DOCUMENT_COMPAT_VERSION,
        },
      })
    }
    return null
  }, [websocketUrl])

  useEffect(() => {
    return () => {
      if (!websocketProvider) return
      websocketProvider.disconnect()
      websocketProvider.destroy()
    }
  }, [websocketProvider])

  const value = useMemo(
    () => ({
      containerRef,
      floatingAnchorElem,
      setFloatingAnchorRef,
      websocketProvider,
    }),
    [floatingAnchorElem, setFloatingAnchorRef, websocketProvider]
  )

  return (
    <EditorContext.Provider value={value}>{children}</EditorContext.Provider>
  )
}
