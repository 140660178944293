/* eslint-disable react-refresh/only-export-components */
import { createContext, type ReactNode, useMemo } from 'react'

import { useModalStackContext } from '../../providers'

type ShortcutScopeContextValue = {
  isInScope: () => boolean
}

const defaultValue: ShortcutScopeContextValue = {
  isInScope: () => true,
}

export const ShortcutScopeContext = createContext(defaultValue)

type ShortcutScopeProviderProps = {
  children: ReactNode
  scope: string | undefined
}

export function ShortcutScopeProvider({
  children,
  scope,
}: ShortcutScopeProviderProps) {
  const { getLastVisibleModalNodeId } = useModalStackContext()

  const value: ShortcutScopeContextValue = useMemo(() => {
    return {
      isInScope: () =>
        getLastVisibleModalNodeId() === scope ||
        (scope === 'app' && getLastVisibleModalNodeId() == null),
    }
  }, [getLastVisibleModalNodeId, scope])

  return (
    <ShortcutScopeContext.Provider value={value}>
      {children}
    </ShortcutScopeContext.Provider>
  )
}
