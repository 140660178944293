import { ChevronDownOutline } from '@motion/icons'
import { classed, type ComponentProps } from '@motion/theme'

import { type ComponentRef, forwardRef, type ReactNode } from 'react'

export type PopoverButtonProps = ComponentProps<typeof StyledButton> & {
  icon?: ReactNode
  placeholder?: ReactNode
  readOnly?: boolean
}

export const PopoverButton = forwardRef<
  ComponentRef<typeof StyledButton>,
  PopoverButtonProps
>(function PopoverButton(props, ref) {
  const { children, muted, placeholder, icon, disabled, readOnly, ...rest } =
    props

  const child =
    typeof children === 'string' ? <Value>{children}</Value> : children

  const reallyDisabled = (disabled || readOnly) ?? false

  const showChevron = !readOnly && !muted

  return (
    <StyledButton
      ref={ref}
      type='button'
      muted={muted}
      disabled={reallyDisabled}
      readOnly={readOnly}
      {...rest}
    >
      {icon && <PrefixIcon>{icon}</PrefixIcon>}

      {/* supports falsy child value like `false`, `null` and `undefined` */}
      {child ? child : <Placeholder>{placeholder}</Placeholder>}

      {showChevron && <ChevronDownIcon aria-hidden='true' />}
    </StyledButton>
  )
})

const StyledButton = classed('button', {
  base: `
    w-full
    flex items-center justify-between

    text-field-text-default
    leading-5
    `,
  variants: {
    muted: {
      true: `
        bg-transparent
        border-transparent

        hover:border-field-border-hover
        active:border-field-border-active
    `,
    },
    sentiment: {
      error: 'border-field-border-error',
    },
    size: {
      small: `
        gap-1.5
        text-xs px-2 py-1
        [&_[data-icon]]:w-3
        [&_[data-icon]]:h-3
      `,
      normal: `
        h-8
        gap-2
        text-sm py-1.5 px-2
        [&_[data-icon]]:size-4
      `,
    },
    readOnly: {
      true: '',
      false: `
        border border-solid border-field-border-default rounded
        bg-field-bg-default

        hover:border-field-border-hover

        focus-visible:outline-0
        focus-visible:transition-shadow
        focus-visible:ring-2 focus-visible:ring-offset-1
        focus-visible:ring-offset-field-bg-default
        focus-visible:ring-field-border-focus

        active:border-field-border-active

        disabled:bg-field-bg-disabled
        disabled:border-field-border-disabled
        [&>*]:disabled:opacity-50
        disabled:cursor-not-allowed
      `,
    },
  },
  defaultVariants: {
    size: 'normal',
    readOnly: false,
  },
})

const PrefixIcon = classed('span', {
  base: 'shrink-0 text-field-icon-default',
})

const ChevronDownIcon = classed(ChevronDownOutline, {
  base: 'shrink-0 text-field-icon-selectChevron w-[18px] h-[18px]',
})

const Value = classed('span', {
  base: 'truncate grow text-left',
})

const Placeholder = classed(Value, {
  base: 'text-field-text-placeholder',
})
