import { BASE_NODES, LexicalTheme } from '@motion/notes-shared'

import {
  type InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer'
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode'
import { TableNode } from '@lexical/table'
import { type EditorThemeClasses } from 'lexical'
import { type ReactElement, type ReactNode, type Ref, useMemo } from 'react'

import { EditorPlugins } from './base-plugins'
import { EditorContent } from './components/editor-content'
import { EditorIcon } from './components/editor-icon'
import { EditorPublicAPIProvider } from './components/editor-public-api-provider'
import {
  EditorContainer,
  EditorInnerContainer,
  Skeleton,
} from './components/styled'
import { type EditorPublicAPI } from './types'

import { EditorProvider, useEditorContext } from '../context'
import { type TitleEditor, type TitleEditorProps } from '../title-editor'

function fallbackErrorHandler(error: Error) {
  // eslint-disable-next-line no-console
  console.error(error)
}

export type EditorProps = {
  editorRef?: Ref<EditorPublicAPI>
  namespace: InitialConfigType['namespace']
  state: InitialConfigType['editorState']
  loading?: boolean
  nodes?: InitialConfigType['nodes']
  plugins?: ReactNode
  onError?: InitialConfigType['onError']
  icon?: ReactNode
  titleEditor?: ReactElement<TitleEditorProps, typeof TitleEditor>
  websocketUrl?: string
  isMobile?: boolean
  theme?: EditorThemeClasses
  readonly?: boolean
}

export function Editor({ websocketUrl, ...props }: EditorProps) {
  return (
    <EditorProvider websocketUrl={websocketUrl}>
      <ContextlessEditor {...props} />
    </EditorProvider>
  )
}

const WEB_NODES = [...BASE_NODES, TableNode, HorizontalRuleNode]

export function ContextlessEditor({
  editorRef,
  namespace,
  state,
  plugins,
  nodes: nodesProp = [],
  onError = fallbackErrorHandler,
  icon,
  titleEditor,
  loading = true,
  isMobile = false,
  theme = LexicalTheme,
  readonly = false,
}: EditorProps) {
  const { containerRef } = useEditorContext()

  const nodes = useMemo(() => [...WEB_NODES, ...nodesProp], [nodesProp])

  const initialConfig: InitialConfigType = useMemo(
    () => ({
      namespace,
      editorState: state,
      nodes,
      theme,
      onError,
    }),
    [namespace, state, nodes, onError, theme]
  )

  return (
    <EditorContainer ref={containerRef}>
      <LexicalComposer initialConfig={initialConfig}>
        <EditorPublicAPIProvider ref={editorRef} />
        {loading && <Skeleton />}
        <EditorInnerContainer loading={loading} isMobile={isMobile}>
          <EditorIcon>{icon}</EditorIcon>
          {titleEditor}
          <EditorContent>
            <EditorPlugins isMobile={isMobile} readonly={readonly}>
              {plugins}
            </EditorPlugins>
          </EditorContent>
        </EditorInnerContainer>
      </LexicalComposer>
    </EditorContainer>
  )
}
