import { filterAndRankMatches } from '@motion/ui-logic'

import { type ReactNode, useCallback, useMemo, useState } from 'react'

import {
  ExpandableCheckboxTreeNode,
  getSearchableNodesWithPath,
  MultiSelectTreeProvider,
  SearchResultCheckboxTreeNode,
  TreeKeyboardProvider,
  TreeNodeList,
  useTreeKeyboardContext,
  type VirtualizedTreeNode,
  type VirtualizedTreeRootNode,
} from '../../virtualized-tree'
import {
  SearchableListInput,
  type SearchableListInputProps,
} from '../components'

export type SearchableTreeMultiSelectProps = {
  rootNode: VirtualizedTreeRootNode
  onSelect: (ids: VirtualizedTreeNode['id'][]) => void
  selectedIds: VirtualizedTreeNode['id'][]
  renderLabel: (node: VirtualizedTreeNode) => ReactNode
  renderIcon: (node: VirtualizedTreeNode) => ReactNode
  computeSearchable?: (node: VirtualizedTreeNode) => boolean
  inputProps?: { placeholder: string }
  showExplicitSelectionOnly?: boolean
}

const RETURN_TRUE = () => true

export const SearchableTreeMultiSelect = (
  props: SearchableTreeMultiSelectProps
) => {
  const {
    inputProps = { placeholder: 'Filter...' },
    rootNode,
    onSelect,
    selectedIds,
    renderLabel,
    renderIcon,
    computeSearchable = RETURN_TRUE,
  } = props

  const [search, setSearch] = useState<string>('')

  const handleInputValueChange = useCallback((search: string) => {
    setSearch(search)
  }, [])

  const leafNodes = useMemo(
    () => getSearchableNodesWithPath(rootNode, computeSearchable),
    [computeSearchable, rootNode]
  )

  const filteredTree: VirtualizedTreeRootNode = useMemo(() => {
    if (!search) return rootNode
    return {
      id: '[ROOT]',
      label: '[ROOT]',
      children: filterAndRankMatches(
        search.trim(),
        leafNodes,
        (item) => item.label
      ),
    }
  }, [leafNodes, rootNode, search])

  const estimateSize = useCallback(() => (search ? 48 : 32), [search])

  return (
    <MultiSelectTreeProvider
      rootNode={filteredTree}
      onSelect={onSelect}
      selectedIds={selectedIds}
      renderNode={(row) =>
        search.trim() ? (
          <SearchResultCheckboxTreeNode row={row}>
            {renderIcon(row.original)}
            {renderLabel(row.original)}
          </SearchResultCheckboxTreeNode>
        ) : (
          <ExpandableCheckboxTreeNode
            row={row}
            renderIcon={renderIcon}
            showExplicitSelectionOnly={props.showExplicitSelectionOnly}
          >
            {renderLabel(row.original)}
          </ExpandableCheckboxTreeNode>
        )
      }
    >
      <TreeKeyboardProvider>
        <ConnectedSearchableListInput
          {...inputProps}
          onValueChange={handleInputValueChange}
          search={search}
        />
        <div className='w-80 flex flex-col max-h-60'>
          {filteredTree.children.length === 0 && (
            <div className='w-full pt-4 pb-2 text-center text-sm text-semantic-neutral-text-subtle'>
              No results
            </div>
          )}

          <TreeNodeList estimateSize={estimateSize} />
        </div>
      </TreeKeyboardProvider>
    </MultiSelectTreeProvider>
  )
}

const ConnectedSearchableListInput = (props: SearchableListInputProps) => {
  const { search, onValueChange, ...inputProps } = props
  const { resetFocusedIndex } = useTreeKeyboardContext()

  const handleInputValueChange = useCallback(
    (search: string) => {
      onValueChange?.(search)
      resetFocusedIndex()
    },
    [onValueChange, resetFocusedIndex]
  )

  return (
    <SearchableListInput
      {...inputProps}
      onValueChange={handleInputValueChange}
      search={search}
    />
  )
}
