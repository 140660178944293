/**
 * Auto Generated
 */
import { type ClientZod } from '../../utils/client-transform'
import type * as Server from '../../server/common/deadline-type'

// Generated Types

export type DeadlineTypeSchema = ClientZod<typeof Server.DeadlineTypeSchema>
export const DeadlineTypeSchema = ['ASAP', 'HARD', 'SOFT', 'NONE'] as const
