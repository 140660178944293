import { $createMentionNode } from '@motion/notes-shared'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_NORMAL,
  getDOMSelection,
  type RangeSelection,
} from 'lexical'
import { type ReactNode, useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import { TOGGLE_INLINE_CREATE_COMMAND } from './command'
import { InlineCreateHoverCard } from './inline-create-hover-card'

import { useEditorContext } from '../../context'

export type InlineCreatePluginProps = {
  render: (
    entityType: string,
    onCreated: (args: {
      entityId: string
      entityLabel: string
      entityType: string
    }) => void,
    onBlur: () => void
  ) => ReactNode
}

export function InlineCreatePlugin({
  render,
}: InlineCreatePluginProps): JSX.Element | null {
  const [editor] = useLexicalComposerContext()
  const { floatingAnchorElem } = useEditorContext()
  const [showInputType, setShowInputType] = useState<string | null>(null)
  const [selectionClone, setSelectionClone] = useState<RangeSelection | null>(
    null
  )

  const closeInput = useCallback(() => {
    setShowInputType(null)
    setSelectionClone(null)
  }, [])

  const handleBlur = useCallback(() => {
    closeInput()
  }, [closeInput])

  const handleOnCreated = useCallback(
    (args: { entityId: string; entityLabel: string; entityType: string }) => {
      editor.update(() => {
        const selection = selectionClone

        const { entityId, entityLabel, entityType } = args
        const mention = $createMentionNode(entityId, entityLabel, entityType)

        if (!mention) return

        if (
          !selection ||
          !selection.isCollapsed() ||
          !$isRangeSelection(selection)
        ) {
          const root = $getRoot()
          root.append(
            $createParagraphNode().append(mention, $createTextNode(' '))
          )
        } else {
          selection.insertNodes([mention, $createTextNode(' ')])
        }

        closeInput()
      })
    },
    [closeInput, editor, selectionClone]
  )

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        TOGGLE_INLINE_CREATE_COMMAND,
        (payload) => {
          const domSelection = getDOMSelection(editor._window)
          if (domSelection !== null) {
            domSelection.removeAllRanges()
          }
          const selection = $getSelection()
          if (!$isRangeSelection(selection)) {
            return false
          }

          setSelectionClone(selection.clone())
          setShowInputType(payload.type)

          return true
        },
        COMMAND_PRIORITY_NORMAL
      )
    )
  }, [editor])

  if (!floatingAnchorElem || !showInputType || !selectionClone) return null

  return (
    <>
      {createPortal(
        <InlineCreateHoverCard
          editor={editor}
          anchorElement={floatingAnchorElem}
        >
          {render(showInputType, handleOnCreated, handleBlur)}
        </InlineCreateHoverCard>,
        floatingAnchorElem
      )}
    </>
  )
}
