import {
  $isCollapsibleHeadingContentNode,
  $isCollapsibleHeadingNode,
} from '@motion/notes-shared'

import { $findMatchingParent } from '@lexical/utils'
import {
  $getSelection,
  $isDecoratorNode,
  $isElementNode,
  $isRangeSelection,
} from 'lexical'

export function $handleOutdentCommand() {
  const selection = $getSelection()

  if (!$isRangeSelection(selection)) {
    return false
  }

  const nodes = selection.getNodes()

  if (nodes.length === 0) {
    return false
  }

  const firstNode = nodes[0]
  const firstNodeParentElement = $findMatchingParent(
    firstNode,
    (node) => $isElementNode(node) || $isDecoratorNode(node)
  )

  const lastNode = nodes[nodes.length - 1]
  const lastNodeParentElement = $findMatchingParent(
    lastNode,
    (node) => $isElementNode(node) || $isDecoratorNode(node)
  )

  const collapsibleContainerNode = $findMatchingParent(
    nodes[0],
    $isCollapsibleHeadingContentNode
  )

  if (collapsibleContainerNode != null) {
    const firstChild = collapsibleContainerNode.getFirstChild()
    const lastChild = collapsibleContainerNode.getLastChild()

    // If the first child of the collapsible heading is selected, we should outdent everything
    if (firstChild?.is(firstNodeParentElement)) {
      const collapsibleHeadingNode =
        collapsibleContainerNode.getPreviousSibling()

      if ($isCollapsibleHeadingNode(collapsibleHeadingNode)) {
        collapsibleContainerNode
          .getChildren()
          .toReversed()
          .forEach((child) => {
            collapsibleHeadingNode.insertAfter(child)
          })

        collapsibleContainerNode.remove()

        return true
      }
    }
    // If the last child of the collapsible heading is selected, we should outdent all selected nodes
    else if (lastChild?.is(lastNodeParentElement)) {
      if (firstNodeParentElement != null && lastNodeParentElement != null) {
        const nodesToMove = firstNodeParentElement?.getNodesBetween(
          lastNodeParentElement
        )

        nodesToMove.toReversed().forEach((node) => {
          collapsibleContainerNode.insertAfter(node)
        })

        return true
      }
    }
    // If the selection starts somewhere in the middle, we should outdent from the middle to the last nodes
    else {
      if (firstNodeParentElement == null || lastChild == null) {
        return false
      }

      const children = collapsibleContainerNode.getChildren()

      const childrenToMove = children.slice(
        children.findIndex((child) => child.is(firstNodeParentElement))
      )

      childrenToMove.toReversed().forEach((node) => {
        collapsibleContainerNode.insertAfter(node)
      })

      return true
    }
  }

  return false
}
