import {
  CollapsibleListNode,
  ListItemNodeReplacement,
  ListNodeReplacement,
} from '@motion/notes-shared'

import { CodeNode } from '@lexical/code'
import { ListItemNode, ListNode } from '@lexical/list'
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'

import { BaseEditor, type BaseEditorProps } from '../base-editor'

type AiDocsEditorProps = {
  initialValue: BaseEditorProps['initialValue']
}

export const AiDocsEditor = (props: AiDocsEditorProps) => {
  return (
    <BaseEditor
      name='ai-docs-editor'
      initialValue={props.initialValue}
      editableClassName='p-3'
      nodes={[
        HorizontalRuleNode,
        QuoteNode,
        CodeNode,
        ListItemNodeReplacement,
        ListNodeReplacement,
        ListItemNode,
        ListNode,
        CollapsibleListNode,
        HeadingNode,
      ]}
      theme={{
        list: {
          listitem: 'ml-4 mt-1.5',
          nested: {
            listitem: 'list-disc  after:!hidden after:hidden !pl-4 no-count',
          },
          ol: 'list-decimal',
          olDepth: [
            'list-style-decimal mb-2.5',
            'list-style-alpha',
            'list-style-roman',
          ],
          ul: 'list-disc',
          ulDepth: ['mb-2.5'],
        },
      }}
      plugins={
        <>
          <MarkdownShortcutPlugin />
          <ListPlugin />
        </>
      }
    />
  )
}
