import { timeout } from '@motion/utils/promise'
import { Sentry } from '@motion/web-base/sentry'

import {
  type IDBPDatabase,
  type IDBPObjectStore,
  openDB,
  type StoreNames,
} from 'idb'

import { log } from './log'
import { type MotionDBConfig } from './types'

import { stats } from '../../performance'
import { DB_VERSION } from '../db/constants'

async function openDatabase(name: string) {
  const db = await openDB<MotionDBConfig>(name, DB_VERSION, {
    upgrade(db, oldVersion, newVersion, tx) {
      log('upgrade', oldVersion, newVersion)
      if (newVersion == null) return

      createStore(db, 'state')
      if (newVersion >= 2) {
        createStore(db, 'react-query', (store) => {
          store.createIndex('timestamp', 'state.dataUpdatedAt')
        })
      }
    },
    blocked(currentVersion, blockedVersion, event) {
      log('blocked', currentVersion, blockedVersion, event)
    },
    blocking(currentVersion, blockedVersion, event) {
      log('blocking', currentVersion, blockedVersion, event)

      // reload the page so that the connection can be re-established
      window.location.reload()
    },
    terminated() {
      log('terminated')
    },
  })

  return db
}

export function openDatabaseWithTimeout(name: string, ms = 3_000) {
  return retry(
    'indexeddb.open',
    () => stats.time('indexeddb.open', () => openDatabase(name)),
    {
      retries: 3,
      timeout: ms,
    }
  )
}

type VersionChangeStore<TName extends StoreNames<MotionDBConfig>> =
  IDBPObjectStore<
    MotionDBConfig,
    ArrayLike<StoreNames<MotionDBConfig>>,
    TName,
    'versionchange'
  >

function createStore<TName extends StoreNames<MotionDBConfig>>(
  db: IDBPDatabase<MotionDBConfig>,
  name: TName,
  configure?: (store: VersionChangeStore<TName>) => void
) {
  if (db.objectStoreNames.contains(name)) return

  log('creating store', name)
  const store = db.createObjectStore(name)
  configure?.(store)
}

type Options = {
  retries: number
  timeout: number
}

async function retry<T>(
  name: string,
  fn: () => Promise<T>,
  opts: Options = { retries: 3, timeout: 1000 }
) {
  let attempt = 0
  while (attempt <= opts.retries) {
    try {
      // eslint-disable-next-line no-await-in-loop
      return await Promise.race([fn(), timeout(name, opts.timeout)])
    } catch (ex) {
      if (attempt >= opts.retries) {
        Sentry.captureException(ex, {
          extra: {
            location: `${name}.retry`,
            attempt,
          },
        })
        throw ex
      }

      Sentry.captureException(ex, {
        level: 'warning',
        extra: {
          location: `${name}.retry`,
          attempt,
        },
      })
    }
    attempt += 1
  }
  throw new Error('Retries exceeded')
}
