import { useDebouncedCallback } from '@motion/react-core/hooks'

import { $isCodeNode, CodeNode } from '@lexical/code'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getNearestNodeFromDOMNode, isHTMLElement } from 'lexical'
import { useEffect, useMemo, useRef, useState } from 'react'

export function useCodeToolbarPosition({
  anchorElem,
}: {
  anchorElem: HTMLElement
}) {
  const [editor] = useLexicalComposerContext()
  const [codeNode, setCodeNode] = useState<CodeNode | null>(null)

  const [shouldLockVisibility, setShouldLockVisibility] =
    useState<boolean>(false)
  const [shouldListenMouseMove, setShouldListenMouseMove] =
    useState<boolean>(false)

  // Keep track of the current mutations to determine if we should listen to mouse move events
  const currentMutations = useRef<Set<string>>(new Set())

  const handleMouseMove = (event: MouseEvent) => {
    const { codeDOMNode, isOutside } = getMouseInfo(event)

    if (codeDOMNode == null && isOutside) {
      setCodeNode(null)
    }

    if (codeDOMNode) {
      // eslint-disable-next-line no-restricted-syntax
      editor.read(() => {
        const maybeCodeNode = $getNearestNodeFromDOMNode(codeDOMNode)

        if ($isCodeNode(maybeCodeNode)) {
          setCodeNode(maybeCodeNode)
        }
      })
    }
  }

  const debouncedHandleMouseMove = useDebouncedCallback(handleMouseMove, 50)

  useEffect(() => {
    if (shouldLockVisibility || !shouldListenMouseMove) {
      return
    }

    document.addEventListener('mousemove', debouncedHandleMouseMove)

    return () => {
      debouncedHandleMouseMove.cancel()
      document.removeEventListener('mousemove', debouncedHandleMouseMove)
    }
  }, [shouldLockVisibility, shouldListenMouseMove, debouncedHandleMouseMove])

  useEffect(() => {
    return editor.registerMutationListener(
      CodeNode,
      (mutations) => {
        editor.getEditorState().read(() => {
          for (const [key, type] of mutations) {
            switch (type) {
              case 'created':
                currentMutations.current.add(key)
                break

              case 'destroyed':
                currentMutations.current.delete(key)
                break

              default:
                break
            }
          }
        })

        const codeNodeKey = codeNode?.getKey()

        if (codeNodeKey && !currentMutations.current.has(codeNodeKey)) {
          setCodeNode(null)
        }

        setShouldListenMouseMove(currentMutations.current.size > 0)
      },
      { skipInitialization: false }
    )
  }, [editor, codeNode])

  const lockVisibility = () => setShouldLockVisibility(true)

  const unlockVisibility = () => setShouldLockVisibility(false)

  const position = useMemo(() => {
    if (codeNode == null)
      return {
        right: '0',
        top: '0',
      }

    let newPosition

    // eslint-disable-next-line no-restricted-syntax
    editor.read(() => {
      if (codeNode) {
        const { y: editorElemY, right: editorElemRight } =
          anchorElem.getBoundingClientRect()
        const { y = 0, right = 0 } =
          editor.getElementByKey(codeNode.getKey())?.getBoundingClientRect() ||
          {}

        newPosition = {
          right: `${editorElemRight - right}px`,
          top: `${y - editorElemY}px`,
        }
      }
    })

    return newPosition
  }, [codeNode, anchorElem, editor])

  return {
    codeNode,
    position,
    lockVisibility,
    unlockVisibility,
  }
}

function getMouseInfo(event: MouseEvent): {
  codeDOMNode: HTMLElement | null
  isOutside: boolean
} {
  const target = event.target

  if (target && isHTMLElement(target)) {
    const codeDOMNode = target.closest<HTMLElement>('code')
    const codeToolbarDOMNode = target.closest<HTMLElement>('.code-toolbar')

    const isOutside = !(codeDOMNode || codeToolbarDOMNode)

    return { codeDOMNode, isOutside }
  }

  return { codeDOMNode: null, isOutside: true }
}
