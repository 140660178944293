/**
 * Auto Generated
 */
import { type ClientZod } from '../utils/client-transform'
import type * as Server from '../server/recurring-tasks'

// Generated Types

export type RecurringTaskFrequencySchema = ClientZod<
  typeof Server.RecurringTaskFrequencySchema
>
export const RecurringTaskFrequencySchema = [
  'DAILY',
  'WEEKLY',
  'BIWEEKLY',
  'MONTHLY',
  'QUARTERLY',
] as const
export type RecurringTaskTypeSchema = ClientZod<
  typeof Server.RecurringTaskTypeSchema
>
export const RecurringTaskTypeSchema = ['NORMAL', 'FOCUS'] as const
export type CreateRecurringTaskSchema = ClientZod<
  typeof Server.CreateRecurringTaskSchema
>
export type CreateRecurringTaskDto = ClientZod<
  typeof Server.CreateRecurringTaskDto.schema
>
export type UpdateRecurringTaskSchema = ClientZod<
  typeof Server.UpdateRecurringTaskSchema
>
export type UpdateRecurringTaskDto = ClientZod<
  typeof Server.UpdateRecurringTaskDto.schema
>
