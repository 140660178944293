const RE_ID = /[^\p{L}\p{N}_]/gu

/**
 * Converts a label to an ID by removing all non-alphanumeric and non-underscore
 * characters.  Allows unicode characters.
 * @param label The label to convert into an ID.
 */
export function sanitizeAgentLabel(label: string) {
  return label.toLowerCase().replace(RE_ID, '_')
}
