import { classed } from '@motion/theme'

import { type ComponentProps } from 'react'

export type TextProps = ComponentProps<typeof Text>

export const Text = classed('p', {
  base: '',
  variants: {
    sentiment: {
      default: 'text-semantic-neutral-text-default',
      subtle: 'text-semantic-neutral-text-subtle',
      disabled: 'text-semantic-neutral-text-disabled',
      onDark: 'text-semantic-neutral-text-onDark',
      inverse: 'text-semantic-neutral-text-inverse',
      error: 'text-semantic-error-text-default',
      success: 'text-semantic-success-text-default',
    },
    size: {
      '2xs': 'text-2xs',
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-[15px] leading-5',
      base: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
      '3xl': 'text-3xl',
      '4xl': 'text-4xl',
      '5xl': 'text-5xl',
      '6xl': 'text-6xl',
      '7xl': 'text-7xl',
      '8xl': 'text-8xl',
      '9xl': 'text-9xl',
    },
    weight: {
      light: 'font-light',
      normal: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
    },
    alignment: {
      start: 'text-start',
      center: 'text-center',
      end: 'text-end',
      justify: 'text-justify',
    },
    truncate: {
      true: 'truncate',
      false: '',
    },
    numeric: {
      true: 'font-mono',
      false: 'font-sans',
    },
    visuallyHidden: {
      true: 'sr-only',
      false: '',
    },
    nowrap: {
      true: 'whitespace-nowrap',
      false: '',
    },
  },
  defaultVariants: {
    sentiment: 'default',
    size: 'base',
    weight: 'normal',
    alignment: 'start',
    truncate: false,
    numeric: false,
    visuallyHidden: false,
    nowrap: false,
  },
})
