import { CarSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { formatTimeRange } from '@motion/ui-logic'

import { DateTime } from 'luxon'
import { useMemo } from 'react'

import {
  getDurationEvent,
  getDurationHeight,
  MIN_DURATION_HEIGHT,
} from './utils'

import { CalendarPalette } from '../palette'

export interface TravelTimesProps extends CalendarPalette {
  startTime: Date
  endTime: Date
  travelBefore: number
  travelAfter: number
}

export const TravelTimes = (props: TravelTimesProps) => {
  const {
    colorId,
    colorHue,
    startTime,
    endTime,
    travelBefore,
    travelAfter,
    ...rest
  } = props

  const durationHeight = useMemo(
    () => getDurationEvent(startTime, endTime),
    [startTime, endTime]
  )

  const travelBeforeHeight = getDurationHeight(
    travelBefore,
    // If the event duration is too short (less than 15px), then we do want to set the minimum height
    // Otherwise, the minimum height is 0 for the 'travel before' so we don't over estimate the height
    travelBefore === 0
      ? durationHeight < MIN_DURATION_HEIGHT
        ? MIN_DURATION_HEIGHT
        : 0
      : MIN_DURATION_HEIGHT
  )

  const afterOffset =
    travelAfter !== 0 ? durationHeight + travelBeforeHeight : 0

  const startTimeISO = startTime.toISOString()
  const endTimeISO = endTime.toISOString()

  return (
    <CalendarPalette colorId={colorId} colorHue={colorHue}>
      <Event {...rest}>
        {travelBefore !== 0 && (
          <TravelDisplay
            offset={0}
            formattedTime={formatTimeRange(
              DateTime.fromISO(startTimeISO).plus({ minutes: -travelBefore }),
              DateTime.fromISO(startTimeISO)
            )}
          />
        )}
        {travelAfter !== 0 && (
          <TravelDisplay
            offset={afterOffset}
            formattedTime={formatTimeRange(
              DateTime.fromISO(endTimeISO),
              DateTime.fromISO(endTimeISO).plus({ minutes: travelAfter })
            )}
          />
        )}
      </Event>
    </CalendarPalette>
  )
}

const Event = classed(
  'div',
  `
  min-w-full h-full overflow-hidden
  rounded
  relative

  border
  border-dashed
  border-calendar-palette-border-default

  before:block
  before:absolute
  before:rounded-l
  before:-left-px
  before:-top-px
  before:-bottom-px
  before:w-[3px]
  before:bg-calendar-palette-border-default
  `
)

type TravelDisplayProps = {
  offset: number
  formattedTime: string
}

const TravelDisplay = (props: TravelDisplayProps) => {
  return (
    <div
      className='flex flex-row gap-1 items-center truncate py-px px-[7px] text-[9px] leading-[12px] text-calendar-palette-text-default leading-1 absolute'
      style={{
        top: props.offset,
      }}
    >
      <CarSolid className='w-2.5 h-2.5' />
      {props.formattedTime}
    </div>
  )
}
