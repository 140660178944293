import { HttpError } from '@motion/rpc'
import { Sentry } from '@motion/web-base/sentry'

import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'

import { onMutationSuccess, onQuerySuccess } from './observers'
import { handleManualCacheEvents } from './per-query-persistor'

export const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30_000,
      gcTime: 1 * 60 * 60 * 1000, // 1 hour
      networkMode: 'always',
      retry: (failureCount, error) => {
        if (error instanceof HttpError && error.status === 429) return false // Too many requests
        if (error instanceof HttpError && error.status === 404) return false
        // failureCount starts at 0, so we retry 3 times
        if (failureCount > 2) return false
        return true
      },
    },
    mutations: {
      networkMode: 'always',
    },
  },
  queryCache: new QueryCache({
    onError(error, query) {
      Sentry.captureException(error, {
        extra: {
          queryKey: query.queryKey,
          queryOptions: query.options,
        },
        tags: { position: 'queryCache' },
      })
    },
  }),
  mutationCache: new MutationCache({
    onError(error, variables) {
      Sentry.captureException(error, {
        extra: {
          variables,
        },
        tags: { position: 'mutationCache' },
      })
    },
  }),
})

client.getQueryCache().config.onSuccess = onQuerySuccess(client)
client.getMutationCache().config.onSuccess = onMutationSuccess(client)

client.getQueryCache().subscribe(handleManualCacheEvents)
