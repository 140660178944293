import { createKey, defineApi, defineMutation } from '@motion/rpc'

import type { RouteTypes } from '../types'

const queryKeys = {
  status: () => createKey('email-verification-status'),
}

export const startEmailVerification = defineMutation<
  RouteTypes<'EmailVerificationController_startEmailVerification'>['request'],
  { sent: boolean }
>().using({
  uri: '/email-verification',
  method: 'POST',
  body: (args) => args,
})

export const verifyEmailVerification = defineMutation<
  RouteTypes<'EmailVerificationController_verifyEmailVerification'>['request'],
  RouteTypes<'EmailVerificationController_verifyEmailVerification'>['response']
>().using({
  uri: '/email-verification/verify',
  method: 'POST',
  body: (args) => args,
  invalidate: queryKeys.status(),
})

export const getEmailVerificationStatus = defineApi<
  RouteTypes<'EmailVerificationController_getEmailVerificationStatus'>['request'],
  RouteTypes<'EmailVerificationController_getEmailVerificationStatus'>['response']
>().using({
  uri: '/email-verification',
  method: 'GET',
  key: queryKeys.status(),
})
