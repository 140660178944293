import {
  $createCollapsibleListNode,
  $createMotionHeadingNode,
  $isCollapsibleListNode,
} from '@motion/notes-shared'

import { $createCodeNode } from '@lexical/code'
import { $createListItemNode } from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createQuoteNode } from '@lexical/rich-text'
import {
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $isElementNode,
  COMMAND_PRIORITY_NORMAL,
} from 'lexical'
import { useEffect } from 'react'

import { TURN_INTO_COMMAND } from './commands'

export function TurnIntoPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerCommand(
      TURN_INTO_COMMAND,
      ({ nodeKey, type }) => {
        const node = $getNodeByKey(nodeKey)

        if (node == null) {
          return false
        }

        switch (type) {
          case 'h1':
          case 'h2':
          case 'h3':
            const headingNode = $createMotionHeadingNode(type)
            node.replace(headingNode, true)
            return true

          case 'paragraph':
            const paragraphNode = $createParagraphNode()
            node.replace(paragraphNode, true)
            return true

          case 'bulleted-list':
          case 'numbered-list':
          case 'check-list':
            const prevNode = node.getTopLevelElement()?.getPreviousSibling()
            const nextNode = node.getTopLevelElement()?.getNextSibling()

            const prevListNode = $isCollapsibleListNode(prevNode)
              ? prevNode
              : null
            const nextListNode = $isCollapsibleListNode(nextNode)
              ? nextNode
              : null

            const listType =
              type === 'check-list'
                ? 'check'
                : type === 'numbered-list'
                  ? 'number'
                  : 'bullet'

            const newListNode = $createCollapsibleListNode(listType, 0)

            const listItemNode = $createListItemNode()

            const childNodes = $isElementNode(node)
              ? node.getChildren()
              : [$createTextNode(node.getTextContent())]

            listItemNode.append(...childNodes)

            // If the previous sibling is a list of the same type, append it to it
            if (prevListNode && prevListNode.getType() === listType) {
              prevListNode.append(listItemNode)
            }
            // If the next sibling is a list of the same time, prepend it to it
            else if (nextListNode && nextListNode.getType() === listType) {
              nextListNode.getFirstChild()?.insertBefore(listItemNode)
            }
            // Otherwise we create a new list and replace the selected node with it
            else {
              newListNode.append(listItemNode)
              node.replace(newListNode)
            }

            return true

          case 'block-quote':
            const blockQuoteNode = $createQuoteNode()
            node.replace(blockQuoteNode, true)
            return true

          case 'code-block':
            const codeNode = $createCodeNode()
            node.replace(codeNode, true)
            return true

          default:
            return false
        }
      },
      COMMAND_PRIORITY_NORMAL
    )
  }, [editor])

  return null
}
