import { LoadingSolid } from '@motion/icons'
import { classed, deriveClassed } from '@motion/theme'

import { type ComponentProps, type MouseEvent } from 'react'

import { Link, type LinkProps } from '../../internal'
import { Shortcut, type ShortcutProps } from '../shortcut'

/*
  css variables
  --b-gap
*/
const StyledButton = classed('button', {
  base: `
    relative inline-flex items-center justify-center
    rounded font-semibold
    gap-[var(--b-gap)]
    box-border

    focus-visible:outline
    focus-visible:outline-2
    focus-visible:outline-offset-1
    focus-visible:outline-button-primary-solid-border-focus
  `,
  variants: {
    alignment: {
      left: 'justify-start',
      center: 'justify-center',
      right: 'justify-end',
    },
    iconOnly: { true: '' },
    fullWidth: {
      true: 'w-full',
    },
    size: {
      normal: `
        setvar-[b-gap=6px]
        text-sm px-[11px] py-[7px]
        [&_[data-icon]]:size-4
      `,
      small: `
        setvar-[b-gap=6px]
        text-xs px-[7px] py-[3px]
        [&_[data-icon]]:size-4
      `,
      xsmall: `
        setvar-[b-gap=4px]
        text-[10px] leading-4 px-[5px] py-px
        [&_[data-icon]]:size-3
      `,
    },
  },
  compoundVariants: [
    {
      iconOnly: true,
      size: 'xsmall',
      className: 'px-[1px] py-[1px]',
    },
    {
      iconOnly: true,
      size: 'small',
      className: 'px-[3px] py-[3px]',
    },
    {
      iconOnly: true,
      size: 'normal',
      className: 'px-[5px] py-[5px]',
    },
  ],
  defaultVariants: {
    size: 'normal',
    fullWidth: false,
  },
})

export type BaseButtonProps = ComponentProps<typeof StyledButton> &
  Partial<Pick<LinkProps, 'external' | 'url'>> & {
    loading?: boolean
    shouldPropagateOnClick?: boolean
    shortcut?: string
    renderShortcutTooltip?: ShortcutProps['renderTooltip']
  }

export const BaseButton = deriveClassed<typeof StyledButton, BaseButtonProps>(
  function Button(props, ref) {
    const {
      url,
      external,
      disabled = false,
      loading = false,
      children,
      onClick,
      shouldPropagateOnClick = true,
      shortcut,
      renderShortcutTooltip,
      ...rest
    } = props

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
      if (!shouldPropagateOnClick) {
        event.stopPropagation()
      }

      onClick?.(event)
    }

    const reallyDisabled = disabled || loading

    const child = (
      <>
        {children}
        {shortcut != null && (
          <Shortcut
            shortcut={shortcut}
            renderTooltip={renderShortcutTooltip}
            sentiment='inherit'
          />
        )}
      </>
    )

    if (url && !reallyDisabled) {
      return (
        <StyledButton
          {...rest}
          ref={ref}
          // Typing the ref is so hard that it's simply easier to type the "as" prop as any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          as={Link as any}
          url={url}
          external={external}
          onClick={handleClick}
        >
          {child}
        </StyledButton>
      )
    }

    return (
      <StyledButton
        ref={ref}
        type='button'
        {...rest}
        disabled={reallyDisabled}
        onClick={handleClick}
      >
        {loading ? (
          <>
            <span className='invisible flex gap-[var(--b-gap)] items-center'>
              {child}
            </span>
            <LoadingSolid className='animate-spin absolute inset-0 m-auto' />
          </>
        ) : (
          child
        )}
      </StyledButton>
    )
  }
)
