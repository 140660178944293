/**
 * Auto Generated
 */
import { type ClientZod } from '../../../utils/client-transform'
import type * as Server from '../../../server/models/agents/variables'

// Generated Types

export type AgentLabelSchema = ClientZod<typeof Server.AgentLabelSchema>
export type AgentVariableType = ClientZod<typeof Server.AgentVariableType>
export const AgentVariableType = ['TEXT', 'DOCUMENTS', 'LOCATION'] as const
export type AgentVariableTextSchema = ClientZod<
  typeof Server.AgentVariableTextSchema
>
export type AgentVariableDocumentsSchema = ClientZod<
  typeof Server.AgentVariableDocumentsSchema
>
export type AgentVariableLocationSchema = ClientZod<
  typeof Server.AgentVariableLocationSchema
>
export type AgentVariableSchema = ClientZod<typeof Server.AgentVariableSchema>
export type AgentVariableLocationType = ClientZod<
  typeof Server.AgentVariableLocationType
>
export const AgentVariableLocationType = [
  'NOTE',
  'FOLDER',
  'PROJECT',
  'WORKSPACE',
] as const
export type AgentVariableValueTextSchema = ClientZod<
  typeof Server.AgentVariableValueTextSchema
>
export type AgentVariableValueDocumentsSchema = ClientZod<
  typeof Server.AgentVariableValueDocumentsSchema
>
export type AgentVariableValueLocationSchema = ClientZod<
  typeof Server.AgentVariableValueLocationSchema
>
export type AgentVariableValueSchema = ClientZod<
  typeof Server.AgentVariableValueSchema
>
/**
 * Represents the enums used for the workflow (used for serialization)
 */
export type AgentVariableScopeTypeSchema = ClientZod<
  typeof Server.AgentVariableScopeTypeSchema
>
/**
 * Represents the enums used for the workflow (used for serialization)
 */
export const AgentVariableScopeTypeSchema = ['WORKFLOW', 'STEP'] as const
/**
 * Schema which is used when listing the workflow with the parameters required
 * to initialize a run of the workflow.  Each record represents an input variable
 * value that must be captured.
 */
export type AgentWorkflowInitVariableSchema = ClientZod<
  typeof Server.AgentWorkflowInitVariableSchema
>
