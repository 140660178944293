import { type EditorThemeClasses } from 'lexical'

export const LexicalTheme: EditorThemeClasses = {
  blockCursor: 'h-[2px] w-full -mt-[5px] mb-[3px] rounded bg-blue-200',
  code: `
    block w-full py-2 px-3 mb-2.5 rounded text-[90%]
    bg-semantic-neutral-surface-raised-bg-subtlest
    border border-semantic-neutral-border-light
  `,
  codeHighlight: {
    atrule: 'text-[#07a]',
    attr: 'text-[#690]',
    boolean: 'text-[#905]',
    builtin: 'text-[#690]',
    cdata: 'text-[slategray]',
    char: 'text-[#690]',
    class: 'text-[#DD4A68]',
    'class-name': 'text-[#DD4A68]',
    comment: 'text-[slategray]',
    constant: 'text-[#905]',
    deleted: 'text-[#905]',
    doctype: 'text-[slategray]',
    entity: 'text-[#9a6e3a]',
    function: 'text-[#DD4A68]',
    important: 'text-[#e90]',
    inserted: 'text-[#690]',
    keyword: 'text-[#07a]',
    namespace: 'opacity-70',
    number: 'text-[#905]',
    operator: 'text-[#9a6e3a]',
    prolog: 'text-[slategray]',
    property: 'text-[#905]',
    punctuation: 'text-[#999]',
    regex: 'text-[#e90]',
    selector: 'text-[#690]',
    string: 'text-[#690]',
    symbol: 'text-[#905]',
    tag: 'text-[#905]',
    url: 'text-[#9a6e3a]',
    variable: 'text-[#e90]',
  },
  hr: 'my-6',
  heading: {
    h1: 'text-3xl font-bold mb-2.5',
    h2: 'text-2xl font-bold mb-2.5',
    h3: 'text-xl font-bold mb-2.5',
    h4: 'text-lg font-bold mb-2.5',
    h5: 'text-base font-bold mb-2.5',
  },
  image: '',
  link: 'text-blue-500 cursor-pointer',
  list: {
    listitem: 'mt-1.5',
    nested: {
      listitem: 'list-none after:!hidden after:hidden !pl-4 no-count',
    },
    ol: '',
    olDepth: [
      'list-style-decimal mb-2.5',
      'list-style-alpha',
      'list-style-roman',
    ],
    ul: '',
    ulDepth: ['mb-2.5'],
    checklist: '!list-none',
    listitemChecked: 'list-checkbox list-checkbox-checked',
    listitemUnchecked: 'list-checkbox',
  },
  listItemNoteInfo: 'list-item-note-info',
  ltr: '',
  paragraph:
    '[&:not(:last-child)]:mb-2.5 relative after:content-[attr(data-placeholder)] after:absolute after:top-0 after:left-0 after:opacity-30',
  placeholder: '',
  quote:
    'relative pl-4 mb-2.5 before:absolute before:left-0 before:top-0 before:bottom-0 before:bg-neutral-200 before:w-1 before:rounded',
  rtl: '',
  text: {
    bold: '',
    code: 'bg-semantic-neutral-surface-raised-bg-subtlest border border-semantic-neutral-border-light text-[90%] pt-0.5 pb-px px-1 rounded-sm',
    hashtag: '',
    italic: 'italic',
    overflowed: '',
    strikethrough: 'line-through',
    underline: 'underline',
    underlineStrikethrough: '[text-decoration:underline_line-through]',
  },
  table:
    'w-fit table-fixed rounded border border-semantic-neutral-border-default border-separate border-spacing-0 overflow-hidden text-[14px]',
  tableCell:
    '[&_*]:mb-0 text-left align-top border-l border-semantic-neutral-border-default first:border-l-0 border-t py-1.5 px-2 text-[14px]',
  tableCellHeader:
    'border-t-0 bg-semantic-neutral-surface-raised-bg-subtle text-[14px]',
  tableCellResizer: '',
  tableCellSelected: '!bg-semantic-primary-bg-active-default',
  tableScrollableWrapper:
    'relative pb-2.5 overflow-y-auto w-[var(--editor-overflow-width)] px-[var(--editor-overflow-padding)] -ml-[var(--editor-overflow-padding)] ',
  tableSelected: '',
  tableSelection: '',
  embedBlock: {
    base: 'mb-2.5',
  },
  collapsible: {
    container: 'collapsible-container',
    content: 'collapsible-content',
    title: 'collapsible-title',
  },
  mark: 'note-comment-mark',
  title: 'font-bold text-4xl leading-snug',
}
