import { classed } from '@motion/theme'

export const Section = classed('div', {
  base: `
    flex items-center px-2 py-1.5 gap-0.5
    bg-dropdown-bg
    border border-semantic-neutral-bg-subtle
    rounded-lg shadow-sm
`,
})

export const Divider = classed('div', {
  base: `h-full mx-1 w-px bg-dropdown-border`,
})
