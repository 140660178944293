export const INDIVIDUAL_MONTHLY_PRICE = 34
export const INDIVIDUAL_ANNUAL_PRICE = 228
export const INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY =
  INDIVIDUAL_ANNUAL_PRICE / 12
export const INDIVIDUAL_ANNUAL_PLAN_SAVINGS_ABSOLUTE =
  INDIVIDUAL_MONTHLY_PRICE * 12 - INDIVIDUAL_ANNUAL_PRICE
export const INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT = 44
export const OVERDUE_SUBSCRIPTION_STATES = ['unpaid', 'past_due']
export const TEAM_BILLING_BUCKETS = [3, 5, 10, 15, 20, 25, 30, 40, 50]
export const TEAM_MINIMUM_BUCKET_SEATS = 3
export const TEAM_DEFAULT_BUCKET_SEATS = 5

export const MAX_TIERED_PRICING_SELF_SERVE_SIZE = 15
export const BASIC_TIER_SEAT_LIMIT = 10
// Deprecate MAX_PRO_TEAM_INITIAL_SIZE once we go to tiered pricing
export const MAX_PRO_TEAM_INITIAL_SIZE = 15
export const MAX_PRO_TEAM_UPGRADE_SIZE = 25

export const LOW_COST_TRIAL_PRICE = 4.95

export const Term = {
  Annual: 'Annual',
  Monthly: 'Monthly',
  LowCostTrial: 'LowCostTrial',
} as const
export type Term = (typeof Term)[keyof typeof Term]

export type TierBillingPrices = {
  team: BillingPrices
  individual: BillingPrices
}

export type BillingPrices = {
  annualPrice: number
  monthlyPrice: number
  annualPricePerMonth: number
  annualSavingsPercent: number
  annualSavingsPercentInteger: number
}

export const INDIVIDUAL_PRICES: BillingPrices = {
  annualPrice: INDIVIDUAL_ANNUAL_PRICE,
  monthlyPrice: INDIVIDUAL_MONTHLY_PRICE,
  annualPricePerMonth: INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY,
  annualSavingsPercent: INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT,
  annualSavingsPercentInteger: Math.round(
    INDIVIDUAL_ANNUAL_PLAN_SAVINGS_PERCENT
  ),
}

export type PlanType = 'Individual' | 'Team' | 'Enterprise'

export const PlanNames: Record<PlanType, string> = {
  Individual: 'Individual',
  Team: 'Business Standard',
  Enterprise: 'Business Pro',
}
