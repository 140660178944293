import { $createCollapsibleHeadingNode } from '@motion/notes-shared'

import { $getSelection, $isRangeSelection } from 'lexical'

import { type InsertCollapsibleHeadingCommandPayload } from '../collapsible-heading-plugin'

export function $handleInsertCollapsibleHeadingCommand(
  payload: InsertCollapsibleHeadingCommandPayload
) {
  const titleNode = $createCollapsibleHeadingNode(payload.heading)

  const selection = $getSelection()

  if ($isRangeSelection(selection)) {
    const node = selection.anchor.getNode()

    node.replace(titleNode)

    titleNode.select()

    return true
  }

  return false
}
