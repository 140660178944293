import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import type { LexicalCommand, LexicalEditor } from 'lexical'
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_NORMAL,
  DELETE_CHARACTER_COMMAND,
  DELETE_LINE_COMMAND,
  INDENT_CONTENT_COMMAND,
  KEY_TAB_COMMAND,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical'
import { useEffect } from 'react'

import { $handleDelete } from './handlers/handle-list-item-delete'
import { $handleOutdentContentCommand } from './handlers/handle-outdent-content-command'
import { $handleIndentAndOutdent, $handleIndentFirstBlock } from './utils'

export const MAX_INDENT_LEVEL = 16

function registerTabIndentation(editor: LexicalEditor) {
  return mergeRegister(
    editor.registerCommand<KeyboardEvent>(
      KEY_TAB_COMMAND,
      (event) => {
        const selection = $getSelection()
        if (!$isRangeSelection(selection)) {
          return true
        }

        event.preventDefault()
        const command: LexicalCommand<void> | null = event.shiftKey
          ? OUTDENT_CONTENT_COMMAND
          : INDENT_CONTENT_COMMAND

        return editor.dispatchCommand(command, undefined)
      },
      COMMAND_PRIORITY_EDITOR
    ),
    editor.registerCommand<KeyboardEvent>(
      INDENT_CONTENT_COMMAND,
      () => {
        const canIndent = $handleIndentFirstBlock()

        if (!canIndent) {
          return true
        }

        return $handleIndentAndOutdent((block) => {
          const indent = block.getIndent()
          block.setIndent(indent + 1)
        })
      },
      COMMAND_PRIORITY_LOW
    ),
    editor.registerCommand<KeyboardEvent>(
      OUTDENT_CONTENT_COMMAND,
      () => {
        return $handleIndentAndOutdent((block) => {
          const indent = block.getIndent()
          if (indent > 0) {
            block.setIndent(indent - 1)
          } else {
            $handleOutdentContentCommand()
          }
        }, true)
      },
      COMMAND_PRIORITY_LOW
    ),
    editor.registerCommand(
      DELETE_CHARACTER_COMMAND,
      (isBackward) => {
        if (!isBackward) {
          return false
        }

        return $handleDelete(editor)
      },
      COMMAND_PRIORITY_NORMAL
    ),
    editor.registerCommand(
      DELETE_LINE_COMMAND,
      (isBackward) => {
        if (!isBackward) {
          return false
        }
        return $handleDelete(editor)
      },
      COMMAND_PRIORITY_NORMAL
    )
  )
}

/**
 * This plugin adds the ability to indent list items only one level
 * more than the node above them
 */
export function TabIndentationPlugin(): null {
  const [editor] = useLexicalComposerContext()
  useEffect(() => {
    return registerTabIndentation(editor)
  }, [editor])

  return null
}
