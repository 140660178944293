import { TextField } from '@motion/ui/forms'

import { useEffect, useRef, useState } from 'react'

export const ClipBoardInspector = () => {
  const [results, setResults] = useState<{ type: string; data: string }[]>([])
  const pasteRef = useRef<HTMLLabelElement | null>(null)

  useEffect(() => {
    const el = pasteRef.current
    if (!el) return
    const handlePaste = (evt: ClipboardEvent) => {
      const { clipboardData } = evt
      if (!clipboardData) return

      const mimeTypesToCheck = clipboardData.types
        .map((type) => {
          const data = clipboardData.getData(type)

          if (!data) return

          if (type === 'text/html') {
            return { type, data: data.replace(/></g, '>\n\n<') }
          }
          return { type, data }
        })
        .filter(Boolean)
      setResults(mimeTypesToCheck)
    }
    el.addEventListener('paste', handlePaste)
    return () => {
      el.removeEventListener('paste', handlePaste)
    }
  }, [setResults])

  return (
    <div className='pb-12 pr-3'>
      <TextField
        ref={pasteRef}
        multiline
        autoSize={{ minRows: 2, maxRows: 2 }}
        placeholder='Paste your content here'
      />

      {results.map(({ type, data }) => (
        <div
          key={type}
          className='bg-semantic-neutral-bg-subtle rounded p-2 mt-3'
        >
          <b>{type}</b>
          <pre className='text-xs whitespace-pre-wrap'>{data}</pre>
        </div>
      ))}
    </div>
  )
}
