import { addClassNamesToElement } from '@lexical/utils'
import {
  $applyNodeReplacement,
  DecoratorNode,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical'

import { createElement, pxSize } from '../../../utils/dom'
import { ParsingContext } from '../../../utils/parsing-context'

const SRC_ATTRIBUTE = 'data-lexical-src-url'
const WIDTH_ATTRIBUTE = 'data-lexical-width'
const TYPE_ATTRIBUTE = 'data-lexical-type'

export type EmbedSrcType = 'youtube' | 'bookmark' | 'video-player'

export type SerializedEmbedNode = Spread<
  {
    src: string
    width: number
    srcType: EmbedSrcType
  },
  SerializedLexicalNode
>

export class EmbedNode<T = void> extends DecoratorNode<T> {
  __src: string
  __srcType: EmbedSrcType
  __width: number

  render(
    nodeKey: NodeKey,
    src: string,
    srcType: EmbedSrcType,
    width: number
  ): T {
    throw new Error('EmbedNode.render method not implemented')
  }

  static getType(): string {
    return 'custom-embed'
  }

  static clone(node: EmbedNode): EmbedNode {
    return new EmbedNode(node.__src, node.__srcType, node.__width, node.__key)
  }

  constructor(
    src: string,
    srcType: EmbedSrcType,
    width?: number,
    key?: NodeKey
  ) {
    super(key)
    this.__src = src
    this.__width = width ?? 0
    this.__srcType = srcType
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = document.createElement('div')
    addClassNamesToElement(element, config.theme.embedBlock?.base)
    return element
  }

  updateDOM(): false {
    return false
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute(SRC_ATTRIBUTE)) {
          return null
        }
        return {
          conversion: $convertEmbedElement,
          priority: 2,
        }
      },
    }
  }

  exportDOM(): DOMExportOutput {
    if (ParsingContext.current.mode === 'publish') {
      return this.exportPublishDOM()
    }

    return this.exportDOMDefault()
  }

  private exportDOMDefault(): DOMExportOutput {
    const element = document.createElement('div')
    element.setAttribute(SRC_ATTRIBUTE, this.__src)
    element.setAttribute(WIDTH_ATTRIBUTE, this.__width.toString())
    element.setAttribute(TYPE_ATTRIBUTE, this.__srcType)
    return { element }
  }

  private exportPublishDOM(): DOMExportOutput {
    if (this.__srcType === 'video-player') {
      const element = createElement('video', {
        src: processUrl(this.__src).uri,
        controls: true,
        width: pxSize(this.__width),
        'data-embed-src-type': this.__srcType,
        'data-lexical-node': this.getType(),
      })

      return { element }
    }

    if (this.__srcType === 'youtube') {
      const el = document.createElement('lite-youtube')
      el.setAttribute('videoid', parseYoutubeId(this.__src) ?? this.__src)
      return { element: el }
    }

    return this.exportDOMDefault()
  }

  static importJSON(serializedNode: SerializedEmbedNode): EmbedNode {
    return $createEmbedNode(
      serializedNode.src,
      serializedNode.srcType,
      serializedNode.width
    ).updateFromJSON(serializedNode)
  }

  exportJSON(): SerializedEmbedNode {
    return {
      ...super.exportJSON(),
      src: this.__src,
      width: this.__width,
      type: this.getType(),
      srcType: this.__srcType,
      version: 1,
    }
  }

  setWidth(width: number): void {
    const writable = this.getWritable()
    writable.__width = width
  }

  setSrc(src: string): void {
    const writable = this.getWritable()
    writable.__src = src
  }

  getSrcType(): EmbedSrcType {
    return this.__srcType
  }

  isInline(): false {
    return false
  }

  decorate(): T {
    return this.render(this.__key, this.__src, this.__srcType, this.__width)
  }
}

export function $isEmbedNode(
  node: LexicalNode | null | undefined
): node is EmbedNode {
  return node instanceof EmbedNode
}

export function $createEmbedNode(
  src: string,
  srcType: EmbedSrcType,
  width?: number
): EmbedNode {
  return $applyNodeReplacement(new EmbedNode(src, srcType, width))
}

export function $convertEmbedElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const src = domNode.getAttribute(SRC_ATTRIBUTE)
  const width = domNode.getAttribute(WIDTH_ATTRIBUTE)
  const srcType = domNode.getAttribute(TYPE_ATTRIBUTE) as EmbedSrcType
  if (src !== null && width !== null && srcType !== null) {
    const node = $createEmbedNode(src, srcType, parseFloat(width))
    return { node }
  }
  return null
}

const PARSE_SIGNED_URL_RE = /recording\/([a-f0-9-]{36})\/signed$/i
function processUrl(uri: string, ctx: ParsingContext = ParsingContext.current) {
  if (ctx?.mode !== 'publish') return { type: 'direct', uri }
  const match = uri.match(PARSE_SIGNED_URL_RE)
  if (match != null) {
    const id = match[1]
    return { type: 'recording', uri: `./recordings/${id}` }
  }
  return { type: 'direct', uri }
}

/*
  The following regular expression to extract the youtube id was taken from
  https://github.com/delucis/astro-embed/blob/main/packages/astro-embed-youtube/matcher.ts
*/
const YOUTUBE_ID_RE =
  /(?=(\s*))\1(?:<a [^>]*?>)??(?=(\s*))\2(?:https?:\/\/)??(?:w{3}\.)??(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/|shorts\/)??([A-Za-z0-9-_]{11})(?:[^\s<>]*)(?=(\s*))\4(?:<\/a>)??(?=(\s*))\5/

function parseYoutubeId(url: string) {
  const match = url.match(YOUTUBE_ID_RE)
  return match?.[3]
}
