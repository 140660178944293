import { createUseQuery } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'
import { makeBillingPrices, Term } from '@motion/ui-logic/billing'
import { type Tier } from '@motion/ui-logic/tiered-pricing'
import { useHasTreatment } from '@motion/web-common/flags'

const useGetTierPricesQuery = createUseQuery(API.subscriptions.getTierPrices)
const useGetTierPricesUnauthorizedQuery = createUseQuery(
  API.subscriptions.getTierPricesUnauthorized
)

/**
 * Fetches tier price from the backend, returned as $/month value
 */
export const useGetTierPrice = (
  bucket: number,
  term: Term,
  tier?: Tier,
  isMobile: boolean = false
): { price?: number; isLoading: boolean } => {
  const { data: allTierPrices, isLoading } = useGetTierPricesQuery(undefined, {
    enabled: useHasTreatment('tiered-pricing-v1') && !!tier && !isMobile,
  })

  if (!tier || isLoading || !allTierPrices) {
    return { price: undefined, isLoading }
  }

  const tierPrices = allTierPrices.tiers.find((prices) => prices.tier === tier)
  if (!tierPrices) {
    return { price: undefined, isLoading }
  }

  const bucketTierPrices = bucket > 1 ? tierPrices.team : tierPrices.individual
  const price =
    term === Term.Annual || term === Term.LowCostTrial
      ? bucketTierPrices.annual / 12
      : bucketTierPrices.monthly

  return { price, isLoading }
}

/**
 * Fetches tier prices from the backend without an authorized user
 */
export const useGetTierPricesUnauthorized = (
  bucket: number,
  term: Term,
  tier?: Tier,
  email?: string
) => {
  const result = useGetTierPricesUnauthorizedQuery(
    { email: email ?? '' },
    {
      enabled: useHasTreatment('tiered-pricing-v1') && !!email,
    }
  )

  const tierPrices = result.data?.tiers.find((prices) => prices.tier === tier)
  if (!tierPrices) {
    return { price: undefined, isLoading: result.isLoading }
  }

  const bucketTierPrices = bucket > 1 ? tierPrices.team : tierPrices.individual
  const price =
    term === Term.Annual || term === Term.LowCostTrial
      ? bucketTierPrices.annual / 12
      : bucketTierPrices.monthly

  return { price, isLoading: result.isLoading }
}

export const useGetTierPrices = (tier: Tier) => {
  const result = useGetTierPricesQuery(undefined, {
    enabled: useHasTreatment('tiered-pricing-v1'),
  })
  const tierPrices = result.data?.tiers.find((prices) => prices.tier === tier)
  return {
    data: tierPrices
      ? {
          team: makeBillingPrices(
            tierPrices.team?.monthly ?? 0,
            tierPrices.team?.annual ?? 0
          ),
          individual: makeBillingPrices(
            tierPrices.individual?.monthly ?? 0,
            tierPrices.individual?.annual ?? 0
          ),
        }
      : undefined,
    isLoading: result.isLoading,
  }
}

export const useGetTierAnnualDollarSavings = (
  tier: Tier | undefined,
  isTeam: boolean
): number => {
  const { data: prices, isLoading } = useGetTierPrices(tier ?? 'BASIC')
  if (isLoading || !prices) {
    return 0
  }
  const termPrices = isTeam ? prices.team : prices?.individual
  return Math.max(
    12 * (termPrices.monthlyPrice - termPrices.annualPricePerMonth),
    0
  )
}
