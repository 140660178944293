import {
  $isCollapsibleHeaderListItem,
  $isCollapsibleHeadingContentNode,
  $isCollapsibleHeadingNode,
  $isCollapsibleListItemNode,
  CollapsibleListItemNode,
  LIST_TOGGLE_COLLAPSE_COMMAND,
} from '@motion/notes-shared'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $dfs, $findMatchingParent, mergeRegister } from '@lexical/utils'
import {
  COMMAND_PRIORITY_CRITICAL,
  DROP_COMMAND,
  type LexicalNode,
} from 'lexical'
import { useCallback, useEffect, useRef } from 'react'

import { createCollapsibleToggleElement } from './utils'

import { useEditorContext } from '../../context'

const $isInsideCollapsedHeadingContentNode = (node: LexicalNode) => {
  const prevSibling = node.getPreviousSibling()
  return (
    $isCollapsibleHeadingContentNode(node) &&
    $isCollapsibleHeadingNode(prevSibling) &&
    !prevSibling.getOpen()
  )
}

const $isInsideCollapsedListNode = (node: LexicalNode) => {
  if (!$isCollapsibleListItemNode(node)) return false

  const prevSibling = node.getPreviousSibling()
  if (!prevSibling || !$isCollapsibleListItemNode(prevSibling)) return false

  return !prevSibling.getOpen()
}

export const CollapsibleArrowButtonsPlugin = () => {
  const [editor] = useLexicalComposerContext()
  const { floatingAnchorElem } = useEditorContext()
  const nodeElementsRef = useRef(
    new Map<string, { motionId: string; button: HTMLButtonElement }>()
  )

  const recalculateCollapsibleToggles = useCallback(() => {
    editor.getEditorState().read(() => {
      if (!floatingAnchorElem) return

      const headingNodes = $dfs().flatMap(({ node }) =>
        $isCollapsibleHeadingNode(node) ||
        ($isCollapsibleListItemNode(node) && $isCollapsibleHeaderListItem(node))
          ? [node]
          : []
      )

      const container = floatingAnchorElem.getBoundingClientRect()

      const newNodeMap = new Map<
        string,
        { motionId: string; button: HTMLButtonElement }
      >()

      for (const headingNode of headingNodes) {
        const collapsed = $findMatchingParent(headingNode, (node) => {
          const isCloseHeadingNode = $isInsideCollapsedHeadingContentNode(node)
          const isCloseListNode = $isInsideCollapsedListNode(node)
          return isCloseHeadingNode || isCloseListNode
        })
        if (collapsed) continue

        const key = headingNode.getKey()
        const motionId = headingNode.getMotionId()
        const domElement = editor.getElementByKey(key)

        if (!domElement) return

        let buttonEntry = nodeElementsRef.current.get(key)

        if (!buttonEntry) {
          const button = createCollapsibleToggleElement({
            onToggle: () =>
              editor.dispatchCommand(LIST_TOGGLE_COLLAPSE_COMMAND, motionId),
          })

          floatingAnchorElem.appendChild(button)
          button.style.position = 'absolute'
          button.style.top = '0'
          buttonEntry = { motionId, button }
          nodeElementsRef.current.set(key, buttonEntry)
        }

        const DATA_ATTRIBUTE_EXPANDED = 'data-expanded'
        if (headingNode.getOpen()) {
          buttonEntry.button.setAttribute(DATA_ATTRIBUTE_EXPANDED, 'true')
        } else {
          buttonEntry.button.setAttribute(DATA_ATTRIBUTE_EXPANDED, 'false')
        }

        const rect = domElement.getBoundingClientRect()
        const targetStyle = window.getComputedStyle(domElement)

        const buttonHeight = 24
        // / top left
        let targetCalculateHeight: number = parseInt(targetStyle.lineHeight, 10)

        if (isNaN(targetCalculateHeight)) {
          // middle
          targetCalculateHeight = rect.bottom - rect.top
        }
        const top =
          rect.top + (targetCalculateHeight - buttonHeight) / 2 - container.top

        const translateX = rect.left - container.left - 26
        const translateY = top

        buttonEntry.button.style.transform = `translate(${translateX}px, ${translateY}px)`

        newNodeMap.set(key, buttonEntry)
      }

      nodeElementsRef.current.forEach((entry, key) => {
        if (!newNodeMap.has(key)) {
          entry.button.remove()
        }
      })

      nodeElementsRef.current = newNodeMap
    })
  }, [editor, floatingAnchorElem])

  useEffect(() => {
    const element = floatingAnchorElem
    if (!element) return

    const resizeObserver = new ResizeObserver(() => {
      recalculateCollapsibleToggles()
    })

    resizeObserver.observe(element)

    const cleanupUpdateListener = mergeRegister(
      editor.registerMutationListener(CollapsibleListItemNode, () => {
        recalculateCollapsibleToggles()
      }),
      editor.registerCommand(
        DROP_COMMAND,
        () => {
          setTimeout(() => recalculateCollapsibleToggles(), 5)
          return false
        },
        COMMAND_PRIORITY_CRITICAL
      )
    )

    return () => {
      cleanupUpdateListener()
      resizeObserver.disconnect()
    }
  }, [editor, recalculateCollapsibleToggles, floatingAnchorElem])

  return null
}
