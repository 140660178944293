import { defineMutation } from '@motion/rpc'
import {
  AgentWorkflowV2SingleResponse,
  CreateAgentWorkflowRequest,
  type RunAgentWorkflowRequest,
} from '@motion/zod/client'

export const runAgentWorkflow = defineMutation<
  RunAgentWorkflowRequest,
  void
>().using({
  method: 'POST',
  uri: (args) => `${__AI_API_HOST__}/agents/run`,
})

export const createAgentWorkflow = defineMutation<
  CreateAgentWorkflowRequest & { workspaceId: string },
  AgentWorkflowV2SingleResponse
>().using({
  method: 'POST',
  uri: (args) =>
    `${__AI_API_HOST__}/beta/workspaces/${args.workspaceId}/agents/create`,
  body: ({ workspaceId, ...data }) => data,
})
