import { $getEditor, LexicalNode } from 'lexical'

import { MotionId } from '../types'

function $isMotionNode(node: LexicalNode): node is LexicalNode & {
  getMotionId(): MotionId
} {
  return 'getMotionId' in node && typeof node.getMotionId === 'function'
}

export function $getNodeByMotionId<T extends LexicalNode>(
  motionId: MotionId
): T | null {
  const editor = $getEditor()

  const editorState = editor.getEditorState()

  const node = Array.from(editorState._nodeMap.values())
    .filter($isMotionNode)
    .find((node) => node.getMotionId() === motionId)

  if (node === null) {
    return null
  }

  return node as unknown as T
}
