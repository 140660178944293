import { ACTIVE_SUBSCRIPTION_STATUSES } from './constants'

export const isActiveStatus = (status?: string) => {
  if (!status) return false
  return ACTIVE_SUBSCRIPTION_STATUSES.includes(status)
}

export const quantityFromSubscription = (subscription: {
  items: { data: { quantity?: number }[] }
}): number =>
  subscription.items.data.reduce((acc, item) => acc + (item.quantity ?? 0), 0)

export const isSubscriptionMonthly = (subscription: {
  items?: { data: { plan: { interval: string } }[] }
}): boolean => subscription.items?.data[0]?.plan.interval === 'month'
