import { classed } from '@motion/theme'

import { type ReactNode } from 'react'

export type EditorIconProps = {
  children: ReactNode
}

export function EditorIcon({ children }: EditorIconProps) {
  if (!children) {
    return null
  }

  return (
    <Container>
      <IconWrapper className='h-auto'>{children}</IconWrapper>
    </Container>
  )
}

export const Container = classed('div', {
  base: 'flex flex-row justify-center mt-8',
})

export const IconWrapper = classed('div', {
  base: 'relative h-full w-full  max-w-[min(84ch,100%)] sm:max-w-[min(84ch,100%-136px)]',
})
