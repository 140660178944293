import {
  HeadingNode as LexicalHeadingNode,
  HeadingTagType,
  type SerializedHeadingNode,
} from '@lexical/rich-text'
import { DOMConversionMap, NodeKey } from 'lexical'
import { v4 } from 'uuid'

import { MotionId, SerializedMotionNode } from '../../../types'

export type SerializedMotionHeadingNode = SerializedMotionNode &
  SerializedHeadingNode

export class MotionHeadingNode extends LexicalHeadingNode {
  __motionId: MotionId

  static getType() {
    return 'motion-heading'
  }

  static clone(node: MotionHeadingNode) {
    return new MotionHeadingNode(
      node.getTag(),
      node.getMotionId(),
      node.getKey()
    )
  }

  constructor(tag: HeadingTagType, motionId: MotionId = v4(), key?: NodeKey) {
    super(tag, key)
    this.__motionId = motionId
  }

  exportJSON(): SerializedMotionHeadingNode {
    return {
      ...super.exportJSON(),
      motionId: this.__motionId,
    }
  }

  static importDOM(): DOMConversionMap | null {
    return super.importDOM()
  }

  static importJSON(
    serializedNode: SerializedMotionHeadingNode
  ): MotionHeadingNode {
    return new MotionHeadingNode(serializedNode.tag, serializedNode.motionId)
  }

  getMotionId() {
    const self = this.getLatest()
    return self.__motionId
  }
}

export function $createMotionHeadingNode(tag: HeadingTagType) {
  return new MotionHeadingNode(tag)
}
