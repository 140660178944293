import { type ReactNode } from 'react'

import { FieldLabel, type FieldLabelProps } from './field-label'

import {
  PopoverButton,
  type PopoverButtonProps,
  PopoverTrigger,
  SearchableList,
} from '../base'

type SelectOption<ValueType extends string> = {
  content: ReactNode
  value: ValueType
}

export type SelectProps<ValueType extends string> = {
  label: FieldLabelProps['label']
  labelHidden?: FieldLabelProps['labelHidden']
  options: SelectOption<ValueType>[]
  value: ValueType
  onChange: (value: ValueType) => void
  size?: PopoverButtonProps['size']
}

export function Select<ValueType extends string>({
  label,
  labelHidden,
  options,
  value,
  onChange,
  size,
}: SelectProps<ValueType>) {
  const selectedValue = options.find((v) => v.value === value)

  return (
    <FieldLabel label={label} labelHidden={labelHidden}>
      <div className='not-sr-only'>
        <PopoverTrigger
          placement='bottom-start'
          renderPopover={({ close }) => {
            return (
              <SearchableList
                searchable={false}
                items={options}
                renderItem={(item) => item.content}
                computeKey={(item) => item.value}
                computeSelected={(item) => value === item.value}
                onSelect={(item) => {
                  onChange(item.value)
                  close()
                }}
              />
            )
          }}
        >
          <PopoverButton size={size}>{selectedValue?.content}</PopoverButton>
        </PopoverTrigger>
      </div>

      <div aria-hidden='true' className='sr-only'>
        <select
          tabIndex={-1}
          value={value}
          onChange={(ev) => onChange(ev.target.value as ValueType)}
        >
          {options.map((opt) => {
            return (
              <option key={opt.value} value={opt.value}>
                {opt.content}
              </option>
            )
          })}
        </select>
      </div>
    </FieldLabel>
  )
}
