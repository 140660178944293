/* c8 ignore start */

import { AutoScheduleSetting } from '@motion/shared/common'
import { createNoneId } from '@motion/shared/identifiers'
import { Sentry } from '@motion/web-base/sentry'
import {
  type LabelSchema,
  type ProjectDefinitionSchema,
  type StageDefinitionSchema,
  type StatusSchema,
  type UserInfoSchema,
  type WorkspaceSchema,
} from '@motion/zod/client'

import { type EntryValue, type ModelCache } from './types'

const SYMBOL_PENDING = Symbol('pending')
const SYMBOL_NO_ACCESS = Symbol('no access')

export function isPending<T>(value: T): boolean {
  // @ts-expect-error - hidden property
  return value[SYMBOL_PENDING] === true || value['__pending__'] === true
}

export function isNoAccess<T>(value: T): boolean {
  // @ts-expect-error - hidden property
  return value[SYMBOL_NO_ACCESS] === true || value['__no_access__'] === true
}

export function createPendingStatus(id: string): StatusSchema {
  return applyPending({
    id,
    color: '#c1c8cd',
    name: 'Pending ...',
    deleted: false,
    isDefaultStatus: false,
    isResolvedStatus: false,
    isSystemStatus: true,
    sortPosition: '',
    workspaceId: '',
    autoScheduleEnabled: false,
    type: null,
    autoScheduleSetting: AutoScheduleSetting.DISABLED,
  })
}

export function createPendingLabel(id: string): LabelSchema {
  return applyPending({
    id,
    name: 'Pending ...',
    deleted: false,
    sortPosition: '',
    color: 'grey',
    workspaceId: '',
  })
}

export function createNoAccessWorkspace(id: string): WorkspaceSchema {
  return applyNoAccess({
    id,
    name: 'Restricted Workspace',
    labelIds: [],
    members: [],
    projectIds: [],
    statusIds: [],
    teamId: null,
    isPersonalWorkspace: false,
    type: 'INDIVIDUAL',
    uniquenessId: id,
    customFieldIds: [],
    projectDefinitionIds: [],
    legacyProjectTemplateIds: [],
  })
}

export function createUnknownUser(id: string): UserInfoSchema {
  return {
    id,
    email: 'Unknown_User',
    name: 'Unknown',
    isPlaceholder: false,
    hasActiveSubscription: false,
    deleted: false,
    picture: null,
    onboardingComplete: true,
  }
}

type WorkspaceStageDefinition = StageDefinitionSchema & {
  workspaceId: WorkspaceSchema['id']
  projectDefinitionId: ProjectDefinitionSchema['id']
}

export function createUnknownLegacyStage(
  id: string | null | undefined
): WorkspaceStageDefinition {
  return {
    id: createNoneId(id ?? ''),
    name: 'Unknown Stage',
    color: 'gray',
    deadlineIntervalDays: 0,
    duration: {
      unit: 'DAYS',
      value: 0,
    },
    tasks: [],
    workspaceId: '',
    projectDefinitionId: '',
    variables: [],
  }
}

export function createUnknownStage(
  id: string | null | undefined
): StageDefinitionSchema {
  return {
    id: createNoneId(id ?? ''),
    name: 'Unknown Stage',
    color: 'gray',
    deadlineIntervalDays: 0,
    duration: {
      unit: 'DAYS',
      value: 0,
    },
    workspaceId: '',
    tasks: [],
    variables: [],
  }
}

function applyPending<T>(obj: T): T {
  return Object.defineProperties(obj, {
    [SYMBOL_PENDING]: {
      enumerable: true,
      writable: false,
      value: true,
    },
    __pending__: {
      enumerable: true,
      writable: false,
      value: true,
    },
  })
}

function applyNoAccess<T>(obj: T): T {
  return Object.defineProperties(obj, {
    [SYMBOL_NO_ACCESS]: {
      enumerable: true,
      writable: false,
      value: true,
    },
    __no_access__: {
      enumerable: true,
      writable: false,
      value: true,
    },
  })
}

export function getUnknown<T extends keyof ModelCache>(
  type: T,
  id: string
): EntryValue<T> | undefined {
  if (type === 'workspaces') {
    // @ts-expect-error - is fine
    return createNoAccessWorkspace(id)
  }

  if (type === 'labels') {
    // @ts-expect-error - is fine
    return createPendingLabel(id)
  }
  if (type === 'statuses') {
    // @ts-expect-error - is fine
    return createPendingStatus(id)
  }
  if (type === 'users') {
    // @ts-expect-error - is fine
    return createUnknownUser(id)
  }
  if (type === 'stageDefinitions') {
    // @ts-expect-error - is fine
    return createUnknownStage(id)
  }

  if (!isLegacyGhostTask(id)) {
    Sentry.captureException(`[${type}] Unknown lookup id`, {
      level: 'warning',
      extra: {
        id,
      },
      fingerprint: ['unknown-lookup-id', type],
    })
  }

  return undefined
}

const isLegacyGhostTask = (id: string) => id.endsWith('|<ghost>')
