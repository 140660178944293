import {
  $createCollapsibleHeadingContentNode,
  $isCollapsibleHeadingContentNode,
  $isCollapsibleHeadingNode,
} from '@motion/notes-shared'

import { $getNearestBlockElementAncestorOrThrow } from '@lexical/utils'
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isRootNode,
} from 'lexical'

export function $handleInsertParagraphCommand() {
  const selection = $getSelection()

  if (!$isRangeSelection(selection)) {
    return false
  }

  const node = selection.anchor.getNode()

  if ($isRootNode(node)) {
    return false
  }

  const blockNode = $getNearestBlockElementAncestorOrThrow(node)
  const nextNode = blockNode.getNextSibling()

  if (!$isCollapsibleHeadingNode(blockNode)) {
    return false
  }

  const paragraphNode = $createParagraphNode()

  if ($isCollapsibleHeadingContentNode(nextNode)) {
    const collapsibleContentNodeFirstChild = nextNode.getFirstChild()

    if (collapsibleContentNodeFirstChild != null) {
      collapsibleContentNodeFirstChild.insertBefore(paragraphNode)
    } else {
      nextNode.append(paragraphNode)
    }

    paragraphNode.selectStart()
  } else {
    const collapsibleContentNode = $createCollapsibleHeadingContentNode()

    blockNode.insertAfter(collapsibleContentNode)

    collapsibleContentNode.append(paragraphNode)

    paragraphNode.selectStart()
  }

  return true
}
