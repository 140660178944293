import {
  type DatetimeSchema,
  type TaskCreationSchema,
  type ViewCreationSchema,
  type AiPwtRequestSchema,
  type AiPwtResponseSchema,
  type AiInferProjectParametersRequestSchema,
  type AiInferProjectParametersResponseSchema,
  type AiGenerateProjectRequestSchema,
  type AiGenerateProjectResponseSchema,
  type CommitTaskUpdateSchema,
  type CommitTaskCreateSchema,
  type TaskModificationsSchema,
  type AiCreateProjectCommitRequestSchema,
  type CreateApiKeyResponseSchema,
  type BaseApiKeySchema,
  type ApiKeySchema,
  type ApiKeyListResponseSchema,
  type GetUsageSchema,
  type ApiUsageSchema,
  type CreateBlockingTimeslotSchema,
  type UpdateBlockingTimeslotSchema,
  type DeleteManyBlockingTimeslotsSchema,
  type BlockingTimeslotResponseSchema,
  type FetchBlockingTimeslotsResponseSchema,
  type CancelBookingDtoSchema,
  type RecheduleBookingSchema,
  type ManualLinkCreateSchema,
  type DeleteTemplateLinkSchema,
  type ReorderTemplateSchema,
  type SetUrlPrefixSchema,
  type UpdateBookingSettingsSchema,
  type UpdateRichAvailabilityMessageSchema,
  type CreateCalendarSchema,
  type UpdateCalendarSchema,
  type UpdateCalendarsSchema,
  type OldCalendarSchema,
  type GetAllCalendarsSchema,
  type CalendarResponseSchema,
  type CalendarsResponseSchema,
  type EditTaskCommentSchema,
  type CreateTaskCommentSchema,
  type CustomFieldGetAll,
  type CreateCustomFieldSchema,
  type SingleCustomFieldResponse,
  type MultipleCustomFieldResponse,
  type UpdateCustomFieldSchema,
  type GetCustomFieldCategories,
  type CopyCustomFieldToWorkspaceSchema,
  type CreateProjectDefinitionRequestSchema,
  type GetSingleProjectDefinitionResponseSchema,
  type GetProjectDefinitionsResponseSchema,
  type UpdateProjectDefinitionRequestSchema,
  type CopyProjectDefinitionSchema,
  type CreateStageDefinitionRequestSchema,
  type UpdateStageDefinitionRequestSchema,
  type UpdateManyStageDefinitionRequestSchema,
  type GetSingleStageDefinitionResponseSchema,
  type GetManyStageDefinitionsResponseSchema,
  type UpdateStageDeadlineRequestSchema,
  type CreateLegacyProjectShimResponseSchema,
  type FetchReadyDisputesResponseSchema,
  type EarlyFraudWarningsResponseSchema,
  type AddEmailAccountSchema,
  type EmailAccountResponseSchema,
  type CreateLabelSchema,
  type UpdateLabelSchema,
  type BatchMetricSchema,
  type UpdateNotificationPreferencesSchema,
  type SavePushDeviceSchema,
  type OnLoginResponseSchema,
  type SendEmailSchema,
  type CreateProjectSchema,
  type UpdateProjectSchema,
  type CreateProjectCommentSchema,
  type TrackSchema,
  type TrackTaskSchema,
  type RecentlyOpenedTasksResponseSchema,
  type RecentlyOpenedEntitiesResponseSchema,
  type CreateRecurringTaskSchema,
  type UpdateRecurringTaskSchema,
  type CreateReferralSchema,
  type getInviteeReferralSchema,
  type ReferralSchema,
  type TrackPartnerStackLinkSchema,
  type SearchSchema,
  type SearchResultsResponseSchema,
  type CreateTaskStatusSchema,
  type PayInvoiceSchema,
  type AdjustCreditBalanceSchema,
  type StripeSubscriptionSchema,
  type CombinedSubscriptionSchema,
  type UnconfirmedSubscriptionSchema,
  type UpdateSubscriptionFeatureTierSchema,
  type ApplyCouponSchema,
  type TeamPriceSchema,
  type GetTierPricesSchema,
  type CreateSubscriptionStripePortalLinkSchema,
  type StripePaymentMethodSchema,
  type GetTeamPricesUnauthorizedRequestSchema,
  type GetTierPricesUnauthorizedRequestSchema,
  type UpdateDefaultPaymentMethodSchema,
  type MigrateTeamMemberSchema,
  type GetTeamSchema,
  type TeamsSubscriptionSeatsUpdateSchema,
  type CreateTeamTaskSchema,
  type UpdateTeamTaskSchema,
  type GetPersonalCompletedTasksSchema,
  type StartTaskSchema,
  type StopTaskSchema,
  type SnoozeTaskSchema,
  type CheckTeamEligibilitySchema,
  type InviteeSchema,
  type InviteTeamMemberSchema,
  type CreateSetupIntentRequestSchema,
  type CreateSetupIntentResponseSchema,
  type EditTeamSchema,
  type ResubscribeTeamSchema,
  type UpdateSeatsSchema,
  type UpdateTeamNameSchema,
  type UpdateTeamMemberSchema,
  type CreateTeamSchema,
  type UpdateTeamMemberRolesSchema,
  type ChangeBillingAdminSchema,
  type GetGanttOutOfOfficeEventsSchema,
  type GetTeammateOutOfOfficeEventsSchema,
  type TeammateOutOfOfficeEventsResponseSchema,
  type GanttOutOfOfficeEventsResponseSchema,
  type CreateTaskForTemplateSchema,
  type CreateTemplateTaskSchema,
  type UpdateTemplateTaskSchema,
  type CreateTemplatedProjectSchema,
  type UpdateProjectForTemplateSchema,
  type UpdateTemplateProjectSchema,
  type TaskModifierSchema,
  type UseProjectTemplateSchema,
  type GenerateCustomMicrosoftTokenSchema,
  type InitGoogleTokensSchema,
  type AddCompletedTutorialSchema,
  type CompletedTutorialsSchema,
  type CoursePayloadSchema,
  type ChangeBillingCycleSchema,
  type SetIapConsentSchema,
  type ApplyDiscountSchema,
  type SetConsumptionInformationSchema,
  type CreateCreditNoteSchema,
  type AddStripeLocalizePaymentSchema,
  type CheckPendingInviteResponseSchema,
  type CreatePaymentIntentUnauthorizedSchema,
  type CreateStripeLocalizeSubscriptionAfterHoldSchema,
  type CreateStripeLocalizeSubscriptionAfterHoldUnauthorizedSchema,
  type CreateStripePortalLinkSchema,
  type CreateSubscriptionSchema,
  type UpdateEmailSchema,
  type UpdateNameSchema,
  type UpdatePasswordSchema,
  type UpdateProfilePicSchema,
  type UserSubCancellationReasonSchema,
  type StartEmailVerificationResponseSchema,
  type CheckEmailVerificationCodeSchema,
  type CheckEmailVerificationCodeResponseSchema,
  type GetEmailVerificationStatusResponseSchema,
  type CreateWorkspaceViewSchema,
  type CreateViewDataSchema,
  type UpdateViewDataSchema,
  type UpdateViewSchema,
  type SingleViewResponseSchema,
  type WorkspaceVersionedViewArray,
  type WorkspaceVersionedView,
  type MultipleViewResponseSchema,
  type CreateViewV2Schema,
  type UpdateViewV2Schema,
  type SingleViewResponseV3Schema,
  type MultipleViewResponseV3Schema,
  type CreateViewV3Schema,
  type UpdateViewV3Schema,
  type AdminCopyProjectDefinitionSchema,
  type AdminCopyDemoProjectDefinitionSchema,
  type CreateWorkspaceSchema,
  type AddUserToWorkspaceSchema,
  type UpdateWorkspaceSchema,
  type UpdateWorkspaceMemberSchema,
  type WorkspaceSummarySchema,
  type UpdateWorkspaceMembersRequestSchema,
  type RemoveWorkspaceMembersRequestSchema,
  type DeprecatedUserSettingsSchema,
  type GetTeamScheduleContextSchema,
  type GetTeamScheduleTasksBodySchema,
  type CreateTeamViewSchema,
  type UpdateTeamViewSchema,
  type TeamScheduleMutateViewResponseSchema,
  type GetTeamViewsResponseSchema,
  type HHActionItemsQueryRequestSchema,
  type HHActionItemsQueryResponseSchema,
  type AIHH_CreateCalendarEventRequestSchema,
  type HHGetMeetingInsightsQuerySchema,
  type HHMeetingInsightsSchema,
  type HHGetMeetingInsightsWithEvent,
  type HHGetMeetingInsightPage,
  type HHApproveActionItemSchema,
  type HHApproveActionItemResponseSchema,
  type HHSendBotNowResponseSchema,
  type HHUpdateMeetingInsightsRequestSchema,
  type HHMeetingInsightsRequestQuerySchema,
  type HHMeetingInsightsQueryResponseSchema,
  type HHMeetingActionItemSchema,
  type HHMeetingActionItemTaskDataSchema,
  type AIHH_CreateCalendarEventResponseSchema,
  type HHTeamMeetingInsightsSettingsSchema,
  type CreatePolicyRequestSchema,
  type UpdatePolicyRequestSchema,
  type PermissionPolicy,
  type ListPolicyResponseSchema,
  type SingleNoteResponseSchema,
  type NotesQueryRequestSchema,
  type NotesQueryReponseSchema,
  type CreateNoteRequestSchema,
  type UpdateNoteRequestSchema,
  type CreateMentionsSchema,
  type DeleteMentionsSchema,
  type NoteSnapshotsResponseSchema,
  type NoteAttachmentUrlSchema,
  type CreateTaskFromSelectionDtoSchema,
  type CreateMentionsResponseSchema,
  type SummarizeTextDtoSchema,
  type SummarizeTextResponseSchema,
  type RewriteTextDtoSchema,
  type RewriteTextResponseSchema,
  type CopyNoteDtoSchema,
  type EstimateTaskPropsDtoSchema,
  type CreateContentDtoSchema,
  type CreateContentResponseSchema,
  type UpdateThreadRequestSchema,
  type SingleThreadResponseSchema,
  type AccessCheckResponseSchema,
  type ActionItemQueryRequestSchema,
  type ApproveActionItemRequestSchema,
  type ActionItemResponseSchema,
  type AgentWorkflowV2SingleIdResponseSchema,
  type AgentWorkflowV2ListingResponseSchema,
  type CreateAgentWorkflowRequestSchema,
  type GetAgentWorkflowsResponseSchema,
  type RunAgentWorkflowSchema,
  type CreateCalendarEventSchema,
  type CalendarEventSchemaV2,
  type UpdateCalendarEventSchema,
  type UpdateCalendarEventResponseSchema,
  type AddCalendarEventToProjectSchema,
  type AddMeetingInsightsToCalendarEventSchema,
  type GetSchedulingAssistantEventsSchema,
  type SchedulingAssistantUserEventsResponseSchema,
  type SchedulingAssistantEventsResponseSchema,
  type ChartQueryRequestSchema,
  type ChartQueryResponseSchema,
  type ChartTaskListResponseSchema,
  type ChartProjectListResponseSchema,
  type CreateCommentSchema,
  type UpdateCommentSchema,
  type CommentsV2SingleIdResponseSchema,
  type ReactionContentSchema,
  type FeedEntriesV2GetAllResponseSchema,
  type UploadFileRequestSchema,
  type UpdateUploadedFileRequestSchema,
  type UploadFileResponseSchema,
  type UploadedFileV2SingleResponseSchema,
  type FoldersV2ResponseSchema,
  type CreateFolderRequestSchema,
  type SingleFolderResponseSchema,
  type AddItemToFolderRequestSchema,
  type UpdateFolderRequestSchema,
  type UpdateItemInFolderRequestSchema,
  type SingleFolderItemResponseSchema,
  type FavoriteItemRequestSchema,
  type LabelsV2GetAllResponseSchema,
  type LabelsV2SingleResponseSchema,
  type V2CreateLabelsSchema,
  type MeetingInsightsSingleRequestSchema,
  type MeetingInsightsSingleResponseSchema,
  type UpdateMeetingInsightsRequestSchema,
  type UpdateRecurringMeetingInsightsRequestSchema,
  type MeetingInsightsQueryRequestSchema,
  type MeetingInsightsQueryResponseSchema,
  type ProjectsV2CompleteRequestSchema,
  type ProjectsV2ResolveRequestSchema,
  type ProjectsV2ShiftRequestSchema,
  type ProjectsV2ApplyDefinitionRequestSchema,
  type ProjectV2ResponseSchema,
  type ProjectsV2CreateRequestSchema,
  type ProjectsV2CreateFromTaskRequestSchema,
  type ProjectsV2UpdateRequestSchema,
  type ProjectsV2UpdateStageDueDateRequestSchema,
  type BulkUpdateProjectStagesDueDate,
  type ProjectsV2SetStageRequestSchema,
  type ProjectsV2AdvanceStageRequestSchema,
  type BulkUpdateProjectsSchema,
  type ProjectQuerySchema,
  type ProjectQueryResponseSchema,
  type ConvertProjectToStageRequestSchema,
  type AddProjectStageRequestSchema,
  type RemoveProjectStageRequestSchema,
  type ScheduledEntitiesGetSchema,
  type ScheduledEntitiesGetResponseSchema,
  type SchedulesGetSchema,
  type SchedulesGetResponseSchema,
  type SharePatchRequestSchema,
  type ShareResponseSchema,
  type PublishRequestSchema,
  type PublishResponseSchema,
  type StatusesV2GetAllResponseSchema,
  type StatusesV2SingleResponseSchema,
  type V2CreateTaskStatusSchema,
  type V2UpdateTaskStatusSchema,
  type TasksV2GetByIdParamsSchema,
  type TasksV2SingleIdResponseSchema,
  type TasksV2CreateSchema,
  type TasksV2UpdateSchema,
  type TasksV2QuerySchema,
  type TasksV2RescheduleUpdateSchema,
  type TasksV2QueryResponseSchema,
  type BulkUpdateTasksSchema,
  type TeamsV2Schema,
  type TeamsSettingsV2GetResponseSchema,
  type UpdateTeamNotetakerSettingsRequestSchema,
  type UserFeaturePermissionsGetSchema,
  type UserSettingsV2GetResponseSchema,
  type UserOnboardingSettingsUpdateSchema,
  type UserConferenceSettingsUpdateSchema,
  type UserSettingsV2UpdateCallToActionsRequestSchema,
  type UserTimezoneSettingsRequestSchema,
  type UserAutoScheduleSettingsRequestSchema,
  type UserFolderSettingsCreateSchema,
  type UserFolderSettingsUpdateSchema,
  type GetCurrentUserResponseSchema,
  type UserTaskDefaultSettingsRequestSchema,
  type UserCalendarDisplaySettingsRequestSchema,
  type UserSidebarDisplaySettingsRequestSchema,
  type UserPageViewSettingsRequestSchema,
  type UserNotetakerSettingsRequestSchema,
  type PageViewSettingsQuerySchema,
  type UserPageViewSettingsSingleResponseSchema,
  type UserPageViewSettingsMultipleResponseSchema,
  type WorkspacesV2GetRequestSchema,
  type WorkspacesV2GetAll,
  type WorkspacesV2Create,
  type WorkspacesV2GetSingle,
  type WorkspacesV2GetSingleWithUsers,
} from '@motion/zod/client'

import {
  type TaskStatus,
  type User,
  type StripeSubscription,
  type TeamSubscription,
  type TeamMember,
  type Team,
  type Workspace,
  type Label,
  type TeamInvite,
  type BookingConflictCalendar,
} from '.prisma/client'

// Source: [cls] ./apps/backend/src/routes/admin/users.admin.dto.ts
export type TeamTrialDto = {
  userId: string
  teamName: string
}

// Source: [cls] ./apps/backend/src/routes/admin/users.admin.dto.ts
export type UserDetailDto = {
  userId: string
  email?: string
  name?: string
}

// Source: [cls] ./apps/backend/src/routes/admin/users.admin.dto.ts
export type TeamAdminDto = {
  userId: string
  admin: boolean
}

// Source: [cls] ./apps/backend/src/routes/admin/users.admin.dto.ts
export type StripeEmailChangeDto = {
  customerId: string
  oldUserId: string
  newUserId?: string
}

// Source: [cls] ./apps/backend/src/routes/admin/users.admin.dto.ts
export type TeammateDataMigrationDto = {
  userMap: {
    oldEmail: string
    newEmail: string
  }[]
}

// Source: [cls] ./apps/backend/src/routes/admin/users.admin.dto.ts
export type AutocancelTrialBulkDto = {
  customers: {
    email: string
    customerId: string
  }[]
  autodowngrade?: boolean
}

// Source: [cls] ./apps/backend/src/routes/admin/users.admin.dto.ts
export type DeleteMainCalendarDto = {
  email: string
}

//Remaining type references
export type * from '@motion/zod/client'

// Source: [zod] ./apps/backend/src/routes/ai/cancellation-interception.dto.ts
export type CancellationInterceptionSchema = {
  content: string
}

// Source: [zod] ./apps/backend/src/routes/ai/cancellation-interception.dto.ts
export type CancellationInterceptionDto = CancellationInterceptionSchema

// Source: [cls] ./apps/backend/src/routes/calendar-contacts/contacts-search.dto.ts
export type ContactsSearchQuery = {
  search: string
  teamOnly: boolean
}

// Source: [zod] ./apps/backend/src/routes/calendar-events/calendar-events.dto.ts
export type CalendarEventSearchSchema = {
  query: string
}

// Source: [zod] ./apps/backend/src/routes/calendar-events/calendar-events.dto.ts
export type CalendarEventSearchDto = CalendarEventSearchSchema

// Source: [zod] ./apps/backend/src/routes/email-accounts/email-accounts.dto.ts
export type CalendarAccessSchema = {
  calendarEmails: string[]
}

// Source: [zod] ./apps/backend/src/routes/email-accounts/email-accounts.dto.ts
export type CalendarAccessDto = CalendarAccessSchema

// Source: [zod] ./apps/backend/src/routes/email-accounts/email-accounts.dto.ts
export type EmailAccountCreateSchema = {
  email: string
  password: string
  type: 'APPLE'
}

// Source: [zod] ./apps/backend/src/routes/email-accounts/email-accounts.dto.ts
export type EmailAccountCreateDto = EmailAccountCreateSchema

// Source: [zod] ./apps/backend/src/routes/email-accounts/email-accounts.dto.ts
export type EmailAccountUpdateSchema = {
  password: string
}

// Source: [zod] ./apps/backend/src/routes/email-accounts/email-accounts.dto.ts
export type EmailAccountUpdateDto = EmailAccountUpdateSchema

// Source: [zod] ./apps/backend/src/routes/email-accounts/email-accounts.dto.ts
export type EmailAccountSchema = {
  createdTime: string
  email: string
  id: string
  name: string | null
  profilePictureUrl: string | null
  providerType: 'APPLE' | 'GOOGLE' | 'MICROSOFT'
  scope: string[]
  status:
    | 'DISABLED'
    | 'OK'
    | 'INVALID_CREDENTIALS'
    | 'INVALID_GRANT'
    | 'NO_CALENDAR_ACCESS'
    | 'UNKNOWN_ERROR'
  updatedTime: string | null
  userId: string
}

// Source: [zod] ./apps/backend/src/routes/email-accounts/email-accounts.dto.ts
export type EmailAccountResponseDto = EmailAccountSchema

// Source: [zod] ./apps/backend/src/routes/email-accounts/email-accounts.dto.ts
export type EmailAccountsFetchResponseSchema = {
  emailAccounts: EmailAccountSchema[]
  mainEmailAccountId: string | null
}

// Source: [zod] ./apps/backend/src/routes/email-accounts/email-accounts.dto.ts
export type EmailAccountsFetchResponseDto = EmailAccountsFetchResponseSchema

// Source: [zod] ./apps/backend/src/routes/mobile/mobile.dto.ts
export type MobileCheckVersionResponseSchema = {
  needsUpdate: boolean
}

// Source: [zod] ./apps/backend/src/routes/mobile/mobile.dto.ts
export type MobileCheckVersionResponseDto = MobileCheckVersionResponseSchema

// Source: [zod] ./apps/backend/src/routes/mobile/mobile.dto.ts
export const PlatformTypes = { ios: 'ios', android: 'android' } as const

// Source: [zod] ./apps/backend/src/routes/mobile/mobile.dto.ts
export type CheckVersionSchema = {
  version: string
  platform: keyof typeof PlatformTypes
}

// Source: [zod] ./apps/backend/src/routes/mobile/mobile.dto.ts
export type CheckVersionDto = CheckVersionSchema

// Source: [zod] ./apps/backend/src/routes/mobile/mobile.dto.ts
export type MobileSettingsSchema = {
  inAppReviewLastSeen?: string | undefined
  hasRespondedToInAppReview?: boolean | undefined
  hasSeenSiriPrompt?: boolean | undefined
}

// Source: [zod] ./apps/backend/src/routes/mobile/mobile.dto.ts
export type MobileSettingsDto = MobileSettingsSchema

// Source: [zod] ./apps/backend/src/routes/mobile/mobile.dto.ts
export type MobileUserFeedbackSchema = {
  feedback: string
}

// Source: [zod] ./apps/backend/src/routes/mobile/mobile.dto.ts
export type MobileUserFeedbackDto = MobileUserFeedbackSchema

// Source: [zod] ./apps/backend/src/routes/mobile/mobile.dto.ts
export type CreateAppleIapSubscriptionSchema = {
  transactionId: string
  bundleId: string
}

// Source: [zod] ./apps/backend/src/routes/mobile/mobile.dto.ts
export type CreateAppleIapSubscriptionDto = CreateAppleIapSubscriptionSchema

// Source: [zod] ./apps/backend/src/routes/oauth/oauth.dto.ts
export type BaseOauthResponseSchema = {
  code?: string | undefined
  state?: string | undefined
  error?: string | undefined
  error_description?: string | undefined
}

// Source: [zod] ./apps/backend/src/routes/oauth/oauth.dto.ts
export type GoogleOauthVerifySchema = BaseOauthResponseSchema & {
  scope?: string | undefined
  authuser?: string | undefined
  prompt?: string | undefined
}

// Source: [zod] ./apps/backend/src/routes/oauth/oauth.dto.ts
export type GoogleOauthVerifyDto = GoogleOauthVerifySchema

// Source: [zod] ./apps/backend/src/routes/oauth/oauth.dto.ts
export type MicrosoftOauthVerifySchema = BaseOauthResponseSchema

// Source: [zod] ./apps/backend/src/routes/oauth/oauth.dto.ts
export type MicrosoftOauthVerifyDto = MicrosoftOauthVerifySchema

// Source: [zod] ./apps/backend/src/routes/oauth/oauth.dto.ts
export type BaseOauthResponseDto = BaseOauthResponseSchema

// Source: [cls] ./apps/backend/src/routes/personal-email/email-signature.dto.ts
export type EmailSignatureSettingsDto = {
  emailSignatures: Record<string, string>
}

// Source: [zod] ./apps/backend/src/routes/privacy/privacy.dto.ts
export type UpdateCookiePrefsSchema = {
  allowOptionalCookies: boolean
}

// Source: [zod] ./apps/backend/src/routes/privacy/privacy.dto.ts
export type UpdateCookiePrefsDto = UpdateCookiePrefsSchema

// Source: [cls] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export enum SettingsProviderType {
  Google = 'google',
  Microsoft = 'microsoft',
  Apple = 'apple',
}

// Source: [cls] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export type AuthSettings = {
  accessToken: string
  expiration: string // ISO8601 date
  type: SettingsProviderType
  needsRefresh: boolean
  idToken: Record<string, unknown>
  scope: string
}

/**
 * @deprecated email accounts should be in Postgres
 */
// Source: [cls] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export interface AuthSettingsCollection {
  [email: string]: AuthSettings
}

// Source: [cls] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export type CalendarListEntry = {
  accessRole?: 'owner' | 'reader' | 'writer'
  id: string
  colorId: string
  colorClass: string
  visible: boolean
  title: string
  primary: boolean
  inCalendarList: boolean
  active: boolean
  frequent: boolean
  frequentModified?: boolean
  editable: boolean
  provider: SettingsProviderType
  owner: string
  email: string
  mine: boolean
  mineModified?: boolean
  isMyCalendar?: boolean // deprecated
}

// Source: [cls] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export interface CalendarList {
  [email: string]: CalendarListEntry[]
}

// Source: [cls] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export type TaskBreakSettings = {
  enabled: boolean
  breakDurationMins: number
  breakIntervalHours: number
  schedule?: string
}

// Source: [cls] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export type DayVerbose =
  | 'Friday'
  | 'Monday'
  | 'Saturday'
  | 'Sunday'
  | 'Thursday'
  | 'Tuesday'
  | 'Wednesday'

// Source: [cls] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export type ScheduleRange = {
  /**
   * Time range, e.g. 9:00am-10:00am
   */
  range: string
  start?: string
  end?: string
  preferred?: boolean
}

// Source: [cls] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export type ScheduleByDow = Record<DayVerbose, ScheduleRange[]>

// Source: [cls] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export type Schedule = {
  schedule: ScheduleByDow
  timezone: string
  title: string
}

// Source: [cls] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export type ScheduleCollection = Record<string, Schedule>

// Types of the Firebase UserSettings model
// Source: [cls] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export type OnboardingType =
  | 'individual'
  | 'individual_no_card'
  | 'team'
  | 'team_invitee'
  | 'team_with_card'

// Source: [cls] ./apps/backend/src/routes/settings/firebase-settings.dto.ts
export type ClientFirebaseSettingsDto = {
  publicTaskNames: boolean
  googleAuth: AuthSettingsCollection
  calendarList: CalendarList
  currentGmail: string
  taskBreakSettings?: TaskBreakSettings | undefined
  taskTimeBlockMode?: 'default' | 'free' | 'no-events' | undefined
  schedules?: ScheduleCollection | undefined
  emailSignatures?: Record<string, string> | undefined
  calendarStartDay: 'sunday' | 'monday'
  calendarTemplates: {
    body: string
    subject: string
  }[]
  cta: Record<string, boolean>
  showAgendaEvents?: boolean | undefined
  showCompletedTasks: boolean
  desktop?: unknown
  theme: 'systemSettings' | 'light' | 'dark'
  isOnboardingComplete?: boolean | undefined
  didSkipOnboarding: boolean
  onboardingProgress?: Record<string, boolean>
  onboardingLatestScreen: string
  lastOnboardTimestamp: number
  onboardingType?: OnboardingType | undefined
  onboardingVersion: string
  onboardingExtraData?: {
    workspaceId?: string
    projectId?: string
    didSkipPmOnboarding?: boolean
  }
  onboardingCompletedSurvey: boolean
  googleRefreshTokens?: Record<string, unknown>
  microsoftRefreshTokens?: Record<string, unknown>
}

// Source: [zod] ./apps/backend/src/routes/settings/settings.dto.ts
export type WorkspacesAndProjectsOrderedSchema = {
  workspaceId: string
  workspaceExpanded: boolean
  projectIdsOrdered: string[]
  filteredStatusIds?: string[] | undefined
}

// Source: [zod] ./apps/backend/src/routes/settings/settings.dto.ts
export type SidebarStateSchema = {
  workspacesAndProjectsOrdered: WorkspacesAndProjectsOrderedSchema[]
}

// Source: [zod] ./apps/backend/src/routes/settings/settings.dto.ts
export type SidebarState = SidebarStateSchema

// Source: [zod] ./apps/backend/src/routes/settings/settings.dto.ts
export type TaskBreakSettingsSchema = {
  enabled: boolean
  breakDurationMins: number
  breakIntervalHours: number
}

// Source: [zod] ./node_modules/@motion/nest-common/src/shared-types/user-settings.ts
export const TaskTimeBlockModes = ['default', 'free', 'no-events'] as const

// Source: [zod] ./apps/backend/src/routes/settings/settings.dto.ts
export type SaveSettingsSchema = {
  publicTaskNames?: boolean | undefined
  taskBreakSettings?: TaskBreakSettingsSchema | undefined
  taskTimeBlockMode?: (typeof TaskTimeBlockModes)[number] | undefined
  sidebarState?: SidebarStateSchema | undefined
  inAppReviewLastSeen?: string | undefined
  hasRespondedToInAppReview?: boolean | undefined
  hasSeenSiriPrompt?: boolean | undefined
}

// Source: [zod] ./apps/backend/src/routes/settings/settings.dto.ts
export type SaveSettingsDto = SaveSettingsSchema

// Source: [zod] ./apps/backend/src/routes/settings/settings.dto.ts
export type ModalStateSchema = Record<string, string | null>

// Source: [zod] ./apps/backend/src/routes/settings/settings.dto.ts
export type ModalStateDto = ModalStateSchema

// Source: [zod] ./node_modules/@motion/zod/src/server/common/dates.ts
export { type DatetimeSchema }

// Source: [zod] ./apps/backend/src/routes/settings/settings.dto.ts
export type ModalStateInfoSchema = {
  key: string
  seen: boolean
  seenAt?: DatetimeSchema | undefined
}

// Source: [zod] ./apps/backend/src/routes/settings/settings.dto.ts
export type ModalStateInfoDto = ModalStateInfoSchema

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/booking.serializers.ts
export type InviteeBookingSerializer = {
  eventStart: string
  eventEnd: string
}

// Slot with duration matching the link's settings
// Source: [cls] ./packages/nest/backend/src/db-models/booking/booking.types.ts
export type AvailabilitySlot = {
  end: string
  preferred: boolean
  start: string
}

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export const BookingQuestionType = {
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  TEXT_ONE_LINE: 'TEXT_ONE_LINE',
  TEXT_MULTIPLE_LINES: 'TEXT_MULTIPLE_LINES',
} as const

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export type BookingQuestionType =
  (typeof BookingQuestionType)[keyof typeof BookingQuestionType]

// Source: [cls] ./packages/nest/backend/src/db-models/booking/booking.types.ts
export type CreateQuestionInput = {
  questionType: BookingQuestionType
  text: string
  required?: boolean
  choices?: string[]
  order?: number
}

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/booking.serializers.ts
export type BookingLinkResponseSerializer = {
  id: string
  hostDisplayName: string
  inviteeEmail: string
  inviteeName: string
  externalEventName: string
  teamId: string
  isSingleUse: boolean
  inviteeBookings: InviteeBookingSerializer[]
  durationChoices: number[]
  durationAvailabilitySlots: Record<number, AvailabilitySlot[]>
  duration: number
  questions: CreateQuestionInput[]
  linkRange?: {
    start: string
    end: string
  }
  bookingData?: InviteeBookingSerializer
  availabilitySlots?: AvailabilitySlot[]
}

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export const PriorityLevel = {
  ASAP: 'ASAP',
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
} as const

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export type PriorityLevel = (typeof PriorityLevel)[keyof typeof PriorityLevel]

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export const ProjectType = { NORMAL: 'NORMAL', TEMPLATE: 'TEMPLATE' } as const

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export type ProjectType = (typeof ProjectType)[keyof typeof ProjectType]

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/projects.serializers.ts
export type ProjectSerializer = {
  id: string
  name: string
  completedTime: string | null
  managerId: string | null
  createdByUserId: string
  dueDate: string | null
  createdTime: string
  updatedTime: string | null
  workspaceId: string
  priorityLevel: PriorityLevel
  statusId: string
  type: ProjectType
  description: string | null
  rank: string | null
  createdWithAi: boolean
  flowTemplateId: string | null
  projectDefinitionId: string | null
  activeStageDefinitionId: string | null
  reconciliationRequestedAt: string | null
  reconciliationCompletedAt: string | null
  reconciliationStatus: string | null
  completedDuration: number
  canceledDuration: number
  duration: number
  completedTaskCount: number
  canceledTaskCount: number
  taskCount: number
  startDate: string | null
  color: string
  scheduledStatus: string | null
  estimatedCompletionTime: string | null
}

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/projects.serializers.ts
export type ProjectLabel = {
  id: string
  projectId: string
  labelId: string
  createdTime: string
  updatedTime: string | null
}

export type { TaskStatus }

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/statuses.serializers.ts
export type TaskStatusSerializer = {
  id: TaskStatus['id']
  color: TaskStatus['color']
  name: TaskStatus['name']
  sortPosition: TaskStatus['sortPosition']
  isResolvedStatus: TaskStatus['isResolvedStatus']
  isDefaultStatus: TaskStatus['isDefaultStatus']
  isSystemStatus: TaskStatus['isSystemStatus']
  autoScheduleEnabled: TaskStatus['autoScheduleEnabled']
  createdTime: TaskStatus['createdTime']
  updatedTime: TaskStatus['updatedTime']
  workspaceId: TaskStatus['workspaceId']
  deletedTime: TaskStatus['deletedTime']
  type: TaskStatus['type']
  autoScheduleSetting: TaskStatus['autoScheduleSetting']
}

export type { User }

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/users.serializers.ts
export type UserSerializer = {
  createdTime: User['createdTime']
  id: User['id']
  email: User['email']
  mainCalendarEmail: User['mainCalendarEmail']
  name: User['name']
  onboardingComplete: User['onboardingComplete']
  type: User['type']
  updatedTime: User['updatedTime']
  lastActive: string | null
  hasActiveSubscription: boolean
  deletedTime: User['deletedTime']
  noExternalCalendarsModeEnabled: User['noExternalCalendarsModeEnabled']
  isPlaceholder: User['isPlaceholder']
  picture: User['picture']
}

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/projects.serializers.ts
export type ProjectWithRelationsSerializer = ProjectSerializer & {
  status: TaskStatusSerializer
  manager: UserSerializer
  labels: ProjectLabel[]
}

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export const TeamTaskType = {
  NORMAL: 'NORMAL',
  RECURRING_INSTANCE: 'RECURRING_INSTANCE',
  CHUNK: 'CHUNK',
  TEMPLATE: 'TEMPLATE',
} as const

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export type TeamTaskType = (typeof TeamTaskType)[keyof typeof TeamTaskType]

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export const TaskDeadlineType = {
  ASAP: 'ASAP',
  HARD: 'HARD',
  SOFT: 'SOFT',
  NONE: 'NONE',
} as const

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export type TaskDeadlineType =
  (typeof TaskDeadlineType)[keyof typeof TaskDeadlineType]

// Keep in sync with motion-extension PMProjectType.ts
// Source: [cls] ./packages/nest/backend/src/db-models/team-tasks/team-tasks.types.ts
export enum PMItemType {
  project,
  task,
  recurringTask,
}

/**
 * This is duplicated from @motion/zod since we can not include zod schemas with class-validator ones
 * @deprecated import from '@motion/shared/custom-fields' instead
 * */
// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/team-tasks.serializers.ts
export type CustomFieldValuesSchema =
  | {
      type: 'text'
      value: string | null
    }
  | {
      type: 'number'
      value: number | null
    }
  | {
      type: 'url'
      value: string | null
    }
  | {
      type: 'date'
      value: string | null
    }
  | {
      type: 'select'
      value: string | null
    }
  | {
      type: 'multiSelect'
      value: string[] | null
    }
  | {
      type: 'person'
      value: string | null
    }
  | {
      type: 'multiPerson'
      value: string[] | null
    }

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/team-tasks.serializers.ts
export type TeamTaskSerializer = {
  id: string
  name: string
  dueDate: string | null
  duration: number | null
  endDate: string | null
  endOn: string | null
  completed: boolean
  assigneeUserId: string | null
  completedTime: string | null
  statusId: string
  projectId: string | null
  createdByUserId: string
  createdTime: string
  updatedTime: string | null
  lastInteractedTime: string | null
  archivedTime: string | null
  workspaceId: string
  minimumDuration: number | null
  scheduledStart: string | null
  scheduledEnd: string | null
  startDate: string | null
  startOn: string | null
  isUnfit: boolean
  type: TeamTaskType
  needsReschedule: boolean
  deadlineType: TaskDeadlineType
  schedule: string | null
  parentChunkTaskId: string | null
  parentRecurringTaskId: string | null
  rank: string | null
  isFixedTimeTask: boolean
  isAutoScheduled: boolean
  isReminderTask: boolean
  isChunkedTask: boolean
  isBusy: boolean
  description: string | null
  itemType: PMItemType
  priorityLevel: PriorityLevel
  completedDuration?: number
  ignoreWarnOnPastDue: boolean
  scheduleOverridden: boolean
  snoozeUntil: string | null
  manuallyStarted: boolean
  estimatedCompletionTime: string | null
  scheduledStatus: string | null
  isFutureSchedulable: boolean
  customFieldValues?: Record<string, CustomFieldValuesSchema>
  taskDefinitionId: string | null
  stageDefinitionId: string | null
  isSyncingWithDefinition: boolean
  scheduleMeetingWithinDays: number | null
  meetingTaskId: string | null
  meetingRelationId: string | null
}

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/team-tasks.serializers.ts
export type TeamTaskThinSerializer = TeamTaskSerializer & {
  assigneeUserIds: string[]
  labelIds: string[]
  blockedTaskIds: string[]
  blockingTaskIds: string[]
  meetingEventId: string
}

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/projects.serializers.ts
export type ProjectWithRelationsAndTasksSerializer =
  ProjectWithRelationsSerializer & {
    tasks: TeamTaskThinSerializer[]
  }

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/subscription.serializers.ts
export type UserFacingPaymentMethodSerializer = {
  id: string
  brand: string
  last4: string
}

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/subscription.serializers.ts
export type InvoiceSettingsSerializer = {
  default_payment_method: string
}

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/subscription.serializers.ts
export type StripeCustomerSerializer = {
  id: string
  balance: number
  currency: string
  invoice_settings: InvoiceSettingsSerializer
}

// Source: [cls] ./node_modules/stripe/types/2020-08-27/Subscriptions.d.ts
export type StripeSubscriptionStatus =
  | 'active'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'past_due'
  | 'trialing'
  | 'unpaid'

export type { StripeSubscription }

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/subscription.serializers.ts
export type UserSubscriptionSerializer = {
  id: string
  status: StripeSubscriptionStatus
  endDate: string
  customer: StripeCustomerSerializer
  paymentMethod: UserFacingPaymentMethodSerializer
  subscription: StripeSubscription
  savingsPercent: number
  savingsAmount: number
  type: string
  isAppleIapSubscription?: boolean
}

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/subscription.serializers.ts
export type SubscriptionsResponseSerializer = UserSubscriptionSerializer & {
  lastActiveDate: string
}

export type { TeamSubscription }

// This unfortunately cannot be called TeamSubscription because that would create a
// conflict with the prisma type during generate-types
// Source: [cls] ./node_modules/@motion/database/src/prisma/types/team-subscriptions.ts
export type TeamSub = Omit<TeamSubscription, 'id'>

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/teams.serializers.ts
export type TeamSubscriptionSerializer = {
  teamId: TeamSub['teamId']
  status: TeamSub['status']
  endDate: TeamSub['endDate']
  isMonthly: TeamSub['isMonthly']
  customer: TeamSub['customer']
  customerId: TeamSub['customerId']
  subscription: TeamSub['subscription']
  createdTime: TeamSub['createdTime']
  updatedTime: TeamSub['updatedTime']
  savingsAmount: null
  savingsPercent: null
  bucketSeats: TeamSub['bucketSeats']
  featurePermissionTierId: TeamSub['featurePermissionTierId']
}

export type { TeamMember }

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/teams.serializers.ts
export type TeamMemberSerializer = {
  id: TeamMember['id']
  teamId: TeamMember['teamId']
  userId: TeamMember['userId']
  role: TeamMember['role']
  status: TeamMember['status']
  createdTime: TeamMember['createdTime']
  updatedTime: TeamMember['updatedTime']
  allowOthersToAutoSchedule: TeamMember['allowOthersToAutoSchedule']
  deletedTime: TeamMember['deletedTime']
  user: UserSerializer
}

export type { Team }

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/teams.serializers.ts
export type TeamSerializer = {
  id: Team['id']
  name: Team['name']
  createdTime: Team['createdTime']
  updatedTime: Team['updatedTime']
  customerId: Team['customerId']
  slug: Team['slug']
  hasBucketPricing: Team['hasBucketPricing']
}

export type { Workspace }

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/workspaces.serializers.ts
export type WorkspaceSerializer = {
  id: Workspace['id']
  name: Workspace['name']
  teamId: Workspace['teamId']
  type: Workspace['type']
  createdTime: Workspace['createdTime']
  updatedTime: Workspace['updatedTime']
  isPersonalWorkspace: boolean
}

export type { Label }

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/workspaces.serializers.ts
export type WorkspaceMemberSerializer = {
  id: string
  workspaceId: string
  userId: string
  isWorkspaceAdmin: boolean
  sortPosition: string
  createdTime: string
  updatedTime: string | null
  deletedTime: string | null
}

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/workspaces.serializers.ts
export type WorkspaceMemberWithRelationsSerializer =
  WorkspaceMemberSerializer & {
    user: UserSerializer
  }

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/workspaces.serializers.ts
export type WorkspaceWithRelationsSerializer = WorkspaceSerializer & {
  savedViews: any[]
  labels: Label[]
  members: WorkspaceMemberWithRelationsSerializer[]
  taskStatuses: TaskStatusSerializer[]
  team: Team
}

export type { TeamInvite }

// Source: [cls] ./packages/nest/backend/src/deprecated-serializers/teams.serializers.ts
export type TeamWithRelationsSerializer = TeamSerializer & {
  members: TeamMemberSerializer[]
  workspaces: WorkspaceWithRelationsSerializer[]
  teamSubscription: TeamSub | null
  invites: TeamInvite[]
}

// Source: [cls] ./packages/nest/backend/src/db-models/booking/booking.dto.ts
export type GetBookingLinkDto = {
  linkId?: string
  userId?: string
  bookingId?: string
  month?: string
  date?: string
  durationChoice?: number
  returnRanges?: boolean
  linkSlug?: string
  urlPrefix?: string
  messageTimezone?: string
}

// Source: [cls] ./node_modules/@motion/shared/src/common/event-conference-type.ts
export const EventConferenceType = {
  none: 'none',
  zoom: 'zoom',
  hangoutsMeet: 'hangoutsMeet',
  meet: 'meet',
  teamsForBusiness: 'teamsForBusiness',
  phone: 'phone',
  customLocation: 'customLocation',
  // Other gcal conference types
  eventHangout: 'eventHangout',
  eventNamedHangout: 'eventNamedHangout',
  // Other MS Graph conference types
  unknown: 'unknown',
  skypeForBusiness: 'skypeForBusiness',
  skypeForConsumer: 'skypeForConsumer',
} as const

// Source: [cls] ./node_modules/@motion/shared/src/common/event-conference-type.ts
export type EventConferenceType =
  (typeof EventConferenceType)[keyof typeof EventConferenceType]

// Source: [cls] ./packages/nest/backend/src/db-models/booking/booking.types.ts
export type ConflictCalendar = {
  calendarId: string
  email: string
  inCalendarList: boolean
  title: string
}

// Source: [cls] ./packages/nest/backend/src/db-models/booking/firebase-booking.types.ts
export type FirebaseQuestionType =
  | 'multiple-choice'
  | 'text-multiple'
  | 'text-single'

// Source: [cls] ./packages/nest/backend/src/db-models/booking/firebase-booking.types.ts
export type FirebaseQuestion = {
  type: FirebaseQuestionType
  required: boolean
  text: string
  choices?: {
    text: string
  }[]
}

// Source: [cls] ./packages/nest/backend/src/db-models/booking/booking.dto.ts
export type CreateTemplateLinkDto = {
  hostEmail: string
  hostCalendarId: string
  hostDisplayName: string
  blockingTimeMins?: number
  bufferMins?: number
  conferenceType: EventConferenceType
  conflictCalendars: ConflictCalendar[]
  customSchedule: Schedule
  daysSpan: number
  durationChoices: number[]
  externalEventName?: string
  hasReminderEmail?: boolean
  linkSlug: string
  maxDailyMeetings?: number
  name: string
  guestEmails?: string[]
  order?: number
  questions?: FirebaseQuestion[]
  reminderEmailBody?: string
  reminderEmailSubject?: string
  reminderEmailPreBookingMins?: number
  scheduleId?: string
  startsIn?: string
  teamAuthorId?: string
  teamId?: string
  templateId?: string
}

// Source: [cls] ./packages/nest/backend/src/db-models/booking/booking.dto.ts
export type UpdateTemplateLinkDto = CreateTemplateLinkDto & {
  templateId: string
}

// Source: [cls] ./packages/nest/backend/src/db-models/booking/booking.dto.ts
export type CreateTemplateChildLinkDto = {
  conflictCalendars: ConflictCalendar[]
  dateList?: string[]
  dateRangeStart?: string
  dateRangeEnd?: string
  durationChoice: number
  guestEmails?: string[]
  inviteeEmail?: string
  inviteeName?: string
  scheduleId?: string
  teamAuthorId?: string
  teamId?: string
  templateId: string
}

// Source: [cls] ./packages/nest/backend/src/db-models/booking/booking.dto.ts
export type CreateBookingDto = {
  recipientEmail: string
  recipientName: string
  recipientTimezone: string
  recipientGuests?: string[]
  start: string
  end: string
  userId: string
  bookingId?: string
  linkShortId: string
  linkId: string
  questions?: unknown[]
  durationChoice?: number
}

export type { BookingConflictCalendar }

// Source: [cls] ./packages/nest/backend/src/db-models/booking/booking.dto.ts
export type UpdateTemplateChildLinkDto = {
  conflictCalendars: BookingConflictCalendar[]
  dateList?: string[]
  dateRangeStart?: string
  dateRangeEnd?: string
  durationChoice?: number
  guestEmails?: string[]
  inviteeEmail?: string
  inviteeName?: string
  scheduleId?: string
  teamAuthorId?: string
  teamId?: string
  linkId: string
}

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export const CalendarEventType = {
  BOOKING: 'BOOKING',
  EXTERNAL_EVENT: 'EXTERNAL_EVENT',
  FLEXIBLE_EVENT: 'FLEXIBLE_EVENT',
  NORMAL: 'NORMAL',
  RECURRING_EVENT: 'RECURRING_EVENT',
  SYNCED_EVENT: 'SYNCED_EVENT',
  TASK: 'TASK',
  TRAVEL_TIME_BEFORE: 'TRAVEL_TIME_BEFORE',
  TRAVEL_TIME_AFTER: 'TRAVEL_TIME_AFTER',
} as const

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export type CalendarEventType =
  (typeof CalendarEventType)[keyof typeof CalendarEventType]

// Source: [cls] ./packages/nest/backend/src/db-models/calendar-events/calendar-events-deprecated.dto.ts
export type CalendarEventsByCalendarIdsDto = {
  email: string
  calendarIds: string[]
  start: string
  end: string
  types?: CalendarEventType[]
  anonymize?: boolean
}

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export const CalendarEventStatus = { BUSY: 'BUSY', FREE: 'FREE' } as const

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export type CalendarEventStatus =
  (typeof CalendarEventStatus)[keyof typeof CalendarEventStatus]

// Source: [cls] ./packages/nest/backend/src/db-models/calendar-events/calendar-events-deprecated.dto.ts
export type CalendarEventOrganizerMutation = {
  email: string
  displayName?: string
}

// Source: [cls] ./packages/nest/backend/src/calendars/calendar-sync/services/calendar-events-v2.types.ts
export const CalendarEventAttendeeStatuses = [
  'needsAction',
  'declined',
  'tentative',
  'accepted',
] as const

// Source: [cls] ./packages/nest/backend/src/calendars/calendar-sync/services/calendar-events-v2.types.ts
export type CalendarEventAttendeeStatus =
  (typeof CalendarEventAttendeeStatuses)[number]

// Source: [cls] ./packages/nest/backend/src/db-models/calendar-events/calendar-events-deprecated.dto.ts
export type CalendarEventAttendeeMutation = {
  email: string
  isOptional: boolean
  status?: CalendarEventAttendeeStatus
}

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export const CalendarEventVisibility = {
  CONFIDENTIAL: 'CONFIDENTIAL',
  DEFAULT: 'DEFAULT',
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
} as const

// Source: [cls] ./node_modules/.prisma/client/index.d.ts
export type CalendarEventVisibility =
  (typeof CalendarEventVisibility)[keyof typeof CalendarEventVisibility]

// Source: [cls] ./packages/nest/backend/src/db-models/calendar-events/calendar-events-deprecated.dto.ts
export type CalendarEventMutation = {
  title?: string
  description?: string
  start?: string
  end?: string
  isAllDay?: boolean
  status?: CalendarEventStatus
  location?: string
  conferenceLink?: string
  organizer?: CalendarEventOrganizerMutation
  attendees?: CalendarEventAttendeeMutation[]
  visibility?: CalendarEventVisibility
  colorId?: string
  sendUpdates?: boolean
  recurrence?: string
  travelTimeBefore?: number | null
  travelTimeAfter?: number | null
}

// Source: [cls] ./packages/nest/backend/src/db-models/calendar-events/calendar-events-deprecated.dto.ts
export type CalendarEventOrganizerDto = {
  email: string
  displayName?: string
}

// Source: [cls] ./packages/nest/backend/src/db-models/calendar-events/calendar-events-deprecated.dto.ts
export type CalendarEventAttendeeDto = {
  email: string
  isOptional: boolean
  status?: CalendarEventAttendeeStatus
  isOrganizer?: boolean
}

// Source: [cls] ./packages/nest/backend/src/db-models/calendar-events/calendar-events-deprecated.dto.ts
export type CreateCalendarEventDto = {
  title: string
  description?: string
  start: string
  end: string
  isAllDay: boolean
  status: CalendarEventStatus
  location?: string
  conferenceLink?: string
  organizer?: CalendarEventOrganizerDto
  attendees?: CalendarEventAttendeeDto[]
  visibility: CalendarEventVisibility
  colorId?: string
  sendUpdates?: boolean
  recurrence?: string
  timezone: string
  conferenceType?: EventConferenceType
  bookingLinkId?: string
  travelTimeBefore?: number | null
  travelTimeAfter?: number | null
}

// Source: [cls] ./packages/nest/backend/src/db-models/calendar-events/calendar-events-deprecated.dto.ts
export type UpdateCalendarEventDto = {
  title?: string
  description?: string
  start?: string
  end?: string
  isAllDay?: boolean
  status?: CalendarEventStatus
  location?: string
  conferenceLink?: string
  organizer?: CalendarEventOrganizerDto
  attendees?: CalendarEventAttendeeDto[]
  visibility?: CalendarEventVisibility
  colorId?: string
  sendUpdates?: boolean
  recurrence?: string
  recurrenceUpdateType?: string
  recurrenceUpdateCutoffDate?: string
  timezone?: string
  conferenceType?: EventConferenceType
  travelTimeBefore?: number | null
  travelTimeAfter?: number | null
}

// Source: [cls] ./packages/nest/backend/src/db-models/calendar-events/calendar-events-deprecated.dto.ts
export type DeleteCalendarEventDto = {
  recurrenceUpdateType?: string
  recurrenceUpdateCutoffDate?: string
  sendUpdates?: boolean
  timezone?: string
}

// Source: [zod] ./packages/nest/backend/src/db-models/calendar-events/calendar-events.dto.ts
export type CalendarEventAttendeeSchema = {
  displayName?: string | undefined
  email: string
  isOptional: boolean
  isOrganizer: boolean
  status?: 'needsAction' | 'declined' | 'tentative' | 'accepted' | undefined
}

// Source: [zod] ./packages/nest/backend/src/db-models/calendar-events/calendar-events.dto.ts
export type CalendarEventSchema = {
  areAttendeesHidden: boolean | null
  attendees: CalendarEventAttendeeSchema[]
  bookingLinkId: string | null
  calendarId: string
  calendarUniqueId: string
  canAttendeesInvite: boolean | null
  canAttendeesModify: boolean | null
  conferenceLink: string | null
  description: string | null
  email: string
  end: string
  isAllDay: boolean
  iCalUid: string | null
  isCancelled: boolean
  isDeleted: boolean
  isPendingSync: boolean
  id: string
  location: string | null
  organizer: {} | null
  providerId: string
  providerType: 'APPLE' | 'GOOGLE' | 'MICROSOFT'
  recurrence: string | null
  recurringEventId: string | null
  status: 'BUSY' | 'FREE'
  start: string
  teamTaskId: string | null
  title: string
  travelTimeAfter: number | null
  travelTimeBefore: number | null
  travelTimeId: string | null
  travelTimeType?: 'after' | 'before' | null | undefined
  type:
    | 'BOOKING'
    | 'EXTERNAL_EVENT'
    | 'NORMAL'
    | 'RECURRING_EVENT'
    | 'TASK'
    | 'TRAVEL_TIME_BEFORE'
    | 'TRAVEL_TIME_AFTER'
  url: string | null
  visibility: 'CONFIDENTIAL' | 'DEFAULT' | 'PUBLIC' | 'PRIVATE'
  meetingTask?:
    | {
        id: string
        workspace: {
          id: string
          name: string
        }
        project: {
          id: string
          name: string
          color: string
        }
      }
    | undefined
}

// Source: [zod] ./packages/nest/backend/src/db-models/calendar-events/calendar-events.dto.ts
export type CalendarEventDto = CalendarEventSchema

// Source: [zod] ./packages/nest/backend/src/db-models/calendar-events/calendar-events.dto.ts
export type CalendarEventResponseSchema = {
  calendarEvent: CalendarEventSchema
  otherEvents?: CalendarEventSchema[] | undefined
}

// Source: [zod] ./packages/nest/backend/src/db-models/calendar-events/calendar-events.dto.ts
export type CalendarEventResponseDto = CalendarEventResponseSchema

// Source: [zod] ./packages/nest/backend/src/db-models/calendar-events/calendar-events.dto.ts
export type CalendarEventFetchSchema = {
  calendarEvents: unknown
  calendarsToSync: string[]
}

// Source: [zod] ./packages/nest/backend/src/db-models/calendar-events/calendar-events.dto.ts
export type CalendarEventFetchResponseDto = CalendarEventFetchSchema

// Source: [zod] ./packages/nest/backend/src/db-models/calendar-events/calendar-events.dto.ts
export type CalendarEventSearchResponseSchema = {
  calendarEvents: CalendarEventSchema[]
}

// Source: [zod] ./packages/nest/backend/src/db-models/calendar-events/calendar-events.dto.ts
export type CalendarEventSearchResponseDto = CalendarEventSearchResponseSchema

// Source: [zod] ./packages/nest/backend/src/db-models/files/files.dto.ts
export type UploadImageSchema = {
  workspaceId?: string | undefined
  resourceId?: string | undefined
  resourceType?:
    | keyof {
        TEAM_TASK: 'TEAM_TASK'
        PROJECT: 'PROJECT'
        RECURRING_TASK: 'RECURRING_TASK'
        TASK_DEFINITION: 'TASK_DEFINITION'
        PROJECT_DEFINITION: 'PROJECT_DEFINITION'
        NOTE: 'NOTE'
      }
    | undefined
}

// Source: [zod] ./packages/nest/backend/src/db-models/files/files.dto.ts
export type UploadImageDto = UploadImageSchema

// Source: [cls] ./packages/nest/backend/src/services/zoom/zoom.dto.ts
export type InitZoomTokensDto = {
  code: string
  hostUrl?: string
}

// Source: [cls] ./packages/nest/backend/src/services/zoom/zoom.dto.ts
export type CreateZoomMeetingDto = {
  topic?: string
  startTime: string
  duration: number
  recurringRule?: string
}

// Source: [cls] ./packages/nest/backend/src/services/zoom/zoom.dto.ts
export type DeleteZoomMeetingDto = {
  meetingId: string
}

// Source: [cls] ./packages/nest/backend/src/services/zoom/zoom.dto.ts
export type UpdateZoomMeetingDto = {
  meetingId: string
  topic?: string
  startTime: string
  duration?: number
  recurringRule?: string | null
}

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export { type TaskCreationSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export type TaskCreationDto = TaskCreationSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export { type ViewCreationSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export type ViewCreationDto = ViewCreationSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export { type AiPwtRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export type AiPwtRequestDto = AiPwtRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export { type AiPwtResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export type AiPwtResponse = AiPwtResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export { type AiInferProjectParametersRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export type AiInferProjectParametersRequest =
  AiInferProjectParametersRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export { type AiInferProjectParametersResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export type AiInferProjectParametersResponse =
  AiInferProjectParametersResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export { type AiGenerateProjectRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export type AiGenerateProjectRequest = AiGenerateProjectRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export { type AiGenerateProjectResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export type AiGenerateProjectResponse = AiGenerateProjectResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export { type CommitTaskUpdateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export type CommitTaskUpdate = CommitTaskUpdateSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export { type CommitTaskCreateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export type CommitTaskCreate = CommitTaskCreateSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export { type TaskModificationsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export type TaskModifications = TaskModificationsSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export { type AiCreateProjectCommitRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai.ts
export type AiCreateProjectCommitRequest = AiCreateProjectCommitRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/api-key.ts
export { type CreateApiKeyResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/api-key.ts
export type CreateApiKeyResponse = CreateApiKeyResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/api-key.ts
export { type BaseApiKeySchema }

// Source: [zod] ./packages/rpc/zod/src/server/api-key.ts
export type CreateApiKeyRequest = BaseApiKeySchema

// Source: [zod] ./packages/rpc/zod/src/server/api-key.ts
export type DeleteApiKeyRequest = BaseApiKeySchema

// Source: [zod] ./packages/rpc/zod/src/server/api-key.ts
export { type ApiKeySchema }

// Source: [zod] ./packages/rpc/zod/src/server/api-key.ts
export type ApiKeyResponse = ApiKeySchema

// Source: [zod] ./packages/rpc/zod/src/server/api-key.ts
export { type ApiKeyListResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/api-key.ts
export type ApiKeyListResponse = ApiKeyListResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/api.ts
export { type GetUsageSchema }

// Source: [zod] ./packages/rpc/zod/src/server/api.ts
export type GetUsageRequest = GetUsageSchema

// Source: [zod] ./packages/rpc/zod/src/server/api.ts
export { type ApiUsageSchema }

// Source: [zod] ./packages/rpc/zod/src/server/api.ts
export type ApiUsageResponse = ApiUsageSchema

// Source: [zod] ./packages/rpc/zod/src/server/blocking-timeslot.ts
export { type CreateBlockingTimeslotSchema }

// Source: [zod] ./packages/rpc/zod/src/server/blocking-timeslot.ts
export type CreateBlockingTimeslotRequest = CreateBlockingTimeslotSchema

// Source: [zod] ./packages/rpc/zod/src/server/blocking-timeslot.ts
export { type UpdateBlockingTimeslotSchema }

// Source: [zod] ./packages/rpc/zod/src/server/blocking-timeslot.ts
export type UpdateBlockingTimeslotRequest = UpdateBlockingTimeslotSchema

// Source: [zod] ./packages/rpc/zod/src/server/blocking-timeslot.ts
export { type DeleteManyBlockingTimeslotsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/blocking-timeslot.ts
export type DeleteManyBlockingTimeslotsRequest =
  DeleteManyBlockingTimeslotsSchema

// Source: [zod] ./packages/rpc/zod/src/server/blocking-timeslot.ts
export { type BlockingTimeslotResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/blocking-timeslot.ts
export type BlockingTimeslotResponse = BlockingTimeslotResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/blocking-timeslot.ts
export { type FetchBlockingTimeslotsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/blocking-timeslot.ts
export type FetchBlockingTimeslotsResponse =
  FetchBlockingTimeslotsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export { type CancelBookingDtoSchema }

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export type CancelBookingDto = CancelBookingDtoSchema

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export { type RecheduleBookingSchema }

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export type RescheduleBookingDto = RecheduleBookingSchema

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export { type ManualLinkCreateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export type CreateManualLinkDto = ManualLinkCreateSchema

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export { type DeleteTemplateLinkSchema }

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export type DeleteTemplateLinkDto = DeleteTemplateLinkSchema

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export { type ReorderTemplateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export type ReorderTemplateDto = ReorderTemplateSchema

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export { type SetUrlPrefixSchema }

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export type SetUrlPrefixDto = SetUrlPrefixSchema

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export { type UpdateBookingSettingsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export type UpdateBookingSettingsDto = UpdateBookingSettingsSchema

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export { type UpdateRichAvailabilityMessageSchema }

// Source: [zod] ./packages/rpc/zod/src/server/booking.ts
export type UpdateRichAvailabilityMessageDto =
  UpdateRichAvailabilityMessageSchema

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export { type CreateCalendarSchema }

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export type CreateCalendarRequest = CreateCalendarSchema

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export { type UpdateCalendarSchema }

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export type UpdateCalendarRequest = UpdateCalendarSchema

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export { type UpdateCalendarsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export type UpdateCalendarsRequest = UpdateCalendarsSchema

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export { type OldCalendarSchema }

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export type CalendarDto = OldCalendarSchema

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export { type GetAllCalendarsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export type GetAllCalendarsResponse = GetAllCalendarsSchema

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export { type CalendarResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export type CalendarResponse = CalendarResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export { type CalendarsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/calendars.ts
export type CalendarsResponse = CalendarsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/comments.ts
export { type EditTaskCommentSchema }

// Source: [zod] ./packages/rpc/zod/src/server/comments.ts
export type EditTaskCommentDto = EditTaskCommentSchema

// Source: [zod] ./packages/rpc/zod/src/server/comments.ts
export { type CreateTaskCommentSchema }

// Source: [zod] ./packages/rpc/zod/src/server/comments.ts
export type CreateTaskCommentDto = CreateTaskCommentSchema

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export { type CustomFieldGetAll }

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export type CustomFieldGetAllResponse = CustomFieldGetAll

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export { type CreateCustomFieldSchema }

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export type CreateCustomFieldRequest = CreateCustomFieldSchema

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export { type SingleCustomFieldResponse }

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export type CreateCustomFieldResponse = SingleCustomFieldResponse

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export { type MultipleCustomFieldResponse }

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export type CreateCustomFieldsResponse = MultipleCustomFieldResponse

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export { type UpdateCustomFieldSchema }

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export type UpdateCustomFieldRequest = UpdateCustomFieldSchema

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export type UpdateCustomFieldResponse = SingleCustomFieldResponse

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export { type GetCustomFieldCategories }

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export type GetCustomFieldCategoriesResponse = GetCustomFieldCategories

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export { type CopyCustomFieldToWorkspaceSchema }

// Source: [zod] ./packages/rpc/zod/src/server/custom-fields.ts
export type CopyCustomFieldToWorkspaceRequest = CopyCustomFieldToWorkspaceSchema

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export { type CreateProjectDefinitionRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export type CreateProjectDefinitionRequest =
  CreateProjectDefinitionRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export { type GetSingleProjectDefinitionResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export type GetSingleProjectDefinitionResponse =
  GetSingleProjectDefinitionResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export { type GetProjectDefinitionsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export type GetProjectDefinitionsResponse = GetProjectDefinitionsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export { type UpdateProjectDefinitionRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export type UpdateProjectDefinitionRequest =
  UpdateProjectDefinitionRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export { type CopyProjectDefinitionSchema }

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export type CopyProjectDefinitionRequest = CopyProjectDefinitionSchema

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export { type CreateStageDefinitionRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export type CreateStageDefinitionRequest = CreateStageDefinitionRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export { type UpdateStageDefinitionRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export type UpdateStageDefinitionRequest = UpdateStageDefinitionRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export { type UpdateManyStageDefinitionRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export type UpdateManyStageDefinitionRequest =
  UpdateManyStageDefinitionRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export { type GetSingleStageDefinitionResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export type GetSingleStageDefinitionResponse =
  GetSingleStageDefinitionResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export { type GetManyStageDefinitionsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export type GetManyStageDefinitionsResponse =
  GetManyStageDefinitionsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export { type UpdateStageDeadlineRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export type UpdateStageDeadlineRequest = UpdateStageDeadlineRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export { type CreateLegacyProjectShimResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/definitions.ts
export type CreateLegacyProjectShimResponse =
  CreateLegacyProjectShimResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/disputes-admin.ts
export { type FetchReadyDisputesResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/disputes-admin.ts
export type FetchReadyDisputesResponseDto = FetchReadyDisputesResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/disputes-admin.ts
export { type EarlyFraudWarningsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/disputes-admin.ts
export type EarlyFraudWarningsResponseDto = EarlyFraudWarningsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/email-accounts.ts
export { type AddEmailAccountSchema }

// Source: [zod] ./packages/rpc/zod/src/server/email-accounts.ts
export type AddEmailAccountDto = AddEmailAccountSchema

// Source: [zod] ./packages/rpc/zod/src/server/email-accounts.ts
export { type EmailAccountResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/email-accounts.ts
export type EmailAccountResponse = EmailAccountResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/labels.ts
export { type CreateLabelSchema }

// Source: [zod] ./packages/rpc/zod/src/server/labels.ts
export type CreateLabelDto = CreateLabelSchema

// Source: [zod] ./packages/rpc/zod/src/server/labels.ts
export { type UpdateLabelSchema }

// Source: [zod] ./packages/rpc/zod/src/server/labels.ts
export type UpdateLabelDto = UpdateLabelSchema

// Source: [zod] ./packages/rpc/zod/src/server/metrics.ts
export { type BatchMetricSchema }

// Source: [zod] ./packages/rpc/zod/src/server/metrics.ts
export type BatchMetricDto = BatchMetricSchema

// Source: [zod] ./packages/rpc/zod/src/server/notifications.ts
export { type UpdateNotificationPreferencesSchema }

// Source: [zod] ./packages/rpc/zod/src/server/notifications.ts
export type UpdateNotificationPreferencesResponse =
  UpdateNotificationPreferencesSchema

// Source: [zod] ./packages/rpc/zod/src/server/notifications.ts
export type UpdateNotificationPreferencesRequest =
  UpdateNotificationPreferencesSchema

// Source: [zod] ./packages/rpc/zod/src/server/notifications.ts
export { type SavePushDeviceSchema }

// Source: [zod] ./packages/rpc/zod/src/server/notifications.ts
export type SavePushDeviceResponse = SavePushDeviceSchema

// Source: [zod] ./packages/rpc/zod/src/server/notifications.ts
export type SavePushDeviceRequest = SavePushDeviceSchema

// Source: [zod] ./packages/rpc/zod/src/server/on-login.ts
export { type OnLoginResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/on-login.ts
export type OnLoginResponse = OnLoginResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/personal-email.ts
export { type SendEmailSchema }

// Source: [zod] ./packages/rpc/zod/src/server/personal-email.ts
export type SendEmailDto = SendEmailSchema

// Source: [zod] ./packages/rpc/zod/src/server/projects.ts
export { type CreateProjectSchema }

// Source: [zod] ./packages/rpc/zod/src/server/projects.ts
export type CreateProjectDto = CreateProjectSchema

// Source: [zod] ./packages/rpc/zod/src/server/projects.ts
export { type UpdateProjectSchema }

// Source: [zod] ./packages/rpc/zod/src/server/projects.ts
export type UpdateProjectDto = UpdateProjectSchema

// Source: [zod] ./packages/rpc/zod/src/server/projects.ts
export { type CreateProjectCommentSchema }

// Source: [zod] ./packages/rpc/zod/src/server/projects.ts
export type CreateProjectCommentDto = CreateProjectCommentSchema

// Source: [zod] ./packages/rpc/zod/src/server/recently-opened.ts
export { type TrackSchema }

// Source: [zod] ./packages/rpc/zod/src/server/recently-opened.ts
export type TrackDto = TrackSchema

// Source: [zod] ./packages/rpc/zod/src/server/recently-opened.ts
export { type TrackTaskSchema }

// Source: [zod] ./packages/rpc/zod/src/server/recently-opened.ts
export type TrackTaskDto = TrackTaskSchema

// Source: [zod] ./packages/rpc/zod/src/server/recently-opened.ts
export { type RecentlyOpenedTasksResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/recently-opened.ts
export type RecentlyOpenedTasksResponse = RecentlyOpenedTasksResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/recently-opened.ts
export { type RecentlyOpenedEntitiesResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/recently-opened.ts
export type RecentlyOpenedEntitiesResponse =
  RecentlyOpenedEntitiesResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/recurring-tasks.ts
export { type CreateRecurringTaskSchema }

// Source: [zod] ./packages/rpc/zod/src/server/recurring-tasks.ts
export type CreateRecurringTaskDto = CreateRecurringTaskSchema

// Source: [zod] ./packages/rpc/zod/src/server/recurring-tasks.ts
export { type UpdateRecurringTaskSchema }

// Source: [zod] ./packages/rpc/zod/src/server/recurring-tasks.ts
export type UpdateRecurringTaskDto = UpdateRecurringTaskSchema

// Source: [zod] ./packages/rpc/zod/src/server/referral.ts
export { type CreateReferralSchema }

// Source: [zod] ./packages/rpc/zod/src/server/referral.ts
export type CreateReferralDto = CreateReferralSchema

// Source: [zod] ./packages/rpc/zod/src/server/referral.ts
export { type getInviteeReferralSchema }

// Source: [zod] ./packages/rpc/zod/src/server/referral.ts
export type GetInviteeReferralDto = getInviteeReferralSchema

// Source: [zod] ./packages/rpc/zod/src/server/referral.ts
export { type ReferralSchema }

// Source: [zod] ./packages/rpc/zod/src/server/referral.ts
export type ReferralDto = ReferralSchema

// Source: [zod] ./packages/rpc/zod/src/server/referral.ts
export { type TrackPartnerStackLinkSchema }

// Source: [zod] ./packages/rpc/zod/src/server/referral.ts
export type TrackPartnerStackLinkDto = TrackPartnerStackLinkSchema

// Source: [zod] ./packages/rpc/zod/src/server/search.ts
export { type SearchSchema }

// Source: [zod] ./packages/rpc/zod/src/server/search.ts
export type SearchDto = SearchSchema

// Source: [zod] ./packages/rpc/zod/src/server/search.ts
export { type SearchResultsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/search.ts
export type SearchResultsResponse = SearchResultsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/status.ts
export { type CreateTaskStatusSchema }

// Source: [zod] ./packages/rpc/zod/src/server/status.ts
export type CreateTaskStatusDto = CreateTaskStatusSchema

// Source: [zod] ./packages/rpc/zod/src/server/status.ts
export type UpdateTaskStatusDto = CreateTaskStatusSchema

// Source: [zod] ./packages/rpc/zod/src/server/stripe-admin.ts
export { type PayInvoiceSchema }

// Source: [zod] ./packages/rpc/zod/src/server/stripe-admin.ts
export type PayInvoiceDto = PayInvoiceSchema

// Source: [zod] ./packages/rpc/zod/src/server/stripe-admin.ts
export { type AdjustCreditBalanceSchema }

// Source: [zod] ./packages/rpc/zod/src/server/stripe-admin.ts
export type AdjustCreditBalanceDto = AdjustCreditBalanceSchema

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export { type StripeSubscriptionSchema }

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export type StripeSubscriptionDto = StripeSubscriptionSchema

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export { type CombinedSubscriptionSchema }

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export type CombinedSubscriptionResponse = CombinedSubscriptionSchema

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export { type UnconfirmedSubscriptionSchema }

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export type DeleteUnconfirmedSubscriptionDto = UnconfirmedSubscriptionSchema

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export { type UpdateSubscriptionFeatureTierSchema }

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export type UpdateSubscriptionFeatureTierDto =
  UpdateSubscriptionFeatureTierSchema

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export { type ApplyCouponSchema }

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export type ApplyCouponDto = ApplyCouponSchema

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export { type TeamPriceSchema }

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export type GetTeamPricesDto = TeamPriceSchema

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export { type GetTierPricesSchema }

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export type GetTierPricesResponse = GetTierPricesSchema

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export { type CreateSubscriptionStripePortalLinkSchema }

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export type CreateSubscriptionStripePortalLinkDto =
  CreateSubscriptionStripePortalLinkSchema

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export { type StripePaymentMethodSchema }

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export type StripePaymentMethodDto = StripePaymentMethodSchema

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export { type GetTeamPricesUnauthorizedRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export type GetTeamPricesUnauthorizedRequestDto =
  GetTeamPricesUnauthorizedRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export { type GetTierPricesUnauthorizedRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export type GetTierPricesUnauthorizedRequestDto =
  GetTierPricesUnauthorizedRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export { type UpdateDefaultPaymentMethodSchema }

// Source: [zod] ./packages/rpc/zod/src/server/subscription.ts
export type UpdateDefaultPaymentMethodDto = UpdateDefaultPaymentMethodSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-admin.ts
export { type MigrateTeamMemberSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-admin.ts
export type MigrateTeamMemberDto = MigrateTeamMemberSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-admin.ts
export { type GetTeamSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-admin.ts
export type GetTeamQuery = GetTeamSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-subscription-admin.ts
export { type TeamsSubscriptionSeatsUpdateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-subscription-admin.ts
export type TeamsSubscriptionSeatsUpdateDto = TeamsSubscriptionSeatsUpdateSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-tasks.ts
export { type CreateTeamTaskSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-tasks.ts
export type CreateTeamTaskDto = CreateTeamTaskSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-tasks.ts
export { type UpdateTeamTaskSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-tasks.ts
export type UpdateTeamTaskDto = UpdateTeamTaskSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-tasks.ts
export { type GetPersonalCompletedTasksSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-tasks.ts
export type GetPersonalCompletedTasksDto = GetPersonalCompletedTasksSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-tasks.ts
export { type StartTaskSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-tasks.ts
export type StartTaskDto = StartTaskSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-tasks.ts
export { type StopTaskSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-tasks.ts
export type StopTaskDto = StopTaskSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-tasks.ts
export { type SnoozeTaskSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-tasks.ts
export type SnoozeTaskDto = SnoozeTaskSchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type CheckTeamEligibilitySchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type CheckTeamEligibilityDto = CheckTeamEligibilitySchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type InviteeSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type InviteTeamMemberDto = InviteeSchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type InviteTeamMemberSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type InviteTeamMemberWithSeatsDto = InviteTeamMemberSchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type CreateSetupIntentRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type CreateSetupIntentRequestDto = CreateSetupIntentRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type CreateSetupIntentResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type CreateSetupIntentResponseDto = CreateSetupIntentResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type EditTeamSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type EditTeamDto = EditTeamSchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type ResubscribeTeamSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type ResubscribeTeamDto = ResubscribeTeamSchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type UpdateSeatsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type UpdateBucketSeatsDto = UpdateSeatsSchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type UpdateTeamNameSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type UpdateTeamNameDto = UpdateTeamNameSchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type UpdateTeamMemberSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type UpdateTeamMemberDto = UpdateTeamMemberSchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type CreateTeamSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type CreateTeamDto = CreateTeamSchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type UpdateTeamMemberRolesSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type UpdateTeamMemberRolesDto = UpdateTeamMemberRolesSchema

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export { type ChangeBillingAdminSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team.ts
export type ChangeBillingAdminDto = ChangeBillingAdminSchema

// Source: [zod] ./packages/rpc/zod/src/server/teammate-events.ts
export { type GetGanttOutOfOfficeEventsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/teammate-events.ts
export type GanttOutOfOfficeEventsRequest = GetGanttOutOfOfficeEventsSchema

// Source: [zod] ./packages/rpc/zod/src/server/teammate-events.ts
export { type GetTeammateOutOfOfficeEventsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/teammate-events.ts
export type TeammateOutOfOfficeEventsRequest =
  GetTeammateOutOfOfficeEventsSchema

// Source: [zod] ./packages/rpc/zod/src/server/teammate-events.ts
export { type TeammateOutOfOfficeEventsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/teammate-events.ts
export type TeammateOutOfOfficeEventsResponse =
  TeammateOutOfOfficeEventsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/teammate-events.ts
export { type GanttOutOfOfficeEventsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/teammate-events.ts
export type GanttOutOfOfficeEventsResponse =
  GanttOutOfOfficeEventsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export { type CreateTaskForTemplateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export type CreateTaskForTemplateDto = CreateTaskForTemplateSchema

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export { type CreateTemplateTaskSchema }

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export type CreateTemplateTaskDto = CreateTemplateTaskSchema

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export { type UpdateTemplateTaskSchema }

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export type UpdateTemplateTaskDto = UpdateTemplateTaskSchema

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export { type CreateTemplatedProjectSchema }

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export type CreateTemplateProjectDto = CreateTemplatedProjectSchema

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export { type UpdateProjectForTemplateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export type UpdateProjectForTemplateDto = UpdateProjectForTemplateSchema

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export { type UpdateTemplateProjectSchema }

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export type UpdateTemplateProjectDto = UpdateTemplateProjectSchema

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export { type TaskModifierSchema }

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export type TaskModifierDto = TaskModifierSchema

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export { type UseProjectTemplateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/templates.ts
export type UseProjectTemplateDto = UseProjectTemplateSchema

// Source: [zod] ./packages/rpc/zod/src/server/token.ts
export { type GenerateCustomMicrosoftTokenSchema }

// Source: [zod] ./packages/rpc/zod/src/server/token.ts
export type GenerateCustomMicrosoftTokenDto = GenerateCustomMicrosoftTokenSchema

// Source: [zod] ./packages/rpc/zod/src/server/token.ts
export { type InitGoogleTokensSchema }

// Source: [zod] ./packages/rpc/zod/src/server/token.ts
export type InitGoogleTokensDto = InitGoogleTokensSchema

// Source: [zod] ./packages/rpc/zod/src/server/tutorials.ts
export { type AddCompletedTutorialSchema }

// Source: [zod] ./packages/rpc/zod/src/server/tutorials.ts
export type AddCompletedTutorialDto = AddCompletedTutorialSchema

// Source: [zod] ./packages/rpc/zod/src/server/tutorials.ts
export { type CompletedTutorialsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/tutorials.ts
export type CompletedTutorialsResponse = CompletedTutorialsSchema

// Source: [zod] ./packages/rpc/zod/src/server/tutorials.ts
export { type CoursePayloadSchema }

// Source: [zod] ./packages/rpc/zod/src/server/tutorials.ts
export type CoursePayloadResponse = CoursePayloadSchema

// Source: [zod] ./packages/rpc/zod/src/server/user-admin.ts
export { type ChangeBillingCycleSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user-admin.ts
export type ChangeBillingCycleDto = ChangeBillingCycleSchema

// Source: [zod] ./packages/rpc/zod/src/server/user-admin.ts
export { type SetIapConsentSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user-admin.ts
export type SetIapConsentDto = SetIapConsentSchema

// Source: [zod] ./packages/rpc/zod/src/server/user-admin.ts
export { type ApplyDiscountSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user-admin.ts
export type ApplyDiscountDto = ApplyDiscountSchema

// Source: [zod] ./packages/rpc/zod/src/server/user-admin.ts
export { type SetConsumptionInformationSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user-admin.ts
export type SetConsumptionInformationDto = SetConsumptionInformationSchema

// Source: [zod] ./packages/rpc/zod/src/server/user-admin.ts
export { type CreateCreditNoteSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user-admin.ts
export type CreateCreditNoteDto = CreateCreditNoteSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type AddStripeLocalizePaymentSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type AddStripeLocalizePaymentDto = AddStripeLocalizePaymentSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type CheckPendingInviteResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type CheckPendingInviteResponseDto = CheckPendingInviteResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type CreatePaymentIntentUnauthorizedSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type CreatePaymentIntentUnauthorizedDto =
  CreatePaymentIntentUnauthorizedSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type CreateStripeLocalizeSubscriptionAfterHoldSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type CreateStripeLocalizeSubscriptionAfterHoldDto =
  CreateStripeLocalizeSubscriptionAfterHoldSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type CreateStripeLocalizeSubscriptionAfterHoldUnauthorizedSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type CreateStripeLocalizeSubscriptionAfterHoldUnauthorizedDto =
  CreateStripeLocalizeSubscriptionAfterHoldUnauthorizedSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type CreateStripePortalLinkSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type CreateStripePortalLinkDto = CreateStripePortalLinkSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type CreateSubscriptionSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type CreateSubscriptionDto = CreateSubscriptionSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type UpdateEmailSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type UpdateEmailDto = UpdateEmailSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type UpdateNameSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type UpdateNameDto = UpdateNameSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type UpdatePasswordSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type UpdatePasswordDto = UpdatePasswordSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type UpdateProfilePicSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type UpdateProfilePicDto = UpdateProfilePicSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type UserSubCancellationReasonSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type UserSubCancellationReasonDto = UserSubCancellationReasonSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type StartEmailVerificationResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type StartEmailVerificationResponse =
  StartEmailVerificationResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type CheckEmailVerificationCodeSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type CheckEmailVerificationCodeDto = CheckEmailVerificationCodeSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type CheckEmailVerificationCodeResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type CheckEmailVerificationCodeResponse =
  CheckEmailVerificationCodeResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export { type GetEmailVerificationStatusResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/user.ts
export type GetEmailVerificationStatusResponse =
  GetEmailVerificationStatusResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type CreateWorkspaceViewSchema }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type CreateWorkspaceViewDto = CreateWorkspaceViewSchema

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type CreateViewDataSchema }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type CreateViewDto = CreateViewDataSchema

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type UpdateViewDataSchema }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type UpdateWorkspaceViewDto = UpdateViewDataSchema

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type UpdateViewSchema }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type UpdateViewDto = UpdateViewSchema

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type SingleViewResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type SingleViewResponse = SingleViewResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type WorkspaceVersionedViewArray }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type GetAllWorkspaceViewsResponse = WorkspaceVersionedViewArray

// Source: [zod] ./packages/rpc/zod/src/server/models/views/version-1.ts
export { type WorkspaceVersionedView }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type SingleWorkspaceViewResponse = WorkspaceVersionedView

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type MultipleViewResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type MultipleViewResponse = MultipleViewResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type CreateViewV2Schema }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type CreateViewV2Dto = CreateViewV2Schema

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type UpdateViewV2Schema }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type UpdateViewV2Dto = UpdateViewV2Schema

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type SingleViewResponseV3Schema }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type SingleViewResponseV3 = SingleViewResponseV3Schema

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type MultipleViewResponseV3Schema }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type MultipleViewResponseV3 = MultipleViewResponseV3Schema

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type CreateViewV3Schema }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type CreateViewV3Dto = CreateViewV3Schema

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export { type UpdateViewV3Schema }

// Source: [zod] ./packages/rpc/zod/src/server/views.ts
export type UpdateViewV3Dto = UpdateViewV3Schema

// Source: [zod] ./packages/rpc/zod/src/server/workspaces-admin.ts
export { type AdminCopyProjectDefinitionSchema }

// Source: [zod] ./packages/rpc/zod/src/server/workspaces-admin.ts
export type AdminCopyProjectDefinitionRequest = AdminCopyProjectDefinitionSchema

// Source: [zod] ./packages/rpc/zod/src/server/workspaces-admin.ts
export { type AdminCopyDemoProjectDefinitionSchema }

// Source: [zod] ./packages/rpc/zod/src/server/workspaces-admin.ts
export type AdminCopyDemoProjectDefinitionRequest =
  AdminCopyDemoProjectDefinitionSchema

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export { type CreateWorkspaceSchema }

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export type CreateWorkspaceRequest = CreateWorkspaceSchema

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export { type AddUserToWorkspaceSchema }

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export type AddUserToWorkspaceRequest = AddUserToWorkspaceSchema

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export { type UpdateWorkspaceSchema }

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export type UpdateWorkspaceRequest = UpdateWorkspaceSchema

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export { type UpdateWorkspaceMemberSchema }

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export type UpdateWorkspaceMemberRequest = UpdateWorkspaceMemberSchema

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export { type WorkspaceSummarySchema }

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export type WorkspaceSummaryResponse = WorkspaceSummarySchema

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export { type UpdateWorkspaceMembersRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export type UpdateWorkspaceMembersRequest = UpdateWorkspaceMembersRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export { type RemoveWorkspaceMembersRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/workspaces.ts
export type RemoveWorkspaceMembersRequest = RemoveWorkspaceMembersRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/deprecated/user-settings.ts
export { type DeprecatedUserSettingsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/deprecated/user-settings.ts
export type DeprecatedUserSettingsResponse = DeprecatedUserSettingsSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-schedule/get-context.ts
export { type GetTeamScheduleContextSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-schedule/get-context.ts
export type GetTeamScheduleContextResponse = GetTeamScheduleContextSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-schedule/team-schedule.ts
export { type GetTeamScheduleTasksBodySchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-schedule/team-schedule.ts
export type GetTeamScheduleRequest = GetTeamScheduleTasksBodySchema

// Source: [zod] ./packages/rpc/zod/src/server/team-schedule/team-schedule.ts
export { type CreateTeamViewSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-schedule/team-schedule.ts
export type CreateTeamViewRequest = CreateTeamViewSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-schedule/team-schedule.ts
export { type UpdateTeamViewSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-schedule/team-schedule.ts
export type UpdateTeamViewRequest = UpdateTeamViewSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-schedule/team-schedule.ts
export { type TeamScheduleMutateViewResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-schedule/team-schedule.ts
export type TeamScheduleMutateViewResponse =
  TeamScheduleMutateViewResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/team-schedule/team-schedule.ts
export { type GetTeamViewsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/team-schedule/team-schedule.ts
export type GetTeamViewsResponse = GetTeamViewsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/action-items/dtos.ts
export { type HHActionItemsQueryRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/action-items/dtos.ts
export type HHActionItemsQueryRequestDto = HHActionItemsQueryRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/action-items/dtos.ts
export { type HHActionItemsQueryResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/action-items/dtos.ts
export type HHActionItemsQueryResponseDto = HHActionItemsQueryResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/calendar/dtos.ts
export { type AIHH_CreateCalendarEventRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/calendar/dtos.ts
export type AIHH_CreateCalendarEventRequest =
  AIHH_CreateCalendarEventRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export { type HHGetMeetingInsightsQuerySchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export type HHGetMeetingInsightsQueryDto = HHGetMeetingInsightsQuerySchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/meeting-insights.ts
export { type HHMeetingInsightsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export type HHGetMeetingInsightsResponse = HHMeetingInsightsSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export { type HHGetMeetingInsightsWithEvent }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export type HHGetMeetingInsightsWithEventResponse =
  HHGetMeetingInsightsWithEvent

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export { type HHGetMeetingInsightPage }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export type HHGetMeetingInsightPageDto = HHGetMeetingInsightPage

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export type HHGetMeetingInsightPageResponse = HHGetMeetingInsightPage

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export { type HHApproveActionItemSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export type HHApproveActionItemSchemaRequestDto = HHApproveActionItemSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export { type HHApproveActionItemResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export type HHApproveActionItemResponseSchemaDto =
  HHApproveActionItemResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export { type HHSendBotNowResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export type HHSendBotNowResponseDto = HHSendBotNowResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export { type HHUpdateMeetingInsightsRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/dtos.ts
export type HHUpdateMeetingInsightsRequestDto =
  HHUpdateMeetingInsightsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/filters.dtos.ts
export { type HHMeetingInsightsRequestQuerySchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/filters.dtos.ts
export type HHMeetingInsightsRequestQueryDto =
  HHMeetingInsightsRequestQuerySchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/filters.dtos.ts
export { type HHMeetingInsightsQueryResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/filters.dtos.ts
export type HHMeetingInsightsQueryResponseDto =
  HHMeetingInsightsQueryResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/meeting-insights.ts
export type HHMeetingInsights = HHMeetingInsightsSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/meeting-insights.ts
export { type HHMeetingActionItemSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/meeting-insights.ts
export type HHMeetingActionItem = HHMeetingActionItemSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/meeting-insights.ts
export { type HHMeetingActionItemTaskDataSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/meeting-insights.ts
export type HHMeetingActionItemTaskData = HHMeetingActionItemTaskDataSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/meeting-insights.ts
export { type AIHH_CreateCalendarEventResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/meeting-insights.ts
export type AIHH_CreateCalendarEventResponse =
  AIHH_CreateCalendarEventResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/settings.dtos.ts
export { type HHTeamMeetingInsightsSettingsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/settings.dtos.ts
export type HHGetMeetingInsightsSettingsResponseDto =
  HHTeamMeetingInsightsSettingsSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/meeting-insights/settings.dtos.ts
export type HHUpsertMeetingInsightsSettingsRequestDto =
  HHTeamMeetingInsightsSettingsSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/permissions/dtos.ts
export { type CreatePolicyRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/permissions/dtos.ts
export type CreatePolicyRequestDto = CreatePolicyRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/permissions/dtos.ts
export { type UpdatePolicyRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/permissions/dtos.ts
export type UpdatePolicyRequestDto = UpdatePolicyRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/permissions/dtos.ts
export { type PermissionPolicy }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/permissions/dtos.ts
export type CreatePolicyResponseDto = PermissionPolicy

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/permissions/dtos.ts
export { type ListPolicyResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/ai-hackerhouse/permissions/dtos.ts
export type ListPolicyResponseDto = ListPolicyResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type SingleNoteResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type SingleNoteResponse = SingleNoteResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type NotesQueryRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type NotesQueryRequest = NotesQueryRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type NotesQueryReponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type NotesQueryResponse = NotesQueryReponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type CreateNoteRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type CreateNoteRequest = CreateNoteRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type UpdateNoteRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type UpdateNoteRequest = UpdateNoteRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type CreateMentionsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type CreateMentionsRequest = CreateMentionsSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type DeleteMentionsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type DeleteMentionsRequest = DeleteMentionsSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type NoteSnapshotsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type NoteSnapshotsResponse = NoteSnapshotsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type NoteAttachmentUrlSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type NoteAttachmentUrlResponse = NoteAttachmentUrlSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type CreateTaskFromSelectionDtoSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type CreateTaskFromSelectionDto = CreateTaskFromSelectionDtoSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type CreateMentionsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type CreateMentionsResponse = CreateMentionsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type SummarizeTextDtoSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type SummarizeTextDto = SummarizeTextDtoSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type SummarizeTextResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type SummarizeTextResponse = SummarizeTextResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type RewriteTextDtoSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type RewriteTextDto = RewriteTextDtoSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type RewriteTextResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type RewriteTextResponse = RewriteTextResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type CopyNoteDtoSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type CopyNoteDto = CopyNoteDtoSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type EstimateTaskPropsDtoSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type EstimateTaskPropsDto = EstimateTaskPropsDtoSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type CreateContentDtoSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type CreateContentDto = CreateContentDtoSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export { type CreateContentResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/notes/dtos.ts
export type CreateContentResponse = CreateContentResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/threads/dtos.ts
export { type UpdateThreadRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/threads/dtos.ts
export type UpdateThreadRequest = UpdateThreadRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/beta/threads/dtos.ts
export { type SingleThreadResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/beta/threads/dtos.ts
export type SingleThreadResponse = SingleThreadResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/access/dtos.ts
export { type AccessCheckResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/access/dtos.ts
export type AccessCheckResponse = AccessCheckResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/action-items/dtos.ts
export { type ActionItemQueryRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/action-items/dtos.ts
export type ActionItemQueryRequest = ActionItemQueryRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/action-items/dtos.ts
export { type ApproveActionItemRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/action-items/dtos.ts
export type ApproveActionItemRequest = ApproveActionItemRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/action-items/dtos.ts
export { type ActionItemResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/action-items/dtos.ts
export type ActionItemResponse = ActionItemResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/agents/dtos.ts
export { type AgentWorkflowV2SingleIdResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/agents/dtos.ts
export type AgentWorkflowV2SingleResponse =
  AgentWorkflowV2SingleIdResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/agents/dtos.ts
export { type AgentWorkflowV2ListingResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/agents/dtos.ts
export type AgentWorkflowV2ListingResponse =
  AgentWorkflowV2ListingResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/agents/dtos.ts
export { type CreateAgentWorkflowRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/agents/dtos.ts
export type CreateAgentWorkflowRequest = CreateAgentWorkflowRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/agents/dtos.ts
export type CreateAgentWorkflowResponse = AgentWorkflowV2SingleIdResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/agents/dtos.ts
export { type GetAgentWorkflowsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/agents/dtos.ts
export type GetAgentWorkflowsResponse = GetAgentWorkflowsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/agents/dtos.ts
export { type RunAgentWorkflowSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/agents/dtos.ts
export type RunAgentWorkflowRequest = RunAgentWorkflowSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export { type CreateCalendarEventSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export type CreateCalendarEventRequest = CreateCalendarEventSchema

// Source: [zod] ./packages/rpc/zod/src/server/models/calendar-event.ts
export { type CalendarEventSchemaV2 }

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export type CreateCalendarEventResponse = CalendarEventSchemaV2

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export { type UpdateCalendarEventSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export type UpdateCalendarEventRequest = UpdateCalendarEventSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export { type UpdateCalendarEventResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export type UpdateCalendarEventResponse = UpdateCalendarEventResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export { type AddCalendarEventToProjectSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export type AddCalendarEventToProjectRequest = AddCalendarEventToProjectSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export { type AddMeetingInsightsToCalendarEventSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export type AddMeetingInsightsToCalendarEventRequest =
  AddMeetingInsightsToCalendarEventSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export { type GetSchedulingAssistantEventsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export type GetSchedulingAssistantEventsRequest =
  GetSchedulingAssistantEventsSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export { type SchedulingAssistantUserEventsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export type SchedulingAssistantUserEvents =
  SchedulingAssistantUserEventsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export { type SchedulingAssistantEventsResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/calendar-events/dtos.ts
export type SchedulingAssistantEventsResponse =
  SchedulingAssistantEventsResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/charts/dtos.ts
export { type ChartQueryRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/charts/dtos.ts
export type ChartQueryRequest = ChartQueryRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/charts/dtos.ts
export { type ChartQueryResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/charts/dtos.ts
export type ChartQueryResponse = ChartQueryResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/charts/dtos.ts
export { type ChartTaskListResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/charts/dtos.ts
export type ChartTaskListResponse = ChartTaskListResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/charts/dtos.ts
export { type ChartProjectListResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/charts/dtos.ts
export type ChartProjectListResponse = ChartProjectListResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/comments/dtos.ts
export { type CreateCommentSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/comments/dtos.ts
export type CreateCommentRequest = CreateCommentSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/comments/dtos.ts
export { type UpdateCommentSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/comments/dtos.ts
export type UpdateCommentRequest = UpdateCommentSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/comments/dtos.ts
export { type CommentsV2SingleIdResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/comments/dtos.ts
export type CommentsV2SingleResponse = CommentsV2SingleIdResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/comments/dtos.ts
export { type ReactionContentSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/comments/dtos.ts
export type ReactionContentRequest = ReactionContentSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/feed-entries/dtos.ts
export { type FeedEntriesV2GetAllResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/feed-entries/dtos.ts
export type FeedEntriesV2GetAllResponse = FeedEntriesV2GetAllResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/files/dtos.ts
export { type UploadFileRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/files/dtos.ts
export type UploadFileRequest = UploadFileRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/files/dtos.ts
export { type UpdateUploadedFileRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/files/dtos.ts
export type UpdateUploadedFileRequest = UpdateUploadedFileRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/files/dtos.ts
export { type UploadFileResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/files/dtos.ts
export type UploadFileResponse = UploadFileResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/files/dtos.ts
export { type UploadedFileV2SingleResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/files/dtos.ts
export type UploadedFileV2SingleResponse = UploadedFileV2SingleResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export { type FoldersV2ResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export type FoldersV2Response = FoldersV2ResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export { type CreateFolderRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export type CreateFolderRequest = CreateFolderRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export { type SingleFolderResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export type CreateFolderResponse = SingleFolderResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export { type AddItemToFolderRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export type AddItemToFolderRequest = AddItemToFolderRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export type AddItemToFolderResponse = SingleFolderResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export { type UpdateFolderRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export type UpdateFolderRequest = UpdateFolderRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export type UpdateFolderResponse = SingleFolderResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export { type UpdateItemInFolderRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export type UpdateFolderItemRequest = UpdateItemInFolderRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export { type SingleFolderItemResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export type SingleFolderItemResponse = SingleFolderItemResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export { type FavoriteItemRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/folders/dtos.ts
export type FavoriteItemRequest = FavoriteItemRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/labels/dtos.ts
export { type LabelsV2GetAllResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/labels/dtos.ts
export type LabelsV2AllResponse = LabelsV2GetAllResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/labels/dtos.ts
export { type LabelsV2SingleResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/labels/dtos.ts
export type LabelsV2SingleResponse = LabelsV2SingleResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/labels/dtos.ts
export { type V2CreateLabelsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/labels/dtos.ts
export type V2CreateLabelDto = V2CreateLabelsSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/meeting-insights/dtos.ts
export { type MeetingInsightsSingleRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/meeting-insights/dtos.ts
export type MeetingInsightsSingleRequest = MeetingInsightsSingleRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/meeting-insights/dtos.ts
export { type MeetingInsightsSingleResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/meeting-insights/dtos.ts
export type MeetingInsightsSingleResponse = MeetingInsightsSingleResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/meeting-insights/dtos.ts
export { type UpdateMeetingInsightsRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/meeting-insights/dtos.ts
export type UpdateMeetingInsightsRequest = UpdateMeetingInsightsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/meeting-insights/dtos.ts
export { type UpdateRecurringMeetingInsightsRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/meeting-insights/dtos.ts
export type UpdateRecurringMeetingInsightsRequest =
  UpdateRecurringMeetingInsightsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/meeting-insights/filters.dtos.ts
export { type MeetingInsightsQueryRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/meeting-insights/filters.dtos.ts
export type MeetingInsightsQueryRequest = MeetingInsightsQueryRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/meeting-insights/filters.dtos.ts
export { type MeetingInsightsQueryResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/meeting-insights/filters.dtos.ts
export type MeetingInsightsQueryResponse = MeetingInsightsQueryResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectsV2CompleteRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectsV2CompleteRequest = ProjectsV2CompleteRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectsV2ResolveRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectsV2ResolveRequest = ProjectsV2ResolveRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectsV2ShiftRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectsV2ShiftRequest = ProjectsV2ShiftRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectsV2ApplyDefinitionRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectsV2ApplyDefinitionRequest =
  ProjectsV2ApplyDefinitionRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectV2ResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectV2SingleResponse = ProjectV2ResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectsV2CreateRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectsV2CreateRequest = ProjectsV2CreateRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectsV2CreateFromTaskRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectsV2CreateFromTaskRequest =
  ProjectsV2CreateFromTaskRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectsV2UpdateRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectsV2UpdateRequest = ProjectsV2UpdateRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectsV2UpdateStageDueDateRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectsV2UpdateStageDueDateRequest =
  ProjectsV2UpdateStageDueDateRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type BulkUpdateProjectStagesDueDate }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type BulkUpdateProjectStagesRequest = BulkUpdateProjectStagesDueDate

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectsV2SetStageRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectsV2SetStageRequest = ProjectsV2SetStageRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectsV2AdvanceStageRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectsV2AdvanceStageRequest = ProjectsV2AdvanceStageRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type BulkUpdateProjectsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type BulkUpdateProjectsRequest = BulkUpdateProjectsSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectQuerySchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectQueryRequest = ProjectQuerySchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ProjectQueryResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ProjectQueryResponse = ProjectQueryResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type ConvertProjectToStageRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type ConvertProjectToStageRequest = ConvertProjectToStageRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type AddProjectStageRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type AddProjectStageRequest = AddProjectStageRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export { type RemoveProjectStageRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/projects/dtos.ts
export type RemoveProjectStageRequest = RemoveProjectStageRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/scheduled-entities/dto.ts
export { type ScheduledEntitiesGetSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/scheduled-entities/dto.ts
export type ScheduledEntitiesGetRequest = ScheduledEntitiesGetSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/scheduled-entities/dto.ts
export { type ScheduledEntitiesGetResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/scheduled-entities/dto.ts
export type ScheduledEntitiesGetResponse = ScheduledEntitiesGetResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/schedules/dtos.ts
export { type SchedulesGetSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/schedules/dtos.ts
export type SchedulesGetRequest = SchedulesGetSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/schedules/dtos.ts
export { type SchedulesGetResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/schedules/dtos.ts
export type SchedulesGetResponse = SchedulesGetResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/share/dtos.ts
export { type SharePatchRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/share/dtos.ts
export type SharePatchRequest = SharePatchRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/share/dtos.ts
export { type ShareResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/share/dtos.ts
export type ShareResponse = ShareResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/share/dtos.ts
export { type PublishRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/share/dtos.ts
export type PublishRequest = PublishRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/share/dtos.ts
export { type PublishResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/share/dtos.ts
export type PublishResponse = PublishResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/statuses/dtos.ts
export { type StatusesV2GetAllResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/statuses/dtos.ts
export type StatusesV2AllResponse = StatusesV2GetAllResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/statuses/dtos.ts
export { type StatusesV2SingleResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/statuses/dtos.ts
export type StatusesV2SingleResponse = StatusesV2SingleResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/statuses/dtos.ts
export { type V2CreateTaskStatusSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/statuses/dtos.ts
export type V2CreateTaskStatusDto = V2CreateTaskStatusSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/statuses/dtos.ts
export { type V2UpdateTaskStatusSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/statuses/dtos.ts
export type V2UpdateTaskStatusDto = V2UpdateTaskStatusSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export { type TasksV2GetByIdParamsSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export type TasksV2GetByIdParams = TasksV2GetByIdParamsSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export { type TasksV2SingleIdResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export type TasksV2SingleResponse = TasksV2SingleIdResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export { type TasksV2CreateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export type TasksV2CreateRequest = TasksV2CreateSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export { type TasksV2UpdateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export type TasksV2UpdateRequest = TasksV2UpdateSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export { type TasksV2QuerySchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export type TasksV2QueryRequest = TasksV2QuerySchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export { type TasksV2RescheduleUpdateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export type TasksV2RescheduleUpdate = TasksV2RescheduleUpdateSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export { type TasksV2QueryResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export type TasksV2QueryResponse = TasksV2QueryResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export { type BulkUpdateTasksSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/tasks/dtos.ts
export type BulkUpdateTasksRequest = BulkUpdateTasksSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/teams/dtos.ts
export { type TeamsV2Schema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/teams/dtos.ts
export type TeamsV2SingleTeamResponse = TeamsV2Schema

// Source: [zod] ./packages/rpc/zod/src/server/v2/teams/dtos.ts
export { type TeamsSettingsV2GetResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/teams/dtos.ts
export type TeamsSettingsV2GetResponse = TeamsSettingsV2GetResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/teams/dtos.ts
export { type UpdateTeamNotetakerSettingsRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/teams/dtos.ts
export type UpdateTeamNotetakerSettingsRequest =
  UpdateTeamNotetakerSettingsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/user-feature-permissions/dtos.ts
export { type UserFeaturePermissionsGetSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/user-feature-permissions/dtos.ts
export type UserFeaturePermissionsGetResponse = UserFeaturePermissionsGetSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserSettingsV2GetResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserSettingsV2GetResponse = UserSettingsV2GetResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserOnboardingSettingsUpdateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserOnboardingSettingsPatchRequest =
  UserOnboardingSettingsUpdateSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserConferenceSettingsUpdateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserConferenceSettingsPatchRequest =
  UserConferenceSettingsUpdateSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserSettingsV2UpdateCallToActionsRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserSettingsV2UpdateCallToActionsRequest =
  UserSettingsV2UpdateCallToActionsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserTimezoneSettingsRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserTimezoneSettingsRequest = UserTimezoneSettingsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserAutoScheduleSettingsRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserAutoScheduleSettingsRequest =
  UserAutoScheduleSettingsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserFolderSettingsCreateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserFolderSettingsCreateRequest = UserFolderSettingsCreateSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserFolderSettingsUpdateSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserFolderSettingsUpdateRequest = UserFolderSettingsUpdateSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type GetCurrentUserResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type GetCurrentUserResponse = GetCurrentUserResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserTaskDefaultSettingsRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserTaskDefaultSettingsPostRequest =
  UserTaskDefaultSettingsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserTaskDefaultSettingsPatchRequest =
  UserTaskDefaultSettingsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserCalendarDisplaySettingsRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserCalendarDisplaySettingsRequest =
  UserCalendarDisplaySettingsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserSidebarDisplaySettingsRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserSidebarDisplaySettingsRequest =
  UserSidebarDisplaySettingsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserPageViewSettingsRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserPageViewSettingsRequest = UserPageViewSettingsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserNotetakerSettingsRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserNotetakerSettingsRequest = UserNotetakerSettingsRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type PageViewSettingsQuerySchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type PageViewSettingsQuery = PageViewSettingsQuerySchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserPageViewSettingsSingleResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserPageViewSettingsSingleResponse =
  UserPageViewSettingsSingleResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export { type UserPageViewSettingsMultipleResponseSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/users/dtos.ts
export type UserPageViewSettingsMultipleResponse =
  UserPageViewSettingsMultipleResponseSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/workspaces/dtos.ts
export { type WorkspacesV2GetRequestSchema }

// Source: [zod] ./packages/rpc/zod/src/server/v2/workspaces/dtos.ts
export type WorkspacesV2GetRequest = WorkspacesV2GetRequestSchema

// Source: [zod] ./packages/rpc/zod/src/server/v2/workspaces/dtos.ts
export { type WorkspacesV2GetAll }

// Source: [zod] ./packages/rpc/zod/src/server/v2/workspaces/dtos.ts
export type WorkspaceV2QueryResponse = WorkspacesV2GetAll

// Source: [zod] ./packages/rpc/zod/src/server/v2/workspaces/dtos.ts
export { type WorkspacesV2Create }

// Source: [zod] ./packages/rpc/zod/src/server/v2/workspaces/dtos.ts
export type WorkspaceV2CreateResponse = WorkspacesV2Create

// Source: [zod] ./packages/rpc/zod/src/server/v2/workspaces/dtos.ts
export { type WorkspacesV2GetSingle }

// Source: [zod] ./packages/rpc/zod/src/server/v2/workspaces/dtos.ts
export type WorkspaceV2SingleResponse = WorkspacesV2GetSingle

// Source: [zod] ./packages/rpc/zod/src/server/v2/workspaces/dtos.ts
export { type WorkspacesV2GetSingleWithUsers }

// Source: [zod] ./packages/rpc/zod/src/server/v2/workspaces/dtos.ts
export type WorkspaceV2SingleWithUsersResponse = WorkspacesV2GetSingleWithUsers
