import type { AllModelsSchema } from '@motion/rpc-types'
import { entries } from '@motion/utils/object'

import type { QueryFilters, QueryKey } from '@tanstack/react-query'

import { applyPartialToTargetStore } from './shared'
import {
  type ModelStore,
  type QueryCacheMatches,
  type QueryCachePatch,
} from './types'
import { matchQueries, updateQueryData } from './utils'

import { type Model } from '../model-cache'
import { type MotionCacheContext, type PatchStoreShape } from '../types'
import { log } from '../utils'

/**
 * Patch the cache with a partial update.
 *
 * @param ctx - The MotionCacheContext with the client and userId.
 * @param filter - The QueryFilters to determine which queries to update.
 * @param patch - The partial update to apply to the cache.
 *
 * @returns An array of QueryCachePatch objects representing the changes made.
 */
export function applyPartialToCaches<TType extends keyof AllModelsSchema>(
  ctx: MotionCacheContext,
  filter: QueryFilters<QueryCacheMatches>,
  type: TType,
  patch: PatchStoreShape
): QueryCachePatch<TType>[] {
  const queries = matchQueries(ctx, filter)

  const cacheUpdates: QueryCachePatch<TType>[] = []

  log.time('patch.all', () => {
    queries.forEach(([key, cacheStore]) => {
      // Not all queries have an operable cache store
      // can be any arbitrary shape, but only do updates on V2ResponseStoreShape
      if (cacheStore == null || cacheStore.models == null) return

      log.time('patch', (): void => {
        const baseEntry = buildMinimalStore(cacheStore, type, patch)
        const updates = processQueryCachePatch(ctx, baseEntry, type, patch, key)
        cacheUpdates.push(...updates)
      })
    })
  })

  updateQueryData(ctx, cacheUpdates)

  return cacheUpdates
}

type FlexibleStore = {
  ids?: string[]
  id?: string
  meta?: {
    model: keyof AllModelsSchema
  }
  models: Partial<AllModelsSchema>
}

function buildMinimalStore<
  TTarget extends QueryCacheMatches,
  TResponse extends PatchStoreShape,
>(cacheStore: TTarget, type: keyof AllModelsSchema, items: TResponse) {
  const baseEntry: FlexibleStore = {
    models: {},
  }

  baseEntry.models[type] = {}

  Object.keys(items).forEach((id) => {
    if (cacheStore.models[type]?.[id] == null) return
    // @ts-expect-error - initialized above
    baseEntry.models[type][id] = cacheStore.models[type][id]
  })

  return baseEntry
}

function processQueryCachePatch<TType extends keyof AllModelsSchema>(
  ctx: MotionCacheContext,
  cacheStore: QueryCacheMatches,
  typeOrTypes: TType | TType[],
  patch: PatchStoreShape,
  key: QueryKey
): QueryCachePatch<TType>[] {
  const cacheUpdates: QueryCachePatch<TType>[] = []

  const types = Array.isArray(typeOrTypes) ? typeOrTypes : [typeOrTypes]
  types.forEach((type) => {
    if (cacheStore.models[type] == null) return

    const targetStore = cacheStore.models[type] as ModelStore<TType>

    entries(patch).forEach(([_id, _partial]) => {
      const id = _id as string
      const partial = _partial as Partial<Model<TType>>

      const applied = applyPartialToTargetStore(targetStore, id, partial)

      if (!applied.changed) return

      log.debug('applied', {
        key,
        type,
        id,
        updates: partial,
        target: targetStore[id],
      })

      cacheUpdates.push({
        operation: 'patch',
        key,
        data: cacheStore,
        id,
        type,
        updates: partial,
        inverse: applied.inverse,
      })
    })
  })

  return cacheUpdates
}
