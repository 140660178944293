import { DragSolid, PlusSolid } from '@motion/icons'
import { type MotionId } from '@motion/notes-shared'
import { IconButton, PopoverTrigger } from '@motion/ui/base'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { type NodeKey } from 'lexical'
import { useRef, useState } from 'react'

import { BlockActionMenu } from './block-action-menu'
import { LexicalDraggableBlockPlugin } from './lexical-draggable-block-plugin'

import { useEditorContext } from '../../context'
import { INSERT_SLASH_MENU_COMMAND } from '../slash-command-plugin'

const DRAGGABLE_BLOCK_MENU_CLASSNAME = 'draggable-block-menu'

type DraggableBlockPluginProps = {
  onPlusClick?: () => void
  onCopyLink: (motionId: MotionId, textContent: string | null) => void
  onDelete?: () => void
}

export function DraggableBlockPlugin({
  onPlusClick,
  onCopyLink,
  onDelete,
}: DraggableBlockPluginProps): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  const { floatingAnchorElem } = useEditorContext()

  const menuRef = useRef<HTMLDivElement>(null)
  const targetLineRef = useRef<HTMLDivElement>(null)

  const [isActionMenuOpen, setIsActionMenuOpen] = useState(false)

  if (!floatingAnchorElem) return null

  const handlePlusIconClick = (nodeKey: NodeKey | null) => () => {
    if (nodeKey == null) return

    editor.dispatchCommand(INSERT_SLASH_MENU_COMMAND, nodeKey)

    onPlusClick?.()
  }

  const handleMenuOpen = () => setIsActionMenuOpen(true)

  const handleMenuClose = () => {
    setIsActionMenuOpen(false)
  }

  const isOnMenu = (element?: HTMLElement): boolean => {
    return (
      isActionMenuOpen ||
      !!element?.closest(`.${DRAGGABLE_BLOCK_MENU_CLASSNAME}`)
    )
  }

  return (
    <LexicalDraggableBlockPlugin
      anchorElem={floatingAnchorElem}
      menuRef={menuRef}
      targetLineRef={targetLineRef}
      renderMenuComponent={({ blockElemKey, onDragStart, onDragEnd }) => (
        <div
          ref={menuRef}
          className='draggable-block-menu absolute top-px -left-11 flex items-start'
        >
          <IconButton
            icon={PlusSolid}
            size='small'
            sentiment='neutral'
            variant='muted'
            onClick={handlePlusIconClick(blockElemKey)}
          />

          <PopoverTrigger
            placement='bottom-start'
            renderPopover={({ close }) => (
              <BlockActionMenu
                nodeKey={blockElemKey}
                onCopyLink={onCopyLink}
                onDelete={onDelete}
                close={close}
              />
            )}
            onClose={handleMenuClose}
          >
            <div draggable onDragStart={onDragStart} onDragEnd={onDragEnd}>
              <IconButton
                icon={DragSolid}
                size='small'
                sentiment='neutral'
                variant='muted'
                onClick={handleMenuOpen}
              />
            </div>
          </PopoverTrigger>
        </div>
      )}
      targetLineComponent={
        <div
          ref={targetLineRef}
          className='pointer-events-none h-1 bg-semantic-primary-border-hover absolute left-0 top-0 transition-opacity'
        />
      }
      isOnMenu={isOnMenu}
    />
  )
}
