import { LexicalTheme } from '@motion/notes-shared'

import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { type InitialConfigType } from '@lexical/react/LexicalComposer'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { type EditorState, type LexicalEditor } from 'lexical'
import { type ReactNode, type RefObject } from 'react'
import { twMerge } from 'tailwind-merge'

import { KeyHandlerPlugin } from './key-handler-plugin'

import { BaseEditor } from '../../base-editor'
import { ExternalLinkPlugin } from '../external-link-plugin'

export type HtmlEditorProps = {
  className?: string
  editableClassName?: string
  onChange?: (
    editorState: EditorState,
    editor: LexicalEditor,
    tags: Set<string>
  ) => void
  onEscape?: () => boolean
  onSubmit?: () => void
  editorRef?: RefObject<LexicalEditor | null>
  initialHtml?: string
  readonly?: boolean
  autoFocus?: boolean
  nodes?: InitialConfigType['nodes']
  plugins?: ReactNode
  placeholder?: string
  label?: string
}

const theme = {
  ...LexicalTheme,
  paragraph: 'mb-0 relative',
}

export const HtmlEditor = (props: HtmlEditorProps) => {
  return (
    <BaseEditor
      name='html-editor'
      editorRef={props.editorRef}
      className={twMerge(`pr-2 flex-grow`, props.className)}
      editableClassName={props.editableClassName}
      theme={theme}
      initialValue={{ format: 'html', value: props.initialHtml ?? '' }}
      placeholder={props.placeholder}
      readOnly={props.readonly}
      autoFocus={props.autoFocus}
      maxLength={4096}
      label={props.label}
      onChange={props.onChange}
      nodes={props.nodes}
      plugins={
        <>
          <HistoryPlugin />
          <ClearEditorPlugin />
          {(props.onEscape || props.onSubmit) && (
            <KeyHandlerPlugin
              onEscape={props.onEscape}
              onSubmit={props.onSubmit}
            />
          )}
          <ExternalLinkPlugin />
          {props.plugins}
        </>
      }
    />
  )
}
