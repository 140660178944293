import { classed, type ComponentProps } from '@motion/theme'
import { range } from '@motion/utils/array'

export const SkeletonLine = classed('p', {
  base: `
    rounded-full
    bg-label-grey-bg
    h-4
    w-full
    inline-flex
  `,
})
export type SkeletonLineProps = ComponentProps<typeof SkeletonLine>

export type SkeletonBodyLinesProps = {
  lines?: number
}

export const SkeletonBodyLines = ({ lines = 3 }: SkeletonBodyLinesProps) => {
  return (
    <div className='flex flex-col w-full gap-0.5'>
      {range(lines).map((l, i, arr) => {
        return (
          <SkeletonLine
            key={i}
            className={i + 1 === arr.length ? 'w-[80%]' : undefined}
          />
        )
      })}
    </div>
  )
}
