import * as amplitude from '@amplitude/analytics-browser'
import { addBreadcrumb } from '@sentry/browser'
import { DateTime } from 'luxon'

import { getMotionClient } from '../env'
import { makeLog } from '../logging'

let amplitudeInitialized = false

const log = makeLog('amplitude')

type InitializeOptions = {
  version: string | undefined
  apiKey: string
  endpoint: string
  deviceId?: string
}

export const initializeAmplitude = (config: InitializeOptions) => {
  amplitude.init(config.apiKey, undefined, {
    serverUrl: config.endpoint,
    appVersion: config.version,
    defaultTracking: false,
    deviceId: config.deviceId,
  })

  amplitudeInitialized = true
}

export const setAnalyticsUserId = (userId: string | undefined) => {
  if (!amplitudeInitialized) return
  log('set analytics id', userId)
  amplitude.setUserId(userId)
}

export const setAnalyticsTeamId = (
  teamId: string,
  teamName: string,
  teamSeatCount: number,
  featurePermissionTierId?: number
) => {
  if (!amplitudeInitialized) return

  log(
    'set analytics team',
    teamId,
    teamName,
    teamSeatCount,
    featurePermissionTierId
  )

  amplitude.setGroup('teamId', teamId)

  const groupIdentifyName = new amplitude.Identify()
    .set('name', teamName)
    .set('teamSeatCount', teamSeatCount)

  if (featurePermissionTierId !== undefined) {
    groupIdentifyName.set('featurePermissionTierId', featurePermissionTierId)
  }

  amplitude.groupIdentify('teamId', teamId, groupIdentifyName)
}

export const setAnalyticsUserProperties = (
  properties: Record<string, unknown>
) => {
  const props = new amplitude.Identify()
  Object.entries(properties).forEach(([key, value]) => {
    if (value == null) {
      props.unset(key)
    } else {
      props.set(key, value as any)
    }
  })
  amplitude.identify(props)
}

/**
 * @deprecated use `recordAnalyticsEvent`. This function wraps `logEvent` with some extra type safety to ensure
 * that events names aren't misspelled and that properties are of the correct type
 */
export const logEvent = (eventName: string, properties: any = {}) => {
  if (!amplitudeInitialized) return

  const eventProperties = {
    localTime: DateTime.local().toString(),
    source: getMotionClient(),
    url: window.location.href,
    userAgent: window.navigator.userAgent,
    screenResolution: getScreenResolution(),
    viewportSize: getViewportSize(),
    isTouchScreen: 'ontouchstart' in window || navigator.maxTouchPoints > 0,
    ...properties,
  }

  log('record', eventName, eventProperties)
  addBreadcrumb({
    category: 'analytics',
    message: eventName,
    data: properties,
    level: 'info',
  })
  amplitude.logEvent(eventName, eventProperties)
}

export const resetAnalytics = () => {
  log('reset')
  amplitude.reset()
}

function getScreenResolution() {
  return {
    width: window.screen.width,
    height: window.screen.height,
  }
}

function getViewportSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}
