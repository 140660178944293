import { templateStr } from '@motion/react-core/strings'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $trimTextContentFromAnchor } from '@lexical/selection'
import { $restoreEditorState } from '@lexical/utils'
import {
  $getSelection,
  $isRangeSelection,
  type EditorState,
  RootNode,
} from 'lexical'
import { useEffect, useState } from 'react'

import { getTextSizeFromEditorState } from '../../utils'

export function MaxLengthPlugin({
  maxLength,
  label = 'Content',
}: {
  maxLength: number
  label?: string
}) {
  const [editor] = useLexicalComposerContext()
  const [showWarning, setShowWarning] = useState(false)

  useEffect(() => {
    let timer: NodeJS.Timeout

    if (showWarning) {
      timer = setTimeout(() => {
        setShowWarning(false)
      }, 3000)
    }

    return () => clearTimeout(timer)
  }, [showWarning])

  useEffect(() => {
    let lastRestoredEditorState: EditorState | null = null

    return editor.registerNodeTransform(RootNode, (rootNode: RootNode) => {
      const selection = $getSelection()
      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return
      }
      const prevEditorState = editor.getEditorState()
      const prevTextContentSize = getTextSizeFromEditorState(prevEditorState)
      const textContentSize = rootNode.getTextContentSize()
      if (prevTextContentSize !== textContentSize) {
        const delCount = textContentSize - maxLength
        const anchor = selection.anchor

        if (delCount > 0) {
          setShowWarning(true)
          // Restore the old editor state instead if the last
          // text content was already at the limit.
          if (
            prevTextContentSize === maxLength &&
            lastRestoredEditorState !== prevEditorState
          ) {
            lastRestoredEditorState = prevEditorState
            $restoreEditorState(editor, prevEditorState)
          } else {
            $trimTextContentFromAnchor(editor, anchor, delCount)
          }
        }
      }
    })
  }, [editor, maxLength])

  return (
    <div
      className='pointer-events-none text-red-600 text-sm font-medium transition-all overflow-hidden'
      style={{
        height: showWarning ? 40 : 0,
        opacity: showWarning ? 1 : 0,
      }}
    >
      {showWarning &&
        templateStr(
          "{{label}} can't be longer than {{maxLength}} characters.",
          {
            label,
            maxLength,
          }
        )}
    </div>
  )
}
