import { $isLinkNode, AutoLinkNode, LinkNode } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import {
  $getNodeByKey,
  $getSelection,
  $isDecoratorNode,
  type LexicalNode,
  type MutationListener,
  type NodeKey,
} from 'lexical'
import { useCallback, useEffect } from 'react'

type LinkTransformPluginProps = {
  transformLink: (link: string, label: string) => LexicalNode | null
  shouldTransformLink: (link: string) => boolean
}

export function LinkTransformPlugin({
  transformLink,
  shouldTransformLink,
}: LinkTransformPluginProps): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  const transformLinkIfNeeded = useCallback(
    (key: NodeKey) => {
      let result = false

      editor.update(function () {
        const linkNode = $getNodeByKey(key)

        if (!$isLinkNode(linkNode)) {
          return
        }

        const url = linkNode.getURL()
        const label = linkNode.getTextContent()

        if (!url) {
          return
        }

        if (shouldTransformLink(url)) {
          const newNode = transformLink(url, label)

          if (newNode != null) {
            if (!$getSelection()) {
              linkNode.selectEnd()
            }

            linkNode.insertAfter(newNode)

            if (linkNode.isAttached()) {
              linkNode.remove()
            }

            if ($isDecoratorNode(newNode)) {
              newNode.selectEnd()
            }

            result = true
          }
        }
      })

      return result
    },
    [editor, transformLink, shouldTransformLink]
  )

  useEffect(() => {
    const listener: MutationListener = (
      nodeMutations,
      { updateTags, dirtyLeaves }
    ) => {
      for (const [key, mutation] of nodeMutations) {
        if (
          mutation === 'created' &&
          updateTags.has('paste') &&
          dirtyLeaves.size <= 3
        ) {
          // There's a conflict with the `MarkdownShortcutPlugin` that causes the transform
          // to throw an error when it's pasted after writing 2 or more words, this fixes it.
          setTimeout(() => transformLinkIfNeeded(key), 0)
        }
      }
    }

    return mergeRegister(
      ...[LinkNode, AutoLinkNode].map((Klass) =>
        editor.registerMutationListener(Klass, (...args) => listener(...args), {
          skipInitialization: true,
        })
      )
    )
  }, [editor, transformLinkIfNeeded])

  return null
}
