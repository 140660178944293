import { useExperiment } from '@motion/web-common/flags'

import {
  INDIVIDUAL_DEFAULT_TRIAL_LENGTH,
  TEAM_DEFAULT_TRIAL_LENGTH,
} from '../constants'

const useIndividualTrialLength = (track: boolean): number => {
  const payload = useExperiment('stripe-trial-length', { track }).payload as
    | { days: number }
    | undefined
  return payload?.days ?? INDIVIDUAL_DEFAULT_TRIAL_LENGTH
}

export const useTrialLength = (isTeam: boolean): number => {
  const individualTrialLength = useIndividualTrialLength(!isTeam)
  return isTeam ? TEAM_DEFAULT_TRIAL_LENGTH : individualTrialLength
}
