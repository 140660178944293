import { AtSymbolSolid, UserCircleSolid } from '@motion/icons'
import { classed, type VariantProps } from '@motion/theme'
import { addComponentName } from '@motion/ui/helpers'
import { getUserColor } from '@motion/utils/color'
import { getNameInitials } from '@motion/utils/string'

import {
  type ComponentRef,
  type CSSProperties,
  forwardRef,
  type ReactNode,
  useEffect,
  useState,
} from 'react'

import { AvatarPill } from './avatar-pill'

export type UserAvatarProps = {
  id?: string | null
  name?: string | null
  size?: number
  outlined?: VariantProps<typeof AvatarPill>['outlined']
  profileUrl?: string | null
  bottomRight?: ReactNode
  topRight?: ReactNode
}

enum Loading {
  Pending,
  Loaded,
  Errored,
}

export const UserAvatar = forwardRef<
  ComponentRef<typeof AvatarPill>,
  UserAvatarProps
>(function UserAvatar(props, ref) {
  const { id, name, profileUrl, size, bottomRight, topRight, outlined } = props

  const isValid = name != null && id != null

  const initials = isValid ? getNameInitials(name) : undefined
  // TODO update color logic based on this https://www.figma.com/file/ECu0r9rHxWS6xug56xsQrk/Components-w%2F-color-tokens?node-id=2855%3A31268&mode=dev
  const color = isValid ? getUserColor(id) : undefined

  const [loadingStatus, setLoadingStatus] = useState(Loading.Pending)
  // When the url changes, we need to reset the loading status
  useEffect(() => {
    setLoadingStatus(Loading.Pending)
  }, [profileUrl])

  if (id === '@me') {
    return (
      <AtSymbolSolid
        width={size ?? '1em'}
        height={size ?? '1em'}
        className='text-semantic-neutral-icon-default'
      />
    )
  }

  const showImage = !!profileUrl && loadingStatus !== Loading.Errored
  const child = showImage ? (
    <img
      className='h-full w-full object-cover'
      onLoad={() => setLoadingStatus(Loading.Loaded)}
      onError={() => setLoadingStatus(Loading.Errored)}
      src={profileUrl}
      alt=''
      role='presentation'
    />
  ) : (
    (initials ?? <UserCircleSolid className='w-full h-full' />)
  )

  const pill = (
    <AvatarPill
      outlined={outlined}
      style={{
        backgroundColor: showImage ? undefined : color,
        width: size ?? '2em',
        height: size ?? '2em',
        fontSize: size ? size / 2 : '0.5em',
        clipPath: topRight ? 'url(#circle-mask)' : undefined,
      }}
      // Add a `data-icon` so it acts as an icon when placed within buttons
      data-icon='avatar-pill'
      ref={ref}
    >
      {topRight && <TopRightClipPath />}
      {child}
    </AvatarPill>
  )

  if (!bottomRight && !topRight) {
    return pill
  }

  const floatingStyles: CSSProperties = {
    width: size ? size / 2 : '0.5em',
    height: size ? size / 2 : '0.5em',
    // @ts-expect-error css variable
    '--xoffset': `5%`,
    '--yoffset': `5%`,
  }

  return (
    <div className='relative shrink-0' {...addComponentName('UserAvatar')}>
      {pill}
      {bottomRight && (
        <FloatingContainer style={floatingStyles} placement='bottom-right'>
          {bottomRight}
        </FloatingContainer>
      )}
      {topRight && (
        <FloatingContainer style={floatingStyles} placement='top-right'>
          {topRight}
        </FloatingContainer>
      )}
    </div>
  )
})

const FloatingContainer = classed('div', {
  base: `
    absolute
    rounded-full
    grid place-items-center
    [&>*]:w-full [&>*]:h-full
    [&_[data-icon]]:w-full [&_[data-icon]]:h-full
  `,
  variants: {
    placement: {
      'bottom-right':
        '-right-[var(--xoffset)] -bottom-[var(--yoffset)] bg-white',
      'top-right': '-right-[var(--xoffset)] -top-[var(--yoffset)]',
    },
  },
})

const TopRightClipPath = () => (
  <svg className='h-0 w-0'>
    <clipPath id='circle-mask' clipPathUnits='objectBoundingBox'>
      <path d='M1 0.343128C0.956254 0.415729 0.876655 0.464286 0.785714 0.464286C0.647643 0.464286 0.535714 0.352357 0.535714 0.214286C0.535714 0.123346 0.584271 0.0437463 0.656872 0H0V1H1V0.343128Z'></path>
    </clipPath>
  </svg>
)
