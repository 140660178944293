import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

export const useScrollBoundsObserver = (threshold: number) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const [elHeight, setElHeight] = useState<number>(0)
  const [scrollTop, setScrollTop] = useState<number>(0)
  const timerId = useRef<NodeJS.Timeout | null>(null)
  const update = useCallback(() => {
    const element = scrollRef.current
    if (!element || timerId.current) return
    timerId.current = setTimeout(() => {
      setScrollTop((prev) => {
        const top = Math.floor(element.scrollTop)
        const bottom = top + Math.floor(elHeight)
        const shouldUpdate =
          Math.abs(prev - top) >= threshold ||
          top < 1 ||
          bottom >= element.scrollHeight
        if (shouldUpdate) return top
        return prev
      })
      timerId.current = null
    }, 50)
  }, [elHeight, threshold])

  const measureHeight = useCallback(() => {
    const element = scrollRef.current
    if (!element) return
    setElHeight(element.getBoundingClientRect().height)
  }, [])

  useEffect(() => {
    const element = scrollRef.current
    if (!element) return
    measureHeight()
    setScrollTop(Math.floor(element.scrollTop))
  }, [measureHeight])

  useLayoutEffect(() => {
    const element = window
    if (!element) return
    element.addEventListener('resize', measureHeight, {
      passive: true,
    })
    return () => {
      element.removeEventListener('resize', measureHeight)
    }
  }, [measureHeight])

  useLayoutEffect(() => {
    const element = scrollRef.current
    if (!element) return

    element.addEventListener('scroll', update, {
      passive: true,
    })

    return () => {
      element.removeEventListener('scroll', update)
    }
  }, [update])

  const scrollBottom = scrollTop + elHeight
  return { scrollRef, scrollTop, scrollBottom }
}
