import { createPortal } from 'react-dom'

export type PortalProps = {
  children: React.ReactNode
  container?: Element | null | undefined
}

export const Portal = ({
  children,
  container,
}: PortalProps): React.ReactPortal => {
  return createPortal(
    children as any,
    container ?? document.body
  ) as React.ReactPortal
}
