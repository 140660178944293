import {
  $createMentionNode,
  $isMentionNode,
  MentionNode,
} from '@motion/notes-shared'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $dfs, mergeRegister } from '@lexical/utils'
import {
  $createTextNode,
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_NORMAL,
  SELECTION_INSERT_CLIPBOARD_NODES_COMMAND,
  type TextNode,
} from 'lexical'
import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import { checkForAtSignTriggerMatch } from './check-for-at-sign-trigger-match'
import { INSERT_MENTION_MENU_COMMAND } from './commands'

import { TypeaheadMenu, TypeaheadMenuOption } from '../common'
import { TypeaheadMenuPlugin } from '../typeahead-menu-plugin'

function getTypeFromMeta(meta: unknown) {
  if (meta && typeof meta === 'object' && 'type' in meta) {
    return meta.type as string
  }
  return ''
}

export type MentionsSearchResults = {
  label?: string
  entities: Array<{
    id: string
    label: string
    meta?: Record<string, unknown>
  }>
}[]

export type MentionsPluginProps = {
  query: string
  setQuery: Dispatch<SetStateAction<string>>
  results: MentionsSearchResults
  renderMentionOption?: (option: TypeaheadMenuOption) => ReactNode
  onMentionCreated?: (
    motionId: string,
    entityId: string,
    entityType: string,
    silent?: boolean
  ) => void
  onMentionDeleted?: (motionId: string) => void
}

export function MentionsPlugin({
  setQuery,
  results,
  renderMentionOption,
  onMentionCreated,
  onMentionDeleted,
}: MentionsPluginProps) {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    const mentionMap = new Map<
      string,
      {
        entityId: string
        entityType: string
        motionId: string
      }
    >()

    return mergeRegister(
      editor.registerMutationListener(MentionNode, (mutations) => {
        editor.getEditorState().read(() => {
          for (const [key, type] of mutations) {
            if (type === 'created') {
              const mentionNode = $getNodeByKey(key)

              if (mentionNode == null) {
                throw new Error(
                  `Mentions Plugin: Could not find created mention node in editor: ${key}`
                )
              }

              if (!$isMentionNode(mentionNode)) {
                throw new Error(
                  `Mentions Plugin: Node is not a mention: ${key}`
                )
              }

              mentionMap.set(key, {
                entityId: mentionNode.getEntityId(),
                entityType: mentionNode.getEntityType(),
                motionId: mentionNode.getMotionId(),
              })
            } else if (type === 'destroyed') {
              const mention = mentionMap.get(key)

              if (mention == null) {
                throw new Error(
                  `Mentions Plugin: Could not find deleted mention in map: ${key}`
                )
              }

              onMentionDeleted?.(mention.motionId)

              mentionMap.delete(key)
            }
          }
        })
      }),
      editor.registerCommand(
        INSERT_MENTION_MENU_COMMAND,
        (nodeKey) => {
          const selection = $getSelection()

          if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
            return false
          }

          selection.insertText('@')

          return true
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        SELECTION_INSERT_CLIPBOARD_NODES_COMMAND,
        ({ nodes, selection }) => {
          const mentionNodes = nodes
            .map((node) => {
              return $dfs(node)
                .map(({ node }) => node)
                .filter((node): node is MentionNode => $isMentionNode(node))
            })
            .flat()

          if (mentionNodes.length > 0) {
            selection.insertNodes(nodes)

            if (onMentionCreated != null) {
              mentionNodes.forEach((mentionNode) => {
                onMentionCreated(
                  mentionNode.getMotionId(),
                  mentionNode.getEntityId(),
                  mentionNode.getEntityType(),
                  true
                )
              })
            }

            return true
          }

          return false
        },
        COMMAND_PRIORITY_NORMAL
      )
    )
  }, [editor, onMentionCreated, onMentionDeleted])

  const options = useMemo(() => {
    if (results == null) {
      return []
    }

    const mappedOptions = results.map((group) => {
      return {
        groupName: group.label,
        options: group.entities.map((entity) => {
          return new TypeaheadMenuOption(entity.id, entity.label, {
            meta: entity.meta,
            keywords: [entity.label],
          })
        }),
      }
    })

    return mappedOptions
  }, [results])

  const onSelectOption = useCallback(
    (
      selectedOption: TypeaheadMenuOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ) => {
      const type = getTypeFromMeta(selectedOption.meta)

      if (type === 'placeholder') {
        closeMenu()
        return
      }

      editor.update(() => {
        const mentionNode = $createMentionNode(
          selectedOption.key,
          selectedOption.title,
          type
        )

        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode)
        }
        const parentNode = mentionNode.getParent()
        const nextSibling = mentionNode.getNextSibling()

        if (nextSibling && $isTextNode(nextSibling)) {
          nextSibling.spliceText(0, 0, ' ', true)
        } else if (parentNode) {
          const textNode = $createTextNode(' ')
          mentionNode.insertAfter(textNode)
          textNode.selectEnd()
        }

        onMentionCreated?.(mentionNode.getMotionId(), selectedOption.key, type)

        closeMenu()
      })
    },
    [editor, onMentionCreated]
  )

  if (!editor.isEditable()) return null

  return (
    <TypeaheadMenuPlugin
      onQueryChange={(matchingString) => setQuery(matchingString || '')}
      onSelectOption={onSelectOption}
      options={options.flatMap((g) => g.options)}
      menuRenderFn={({
        selectedIndex,
        selectOptionAndCleanUp,
        setHighlightedIndex,
      }) => (
        <TypeaheadMenu
          options={options}
          selectedIndex={selectedIndex}
          selectOptionAndCleanUp={selectOptionAndCleanUp}
          setHighlightedIndex={setHighlightedIndex}
          renderOptionContent={renderMentionOption}
          showGroupTitles
        />
      )}
      triggerFn={checkForAtSignTriggerMatch}
    />
  )
}
