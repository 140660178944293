import { type VirtualizedTreeNode, type VirtualizedTreeRootNode } from './types'

export function getSearchableNodesWithPath(
  rootNode: VirtualizedTreeNode,
  isSearchableNode: (node: VirtualizedTreeNode) => boolean = (node) =>
    !node.children,
  parentPath: string[] = [],
  prettyPath: string[] = [],
  parentDepth: number = 0
): VirtualizedTreeNode[] {
  const seachableNodes: VirtualizedTreeNode[] = []

  // Continue searching children regardless
  for (const childNode of rootNode.children || []) {
    if (childNode.disabled) continue // Skip disabled nodes

    const currentPath = [...parentPath, childNode.id]
    const currentPrettyPath = [...prettyPath, childNode.label].filter(Boolean)
    const currentDepth = parentDepth + 1

    if (isSearchableNode(childNode)) {
      seachableNodes.push({
        id: childNode.id,
        label: childNode.label,
        prettyPath,
        depth: currentDepth,
        isLeafNode: true,
        path: currentPath,
        color: childNode.color,
        type: childNode.type,
      })
    }

    seachableNodes.push(
      ...getSearchableNodesWithPath(
        childNode,
        isSearchableNode,
        currentPath,
        currentPrettyPath,
        currentDepth
      )
    )
  }

  return seachableNodes
}

export function isRootNode(
  node: VirtualizedTreeNode
): node is VirtualizedTreeRootNode {
  return node.id === '[ROOT]'
}

export function getExpandedStateToSelected(
  node: VirtualizedTreeNode,
  selectedId: VirtualizedTreeNode['id']
): Record<string, true> {
  if (!isRootNode(node) && node.id === selectedId) {
    return { [node.id]: true }
  }

  if (node.children) {
    for (const childNode of node.children) {
      const childPath = getExpandedStateToSelected(childNode, selectedId)
      if (Object.keys(childPath).length > 0) {
        if (isRootNode(node)) return childPath
        return { [node.id]: true, ...childPath }
      }
    }
  }

  return {}
}
