import { useForceUpdate } from '@motion/react-core/hooks'
import { createDeferred, type Deferred } from '@motion/utils/promise'
import { Sentry } from '@motion/web-base/sentry'

import React, { createContext, type ReactNode, useMemo, useRef } from 'react'

import {
  type ModalDefinitions,
  ModalDismissed,
  type PromptCallbacks,
} from './definitions'
import {
  type ClosedModalOptions,
  type InternalModalApi,
  type ModalOnDismissFn,
  type ModalOptions,
  type OpenedModalOptions,
} from './types'
import type { ModalPromptType, WithOptional } from './types.internal'

type CurrentModal<T extends Record<string, unknown> = Record<string, unknown>> =
  {
    name: string
    props: Omit<T, keyof PromptCallbacks<unknown>> | undefined
    deferred?: Deferred<unknown> | null
    callbacks: {
      accept?: (value: unknown) => void
      dismiss?: ModalOnDismissFn
    }
  }

const NOT_IN_CONTEXT = () => {
  throw new Error('ModalApiModalContext not found')
}

export { ModalDismissed }

export const ModalApiContext = createContext<{
  api: InternalModalApi
  token: unknown
}>({
  api: {
    status: NOT_IN_CONTEXT,
    open: NOT_IN_CONTEXT,
    dismiss: NOT_IN_CONTEXT,
    prompt: NOT_IN_CONTEXT,
    updateProps: NOT_IN_CONTEXT,
    updateOnDismiss: NOT_IN_CONTEXT,
    dismissAll: NOT_IN_CONTEXT,
  },
  token: null,
})

export type ModalApiProviderProps = {
  children: ReactNode
}

export const ModalApiProvider = <TModals = ModalDefinitions>(
  props: ModalApiProviderProps
) => {
  const update = useForceUpdate()
  const stack = useRef<CurrentModal[]>([])

  const api = useMemo((): InternalModalApi<TModals> => {
    const setStack = (fn: (prev: CurrentModal[]) => CurrentModal[]) => {
      stack.current = fn(stack.current)
      update()
    }

    const findByName = (name?: string) => {
      return name == null
        ? stack.current[stack.current.length - 1]
        : findLast(stack.current, (x) => x.name === name)
    }

    async function closeWithValue(
      name: string | undefined,
      value: typeof ModalDismissed | unknown
    ) {
      const found = findByName(name)

      if (!found) return

      if (!found.deferred) {
        const proceed = found.callbacks.dismiss
          ? ((await found.callbacks.dismiss()) ?? true)
          : true
        if (!proceed) return
        return setStack((current) => current.filter((x) => x !== found))
      }

      let proceedWithClosing = true

      if (value === ModalDismissed) {
        proceedWithClosing = found.callbacks.dismiss
          ? ((await found.callbacks.dismiss()) ?? true)
          : true
      } else {
        found.callbacks.accept?.(value)
      }

      if (proceedWithClosing) {
        found.deferred.resolve(value)
        found.deferred = null
        setStack((current) => current.filter((x) => x !== found))
      }
    }

    // Force close all modals
    // WARNING: Should only be called in specific circumstances since it bypasses the modal dismissal process
    async function dismissAll() {
      void Promise.all(
        stack.current.map((x) => closeWithValue(x.name, ModalDismissed))
      )
    }

    return {
      status<TName extends string & keyof TModals>(
        name: TName
      ): ModalOptions<TModals[TName]> {
        const found = findByName(name)
        if (!found) {
          return { name, visible: false } as ClosedModalOptions
        }

        const { deferred } = found
        if (deferred) {
          return {
            name,
            visible: true,
            props: found.props as TModals[TName],
            close: (
              value:
                | ModalPromptType<TModals, TName>
                | typeof ModalDismissed = ModalDismissed
            ) => closeWithValue(name, value),
          } as OpenedModalOptions<TModals[TName]>
        }

        return {
          name,
          visible: true,
          props: found.props as TModals[TName],
          close: () => closeWithValue(name, undefined),
        } as OpenedModalOptions<TModals[TName]>
      },
      open<T extends Record<string, unknown>>(name: string, modalProps?: T) {
        Sentry.addBreadcrumb({
          type: 'navigation',
          category: 'modal',
          message: name,
          level: 'info',
          data: modalProps,
        })

        setStack((value) => [
          ...value,
          { name, props: modalProps, callbacks: {} },
        ])
      },
      prompt<TName extends string & keyof TModals>(
        name: TName,
        modalProps: WithOptional<TModals[TName], 'onValue' | 'onDismiss'>
      ): Promise<typeof ModalDismissed | ModalPromptType<TModals, TName>> {
        const current = findByName(name)
        if (current && current.deferred) {
          return current.deferred.promise as Promise<
            ModalPromptType<TModals, TName>
          >
        }

        const deferred = createDeferred<unknown>()

        const { onValue, onDismiss, ...valueProps } = modalProps

        const newModal: CurrentModal<typeof modalProps> = {
          name,
          callbacks: {
            // @ts-expect-error - public interface is properly typed
            accept: (value) => onValue?.(value),
            // @ts-expect-error - public interface is properly typed
            dismiss: () => onDismiss?.(),
          },
          props: valueProps,
          deferred: deferred,
        }

        Sentry.addBreadcrumb({
          type: 'navigation',
          category: 'prompts',
          message: name,
          level: 'info',
          data: modalProps,
        })

        setStack((prev) => [...prev, newModal])

        return deferred.promise as Promise<ModalPromptType<TModals, TName>>
      },
      async dismiss(name?: string) {
        await closeWithValue(name, ModalDismissed)
      },
      updateProps<T extends Record<string, unknown>>(
        name: string,
        modalProps: T
      ) {
        setStack((current) =>
          current.map((x) => {
            if (x.name === name) {
              return {
                ...x,
                props: modalProps,
              }
            }
            return x
          })
        )
      },
      updateOnDismiss(name, callback) {
        setStack((current) =>
          current.map((x) => {
            if (x.name === name) {
              return {
                ...x,
                callbacks: {
                  ...x.callbacks,
                  dismiss: callback,
                },
              }
            }
            return x
          })
        )
      },
      dismissAll,
    }
  }, [update])

  return React.createElement(
    ModalApiContext.Provider,
    // eslint-disable-next-line react-compiler/react-compiler
    { value: { api, token: update.token } },
    props.children
  )
}

function findLast<T>(arr: T[], predicate: (item: T) => boolean): T | undefined {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i])) return arr[i]
  }
  return undefined
}
