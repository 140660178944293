import {
  AtSymbolSolid,
  AutoscheduleStarSolid,
  CodeSolid,
  NoteSolid,
  ProjectCubeSolid,
  RTECheckListSolid,
  RTEDividerSolid,
  RTEH1Solid,
  RTEH2Solid,
  RTEH3Solid,
  RTEOrderedListSolid,
  RTEQuoteSolid,
  RTEUnorderedListSolid,
  TableOutline,
  TaskSolid,
  UploadSolid,
} from '@motion/icons'
import { computeSearchScore } from '@motion/ui-logic'

import {
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode'
import { useBasicTypeaheadTriggerMatch } from '@lexical/react/LexicalTypeaheadMenuPlugin'
import { $createHeadingNode } from '@lexical/rich-text'
import { $setBlocksType } from '@lexical/selection'
import { INSERT_TABLE_COMMAND } from '@lexical/table'
import {
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  COMMAND_PRIORITY_NORMAL,
  type LexicalEditor,
  type TextNode,
} from 'lexical'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { INSERT_SLASH_MENU_COMMAND } from './commands'
import { CREATE_CONTENT_SLASH_COMMAND_OPTION } from './constants'

import { TRIGGER_ATTACHMENT_UPLOAD } from '../attachment-plugin/commands'
import { FORMAT_QUOTE_COMMAND, INSERT_CODE_BLOCK_COMMAND } from '../commands'
import { TypeaheadMenu, TypeaheadMenuOption } from '../common'
import { TOGGLE_INLINE_CREATE_COMMAND } from '../inline-create-plugin/command'
import { INSERT_MENTION_MENU_COMMAND } from '../mentions-plugin/commands'
import { TypeaheadMenuPlugin } from '../typeahead-menu-plugin'

export type SlashCommandPluginOptions = {
  enableAI?: boolean
}

function getBaseOptions(
  editor: LexicalEditor,
  { enableAI = false }: SlashCommandPluginOptions = {}
) {
  return [
    {
      groupName: 'Create',
      options: [
        new TypeaheadMenuOption('new-task', 'New task', {
          icon: <TaskSolid />,
          keywords: ['create', 'new', 'task'],
          onSelect: () =>
            editor.dispatchCommand(TOGGLE_INLINE_CREATE_COMMAND, {
              type: 'task',
            }),
        }),
        new TypeaheadMenuOption('new-project', 'New project', {
          icon: <ProjectCubeSolid />,
          keywords: ['create', 'new', 'project'],
          onSelect: () =>
            editor.dispatchCommand(TOGGLE_INLINE_CREATE_COMMAND, {
              type: 'project',
            }),
        }),
        new TypeaheadMenuOption('new-doc', 'New doc', {
          icon: <NoteSolid />,
          keywords: ['create', 'new', 'doc'],
          onSelect: () =>
            editor.dispatchCommand(TOGGLE_INLINE_CREATE_COMMAND, {
              type: 'note',
            }),
        }),
        enableAI &&
          new TypeaheadMenuOption(
            CREATE_CONTENT_SLASH_COMMAND_OPTION,
            'Create content',
            {
              icon: <AutoscheduleStarSolid />,
              keywords: ['create', 'content'],
            }
          ),
      ].filter(Boolean),
    },
    {
      groupName: 'Search',
      options: [
        new TypeaheadMenuOption(
          'search-mention',
          'Search docs, projects or tasks',
          {
            icon: <AtSymbolSolid />,
            keywords: ['search', 'doc', 'project', 'task'],
            onSelect: () =>
              editor.dispatchCommand(INSERT_MENTION_MENU_COMMAND, undefined),
          }
        ),
      ],
    },
    {
      groupName: 'Headings',
      options: (
        [
          [1, RTEH1Solid],
          [2, RTEH2Solid],
          [3, RTEH3Solid],
        ] as const
      ).map(
        ([n, Icon]) =>
          new TypeaheadMenuOption(`h${n}`, `Heading ${n}`, {
            icon: <Icon />,
            keywords: ['heading', 'header', `h${n}`],
            onSelect: () =>
              editor.update(() => {
                const selection = $getSelection()
                if ($isRangeSelection(selection)) {
                  $setBlocksType(selection, () => $createHeadingNode(`h${n}`))
                }
              }),
          })
      ),
    },
    {
      groupName: 'Lists',
      options: [
        new TypeaheadMenuOption('numbered-list', 'Numbered list', {
          icon: <RTEOrderedListSolid />,
          keywords: ['numbered list', 'ordered list', 'ol'],
          onSelect: () =>
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined),
        }),
        new TypeaheadMenuOption('bulleted-list', 'Bulleted list', {
          icon: <RTEUnorderedListSolid />,
          keywords: ['bulleted list', 'unordered list', 'ul'],
          onSelect: () =>
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined),
        }),
        new TypeaheadMenuOption('check-list', 'Check list', {
          icon: <RTECheckListSolid />,
          keywords: ['check list', 'todo list'],
          onSelect: () =>
            editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined),
        }),
      ],
    },
    {
      groupName: 'Blocks',
      options: [
        new TypeaheadMenuOption('table', 'Table', {
          icon: <TableOutline />,
          keywords: [],
          onSelect: () =>
            editor.dispatchCommand(INSERT_TABLE_COMMAND, {
              columns: '2',
              rows: '3',
              includeHeaders: { rows: true, columns: false },
            }),
        }),
        new TypeaheadMenuOption('blockquote', 'Blockquote', {
          icon: <RTEQuoteSolid />,
          keywords: ['quote'],
          onSelect: () => editor.dispatchCommand(FORMAT_QUOTE_COMMAND, true),
        }),
        new TypeaheadMenuOption('divider', 'Divider', {
          icon: <RTEDividerSolid />,
          keywords: ['divider', 'separator', 'hr'],
          onSelect: () =>
            editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined),
        }),
        new TypeaheadMenuOption('code-block', 'Code block', {
          icon: <CodeSolid />,
          keywords: ['codeblock', 'code'],
          onSelect: () =>
            editor.dispatchCommand(INSERT_CODE_BLOCK_COMMAND, undefined),
        }),
      ],
    },
    {
      groupName: 'Attachments',
      options: [
        new TypeaheadMenuOption('attachment', 'Upload attachment', {
          icon: <UploadSolid />,
          keywords: [],
          onSelect: () =>
            editor.dispatchCommand(TRIGGER_ATTACHMENT_UPLOAD, undefined),
        }),
      ],
    },
    // Disabling collapsible headings for now as they are not working correctly
    // {
    //   groupName: 'Collapsible',
    //   options: (
    //     [
    //       [1, RTEH1Solid],
    //       [2, RTEH2Solid],
    //       [3, RTEH3Solid],
    //     ] as const
    //   ).map(
    //     ([n, Icon]) =>
    //       new TypeaheadMenuOption(`collapsible-h${n}`, `Toggle heading ${n}`, {
    //         icon: <Icon />,
    //         keywords: [
    //           'toggle',
    //           'collapsible',
    //           'expandable',
    //           'heading',
    //           'header',
    //           `h${n}`,
    //         ],
    //         onSelect: () => {
    //           editor.dispatchCommand(INSERT_COLLAPSIBLE_HEADING_COMMAND, {
    //             heading: `h${n}`,
    //           })
    //         },
    //       })
    //   ),
    // },
  ].filter(Boolean)
}

type SlashCommandPluginProps = {
  onSelectOption?: (option: TypeaheadMenuOption['key']) => void
  enableAI?: SlashCommandPluginOptions['enableAI']
}

export const SlashCommandPlugin = ({
  onSelectOption,
  enableAI,
}: SlashCommandPluginProps) => {
  const [editor] = useLexicalComposerContext()
  const [query, setQuery] = useState<string | null>(null)

  useEffect(() => {
    return editor.registerCommand(
      INSERT_SLASH_MENU_COMMAND,
      (nodeKey) => {
        const node = $getNodeByKey(nodeKey)

        if (node == null) {
          return false
        }

        const textNode = $createTextNode('/')
        const paragraphNode =
          $isParagraphNode(node) && node.isEmpty()
            ? node
            : $createParagraphNode()

        paragraphNode.append(textNode)

        if (!node.is(paragraphNode)) {
          node.insertAfter(paragraphNode)
        }

        paragraphNode.selectEnd()

        return true
      },
      COMMAND_PRIORITY_NORMAL
    )
  }, [editor])

  const checkForTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0,
  })

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(editor, { enableAI })
    if (!query) {
      return baseOptions
    }
    let optionCount = 0
    const filteredOtions = baseOptions.map((group) => {
      const optionsWithScore = group.options.map(
        (option): [TypeaheadMenuOption, number] => [
          option,
          Math.max(
            computeSearchScore(option.title, query),
            ...option.keywords.map((keyword) =>
              computeSearchScore(keyword, query)
            )
          ),
        ]
      )

      const maxGroupScore = Math.max(
        ...optionsWithScore.map(([_, score]) => score)
      )

      const filtered = optionsWithScore
        .filter(([_, score]) => score > 0)
        .sort((a, b) => b[1] - a[1])
        .map(([option]) => option)

      optionCount += filtered.length

      return {
        ...group,
        options: filtered,
        score: maxGroupScore,
      }
    })

    if (optionCount === 0 && query.length > 0) {
      return [
        {
          options: [
            {
              id: 'no-results-placeholder',
              keywords: [],
              key: 'no-results-placeholder',
              setRefElement: () => null,
              title: 'No results found',
              onSelect: () => null,
            },
          ],
        },
      ]
    }

    const sortedOptions = filteredOtions.sort((a, b) => b.score - a.score)

    return sortedOptions
  }, [editor, query, enableAI])

  const handleSelectOption = useCallback(
    (
      selectedOption: TypeaheadMenuOption,
      nodeToRemove: TextNode | null,
      closeMenu: () => void,
      matchingString: string
    ) => {
      editor.update(() => {
        nodeToRemove?.remove()
        if (selectedOption?.onSelect) {
          selectedOption.onSelect(matchingString)
        }
        closeMenu()
      })
      onSelectOption?.(selectedOption.key)
    },
    [editor, onSelectOption]
  )

  return (
    <TypeaheadMenuPlugin<TypeaheadMenuOption>
      onQueryChange={(matchingString) => setQuery(matchingString)}
      onSelectOption={handleSelectOption}
      options={options.flatMap((g) => g.options)}
      menuRenderFn={({
        selectedIndex,
        selectOptionAndCleanUp,
        setHighlightedIndex,
      }) => (
        <TypeaheadMenu
          options={options}
          selectedIndex={selectedIndex}
          selectOptionAndCleanUp={selectOptionAndCleanUp}
          setHighlightedIndex={setHighlightedIndex}
        />
      )}
      triggerFn={checkForTriggerMatch}
    />
  )
}
