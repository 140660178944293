import { createTaskFilterFn } from '@motion/ui-logic/pm/data'
import { toMerged } from '@motion/utils/core'
import { entries, keys } from '@motion/utils/object'
import { Sentry } from '@motion/web-base/sentry'
import {
  type AllModelsSchema,
  type NormalTaskSchema,
  type ProjectSchema,
} from '@motion/zod/client'

import {
  notifyManager,
  type QueryFilters,
  type QueryKey,
} from '@tanstack/react-query'

import {
  createAppendNewTaskFilterFn,
  isTaskQuery,
  isTaskType,
} from './task-utils'

import {
  type CacheEntry,
  type Model,
  MODEL_CACHE_KEY,
  type ModelCacheCollection,
  type ModelId,
} from '../../model-cache'
import { type MotionCacheContext } from '../../types'
import { log } from '../../utils'
import { ARCHIVED_TIME_KEY, DELETE_MODEL } from '../constants'
import {
  type ModelStore,
  type QueryCacheDelete,
  type QueryCacheMatches,
  type QueryCachePatch,
  type QueryCacheUpsert,
} from '../types'

export function matchQueries(
  ctx: MotionCacheContext,
  filter: QueryFilters<QueryCacheMatches>
) {
  const queries = ctx.client.getQueriesData<QueryCacheMatches>(filter)
  log.debug('queries', queries)
  return queries.filter(([_, matches]) => matches != null)
}

type AnyCacheOperations<
  TType extends keyof AllModelsSchema = keyof AllModelsSchema,
> = QueryCacheUpsert<TType> | QueryCachePatch<TType> | QueryCacheDelete<TType>

function groupByKey<TType extends keyof AllModelsSchema>(
  updates: AnyCacheOperations<TType>[]
) {
  const groups = new Map<QueryKey, AnyCacheOperations<TType>[]>()
  for (const op of updates) {
    let bucket = groups.get(op.key)
    if (bucket == null) {
      bucket = []
      groups.set(op.key, bucket)
    }
    bucket.push(op)
  }

  return groups
}

export function updateQueryData<TType extends keyof AllModelsSchema>(
  ctx: MotionCacheContext,
  cacheUpdates: AnyCacheOperations<TType>[]
) {
  const now = Date.now()
  notifyManager.batch(() => {
    log.time(`update query data`, () => {
      const grouped = groupByKey(cacheUpdates)

      for (const [key, ops] of grouped.entries()) {
        const [modelsToDelete, otherData] = getModelsToDeleteAndOtherData(ops)

        if (key === MODEL_CACHE_KEY && modelsToDelete.length > 0) {
          Sentry.captureMessage('Should not remove models from model cache', {
            extra: {
              modelsToDelete,
            },
          })
        }

        if (modelsToDelete.length > 0) {
          log.debug(`updating key - delete models`, ops.length, {
            key,
            ops,
            modelsToDelete,
          })
        }

        const mergedData = otherData[otherData.length - 1] ?? {}
        if (otherData.length > 0) {
          log.debug(`updating key`, otherData.length, {
            key,
            ops,
            mergedData,
          })
        }

        ctx.client.setQueryData(
          key,
          (old: QueryCacheMatches | undefined) => {
            const merged = toMerged(old ?? {}, mergedData)
            const withoutDeleted = removeModels(merged, modelsToDelete)

            return withoutDeleted
          },
          { updatedAt: now }
        )
      }
    })
  })
}

function getModelsToDeleteAndOtherData<TType extends keyof AllModelsSchema>(
  ops: AnyCacheOperations<TType>[]
): [{ id: ModelId; type: TType }[], QueryCacheMatches[]] {
  return ops.reduce<[{ id: ModelId; type: TType }[], QueryCacheMatches[]]>(
    ([deleteOps, otherData], op) => {
      if (op.data === DELETE_MODEL) {
        return [deleteOps.concat({ id: op.id, type: op.type }), otherData]
      }
      return [deleteOps, otherData.concat([op.data])]
    },
    [[], []]
  )
}

export function removeModels<
  P extends QueryCacheMatches,
  TType extends keyof AllModelsSchema,
>(old: P, modelsToDelete: { id: ModelId; type: TType }[]): P {
  return modelsToDelete.reduce(
    (acc, model) => {
      const notMatchingId = (id: ModelId) => id !== model.id

      if ('ids' in acc && 'meta' in acc && acc.meta.model === model.type) {
        acc.ids = acc.ids.filter(notMatchingId)
      }

      if (acc.models[model.type]?.[model.id] != null) {
        acc.models = {
          ...acc.models,
          [model.type]: Object.fromEntries(
            // @ts-expect-error - defined
            entries(acc.models[model.type]).filter(([id]) => notMatchingId(id))
          ),
        }
      }

      return acc
    },
    { ...old }
  )
}

export function shouldAppendNew<TType extends keyof AllModelsSchema>({
  ctx,
  key,
  ...taskWithType
}: {
  ctx: MotionCacheContext
  key: QueryKey
  type: TType
  model: Model<TType>
}) {
  /**
   * Only filter tasks.
   *
   * Add additional filters here as needed.
   */
  if (!isTaskType(taskWithType)) {
    return true
  }

  const appendNewTaskFilterFn = createAppendNewTaskFilterFn(ctx)

  const opt = appendNewTaskFilterFn(key)
  if (typeof opt === 'boolean') return opt

  return opt(taskWithType)
}

export function getResponseModelType(
  source: QueryCacheMatches
): keyof AllModelsSchema | null {
  if (!('meta' in source)) return null
  return source.meta.model
}

export function isModelRecordModelCacheCollection<
  TType extends keyof AllModelsSchema,
>(target: ModelStore<TType>): target is ModelCacheCollection<Model<TType>> {
  if (target == null) return false

  // If no keys, it's not structured as a model cache collection
  if (Object.keys(target).length === 0) return false

  return Object.values(target).every(isModelCacheEntry)
}

export function buildDeletedInverse<TType extends keyof AllModelsSchema>(
  target: QueryCacheMatches,
  type: TType,
  id: ModelId
): Model<TType> | null {
  const modelRecord = target.models[type] as ModelStore<TType>
  if (modelRecord == null || modelRecord[id] == null) return null

  return isModelRecordModelCacheCollection(modelRecord)
    ? modelRecord[id].value
    : modelRecord[id]
}

export function buildInverse<TType extends keyof AllModelsSchema>(
  target: Model<TType>,
  changes: Partial<Model<TType>>
): Partial<Model<TType>> {
  return keys(changes).reduce((acc, key) => {
    acc[key] = target[key]
    return acc
  }, {} as Model<TType>)
}

export const isModelCacheEntry = (obj: unknown): obj is CacheEntry<any> => {
  if (obj == null) return false
  if (typeof obj !== 'object') return false
  return 'updatedAt' in obj && 'value' in obj
}

export function isNormalTaskOrProject(
  target: object
): target is NormalTaskSchema | ProjectSchema {
  return 'type' in target && target.type === 'NORMAL'
}

export function mergeCacheData<TType extends keyof AllModelsSchema>(
  target: Model<TType>,
  updates: Partial<Model<TType>>
): Model<TType> {
  return toMerged(target, updates)
}

export function shouldRemoveOldEntity<TType extends keyof AllModelsSchema>({
  ctx,
  key,
  ...modelWithType
}: {
  ctx: MotionCacheContext
  key: QueryKey
  type: TType
  model: Model<TType>
}) {
  // If archived task, then remove it
  if (
    ARCHIVED_TIME_KEY in modelWithType &&
    modelWithType[ARCHIVED_TIME_KEY] != null
  ) {
    return true
  }

  // Only for tasks so far
  if (!isTaskType(modelWithType)) {
    return false
  }

  // Don't remove from the model cache
  if (key === MODEL_CACHE_KEY) {
    return false
  }

  // If the task is being updated to a new relation (project, workspace), we need to remove it from the old relation's query
  const filterArg = key[key.length - 1]
  if (!isTaskQuery(filterArg)) return false
  if (filterArg.filters.length === 0) return false

  const filterTask = createTaskFilterFn(filterArg.filters, ctx)
  const matches = filterTask(modelWithType.model)
  const shouldRemove = !matches

  return shouldRemove
}
