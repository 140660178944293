import { classed } from '@motion/theme'
import { addComponentName } from '@motion/ui/helpers'

import React, { type ReactNode, useMemo } from 'react'

import { type SearchableListItemProps } from './types'

import { useItemSelectors, useSelectionContext } from '../hooks'
import {
  SelectionContext,
  type SelectionContextType,
} from '../hooks/selection-context'
import { ITEM_HEIGHT } from '../utils'

export type SearchableListContentProps = {
  children: React.ReactNode
  search: string
  activeValue: string | null
  setActiveValue: (value: string | null) => void

  containerRef?: React.RefObject<HTMLDivElement | null>
  keyboardControlRef?: React.RefObject<HTMLElement | null>
}

export const SearchableListContent = (props: SearchableListContentProps) => {
  const fallbackRef = React.useRef<HTMLDivElement>(null)
  const containerRef = props.containerRef ?? fallbackRef
  const wrapperRef = props.keyboardControlRef ?? fallbackRef

  const { setActiveValue } = props

  const selectors = useItemSelectors(containerRef)
  const {
    getActiveItem,
    getNextActiveItemValueByChange,
    getSelectedItemValue,
  } = selectors

  React.useLayoutEffect(() => {
    // Set active value to the selected value on mount
    const newValue = getSelectedItemValue()
    setActiveValue(newValue || null)
  }, [getSelectedItemValue, setActiveValue])

  React.useLayoutEffect(() => {
    if (!wrapperRef.current) return

    const ref = wrapperRef.current

    function updateActiveByChange(change: -1 | 1) {
      // Update the active value to the next or the previous
      const newActiveValue = getNextActiveItemValueByChange(change)
      if (newActiveValue) {
        setActiveValue(newActiveValue)
      }
    }

    function onKeyDown(e: KeyboardEvent) {
      if (e.defaultPrevented) return

      switch (e.key) {
        case 'ArrowDown': {
          e.preventDefault()
          updateActiveByChange(1)
          break
        }
        case 'ArrowUp': {
          e.preventDefault()
          updateActiveByChange(-1)
          break
        }
        case 'Tab':
        case 'Enter': {
          // Trigger item onSelect
          e.preventDefault()
          const item = getActiveItem()
          if (item) {
            item.click()
          }
        }
      }
    }

    ref.addEventListener('keydown', onKeyDown)

    return () => void ref.removeEventListener('keydown', onKeyDown)
  }, [
    getActiveItem,
    getNextActiveItemValueByChange,
    setActiveValue,
    wrapperRef,
  ])

  const providerValue = useMemo((): SelectionContextType => {
    return {
      active: props.activeValue,
      setActive: props.setActiveValue,
      search: props.search,
      ...selectors,
    }
  }, [props.activeValue, props.search, props.setActiveValue, selectors])

  return (
    <div
      ref={fallbackRef}
      role='menu'
      {...addComponentName('SearchableListContent')}
    >
      <SelectionContext.Provider value={providerValue}>
        {props.children}
      </SelectionContext.Provider>
    </div>
  )
}

export const SearchableListItem = ({
  disabled,
  children,
  value,
  active,
  setActiveValue,
  onSelect,
  role,
  className,
  selected,
  height,
}: SearchableListItemProps) => {
  const handleSetActive = () => setActiveValue(value)
  return (
    <SearchableListItemContainer
      data-value={value || undefined}
      data-searchable-list-item
      data-active={active || undefined}
      aria-disabled={disabled || undefined}
      className={className}
      style={{ height: height ?? ITEM_HEIGHT }}
      onClick={disabled ? undefined : () => onSelect?.()}
      onPointerMove={disabled ? undefined : handleSetActive}
      onPointerDown={disabled ? undefined : handleSetActive}
      tabIndex={active ? 0 : -1}
      role={role}
      aria-selected={selected || undefined}
    >
      {children}
    </SearchableListItemContainer>
  )
}

type ConnectedSearchableListItemProps = {
  children: ReactNode
  value: string
  role?: string
  onSelect(): void
  disabled?: boolean
  className?: string
}
export const ConnectedSearchableListItem = (
  props: ConnectedSearchableListItemProps
) => {
  const selectors = useSelectionContext()

  return (
    <SearchableListItem
      active={selectors.active === props.value}
      value={props.value}
      setActiveValue={selectors.setActive}
      role={props.role ?? 'menuitem'}
      onSelect={props.onSelect}
      disabled={props.disabled}
      className={props.className}
    >
      {props.children}
    </SearchableListItem>
  )
}

export const SearchableListItemContainer = classed('div', {
  base: `
    group/menuitem
    relative
    flex items-center
    min-w-[180px] px-3
    cursor-pointer select-none
    truncate text-sm

    text-dropdown-item-text-default
    data-[active]:bg-dropdown-item-bg-hover
    rounded

    focus:outline-none

    aria-[disabled="true"]:opacity-40
  `,
})
