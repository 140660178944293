import {
  type Breadcrumb,
  type BreadcrumbHint,
  breadcrumbsIntegration,
  type BrowserOptions,
  type ErrorEvent,
  type EventHint,
  globalHandlersIntegration,
  init,
  replayIntegration,
  setExtra,
} from '@sentry/browser'

import { onNetworkStatusChanged } from '../env'
import { makeLog } from '../logging'

let lastErrors: string[] = []

const log = makeLog('sentry')

export type InitializeSentryOptions = {
  version: string
  onBeforeBreadcrumb?: (
    breadcrumb: Breadcrumb,
    hint?: BreadcrumbHint
  ) => Breadcrumb | null
  onBeforeSend?: (event: ErrorEvent, hint: EventHint) => ErrorEvent | null
  allowUrls?: BrowserOptions['allowUrls']
}

export { setUser as setSentryUser } from '@sentry/browser'

export const replay: ReturnType<typeof replayIntegration> = replayIntegration({
  maskAllText: true,
  maskAllInputs: true,
  blockAllMedia: true,
})

onNetworkStatusChanged((status) => {
  setExtra('network-status', status)
})

function isEditorBreadcrumb(hint?: BreadcrumbHint) {
  if (!window.location.pathname.startsWith('/web/pm/docs')) {
    return false
  }

  if (
    hint == null ||
    hint.event == null ||
    hint.event.target == null ||
    hint.event.target.closest('#note-editor') == null
  ) {
    return false
  }

  return true
}

// Set up to only send to Sentry if in Prod, otherwise log to browser console
export const initializeSentry = (options: InitializeSentryOptions) => {
  const {
    onBeforeBreadcrumb = (crumb) => crumb,
    onBeforeSend = (event) => event,
    allowUrls,
  } = options

  init({
    beforeBreadcrumb(breadcrumb, hint) {
      try {
        if (!breadcrumb.category) {
          log('breadcrumb', breadcrumb)
        }
        if (!__SENTRY_ENABLED__) {
          return null
        }

        // Don't send breadcrumbs from the editor since they contain sensitive data
        if (isEditorBreadcrumb(hint)) {
          return null
        }

        return onBeforeBreadcrumb(breadcrumb, hint)
      } catch (e) {
        log.warn(
          'An error was thrown while recording breadcrumb. Dropping breadcrumb',
          breadcrumb,
          e
        )
        return null
      }
    },
    beforeSend: (event, hint) => {
      try {
        if (!__SENTRY_ENABLED__) {
          log('disabled', {
            type: 'event',
            payload: event,
          })
          return null
        }

        const modified = onBeforeSend(event, hint)
        if (modified == null) return null

        if (
          modified.exception &&
          modified.exception.values &&
          modified.exception.values.length
        ) {
          const lastError = modified.exception.values[0].value

          if (lastError && !lastErrors.includes(lastError)) {
            lastErrors.unshift(lastError)
            lastErrors = lastErrors.slice(0, 5)
          } else {
            return null
          }
        }

        return modified
      } catch (e) {
        log.warn(
          'An error was thrown while sending event. Dropping event',
          event,
          e
        )
        return null
      }
    },
    // motion-web
    // https://inmotion.sentry.io/projects/motion-web
    dsn: 'https://820f833d4063013505ae8080eeac79f9@o348473.ingest.sentry.io/4506854753566720',
    environment: __SENTRY_ENVIRONMENT__,
    release: __SENTRY_RELEASE__,
    allowUrls,
    integrations: [
      breadcrumbsIntegration({
        console: false,
      }),
      globalHandlersIntegration({
        onerror: true,
        onunhandledrejection: false,
      }),
      replay,
    ],

    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  })

  log('initialized', { version: options.version })
}
