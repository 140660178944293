import { type MotionDesignTokenNames } from './types'

/**
 * Gets the reference to the css variable for a given token
 * @param token the token to get the css variable for
 * @returns the formatted variable
 */
export function cssVar(token: MotionDesignTokenNames) {
  return `var(--motion-${token})`
}

/**
 * Create a new css color that will toggle between the `base` and the `active` color based on the `--motion-is-active` css variable
 * If there are only 1 color, then `base` becomes 25% lighter and `active` is the color
 *
 * If `motion-is-active` is `0`, the color output is going to be `base`
 * If `motion-is-active` is `1`, the color output is going to be `active`
 */
export function cssColorMixActive(color: string): string
export function cssColorMixActive(base: string, active: string): string
export function cssColorMixActive(color1: string, color2?: string): string {
  const base =
    color2 == null ? `color-mix(in srgb, ${color1} 75%, white)` : color1
  const active = color2 == null ? color1 : color2

  return `color-mix(in srgb,
    ${base} calc(100% - var(--motion-is-active, 0) * 100%),
    ${active} calc(var(--motion-is-active, 0) * 100%)
  )`
}
