import { FeatureTier } from '@motion/zod/client'

import { Term } from '../billing'

export type Tier = (typeof FeatureTier)[number]

export type TierPrices = {
  singleSeat: {
    monthly: number
    annual: number
  }
  multiSeat: {
    monthly: number
    annual: number
  }
}

const Delta = ['upgrade', 'downgrade', 'no_change'] as const
export type Delta = (typeof Delta)[number]

export const getTierDelta = (current: Tier, target: Tier): Delta => {
  if (FeatureTier.indexOf(current) > FeatureTier.indexOf(target)) {
    return 'downgrade'
  }
  if (FeatureTier.indexOf(current) < FeatureTier.indexOf(target)) {
    return 'upgrade'
  }
  return 'no_change'
}

export const getTermDelta = (current: Term, target: Term): Delta => {
  if (current === Term.Monthly && target === Term.Annual) {
    return 'upgrade'
  }
  if (current === Term.Annual && target === Term.Monthly) {
    return 'downgrade'
  }
  return 'no_change'
}

export const getBucketDelta = (current: number, target: number): Delta => {
  if (current > target) {
    return 'downgrade'
  }
  if (current < target) {
    return 'upgrade'
  }
  return 'no_change'
}

// Display specific context
export type TierDisplayMode =
  | 'onboarding'
  | 'billingSettings'
  | 'upsell'
  | 'resubscribe'
  | 'teamUpgrade'
