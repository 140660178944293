import { useMemo } from 'react'

interface ClickableElement extends Element {
  click(): void
}

// Selectors
const ITEM_SELECTOR = `[data-searchable-list-item]`
const VALID_ITEM_SELECTOR = `${ITEM_SELECTOR}:not([aria-disabled="true"])`
const ACTIVE_ITEM_SELECTOR = `${ITEM_SELECTOR}[data-active="true"]`
const SELECTED_ITEM_SELECTOR = `${ITEM_SELECTOR}[aria-selected="true"]`
const VALUE_ATTR = `data-value`

export const useItemSelectors = (
  ref: React.RefObject<HTMLDivElement | null>
) => {
  return useMemo(() => {
    function getFirstActiveItemValue() {
      const item = getValidItems().find((item) => !item.ariaDisabled)
      scrollSelectedIntoView(item)
      const value = item?.getAttribute(VALUE_ATTR)
      return value
    }

    function getActiveItem() {
      if (!ref.current) return
      return ref.current.querySelector(ACTIVE_ITEM_SELECTOR) as ClickableElement
    }

    function getSelectedItemValue() {
      if (!ref.current) return
      const item = ref.current.querySelector(
        SELECTED_ITEM_SELECTOR
      ) as ClickableElement
      if (item) {
        scrollSelectedIntoView(item)
        return item.getAttribute(VALUE_ATTR)
      }
      return getFirstActiveItemValue()
    }

    function getValidItems() {
      if (!ref.current) return []
      return Array.from(ref.current.querySelectorAll(VALID_ITEM_SELECTOR))
    }

    function getNextActiveItemValueByChange(change: -1 | 1) {
      const active = getActiveItem()
      const items = getValidItems()
      const index = items.findIndex((item) => item === active)

      // Get item at this index
      const nextItem = items[index + change]
      scrollSelectedIntoView(nextItem)
      return nextItem?.getAttribute(VALUE_ATTR)
    }

    function scrollSelectedIntoView(item: Element | undefined) {
      if (!item) return
      item.scrollIntoView({ block: 'nearest' })
    }

    return {
      scrollSelectedIntoView,
      getActiveItem,
      getNextActiveItemValueByChange,
      getFirstActiveItemValue,
      getSelectedItemValue,
    }
  }, [ref])
}

export type ItemSelectors = ReturnType<typeof useItemSelectors>
