import { useResizeObserver } from '@motion/react-core/hooks'

import {
  memo,
  type ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react'

export interface ItemsMeasurements {
  containerWidth: number
  activatorWidth: number
  suffixWidth: number
  hiddenTabWidths: number[]
}

export interface ItemsMeasurerProps<T> {
  selectedIndex: number
  items: T[]
  activator: ReactNode
  suffix: ReactNode
  renderItem: (item: T, index: number) => ReactNode
  handleMeasurement(measurements: ItemsMeasurements): void
}

export const ItemsMeasurer = memo(function ItemsMeasurer<T>({
  selectedIndex,
  items,
  activator,
  suffix,
  renderItem,
  handleMeasurement: handleMeasurementProp,
}: ItemsMeasurerProps<T>) {
  const animationFrame = useRef<number | null>(null)
  const containerRef = useRef<HTMLUListElement | null>(null)

  const handleMeasurement = useCallback(() => {
    if (animationFrame.current) {
      cancelAnimationFrame(animationFrame.current)
    }

    animationFrame.current = requestAnimationFrame(() => {
      if (!containerRef.current) {
        return
      }

      const containerWidth = containerRef.current.offsetWidth
      const hiddenTabNodes = containerRef.current.children
      const hiddenTabNodesArray = Array.from(hiddenTabNodes)

      const hiddenTabWidths = hiddenTabNodesArray.map((node) => {
        return Math.ceil(node.getBoundingClientRect().width)
      })
      const [activatorWidth, ...suffixWidths] = hiddenTabWidths.splice(
        items.length - hiddenTabWidths.length
      )

      handleMeasurementProp({
        containerWidth,
        activatorWidth,
        suffixWidth: suffixWidths.reduce((acc, w) => acc + w, 0),
        hiddenTabWidths,
      })
    })
  }, [handleMeasurementProp, items.length])

  useResizeObserver(containerRef, () => {
    handleMeasurement()
  })

  useLayoutEffect(() => {
    handleMeasurement()
  }, [handleMeasurement, items, selectedIndex])

  const itemsMarkup = items.map((item, index) => {
    return renderItem(item, index)
  })

  return (
    <ul className='invisible flex h-0 flex-wrap' ref={containerRef}>
      {itemsMarkup}
      {activator}
      {suffix}
    </ul>
  )
})
