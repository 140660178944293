/**
 * The values here map to the expected api property
 * values being toggled in the backend.
 */
export enum NotificationPreferenceId {
  BrowserMeetingReminder = 'browserMeetingReminder',
  BrowserTaskReminder = 'browserTaskReminder',

  EmailTaskAssignedToYou = 'emailTaskAssignedToYou',
  EmailTaskStatusChanged = 'emailTaskStatusChanged',
  EmailMentionedInComment = 'emailMentionedInComment',
  EmailMentionedInNote = 'emailMentionedInNote',
  EmailTaskArchived = 'emailTaskArchived',
  EmailTaskCreated = 'emailTaskCreated',
  EmailFlows = 'emailFlows',
  EmailBulkOperations = 'emailBulkOperations',

  MobileTaskAssignedToYou = 'mobileTaskAssignedToYou',
  MobileTaskStatusChanged = 'mobileTaskStatusChanged',
  MobileMentionedInComment = 'mobileMentionedInComment',
  MobileMentionedInNote = 'mobileMentionedInNote',
  MobileTaskAutoArchived = 'mobileTaskArchived',
}

export type NotificationPreferenceEntry = {
  id: NotificationPreferenceId
  title: string
}

export const browserNotifications: NotificationPreferenceEntry[] = [
  {
    id: NotificationPreferenceId.BrowserMeetingReminder,
    title: 'Meeting Reminders',
  },
  {
    id: NotificationPreferenceId.BrowserTaskReminder,
    title: 'Task Reminders',
  },
]

export const emailNotifications: NotificationPreferenceEntry[] = [
  {
    id: NotificationPreferenceId.EmailTaskAssignedToYou,
    title: 'Task assigned to you',
  },
  {
    id: NotificationPreferenceId.EmailTaskStatusChanged,
    title: 'Status changed on task you are assigned to',
  },
  {
    id: NotificationPreferenceId.EmailMentionedInComment,
    title: 'Mentioned in comment',
  },
  {
    id: NotificationPreferenceId.EmailMentionedInNote,
    title: 'Mentioned in document',
  },
  {
    id: NotificationPreferenceId.EmailTaskArchived,
    title: 'Task auto-archived',
  },
  {
    id: NotificationPreferenceId.EmailTaskCreated,
    title: 'Task Created with Siri',
  },
  {
    id: NotificationPreferenceId.EmailFlows,
    title: 'Project stage changes',
  },
  {
    id: NotificationPreferenceId.EmailBulkOperations,
    title: 'Bulk operation performed',
  },
]

export const mobileNotifications: NotificationPreferenceEntry[] = [
  {
    id: NotificationPreferenceId.MobileTaskAssignedToYou,
    title: 'Task assigned to you',
  },
  {
    id: NotificationPreferenceId.MobileTaskStatusChanged,
    title: 'Status changed on task you are assigned to',
  },
  {
    id: NotificationPreferenceId.MobileMentionedInComment,
    title: 'Mentioned in comment',
  },
  {
    id: NotificationPreferenceId.MobileMentionedInNote,
    title: 'Mentioned in document',
  },
  {
    id: NotificationPreferenceId.MobileTaskAutoArchived,
    title: 'Task auto-archived',
  },
]
