import { type NodeKey } from 'lexical'

import { ResizableBlock } from './resizable-block'

type YoutubeEmbedComponentProps = {
  nodeKey: NodeKey
  width: number
  src: string
}

const parseUrl = (url: string) => {
  const match =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url)

  const id = match ? (match?.[2].length === 11 ? match[2] : null) : null

  if (id != null) {
    return {
      id,
      url,
    }
  }

  return null
}
export const YoutubeEmbedComponent = (props: YoutubeEmbedComponentProps) => {
  const parsed = parseUrl(props.src)
  return (
    <ResizableBlock
      nodeKey={props.nodeKey}
      initialWidth={props.width}
      aspectRatio='16/9'
    >
      {parsed ? (
        <iframe
          className='w-full h-full absolute top-0 left-0'
          src={`https://www.youtube-nocookie.com/embed/${parsed.id}`}
          title='YouTube video player'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerPolicy='strict-origin-when-cross-origin'
          allowFullScreen
        />
      ) : (
        <div>Not a youtube video</div>
      )}
    </ResizableBlock>
  )
}
