import { $isCodeHighlightNode } from '@lexical/code'
import { $isListItemNode, $isListNode } from '@lexical/list'
import { $isQuoteNode } from '@lexical/rich-text'
import { mergeRegister } from '@lexical/utils'
import {
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
  getDOMSelection,
  type LexicalEditor,
  type LexicalNode,
  type NodeKey,
  type TextFormatType,
} from 'lexical'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import {
  TextSelectionToolbar,
  type TextSelectionToolbarProps,
} from './components/text-selection-toolbar'

import { getSelectedNode } from '../../utils'

type UseFloatingTextFormatToolbarProps = {
  editor: LexicalEditor
  anchorElem: HTMLElement | null
  onSelectOption?: TextSelectionToolbarProps['onSelectOption']
  hasDocsAiOnToolbar?: TextSelectionToolbarProps['hasDocsAiOnToolbar']
  onCreateTaskWithAI?: TextSelectionToolbarProps['onCreateTaskWithAI']
  onOpenCreateTaskWithAiModal?: TextSelectionToolbarProps['onOpenCreateTaskWithAiModal']
  onOpenSummarizeWithAiModal?: TextSelectionToolbarProps['onOpenSummarizeWithAiModal']
  onOpenRewriteModal?: TextSelectionToolbarProps['onOpenRewriteModal']
}

export function useFloatingTextFormatToolbar({
  editor,
  anchorElem,
  onSelectOption,
  hasDocsAiOnToolbar,
  onCreateTaskWithAI,
  onOpenCreateTaskWithAiModal,
  onOpenSummarizeWithAiModal,
  onOpenRewriteModal,
}: UseFloatingTextFormatToolbarProps): JSX.Element | null {
  const isMouseDownRef = useRef(false)

  const [isText, setIsText] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isCode, setIsCode] = useState(false)
  const [isQuote, setIsQuote] = useState(false)
  const [selectedNodeCount, setSelectedNodeCount] = useState(0)
  const [selectedListItemKeys, setSelectedListItemKeys] = useState<NodeKey[]>(
    []
  )

  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      // Should not pop up the floating toolbar when using IME input
      if (editor.isComposing() || isMouseDownRef.current) {
        return
      }
      const selection = $getSelection()
      const nativeSelection = getDOMSelection(editor._window)
      const rootElement = editor.getRootElement()

      if (
        nativeSelection !== null &&
        (!$isRangeSelection(selection) ||
          rootElement === null ||
          !rootElement.contains(nativeSelection.anchorNode))
      ) {
        setIsText(false)
        return
      }

      if (!$isRangeSelection(selection)) {
        return
      }

      const node = getSelectedNode(selection)

      const selectedNodes = selection.getNodes()

      const formatStates: Record<TextFormatType, boolean> = {
        bold: false,
        italic: false,
        underline: false,
        strikethrough: false,
        code: false,
        highlight: false,
        subscript: false,
        superscript: false,
        lowercase: false,
        capitalize: false,
        uppercase: false,
      }
      let isQuote = false

      const selectedNodeKeys = new Set<NodeKey>(
        selectedNodes.map((node) => node.getKey())
      )

      // Track selected list item nodes for create task with AI
      const selectedListItemNodes = new Set<LexicalNode>()

      let hasNestedListItemsInSelection = false

      for (const node of selectedNodes) {
        if ($isTextNode(node)) {
          for (const format of Object.keys(formatStates) as TextFormatType[]) {
            formatStates[format] ||= node.hasFormat(format)
          }
        }
        const parent = node.getParent()
        if ($isQuoteNode(parent)) {
          isQuote = true
        }

        if ($isListItemNode(node) && $isListNode(parent)) {
          if (selectedNodeKeys.has(parent.getKey())) {
            hasNestedListItemsInSelection = true
          } else {
            selectedListItemNodes.add(node)
          }
        }
      }

      if (!hasNestedListItemsInSelection) {
        setSelectedListItemKeys(
          Array.from(selectedListItemNodes).map((node) => node.getKey())
        )
      }

      setIsBold(formatStates.bold)
      setIsItalic(formatStates.italic)
      setIsUnderline(formatStates.underline)
      setIsStrikethrough(formatStates.strikethrough)
      setIsCode(formatStates.code)

      setIsQuote(isQuote)

      if (
        !$isCodeHighlightNode(selection.anchor.getNode()) &&
        selection.getTextContent() !== ''
      ) {
        setIsText($isTextNode(node) || $isParagraphNode(node))
      } else {
        setIsText(false)
      }

      const rawTextContent = selection.getTextContent().replace(/\n/g, '')
      if (!selection.isCollapsed() && rawTextContent === '') {
        setIsText(false)
      }

      setSelectedNodeCount(selectedNodes.length)
    })
  }, [editor])

  useEffect(() => {
    document.addEventListener('selectionchange', updatePopup)
    return () => {
      document.removeEventListener('selectionchange', updatePopup)
    }
  }, [updatePopup])

  const handleMouseDown = useCallback(() => {
    isMouseDownRef.current = true
    setIsText(false)
  }, [])

  const handleMouseUp = useCallback(() => {
    isMouseDownRef.current = false
    updatePopup()
  }, [updatePopup])

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [handleMouseDown, handleMouseUp])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup()
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsText(false)
        }
      })
    )
  }, [editor, updatePopup])

  if (!editor.isEditable()) {
    return null
  }

  if (anchorElem == null || !isText) {
    return null
  }

  return createPortal(
    <TextSelectionToolbar
      editor={editor}
      anchorElem={anchorElem}
      isBold={isBold}
      isItalic={isItalic}
      isStrikethrough={isStrikethrough}
      isUnderline={isUnderline}
      isCode={isCode}
      isQuote={isQuote}
      onSelectOption={onSelectOption}
      hasDocsAiOnToolbar={hasDocsAiOnToolbar}
      onOpenCreateTaskWithAiModal={onOpenCreateTaskWithAiModal}
      onCreateTaskWithAI={onCreateTaskWithAI}
      isSingularTextSelection={isText && selectedNodeCount === 1}
      selectedListItemKeys={selectedListItemKeys}
      onOpenSummarizeWithAiModal={onOpenSummarizeWithAiModal}
      onOpenRewriteModal={onOpenRewriteModal}
    />,
    anchorElem
  )
}
