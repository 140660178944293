import { $isRootTextContentEmpty } from '@lexical/text'
import { type EditorState, type LexicalEditor } from 'lexical'
import { useCallback } from 'react'

export const useOnChange = (setCanSubmit: (canSubmit: boolean) => void) => {
  return useCallback(
    (editorState: EditorState, editor: LexicalEditor) => {
      editorState.read(() => {
        setCanSubmit(!$isRootTextContentEmpty(editor.isComposing(), true))
      })
    },
    [setCanSubmit]
  )
}
