import { EmbedNode } from '@motion/notes-shared'

import { BookmarkEmbedComponent } from './components/bookmark-embed-component'
import { VideoPlayerEmbedComponent } from './components/video-player-embed-component'
import { YoutubeEmbedComponent } from './components/youtube-embed-component'

EmbedNode.prototype.render = (nodeKey, src, srcType, width) => {
  switch (srcType) {
    case 'youtube':
      return <YoutubeEmbedComponent nodeKey={nodeKey} width={width} src={src} />
    case 'bookmark':
      return (
        <BookmarkEmbedComponent nodeKey={nodeKey} width={width} src={src} />
      )
    default:
      return (
        <VideoPlayerEmbedComponent nodeKey={nodeKey} width={width} src={src} />
      )
  }
}

export { EmbedNode }
