import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { MarkNode } from '@lexical/mark'
import { QuoteNode } from '@lexical/rich-text'
import { TableCellNode, TableRowNode } from '@lexical/table'

import { ActionItemBlockNode } from './action-item'
import { AttachmentNode } from './attachment'
import {
  CollapsibleHeadingContentNode,
  CollapsibleHeadingNode,
} from './collapsible-heading'
import {
  CollapsibleListItemNode,
  CollapsibleListNode,
  ListItemNodeReplacement,
  ListNodeReplacement,
} from './collapsible-list'
import {
  CollapsibleContainerNode,
  CollapsibleContentNode,
  CollapsibleTitleNode,
} from './collapsible-section'
import { EmbedNode } from './embed'
import { HeadingNodeReplacement, MotionHeadingNode } from './heading'
import { HorizontalRuleNode as HeadlessHorizontalRuleNode } from './horizontal-rule'
import { MentionNode } from './mention'
import { PublishTableNode } from './table-node'

import { monkeyPatchClearInlineStyles } from '../../utils/monkey-patch-node'

monkeyPatchClearInlineStyles(TableCellNode)
monkeyPatchClearInlineStyles(TableRowNode)

export const BASE_NODES = [
  AutoLinkNode,
  CodeHighlightNode,
  CodeNode,
  CollapsibleContainerNode,
  CollapsibleContentNode,
  CollapsibleHeadingContentNode,
  CollapsibleHeadingNode,
  CollapsibleListItemNode,
  CollapsibleListNode,
  CollapsibleTitleNode,
  HeadingNodeReplacement,
  LinkNode,
  ListItemNodeReplacement,
  ListNodeReplacement,
  MarkNode,
  MotionHeadingNode,
  QuoteNode,
  TableCellNode,
  TableRowNode,
]

/**
 * Web and Mobile override the `render` method for these nodes,
 * therefor they pass their own versions of these nodes to the editor.
 *
 * This constant is meant to be used when using the editor in headless mode.
 *
 * Be sure to add new decorator nodes to this list so the backend knows how to
 * handle them.
 */
export const SERVER_NODES = [
  ...BASE_NODES,
  ActionItemBlockNode,
  AttachmentNode,
  HeadlessHorizontalRuleNode,
  MentionNode,
  EmbedNode,
  PublishTableNode,
]
