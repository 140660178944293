export const updateHighlights = (
  matchRanges: Range[],
  currentIndex: number
) => {
  CSS.highlights.clear()

  if (matchRanges.length === 0) return

  // Create a highlight for all matches
  const allMatchesHighlight = new Highlight(...matchRanges)
  CSS.highlights.set('search-results', allMatchesHighlight)

  // Create a different highlight for the current selection
  if (currentIndex < 0) return
  const currentSelection = new Highlight(matchRanges[currentIndex])
  CSS.highlights.set('current-selection', currentSelection)
}

// Lowercase and strip diacritics
export const normalizeString = (str: string) => {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
}

export const selectCurrentMatch = (
  matches: Range[],
  currentMatchIndex: number
) => {
  if (
    matches.length > 0 &&
    currentMatchIndex >= 0 &&
    currentMatchIndex < matches.length
  ) {
    const currentRange = matches[currentMatchIndex]
    if (currentRange) {
      // Create a selection with the current match
      const selection = window.getSelection()
      if (selection) {
        selection.removeAllRanges()
        selection.addRange(currentRange.cloneRange())
      }
    }
  }
}

const isNodeInContainer = (container: HTMLElement | null, node: Node) => {
  if (!container) return false
  return (
    container.contains(node) ||
    (node.parentElement && container.contains(node.parentElement))
  )
}

export const findMatchedRanges = (
  query: string,
  container: HTMLElement,
  excludedContainers: HTMLElement[] = []
) => {
  const treeWalker = document.createTreeWalker(container, NodeFilter.SHOW_TEXT)

  const textNodes = []
  let currentNode = treeWalker.nextNode()

  while (currentNode) {
    textNodes.push(currentNode)
    currentNode = treeWalker.nextNode()
  }

  const allRanges: Range[] = []

  textNodes.forEach((el) => {
    if (
      !el.textContent ||
      excludedContainers.some((node) => isNodeInContainer(node, el))
    ) {
      return
    }

    const text = normalizeString(el.textContent ?? '')

    let startPos = 0
    if (text) {
      while (startPos < text.length) {
        const index = text.indexOf(query, startPos)
        if (index === -1) break

        const range = new Range()
        range.setStart(el, index)
        range.setEnd(el, index + query.length)
        allRanges.push(range)

        startPos = index + query.length
      }
    }
  })
  return allRanges
}
