import { canParseUrl } from '@motion/utils/string'

import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HorizontalRulePlugin } from '@lexical/react/LexicalHorizontalRulePlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { TablePlugin } from '@lexical/react/LexicalTablePlugin'
import { type ReactNode } from 'react'

import { useEditorContext } from '../context'
import {
  CodeHighlightPlugin,
  CodeToolbarPlugin,
  CustomFormattingPlugin,
  DevToolsPlugin,
  EmbedPlugin,
  LexicalAutoLinkPlugin,
  LinkEditorPlugin,
  ParagraphPlaceholderPlugin,
  ReadOnlyPlugin,
  TabIndentationPlugin,
  TableColumnResizingPlugin,
  TableHoverActionsPlugin,
  TrailingEmptyLinePlugin,
} from '../plugins'
import {
  CollapsibleArrowButtonsPlugin,
  CollapsibleHeadingPlugin,
} from '../plugins/collapsible-heading-plugin'
import { TurnIntoPlugin } from '../plugins/turn-into-plugin'

export type EditorPluginsProps = {
  children: ReactNode
  isMobile?: boolean
  readonly?: boolean
}

export function EditorPlugins({
  children,
  isMobile = false,
  readonly = false,
}: EditorPluginsProps) {
  const { setFloatingAnchorRef } = useEditorContext()

  return (
    <>
      <RichTextPlugin
        contentEditable={
          <div
            id='note-editor'
            className='motion-docs-editor'
            ref={setFloatingAnchorRef}
          >
            <ContentEditable
              className='focus:outline-none inset-0 pb-80 pt-4 w-[var(--editor-overflow-width)] px-[var(--editor-overflow-padding)] -ml-[var(--editor-overflow-padding)] leading-[1.6]'
              id='lexical-content-editable'
            />
          </div>
        }
        placeholder={null}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <CodeHighlightPlugin />
      <LinkPlugin validateUrl={canParseUrl} />
      <LexicalAutoLinkPlugin />
      <ListPlugin />
      <CheckListPlugin />
      <TabIndentationPlugin />
      <MarkdownShortcutPlugin />
      <HorizontalRulePlugin />
      <TablePlugin hasHorizontalScroll hasTabHandler />
      <CustomFormattingPlugin />
      <TableColumnResizingPlugin />
      <CodeToolbarPlugin />
      {!isMobile && <LinkEditorPlugin />}
      <TableHoverActionsPlugin />
      <TrailingEmptyLinePlugin />
      <ParagraphPlaceholderPlugin
        placeholder={
          isMobile
            ? 'Write something'
            : "Write something, or type '/' for commands..."
        }
      />
      <CollapsibleHeadingPlugin />
      <EmbedPlugin />
      <ReadOnlyPlugin readOnly={readonly} />
      <CollapsibleArrowButtonsPlugin />
      {!isMobile && <DevToolsPlugin />}
      <TurnIntoPlugin />
      {children}
    </>
  )
}
