import { addComponentName } from '@motion/ui/helpers'

import { type InitialConfigType } from '@lexical/react/LexicalComposer'
import { createDOMRange, createRectsFromDOMRange } from '@lexical/selection'
import type { LexicalEditor } from 'lexical'
import { $getSelection, $isRangeSelection } from 'lexical'
import {
  type ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'

import { CommentInputBox } from './comment-input-box'

type CommentHoverCardProps = {
  editor: LexicalEditor
  onSubmitComment: (htmlBody: string, mentions: Array<string>) => Promise<void>
  anchorElement: HTMLDivElement
  editorNodes?: InitialConfigType['nodes']
  editorPlugins?: ReactNode
}

export const CommentHoverCard = ({
  editor,
  onSubmitComment,
  anchorElement,
  editorNodes,
  editorPlugins,
}: CommentHoverCardProps) => {
  const boxRef = useRef<HTMLDivElement>(null)

  const selectionState = useMemo(
    () => ({
      container: document.createElement('div'),
      elements: [],
    }),
    []
  )

  const updateLocation = useCallback(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()

      if ($isRangeSelection(selection)) {
        const anchor = selection.anchor
        const focus = selection.focus
        const range = createDOMRange(
          editor,
          anchor.getNode(),
          anchor.offset,
          focus.getNode(),
          focus.offset
        )
        const boxElem = boxRef.current
        const anchorRect = anchorElement.getBoundingClientRect()

        if (range !== null && boxElem !== null) {
          const { left, bottom } = range.getBoundingClientRect()
          const selectionRects = createRectsFromDOMRange(editor, range)

          boxElem.style.left = `${left - anchorRect.left}px`
          boxElem.style.top = `${bottom - anchorRect.top + 4}px`

          const selectionRectsLength = selectionRects.length
          const { container } = selectionState
          const elements: Array<HTMLSpanElement> = selectionState.elements
          const elementsLength = elements.length

          for (let i = 0; i < selectionRectsLength; i++) {
            const selectionRect = selectionRects[i]
            let elem: HTMLSpanElement = elements[i]
            if (elem === undefined) {
              elem = document.createElement('span')
              // eslint-disable-next-line react-compiler/react-compiler
              elements[i] = elem
              container.appendChild(elem)
            }

            elem.classList.add('note-comment-selection')

            const style = `
              top:${selectionRect.top - anchorRect.top}px;
              left:${selectionRect.left - anchorRect.left}px;
              height:${selectionRect.height}px;
              width:${selectionRect.width}px;
            `
            elem.style.cssText = style
          }
          for (let i = elementsLength - 1; i >= selectionRectsLength; i--) {
            const elem = elements[i]
            container.removeChild(elem)
            elements.pop()
          }
        }
      }
    })
  }, [anchorElement, editor, selectionState])

  useLayoutEffect(() => {
    updateLocation()
    const container = selectionState.container

    anchorElement.appendChild(container)
    return () => {
      anchorElement.removeChild(container)
    }
  }, [anchorElement, selectionState.container, updateLocation])

  useEffect(() => {
    window.addEventListener('resize', updateLocation)

    return () => {
      window.removeEventListener('resize', updateLocation)
    }
  }, [updateLocation])

  const submitComment = useCallback(
    async (bodyHtml: string, mentions: Array<string>) => {
      try {
        await onSubmitComment(bodyHtml, mentions)
      } catch (err) {
        throw err
      }
    },
    [onSubmitComment]
  )

  return (
    <div
      className='block absolute w-80 shadow-lg'
      ref={boxRef}
      {...addComponentName('CommentHoverCard')}
    >
      <CommentInputBox
        onSubmit={submitComment}
        autoFocus
        nodes={editorNodes}
        plugins={editorPlugins}
      />
    </div>
  )
}
