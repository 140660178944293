import { LOW_COST_TRIAL_PRICE, Term } from '@motion/ui-logic/billing'
import { useExperiment, useHasTreatment } from '@motion/web-common/flags'

import { useTrialLength } from './use-trial-length'

export const useLowCostTrialExperiment = (
  term: Term,
  setTerm: (term: Term) => void,
  isTeam: boolean,
  isInitialPurchase: boolean
) => {
  const tieredPricingEnabled = useHasTreatment('tiered-pricing-v1')
  const eligible =
    isInitialPurchase && ((isTeam && tieredPricingEnabled) || !isTeam) // maximize-annual-b2b is only for tiered pricing
  const maximizeAnnualB2cPayload = useExperiment('maximize-annual-b2c', {
    track: eligible,
  }).payload
  const maximizeAnnualB2bPayload = useExperiment('maximize-annual-b2b', {
    track: eligible,
  }).payload

  const lowCostTrialPriceId = eligible
    ? isTeam
      ? maximizeAnnualB2bPayload?.priceId
      : maximizeAnnualB2cPayload?.priceId
    : undefined

  const normalTrialLength = useTrialLength(isTeam)
  const annualTrialLength = !isInitialPurchase ? 0 : normalTrialLength
  const monthlyTrialLength =
    !isInitialPurchase || lowCostTrialPriceId ? 0 : normalTrialLength
  const chosenTrialLength =
    term === Term.Annual
      ? annualTrialLength
      : term === Term.Monthly
        ? monthlyTrialLength
        : 0

  return {
    lowCostTrialPriceId,
    lowCostAmount: LOW_COST_TRIAL_PRICE,
    annualTrialLength,
    monthlyTrialLength,
    chosenTrialLength,
  }
}
