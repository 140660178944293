import { defineApi } from '@motion/rpc'

import { queryKeys as feedKeys } from './keys'

import type { RouteTypes } from '../types'

type FeedRequest = (
  | RouteTypes<'TasksController_getFeedById'>['request']
  | RouteTypes<'ProjectsController_getFeedById'>['request']
) & { type: 'task' | 'project' }

type FeedResponse =
  | RouteTypes<'TasksController_getFeedById'>['response']
  | RouteTypes<'ProjectsController_getFeedById'>['response']

export const getFeedById = defineApi<FeedRequest, FeedResponse>().using({
  uri: (args) =>
    args.type === 'task'
      ? `/v2/tasks/${args.id}/feed`
      : `/v2/projects/${args.id}/feed`,
  key: (args) => feedKeys.feedById(args.id),
})
