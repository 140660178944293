import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin'

export class TypeaheadMenuOption extends MenuOption {
  // What shows up in the editor
  title: string
  // Icon for display
  icon?: JSX.Element
  // For extra searching.
  keywords: Array<string>
  // TBD
  keyboardShortcut?: string
  // What happens when you select this option?
  onSelect?: (queryString: string) => void
  // Metadata
  meta?: unknown

  constructor(
    key: string,
    title: string,
    options: {
      icon?: JSX.Element
      meta?: unknown
      keywords?: Array<string>
      keyboardShortcut?: string
      onSelect?: (queryString: string) => void
    }
  ) {
    super(key)
    this.title = title
    this.keywords = options.keywords || []
    this.meta = options.meta
    this.icon = options.icon
    this.keyboardShortcut = options.keyboardShortcut
    this.onSelect = options.onSelect ? options.onSelect.bind(this) : undefined
  }
}
