import {
  DecoratorNode,
  DOMConversionMap,
  DOMExportOutput,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical'

export type SerializedActionItemBlockNode = Spread<
  {
    actionItemId: string
  },
  SerializedLexicalNode
>

export const DATA_ACTION_ITEM_BLOCK_ID_ATTRIBUTE = 'data-action-item-block-id'

export class ActionItemBlockNode<T = void> extends DecoratorNode<T> {
  __actionItemId: string

  render(nodeKey: NodeKey, actionItemId: string): T {
    throw new Error('ActionItemBlockNode.render method not implemented')
  }

  static getType(): string {
    return 'action-item-block'
  }

  static clone(node: ActionItemBlockNode): ActionItemBlockNode {
    return new ActionItemBlockNode(node.__actionItemId, node.__key)
  }

  constructor(actionItemId: string, key?: string) {
    super(key)
    this.__actionItemId = actionItemId
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('div')
    dom.classList.add('mb-2')
    return dom
  }

  updateDOM(): false {
    return false
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute(DATA_ACTION_ITEM_BLOCK_ID_ATTRIBUTE)) {
          return null
        }
        return {
          conversion: $convertActionItemBlockElement,
          priority: 2,
        }
      },
    }
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('div')
    element.setAttribute(
      DATA_ACTION_ITEM_BLOCK_ID_ATTRIBUTE,
      this.__actionItemId
    )
    return { element }
  }

  static importJSON(
    serializedNode: SerializedActionItemBlockNode
  ): ActionItemBlockNode {
    return $createActionItemBlockNode(
      serializedNode.actionItemId
    ).updateFromJSON(serializedNode)
  }

  exportJSON(): SerializedActionItemBlockNode {
    return {
      ...super.exportJSON(),
      actionItemId: this.__actionItemId,
    }
  }

  decorate(): T {
    return this.render(this.__key, this.__actionItemId)
  }

  isInline(): boolean {
    return false
  }

  setActionItemId(actionItemId: string) {
    const self = this.getWritable()
    self.__actionItemId = actionItemId
  }
}

export function $isActionItemNodeBlock(
  node: LexicalNode | null
): node is ActionItemBlockNode {
  return node instanceof ActionItemBlockNode
}

export function $createActionItemBlockNode(actionItemId: string) {
  return new ActionItemBlockNode(actionItemId)
}

export function $convertActionItemBlockElement(domNode: HTMLElement) {
  const actionItemId = domNode.getAttribute(DATA_ACTION_ITEM_BLOCK_ID_ATTRIBUTE)
  if (!actionItemId) {
    return null
  }

  return {
    node: $createActionItemBlockNode(actionItemId),
  }
}
