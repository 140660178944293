import { getUserColor } from '@motion/utils/color'

import {
  $applyNodeReplacement,
  DecoratorNode,
  type DOMConversionMap,
  type DOMExportOutput,
  isHTMLAnchorElement,
  type LexicalNode,
  type NodeKey,
  type SerializedLexicalNode,
  type Spread,
} from 'lexical'
import { v4 } from 'uuid'

import {
  DATA_ATTRIBUTE_ENTITY_ID,
  DATA_ATTRIBUTE_ENTITY_LABEL,
  DATA_ATTRIBUTE_ENTITY_TYPE,
} from './constants'
import { getMentionDomNodeAttributes } from './utils'

import { ParsingContext } from '../../../utils'

export type SerializedMentionNode = Spread<
  {
    motionId: string
    entityId: string
    entityLabel: string
    entityType: string
  },
  SerializedLexicalNode
>

export class MentionNode<T = void> extends DecoratorNode<T> {
  __motionId: string

  __entityId: string
  __entityType: string
  __entityLabel: string

  render(
    nodeKey: NodeKey,
    entityId: string,
    entityType: string,
    entityLabel: string
  ): T {
    throw new Error('MentionNode.render method not implemented')
  }

  static parseMentionUrl(link: string, label: string): [string, string] | null {
    throw new Error('MentionNode.parseMentionUrl method not implemented')
  }

  getMentionUrl(entityId: string, entityType: string): string | null {
    return null
  }

  static getType(): string {
    return 'motion-mention'
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(
      node.__entityId,
      node.__entityLabel,
      node.__entityType,
      node.__motionId,
      node.__key
    )
  }

  constructor(
    entityId: string,
    entityLabel: string,
    entityType: string,
    motionId: string = v4(),
    key?: NodeKey
  ) {
    super(key)

    this.__entityId = entityId
    this.__entityLabel = entityLabel
    this.__entityType = entityType
    this.__motionId = motionId
  }

  createDOM(): HTMLElement {
    const dom = document.createElement('span')
    dom.style.display = 'inline-block'
    dom.style.padding = '0 1px'
    dom.classList.add('mention')
    return dom
  }

  updateDOM(): boolean {
    return false
  }

  static importDOM(): DOMConversionMap | null {
    return {
      a: () => ({
        conversion: (domNode: HTMLElement): { node: MentionNode } | null => {
          // Check if the node is a link
          if (!isHTMLAnchorElement(domNode)) {
            return null
          }

          try {
            const [entityId, entityType] =
              MentionNode.parseMentionUrl(
                domNode.href,
                domNode.textContent ?? ''
              ) || []

            if (entityId == null || entityType == null) {
              return null
            }

            const entityLabel = domNode.textContent?.replace('@', '') ?? ''

            return {
              node: new MentionNode(entityId, entityLabel, entityType),
            }
          } catch (error) {
            return null
          }
        },
        priority: 0,
      }),
      span: () => ({
        conversion: (domNode: HTMLElement): { node: MentionNode } | null => {
          const { entityId, entityLabel, entityType } =
            getMentionDomNodeAttributes(domNode)

          if (
            entityId !== null &&
            entityLabel !== null &&
            entityType !== null
          ) {
            const node = new MentionNode(entityId, entityLabel, entityType)
            return { node }
          }

          return null
        },
        priority: 2,
      }),
    }
  }

  exportDOM(): DOMExportOutput {
    const mentionUrl = this.getMentionUrl(this.__entityId, this.__entityType)

    const content =
      ParsingContext.current.mode === 'publish'
        ? this.__entityLabel
        : `@${this.__entityLabel}`

    // Fallback to a span element if can't build a link
    if (mentionUrl == null) {
      const element = document.createElement('span')

      element.setAttribute(DATA_ATTRIBUTE_ENTITY_ID, this.__entityId)
      element.setAttribute(DATA_ATTRIBUTE_ENTITY_LABEL, this.__entityLabel)
      element.setAttribute(DATA_ATTRIBUTE_ENTITY_TYPE, this.__entityType)
      element.textContent = content

      if (this.__entityType === 'user') {
        element.setAttribute(
          'data-initials',
          getUserInitials(this.__entityLabel)
        )
        element.setAttribute('data-color', getUserColor(this.__entityId))
      }

      return { element }
    }

    const element = document.createElement('a')

    element.href = mentionUrl
    element.textContent = content

    return { element }
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    return new MentionNode(
      serializedNode.entityId,
      serializedNode.entityLabel,
      serializedNode.entityType,
      serializedNode.motionId
    )
  }

  exportJSON(): SerializedMentionNode {
    return {
      motionId: this.__motionId,
      entityId: this.__entityId,
      entityLabel: this.__entityLabel,
      entityType: this.__entityType,
      type: this.getType(),
      version: 1,
    }
  }

  getMotionId() {
    const self = this.getLatest()
    return self.__motionId
  }

  getEntityId() {
    const self = this.getLatest()
    return self.__entityId
  }

  getEntityType() {
    const self = this.getLatest()
    return self.__entityType
  }

  getEntityLabel() {
    const self = this.getLatest()
    return self.__entityLabel
  }

  setEntityId(entityId: string) {
    const self = this.getWritable()
    self.__entityId = entityId
  }

  getTextContent(): string {
    return `@${this.__entityLabel}`
  }

  getTextContentSize(): number {
    return this.getTextContent().length
  }

  decorate(): T {
    return this.render(
      this.__key,
      this.__entityId,
      this.__entityType,
      this.__entityLabel
    )
  }
}

export function $isMentionNode(node: LexicalNode | null): node is MentionNode {
  return node instanceof MentionNode
}

export function $createMentionNode(
  entityId: string,
  entityLabel: string,
  entityType: string,
  motionId?: string
): MentionNode {
  return $applyNodeReplacement(
    new MentionNode(entityId, entityLabel, entityType, motionId)
  )
}

function getUserInitials(name: string) {
  return name
    .split(' ')
    .map((n) => n[0])
    .join('')
    .slice(0, 2)
}
