import {
  CommentOutline,
  RTEBoldSolid,
  RTECodeblockOutline,
  RTEItalicizedSolid,
  RTEQuoteSolid,
  RTEStrikethroughSolid,
  RTEUnderlineSolid,
} from '@motion/icons'
import { $isCollapsibleListItemNode } from '@motion/notes-shared'
import { type TasksV2SingleResponse } from '@motion/rpc-types'

import { $findMatchingParent, mergeRegister } from '@lexical/utils'
import {
  $getSelection,
  $isRangeSelection,
  $isRootNode,
  FORMAT_TEXT_COMMAND,
  getDOMSelection,
  type LexicalEditor,
  type NodeKey,
} from 'lexical'
import { useCallback, useEffect, useRef, useState } from 'react'

import { Divider, Section } from './styled'
import { TextSelectionAskAIDropdown } from './text-selection-ask-ai-dropdown'
import { TextSelectionTurnIntoDropdown } from './text-selection-turn-into-dropdown'
import { ToolbarButton } from './toolbar-button'

import { getDOMRangeRect } from '../../../utils'
import { FORMAT_QUOTE_COMMAND } from '../../commands'
import { INSERT_INLINE_COMMENT_COMMAND } from '../../comments-plugin'
import { setFloatingElemPosition } from '../utils'

export type TextSelectionToolbarProps = {
  editor: LexicalEditor
  anchorElem: HTMLElement
  isBold: boolean
  isCode: boolean
  isItalic: boolean
  isStrikethrough: boolean
  isUnderline: boolean
  isQuote: boolean
  onSelectOption?: (option: string) => void
  hasDocsAiOnToolbar?: boolean
  onOpenCreateTaskWithAiModal?: (
    selectedText: string,
    createMultiple: boolean
  ) => void
  onOpenSummarizeWithAiModal?: (selectedText: string) => void
  onCreateTaskWithAI?: (
    selectedText: string
  ) => Promise<TasksV2SingleResponse | void>
  onOpenRewriteModal?: (selectedText: string) => void
  /**
   * Whether the user has selected text within a single node
   */
  isSingularTextSelection?: boolean
  /**
   * The keys of the selected list item nodes
   */
  selectedListItemKeys?: NodeKey[]
}

export const TextSelectionToolbar = ({
  editor,
  anchorElem,
  isBold,
  isItalic,
  isUnderline,
  isCode,
  isStrikethrough,
  isQuote,
  onSelectOption,
  hasDocsAiOnToolbar,
  onOpenCreateTaskWithAiModal,
  onCreateTaskWithAI,
  isSingularTextSelection,
  selectedListItemKeys,
  onOpenSummarizeWithAiModal,
  onOpenRewriteModal,
}: TextSelectionToolbarProps) => {
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null)

  // This will be used for the `turn-into` feature

  const [blockNodeKey, setBlockNodeKey] = useState<NodeKey | null>(null)

  const [toolbarPosition, setToolbarPosition] = useState<{
    top: number
    left: number
  } | null>(null)

  const $updateTextSelectionToolbar = useCallback(() => {
    const selection = $getSelection()

    // If the selection is a range selection and not collapsed, we want to retrieve
    // the top level node of the selection to determine the block node key
    if ($isRangeSelection(selection) && !selection.isCollapsed()) {
      const startNode = selection.anchor.getNode()
      const endNode = selection.focus.getNode()

      // Special handling for list items
      const startListItemNodeParent = $findMatchingParent(
        startNode,
        $isCollapsibleListItemNode
      )
      const endListItemNodeParent = $findMatchingParent(
        endNode,
        $isCollapsibleListItemNode
      )

      if (startListItemNodeParent != null && endListItemNodeParent != null) {
        if (startListItemNodeParent.is(endListItemNodeParent)) {
          setBlockNodeKey(startListItemNodeParent?.getKey())
        }
      } else if (!$isRootNode(startNode) && !$isRootNode(endNode)) {
        const startTopLevelNode = startNode.getTopLevelElementOrThrow()
        const endTopLevelNode = endNode.getTopLevelElementOrThrow()

        if (startTopLevelNode.is(endTopLevelNode)) {
          setBlockNodeKey(startTopLevelNode?.getKey())
        }
      }
    }

    const nativeSelection = getDOMSelection(editor._window)

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current

    if (popupCharStylesEditorElem === null) {
      return
    }

    const rootElement = editor.getRootElement()
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement)

      setToolbarPosition(
        setFloatingElemPosition(
          rangeRect,
          popupCharStylesEditorElem,
          anchorElem
        )
      )
    } else {
      setToolbarPosition(null)
    }
  }, [anchorElem, editor])

  useEffect(() => {
    editor.getEditorState().read(() => {
      $updateTextSelectionToolbar()
    })
    return mergeRegister(
      // This one is needed to reset the state if user starts typing
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateTextSelectionToolbar()
        })
      })
    )
  }, [$updateTextSelectionToolbar, editor])

  return (
    <div
      ref={popupCharStylesEditorRef}
      className='absolute flex gap-1'
      onMouseDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      style={{
        top: 0,
        opacity: toolbarPosition ? 1 : 0,
        transform: toolbarPosition
          ? `translate(${toolbarPosition.left}px, ${toolbarPosition.top}px)`
          : undefined,
        pointerEvents: toolbarPosition ? 'auto' : 'none',
      }}
    >
      <Section>
        <TextSelectionTurnIntoDropdown blockNodeKey={blockNodeKey} />

        <ToolbarButton
          title='Bold'
          ariaLabel='Format text as bold'
          onClick={() => {
            onSelectOption?.('bold')
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
          }}
          active={isBold}
        >
          <RTEBoldSolid className='!text-semantic-neutral-text-subtle' />
        </ToolbarButton>
        <ToolbarButton
          title='Italic'
          ariaLabel='Format text as italics'
          onClick={() => {
            onSelectOption?.('italic')
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
          }}
          active={isItalic}
        >
          <RTEItalicizedSolid className='!text-semantic-neutral-text-subtle' />
        </ToolbarButton>
        <ToolbarButton
          title='Underline'
          ariaLabel='Format text to underlined'
          onClick={() => {
            onSelectOption?.('underline')
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
          }}
          active={isUnderline}
        >
          <RTEUnderlineSolid className='!text-semantic-neutral-text-subtle' />
        </ToolbarButton>
        <ToolbarButton
          title='Strikethrough'
          ariaLabel='Format text with a strikethrough'
          onClick={() => {
            onSelectOption?.('strikethrough')
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
          }}
          active={isStrikethrough}
        >
          <RTEStrikethroughSolid className='!text-semantic-neutral-text-subtle' />
        </ToolbarButton>
        <ToolbarButton
          title='Code'
          ariaLabel='Format text as code'
          onClick={() => {
            onSelectOption?.('code')
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')
          }}
          active={isCode}
        >
          <RTECodeblockOutline className='!text-semantic-neutral-text-subtle' />
        </ToolbarButton>

        <ToolbarButton
          title='Block quote'
          ariaLabel='Format text as a block quote'
          onClick={() => {
            onSelectOption?.('quote')
            editor.dispatchCommand(FORMAT_QUOTE_COMMAND, true)
          }}
          active={isQuote}
        >
          <RTEQuoteSolid className='!text-semantic-neutral-text-subtle' />
        </ToolbarButton>
      </Section>
      <Section>
        <ToolbarButton
          title='Add comment'
          ariaLabel='Add a comment'
          onClick={() => {
            onSelectOption?.('comment')
            editor.dispatchCommand(INSERT_INLINE_COMMENT_COMMAND, undefined)
          }}
        >
          <CommentOutline className='!text-semantic-neutral-text-subtle' />
        </ToolbarButton>
        {hasDocsAiOnToolbar && (
          <>
            <Divider />
            <TextSelectionAskAIDropdown
              editor={editor}
              onOpenTaskModal={onOpenCreateTaskWithAiModal}
              onCreateTaskWithAi={onCreateTaskWithAI}
              isSingularTextSelection={isSingularTextSelection}
              selectedListItemKeys={selectedListItemKeys}
              onOpenSummarizeModal={onOpenSummarizeWithAiModal}
              onOpenRewriteModal={onOpenRewriteModal}
            />
          </>
        )}
      </Section>
    </div>
  )
}
