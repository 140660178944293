import { createCommand, type LexicalCommand, type NodeKey } from 'lexical'

type AttachmentPayloadFields = {
  attachmentId: string
  isUploading: boolean
  isPreview: boolean
  mimeType: string
  filename: string
  size: number
}

export type InsertAttachmentCommandPayload = {
  attachments: [string, File][]
  isUploading: boolean
  isPreview: boolean
}

export const INSERT_ATTACHMENT_NODES: LexicalCommand<InsertAttachmentCommandPayload> =
  createCommand('INSERT_ATTACHMENT_NODES')

export type UpdateAttachmentCommandPayload = {
  attachmentId: AttachmentPayloadFields['attachmentId']
  updates: Partial<AttachmentPayloadFields>
}

export const UPDATE_ATTACHMENT_NODE: LexicalCommand<UpdateAttachmentCommandPayload> =
  createCommand('UPDATE_ATTACHMENT_NODE')

export type SetAttachmentPreviewCommandPayload = {
  nodeKey: NodeKey
  isPreview: boolean
}

export const SET_ATTACHMENT_PREVIEW_NODE: LexicalCommand<SetAttachmentPreviewCommandPayload> =
  createCommand('SET_ATTACHMENT_PREVIEW_NODE')

export type DeleteAttachmentCommandPayload = NodeKey

export const DELETE_ATTACHMENT_NODE: LexicalCommand<DeleteAttachmentCommandPayload> =
  createCommand('DELETE_ATTACHMENT_NODE')

export const TRIGGER_ATTACHMENT_UPLOAD: LexicalCommand<void> = createCommand(
  'TRIGGER_ATTACHMENT_UPLOAD'
)
