import { isCanceledStatus } from '@motion/shared/common'
import { entries } from '@motion/utils/object'

import { type QueryClient } from '@tanstack/react-query'

import { getCurrentUser } from '../current-user'
import { getModelCache } from '../model-cache'
import {
  type MotionCacheContext,
  type V2QueryResponseShape,
  type V2SingleResponseShape,
} from '../types'

export function isQueryResponse(
  source: unknown
): source is V2QueryResponseShape {
  return source != null && typeof source === 'object' && 'ids' in source
}

export function getCanceledStatusIds(client: QueryClient) {
  const modelCache = getModelCache(client)
  const canceledStatusIds = entries(modelCache.statuses)
    .filter(([_, statusEntry]) => isCanceledStatus(statusEntry.value))
    .map(([id]) => id)

  return canceledStatusIds
}

export function isResponseV2Shape(
  source: unknown
): source is V2QueryResponseShape | V2SingleResponseShape {
  return (
    source != null &&
    typeof source === 'object' &&
    'models' in source &&
    typeof source.models === 'object'
  )
}

export function buildMotionCacheContext({
  client,
}: {
  client: QueryClient
}): MotionCacheContext {
  const userId = getCurrentUser(client)?.id ?? null
  const canceledStatusIds = getCanceledStatusIds(client)

  return { client, userId, canceledStatusIds }
}
