/**
 * Auto Generated
 */
import { type ClientZod } from '../../../utils/client-transform'
import type * as Server from '../../../server/common/filters/deadline-status'

// Generated Types

export type DeadlineStatusAllowedFilterValues = ClientZod<
  typeof Server.DeadlineStatusAllowedFilterValues
>
export const DeadlineStatusAllowedFilterValues = [
  'none',
  'on-track',
  'missed-deadline',
  'scheduled-past-deadline',
] as const
export type DeadlineStatusFilterSchema = ClientZod<
  typeof Server.DeadlineStatusFilterSchema
>
export type DeadlineStatusWithReasonFilterSchema = ClientZod<
  typeof Server.DeadlineStatusWithReasonFilterSchema
>
