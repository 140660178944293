import type { NodeKey } from 'lexical'

import { ResizableBlock } from './resizable-block'

function getDomain(url: string) {
  try {
    const parsedUrl = new URL(url)
    return parsedUrl.hostname
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Invalid URL:', error)
    return null
  }
}

// Example usage
export function BookmarkEmbedComponent({
  src,
  nodeKey,
  width,
}: {
  nodeKey: NodeKey
  src: string
  width: number
}): JSX.Element {
  const domain = getDomain(src)
  const favicon = `https://www.google.com/s2/favicons?domain=${domain}&sz=64`
  return (
    <ResizableBlock nodeKey={nodeKey} initialWidth={width} resizable={false}>
      <div className='rounded-md border border-neutral-200 p-4 flex gap-2'>
        <img src={favicon} className='shrink-0 w-4 h-4' />
        <span className='text-sm text-neutral-500 '>{src}</span>
      </div>
    </ResizableBlock>
  )
}
