/**
 * Auto Generated
 */
import { type ClientZod } from '../../utils/client-transform'
import type * as Server from '../../server/common/tiers'

// Generated Types

export type FeatureTier = ClientZod<typeof Server.FeatureTier>
export const FeatureTier = [
  'BASIC',
  'PRO',
  'PROAI',
  'PROPLUS',
  'ENTERPRISE',
  'BUSINESS',
  'BUSINESSPLUS',
  'BUSINESSAI',
] as const
