import { createPortal } from 'react-dom'

import { CodeLanguageDropdown } from './components/code-language-dropdown'
import { useCodeToolbarPosition } from './hooks/use-code-toolbar-position'

import { useEditorContext } from '../../context'

function CodeToolbarContainer({ anchorElem }: { anchorElem: HTMLElement }) {
  const { codeNode, position, lockVisibility, unlockVisibility } =
    useCodeToolbarPosition({ anchorElem })

  if (!codeNode) return null

  return (
    <div
      role='toolbar'
      className='code-toolbar absolute pt-2 pr-2 flex gap-1'
      style={{ ...position }}
    >
      <CodeLanguageDropdown
        codeNodeKey={codeNode.getKey()}
        onDropdownOpen={lockVisibility}
        onDropdownClose={unlockVisibility}
      />
    </div>
  )
}

export function CodeToolbarPlugin(): React.ReactPortal | null {
  const { floatingAnchorElem } = useEditorContext()

  if (floatingAnchorElem == null) return null

  return createPortal(
    <CodeToolbarContainer anchorElem={floatingAnchorElem} />,
    floatingAnchorElem
  ) as React.ReactPortal
}
