import { FormModal } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { useState } from 'react'

export type WorkEmailModalProps = {
  isOpen: boolean
  onClose: () => void
  onAction: (newEmail: string, onError: () => void) => void
}

export const WorkEmailModal = ({
  isOpen,
  onClose,
  onAction,
}: WorkEmailModalProps) => {
  const [workEmail, setWorkEmail] = useState('')
  const [error, setError] = useState<string | null>(null)

  return (
    <FormModal
      visible={isOpen}
      onClose={onClose}
      nonDismissable
      title='What is your work email?'
      submitAction={{
        text: 'Submit',
        onAction: () => {
          onAction(workEmail, () =>
            setError(
              workEmail
                ? `${workEmail} is not a valid work email, please enter an email for your company's domain`
                : 'Please enter your work email'
            )
          )
        },
      }}
    >
      <div className='flex flex-col gap-2'>
        <TextField
          value={workEmail}
          onChange={setWorkEmail}
          placeholder='Enter work email'
        />
        {error && (
          <div className='text-semantic-error-text-default text-xs'>
            {error}
          </div>
        )}
      </div>
    </FormModal>
  )
}
