import {
  $isCollapsibleListItemNode,
  $isCollapsibleListNode,
} from '@motion/notes-shared'

import { $isHeadingNode } from '@lexical/rich-text'
import type { LexicalEditor } from 'lexical'
import {
  $createParagraphNode,
  $getNearestRootOrShadowRoot,
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isRootNode,
  $isTextNode,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical'

import { $getLastListItem } from '../utils'

export function $handleDelete(editor: LexicalEditor) {
  const selection = $getSelection()

  if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
    return false
  }

  const node = selection.anchor.getNode()

  if (selection.anchor.offset > 0 || node.getPreviousSibling()) {
    return false
  }

  if ($isTextNode(node)) {
    const topLevelElement = node.getTopLevelElement()
    const previousSibling = topLevelElement?.getPreviousSibling()

    if (
      ($isParagraphNode(topLevelElement) || $isHeadingNode(topLevelElement)) &&
      $isCollapsibleListNode(previousSibling)
    ) {
      const lastListItem = $getLastListItem(previousSibling)
      const firstChild = lastListItem?.getFirstChild()
      if ($isParagraphNode(firstChild)) {
        const children = topLevelElement.getChildren()
        firstChild.append(...children)
        topLevelElement.remove()
      } else if (lastListItem) {
        const paragraph = $createParagraphNode()
        paragraph.append(...topLevelElement.getChildren())
        lastListItem.append(paragraph)
        topLevelElement.remove()
      }
      return true
    }
  }

  if ($isRootNode(node)) {
    return false
  }

  const parentBlock = $getNearestRootOrShadowRoot(node)
  if (!$isCollapsibleListItemNode(parentBlock)) return false

  const firstChild = parentBlock.getFirstChild()
  if (node.getTopLevelElement() !== firstChild) return false

  const previousSibling = parentBlock.getPreviousSibling()
  const hasPreviousSibling = $isCollapsibleListItemNode(previousSibling)

  if (hasPreviousSibling) {
    const firstChild = parentBlock.getFirstChild()
    const previousFirstChild = previousSibling.getFirstChild()

    if ($isCollapsibleListNode(previousFirstChild)) {
      editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
      return true
    }
    if ($isParagraphNode(previousFirstChild) && $isParagraphNode(firstChild)) {
      previousFirstChild.selectEnd()
      previousFirstChild.append(...firstChild.getChildren())
      previousSibling.append(...firstChild.getNextSiblings())
    } else {
      const children = parentBlock.getChildren()
      previousSibling.append(...children)
    }
    parentBlock.remove()
    return true
  }

  const listNode = parentBlock.getParentOrThrow()
  const listNodeParent = listNode.getParentOrThrow()
  const isIndented = $isCollapsibleListItemNode(listNodeParent)

  if (isIndented) {
    editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
    return true
  }
  editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
  return true
}
