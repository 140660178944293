import { createCommand, type LexicalNode, type NodeKey } from 'lexical'

export type ToggleInlineCreateCommandPayload = {
  type: string
}

export type ReplaceInlineCreateCommandPayload = {
  key: NodeKey
  transform: () => LexicalNode
}

export type BlurInlineCreateCommandPayload = {
  key: NodeKey
}

export const TOGGLE_INLINE_CREATE_COMMAND =
  createCommand<ToggleInlineCreateCommandPayload>(
    'TOGGLE_INLINE_CREATE_COMMAND'
  )
