/**
 * Auto Generated
 */
import { type ClientZod } from '../../../utils/client-transform'
import type * as Server from '../../../server/ai-hackerhouse/meeting-insights/meeting-insights'

// Generated Types

export type HHMeetingActionItemTaskDataSchema = ClientZod<
  typeof Server.HHMeetingActionItemTaskDataSchema
>
export type HHMeetingActionItemSchema = ClientZod<
  typeof Server.HHMeetingActionItemSchema
>
export type HHMeetingNotes = ClientZod<typeof Server.HHMeetingNotes>
export type HHMeetingBotStatus = ClientZod<typeof Server.HHMeetingBotStatus>
export const HHMeetingBotStatus = [
  'DONT_SCHEDULE',
  'NEEDS_SCHEDULING',
  'SCHEDULED',
  'SCHEDULING',
  // TODO: Deprecate after moving to Temporal
  'FAILED_TO_SCHEDULE',
  'IN_CALL',
  'COMPLETED',
  'PROCESSING',
  'FAILED_TO_PROCESS',
  'BOT_NEVER_ADMITTED',
] as const
export type HHMeetingBotStatusDetails = ClientZod<
  typeof Server.HHMeetingBotStatusDetails
>
export const HHMeetingBotStatusDetails = [
  // when `SCHEDULED`
  'BOT_JOINING_CALL',
  'BOT_IN_WAITING_ROOM',
  // when `IN_CALL`
  'BOT_IN_CALL_NOT_RECORDING',
  'BOT_IN_CALL_RECORDING',
  'BOT_CALL_ENDED',
  // when `COMPLETED`
  'MEETING_PARTIALLY_RECORDED',
  'MEETING_HAD_NO_SPEECH',
  // when `BOT_NEVER_ADMITTED`
  'BOT_KICKED_FROM_WAITING_ROOM',
  'BOT_KICKED_FROM_CALL',
  // possible for `COMPLETED` as well
  'RECORDING_PERMISSIONS_DENIED',
] as const
export type MeetingInsightsVisibility = ClientZod<
  typeof Server.MeetingInsightsVisibility
>
export const MeetingInsightsVisibility = [
  'PRIVATE',
  'TEAM',
  'EXTERNAL',
] as const
export type HHMeetingInsightEventData = ClientZod<
  typeof Server.HHMeetingInsightEventData
>
export type HHMeetingInsightsSchema = ClientZod<
  typeof Server.HHMeetingInsightsSchema
>
export type HHMeetingInsightsSettingsSchema = ClientZod<
  typeof Server.HHMeetingInsightsSettingsSchema
>
export type HHMeetingInsights = ClientZod<
  typeof Server.HHMeetingInsights.schema
>
export type HHMeetingActionItem = ClientZod<
  typeof Server.HHMeetingActionItem.schema
>
export type HHMeetingActionItemTaskData = ClientZod<
  typeof Server.HHMeetingActionItemTaskData.schema
>
export type AIHH_CreateCalendarEventResponseSchema = ClientZod<
  typeof Server.AIHH_CreateCalendarEventResponseSchema
>
export type AIHH_CreateCalendarEventResponse = ClientZod<
  typeof Server.AIHH_CreateCalendarEventResponse.schema
>
