import {
  HocuspocusProvider,
  type HocuspocusProviderWebsocket,
} from '@hocuspocus/provider'
import { type Provider } from '@lexical/yjs'
import { Doc } from 'yjs'

import { PollingProvider } from './polling-provider'

function isVersionError(error: { reason: string }): boolean {
  if (!error || !error.reason) return false
  return error.reason === 'auth-failed-version-behind'
}

export function createWebsocketProvider(
  id: string,
  yjsDocMap: Map<string, Doc>,
  websocketProvider: HocuspocusProviderWebsocket,
  getToken: () => Promise<string> = async () => '',
  onStatus: (message: { status: string }) => void = () => null,
  onSynced: (message: { state: boolean }) => void = () => null,
  onVersionError: () => void = () => null
) {
  const doc = getDocFromMap(id, yjsDocMap)

  const provider = new HocuspocusProvider({
    name: `lexical-${id}`,
    websocketProvider,
    document: doc,
    token: async () => {
      return getToken()
    },
    connect: false,
    preserveConnection: true,
    onStatus,
    onSynced,
    onAuthenticationFailed: (error: { reason: string }) => {
      if (isVersionError(error)) {
        onVersionError()
      }
    },
  })

  return provider
}

function getDocFromMap(id: string, yjsDocMap: Map<string, Doc>): Doc {
  let doc = yjsDocMap.get(id)

  if (doc === undefined) {
    doc = new Doc()
    yjsDocMap.set(id, doc)
  } else {
    doc.load()
  }

  return doc
}

export function createPollingProvider(
  id: string,
  yjsDocMap: Map<string, Doc>,
  fetchState: () => Promise<string>,
  saveState: (updates: { state?: string; title?: string }) => void
): Provider {
  if (fetchState == null || saveState == null) {
    throw new Error(
      'fetchState and saveState are required for polling collaboration provider'
    )
  }

  const doc = getDocFromMap(id, yjsDocMap)

  const provider = new PollingProvider(doc, fetchState, saveState)

  return provider
}
