import {
  addBreadcrumb,
  captureEvent,
  captureException,
  captureMessage,
  flush,
  setUser,
} from '@sentry/browser'
import { type ScopeContext } from '@sentry/core'

import { shouldBeIgnored } from './utils'

import { logEvent } from '../analytics/amplitude'
import { makeLog } from '../logging'

const log = makeLog('[sentry]')

type Context = Partial<ScopeContext> & {
  extra?: { position?: never }
}

const captureExceptionWithWrappedErrors = (
  exception: any,
  captureContext: Context = {}
) => {
  captureContext.tags = captureContext.tags ?? {}
  captureContext.tags.reqId =
    captureContext.tags.reqId ?? exception?.reqId ?? 'unknown'
  const message = getExceptionMessage(exception)

  // Filter out errors that are not worth logging
  if (shouldBeIgnored(exception)) {
    logEvent('NON_ACTIONABLE_WEB_ERROR', { message })
    return
  }

  if (!(exception instanceof Error)) {
    return captureException(
      new Error(message, {
        cause: exception,
      }),
      captureContext
    )
  }

  // Apply the structured data cause as extra metadata because Sentry doesn't render it otherwise
  if (exception.cause != null && !(exception.cause instanceof Error)) {
    captureContext.extra = {
      ...captureContext.extra,
      cause: exception.cause,
    }
  }

  return captureException(exception, captureContext)
}

function getExceptionMessage(exception: any) {
  if (typeof exception === 'string') return exception

  if (!exception) {
    return 'Unrecognized error (no exception)'
  }

  if (exception.message) {
    return exception.message
  }

  if (exception.constructor?.name) {
    return `${exception.constructor?.name} (ctor)`
  }

  return 'Unrecognized error'
}

const instance = {
  flush,
  captureException: captureExceptionWithWrappedErrors,
  captureMessage,
  addBreadcrumb,
  captureEvent,
  setUser: (user: { id: string } | null) => {
    setUser(user)
    log('set user', user)
  },
}

export { instance as Sentry }
