import { type ActionItem, ActionList } from '@motion/ui/base'

import { type ReactNode } from 'react'

import { type TypeaheadMenuOption } from './typeahead-menu-option'

interface TypeaheadMenuProps<T> {
  options: { groupName?: string; options: T[] }[]
  selectedIndex: number | null
  selectOptionAndCleanUp: (option: T) => void
  setHighlightedIndex: (index: number) => void
  renderOptionContent?: (option: T) => ReactNode
  showGroupTitles?: boolean
}

export const TypeaheadMenu = <T extends TypeaheadMenuOption>({
  options,
  selectedIndex,
  selectOptionAndCleanUp,
  setHighlightedIndex,
  renderOptionContent,
  showGroupTitles,
}: TypeaheadMenuProps<T>): JSX.Element | null => {
  let index = -1
  return (
    <ActionList
      sections={options.map((group) => ({
        title: showGroupTitles ? group.groupName : undefined,
        items: group.options.map((option) => {
          const i = ++index
          return {
            prefix: option.icon,
            content:
              renderOptionContent != null
                ? renderOptionContent(option)
                : option.title,
            onAction: () => selectOptionAndCleanUp(option),
            onMouseMove: () =>
              selectedIndex !== i ? setHighlightedIndex(i) : null,
            active: selectedIndex === i,
            setRefElement: option.setRefElement,
          } satisfies ActionItem
        }),
      }))}
    />
  )
}
