import {
  HeadingNode,
  HeadingTagType,
  SerializedHeadingNode,
} from '@lexical/rich-text'
import {
  DOMConversionMap,
  EditorConfig,
  isHTMLElement,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedElementNode,
  Spread,
} from 'lexical'
import { v4 } from 'uuid'

import { MotionId, SerializedMotionNode } from '../../../types'

export type SerializedCollapsibleHeadingNode = Spread<
  {
    open: boolean
  } & SerializedMotionNode,
  SerializedElementNode
>

const DATA_ATTRIBUTE_EXPANDED = 'data-expanded'

export class CollapsibleHeadingNode extends HeadingNode {
  __motionId: MotionId
  __open: boolean

  static getType(): string {
    return 'collapsible-heading'
  }

  static clone(node: CollapsibleHeadingNode): CollapsibleHeadingNode {
    return new CollapsibleHeadingNode(
      node.getTag(),
      node.getOpen(),
      node.getMotionId(),
      node.getKey()
    )
  }

  static importDOM(): DOMConversionMap | null {
    return super.importDOM()
  }

  static importJSON(
    serializedNode: SerializedHeadingNode
  ): CollapsibleHeadingNode {
    return new CollapsibleHeadingNode(serializedNode.tag)
  }

  constructor(
    tag: HeadingTagType,
    open: boolean = true,
    motionId: MotionId = v4(),
    key?: NodeKey
  ) {
    super(tag, key)

    this.__motionId = motionId
    this.__open = open
  }

  // @ts-expect-error - The super class definition is incorrect
  createDOM(config: EditorConfig, editor: LexicalEditor): HTMLElement {
    const dom = super.createDOM(config)

    dom.classList.add(...config.theme.collapsible.title.split(' '))

    if (this.__open) {
      dom.setAttribute(DATA_ATTRIBUTE_EXPANDED, 'true')
    } else {
      dom.setAttribute(DATA_ATTRIBUTE_EXPANDED, 'false')
    }

    return dom
  }

  updateDOM(prevNode: this, dom: HTMLElement, config: EditorConfig): boolean {
    if (this.__open) {
      dom.setAttribute(DATA_ATTRIBUTE_EXPANDED, 'true')
    } else {
      dom.setAttribute(DATA_ATTRIBUTE_EXPANDED, 'false')
    }
    return super.updateDOM(prevNode, dom, config)
  }

  getMotionId(): MotionId {
    const self = this.getLatest()
    return self.__motionId
  }

  setOpen(open: boolean): void {
    const writable = this.getWritable()
    writable.__open = open
  }

  getOpen(): boolean {
    return this.getLatest().__open
  }

  toggleOpen(): void {
    this.setOpen(!this.getOpen())
  }

  exportDOM(editor: LexicalEditor) {
    const ret = super.exportDOM(editor)
    const el = ret.element
    if (isHTMLElement(el)) {
      el.setAttribute('data-collapsible', 'true')
    }

    return ret
  }
}

export function $createCollapsibleHeadingNode(
  tag: HeadingTagType,
  open?: boolean
): CollapsibleHeadingNode {
  return new CollapsibleHeadingNode(tag, open)
}

export function $isCollapsibleHeadingNode(
  node: LexicalNode | null | undefined
): node is CollapsibleHeadingNode {
  return node instanceof CollapsibleHeadingNode
}
