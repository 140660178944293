import { ArrowUpSolid, XSolid } from '@motion/icons'
import { MentionNode } from '@motion/notes-shared'
import { classed } from '@motion/theme'
import { IconButton } from '@motion/ui/base'
import { addComponentName } from '@motion/ui/helpers'
import { debounce } from '@motion/utils/core'

import { $generateHtmlFromNodes } from '@lexical/html'
import { type InitialConfigType } from '@lexical/react/LexicalComposer'
import { $nodesOfType, CLEAR_EDITOR_COMMAND, type LexicalEditor } from 'lexical'
import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react'

import { HtmlEditor } from './html-editor'
import { useOnChange } from './use-on-change'

import { populateFromHtml } from '../../utils'

export type CommentInputBoxProps = {
  onSubmit: (bodyHtml: string, mentions: Array<string>) => Promise<void>
  onCancel?: (hasChanged: boolean, cb: (discard: boolean) => void) => void
  editable?: boolean
  initialHtml?: string
  autoFocus?: boolean
  nodes?: InitialConfigType['nodes']
  plugins?: ReactNode
}

export const CommentInputBox = ({
  onSubmit,
  onCancel,
  editable = true,
  initialHtml,
  autoFocus,
  nodes,
  plugins,
}: CommentInputBoxProps) => {
  const commentEditorRef = useRef<LexicalEditor>(null)
  const [canSubmit, setCanSubmit] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const [isBlockLayout, setIsBlockLayout] = useState(false)

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const entry = entries[0]
      if (!entry) return

      if (!isBlockLayout && entry.contentRect.height > 50) {
        setIsBlockLayout(true)
      }
    })

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => observer.disconnect()
  }, [isBlockLayout])

  useEffect(() => {
    if (!canSubmit) {
      setIsBlockLayout(false)
    }
  }, [canSubmit])

  const submitComment = async () => {
    const editor = commentEditorRef.current

    if (!editor || !canSubmit) {
      setIsSubmitting(false)
      return
    }

    try {
      const htmlBody = editor.getEditorState().read(() => {
        return $generateHtmlFromNodes(editor, null)
      })

      const mentions = editor.getEditorState().read(() => {
        const mentionNodes = $nodesOfType(MentionNode)

        return mentionNodes
          .filter((node) => node.getEntityType() === 'user')
          .map((node) => node.getEntityId())
      })
      await onSubmit(htmlBody, mentions)

      if (!initialHtml) {
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
      }
    } catch (err) {
      throw err
    } finally {
      setIsSubmitting(false)
    }
  }

  const debouncedSubmitComment = debounce(submitComment, 300)

  const handleSubmit = () => {
    setIsSubmitting(true)
    debouncedSubmitComment()
  }

  const handleEscape = () => {
    handleCancel()
    return true
  }

  const resetEditor = useCallback(() => {
    const editor = commentEditorRef.current
    if (!editor) return
    if (initialHtml) {
      editor.update(() => {
        populateFromHtml(editor, initialHtml)
      })
    }
  }, [initialHtml])

  const handleCancel = useCallback(() => {
    const editor = commentEditorRef.current
    if (!editor) return false

    const htmlBody = editor.getEditorState().read(() => {
      return $generateHtmlFromNodes(editor, null)
    })
    const hasChanged = htmlBody !== initialHtml
    onCancel?.(hasChanged, (discard) => {
      if (!discard) return
      resetEditor()
    })
  }, [initialHtml, resetEditor, onCancel])

  const onChange = useOnChange(setCanSubmit)

  return (
    <CommentContainer
      ref={containerRef}
      editable={editable}
      isBlockLayout={isBlockLayout}
      {...addComponentName('CommentInputBox')}
    >
      <HtmlEditor
        editorRef={commentEditorRef}
        readonly={!editable || isSubmitting}
        initialHtml={initialHtml}
        onEscape={handleEscape}
        onSubmit={handleSubmit}
        autoFocus={autoFocus}
        onChange={onChange}
        nodes={nodes}
        plugins={plugins}
        placeholder='Add a comment...'
        label='Comment'
      />
      {editable && (
        <div className='pb-1.5 flex gap-1 justify-end'>
          {onCancel && (
            <IconButton
              onClick={handleCancel}
              variant='muted'
              sentiment='neutral'
              size='small'
              icon={XSolid}
              disabled={isSubmitting}
            />
          )}
          <IconButton
            onClick={handleSubmit}
            disabled={!canSubmit || isSubmitting}
            size='small'
            icon={ArrowUpSolid}
          />
        </div>
      )}
    </CommentContainer>
  )
}

const CommentContainer = classed('div', {
  base: `w-full relative flex items-end`,
  variants: {
    editable: {
      true: `bg-dropdown-bg border border-dropdown-border rounded-lg px-2`,
      false: ``,
    },
    isBlockLayout: {
      true: 'block',
      false: 'flex',
    },
  },
})
