import { LexicalNode } from 'lexical'

import { MotionId } from '../types'

export function $isMotionNode(
  node: LexicalNode | undefined | null
): node is LexicalNode & {
  getMotionId(): MotionId
} {
  return (
    node != null &&
    'getMotionId' in node &&
    typeof node.getMotionId === 'function'
  )
}
