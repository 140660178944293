import {
  useMyUserSettings,
  useSaveOnboardingSettings,
} from '@motion/web-common/settings'

import { useCallback } from 'react'

import { type PersistedOnboardingState } from '../../types'

export const usePersistedOnboardingState = (): [
  PersistedOnboardingState | undefined,
  (data: Partial<PersistedOnboardingState>) => Promise<void>,
] => {
  const { mutateAsync } = useSaveOnboardingSettings()

  const { data } = useMyUserSettings(undefined, {
    meta: { source: 'usePersistedOnboardingState' },
  })

  const update = useCallback(
    async (data: Partial<PersistedOnboardingState>) => {
      await mutateAsync(data)
    },
    [mutateAsync]
  )

  return [data?.onboarding, update] as const
}
