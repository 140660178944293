import {
  BanSolid,
  CalendarSolid,
  CompletedEventSolid,
  EventTaskSolid,
  GhostEventTaskSolid,
  NonVisitedStatusSolid,
  StatusCanceledSolid,
  StatusCompletedSolid,
  StatusSolid,
  type SvgIcon,
} from '@motion/icons'
import { isCanceledStatus, isCompletedStatus } from '@motion/shared/common'
import { getStatusColorClass } from '@motion/ui-logic'

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type StatusLike = {
  name: string
  color: string
  type: 'DEFAULT' | 'COMPLETED' | 'CANCELED' | null
}

export type StatusIconProps = {
  item: StatusLike
  width?: string | number
  height?: string | number
  taskVariant?: 'default' | 'scheduling' | 'meeting'
  // We shouldn't use `is` in prop names, will be good to change this to `unvisitedStage` in the future
  iconVariant?: 'default' | 'blocker' | 'isUnvisitedStage'
}

const taskVariantToIconVariantsSvg: Record<
  Required<StatusIconProps>['taskVariant'],
  Partial<
    Record<
      Required<StatusIconProps>['iconVariant'] | 'completed' | 'canceled',
      SvgIcon
    >
  >
> = {
  default: {
    default: StatusSolid,
    blocker: BanSolid,
    isUnvisitedStage: NonVisitedStatusSolid,
    completed: StatusCompletedSolid,
    canceled: StatusCanceledSolid,
  },
  scheduling: {
    default: EventTaskSolid,
    blocker: BanSolid,
    isUnvisitedStage: GhostEventTaskSolid,
    completed: StatusCompletedSolid,
    canceled: StatusCanceledSolid,
  },
  meeting: {
    default: CalendarSolid,
    isUnvisitedStage: CalendarSolid,
    completed: CompletedEventSolid,
  },
}

const getStatusIconSvg = (
  item: StatusIconProps['item'],
  taskVariant: Required<StatusIconProps>['taskVariant'],
  iconVariant: Required<StatusIconProps>['iconVariant']
) => {
  const calculatedIconVariant = isCompletedStatus(item)
    ? 'completed'
    : isCanceledStatus(item)
      ? 'canceled'
      : iconVariant

  const iconVariantsSvg =
    taskVariantToIconVariantsSvg[taskVariant][calculatedIconVariant]

  if (iconVariantsSvg) {
    return iconVariantsSvg
  }

  return StatusSolid
}

export const StatusIcon = forwardRef<SVGSVGElement, StatusIconProps>(
  function StatusIcon(
    {
      item,
      width = 16,
      height = 16,
      iconVariant = 'default',
      taskVariant = 'default',
    },
    ref
  ) {
    const colorClass = getStatusColorClass(item)
    const legacyColor = colorClass ? undefined : item?.color
    const Icon = getStatusIconSvg(item, taskVariant, iconVariant)

    return (
      <Icon
        ref={ref}
        className={twMerge('inline-flex shrink-0', colorClass)}
        style={{ color: legacyColor }}
        width={width}
        height={height}
      />
    )
  }
)
