import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { eventFiles } from '@lexical/rich-text'
import {
  calculateZoomLevel,
  isHTMLElement,
  mergeRegister,
} from '@lexical/utils'
import {
  $getNearestNodeFromDOMNode,
  $getNodeByKey,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  DRAGOVER_COMMAND,
  DROP_COMMAND,
  type LexicalEditor,
  type NodeKey,
} from 'lexical'
import {
  type DragEvent as ReactDragEvent,
  type JSX,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'

import { useDebugVisualizer } from './debug-visualizer'
import {
  $getDraggable,
  $getDropTarget,
  $getNodesToDrag,
  $handleDrop,
} from './handle-drop'
import { useEditorLastUpdate } from './use-editor-last-update'
import {
  getBlockElement,
  hideTargetLine,
  setDragImage,
  setMenuPosition,
  setTargetLine,
} from './utils'

type RenderMenuComponentParams = {
  blockElemKey: NodeKey | null
  onDragStart: (event: ReactDragEvent<HTMLDivElement>) => void
  onDragEnd: () => void
}

function useDraggableBlockMenu(
  editor: LexicalEditor,
  anchorElem: HTMLElement,
  menuRef: React.RefObject<HTMLElement | null>,
  targetLineRef: React.RefObject<HTMLElement | null>,
  isEditable: boolean,
  renderMenuComponent: (params: RenderMenuComponentParams) => ReactNode,
  targetLineComponent: ReactNode,
  isOnMenu: (element?: HTMLElement) => boolean,
  debugVisualizerEnabled: boolean = false
): JSX.Element {
  const lastEditorUpdateRef = useEditorLastUpdate(editor, anchorElem)

  const debugVisualizer = useDebugVisualizer(debugVisualizerEnabled)

  const draggedNodesRef = useRef<string[] | null>(null)

  const [draggableBlockElem, setDraggableBlockElem] =
    useState<HTMLElement | null>(null)

  const [draggableBlockElemKey, setDraggableBlockElemKey] =
    useState<NodeKey | null>(null)

  // Update debug highlighting when draggable element changes
  useEffect(() => {
    debugVisualizer.highlightDraggable(draggableBlockElem)
    return () => {
      debugVisualizer.clearAll()
    }
  }, [debugVisualizer, draggableBlockElem])

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement

    function onKeyDown(event: KeyboardEvent) {
      setDraggableBlockElem(null)
    }

    function onMouseMove(event: MouseEvent) {
      const target = event.target
      if (!isHTMLElement(target)) {
        setDraggableBlockElem(null)
        return
      }

      if (draggedNodesRef.current) {
        return null
      }

      if (isOnMenu(target as HTMLElement)) {
        return
      }

      const [_draggableBlockElem, _draggableBlockElemKey] = getBlockElement({
        anchorElem,
        editor,
        event,
        getDroppable: false,
        lastUpdateTimestamp: lastEditorUpdateRef.current,
      })

      const draggable = editor.getEditorState().read(() => {
        return $getDraggable(_draggableBlockElemKey)
      })

      const draggableKey = draggable ? draggable.getKey() : null
      const draggableBlockElem =
        draggable && draggableKey ? editor.getElementByKey(draggableKey) : null

      setDraggableBlockElem((prev) =>
        prev === draggableBlockElem ? prev : draggableBlockElem
      )
      setDraggableBlockElemKey((prev) =>
        prev === draggableKey ? prev : draggableKey
      )
    }

    function onMouseLeave() {
      if (!isOnMenu()) {
        setDraggableBlockElem(null)
      }
    }

    if (scrollerElem != null) {
      scrollerElem.addEventListener('keydown', onKeyDown)
      scrollerElem.addEventListener('mousemove', onMouseMove)
      scrollerElem.addEventListener('mouseleave', onMouseLeave)
    }

    return () => {
      if (scrollerElem != null) {
        scrollerElem.removeEventListener('keydown', onKeyDown)
        scrollerElem.removeEventListener('mousemove', onMouseMove)
        scrollerElem.removeEventListener('mouseleave', onMouseLeave)
      }
    }
  }, [anchorElem, editor, isOnMenu, lastEditorUpdateRef])

  useEffect(() => {
    if (menuRef.current) {
      setMenuPosition(draggableBlockElem, menuRef.current, anchorElem)
    }
  }, [anchorElem, draggableBlockElem, menuRef])

  useEffect(() => {
    function onDragover(event: DragEvent): boolean {
      if (!draggedNodesRef.current) {
        return false
      }
      const dragData = draggedNodesRef.current

      const [isFileTransfer] = eventFiles(event)
      if (isFileTransfer) {
        return false
      }

      const { pageY, target } = event
      if (!isHTMLElement(target)) {
        return false
      }

      const [targetBlockElem] = getBlockElement({
        anchorElem,
        editor,
        event,
        getDroppable: true,
        lastUpdateTimestamp: lastEditorUpdateRef.current,
      })

      const targetLineElem = targetLineRef.current

      // Clear previous target highlight
      debugVisualizer.clearTargetHighlight()

      if (
        targetBlockElem === null ||
        targetLineElem === null ||
        !dragData?.[0]
      ) {
        // Hide to target line if there's no target, to give a better US when it's not working.
        hideTargetLine(targetLineRef.current)
        return false
      }

      const draggedNode = $getNodeByKey(dragData[0])
      if (!draggedNode) {
        return false
      }

      let targetNode = $getNearestNodeFromDOMNode(targetBlockElem)
      if (!targetNode) {
        return false
      }
      const dropTarget = $getDropTarget(targetNode, draggedNode)
      const dropTargetElem = editor.getElementByKey(dropTarget.getKey())
      if (!dropTargetElem) {
        return false
      }

      // Highlight drop target element
      debugVisualizer.highlightTarget(dropTargetElem)

      setTargetLine(
        targetLineElem,
        dropTargetElem,
        pageY / calculateZoomLevel(target),
        anchorElem
      )
      // Prevent default event to be able to trigger onDrop events
      event.preventDefault()
      return true
    }

    function $onDrop(event: DragEvent): boolean {
      if (!draggedNodesRef.current) {
        return false
      }

      // Clear target debug highlight on drop
      debugVisualizer.clearTargetHighlight()

      const [isFileTransfer] = eventFiles(event)
      if (isFileTransfer) {
        return false
      }

      const { target, pageY } = event
      const dragData = draggedNodesRef.current

      if (!dragData?.[0]) {
        return false
      }

      const draggedNode = $getNodeByKey(dragData[0])
      if (!draggedNode) {
        return false
      }

      if (!isHTMLElement(target)) {
        return false
      }

      const [targetBlockElem] = getBlockElement({
        anchorElem,
        editor,
        event,
        getDroppable: true,
        lastUpdateTimestamp: lastEditorUpdateRef.current,
      })

      if (!targetBlockElem) {
        return false
      }

      let targetNode = $getNearestNodeFromDOMNode(targetBlockElem)

      if (!targetNode) {
        return false
      }

      if (targetNode === draggedNode) {
        return true
      }

      const { dropTarget, nodesToInsert } = $handleDrop(targetNode, draggedNode)

      const targetBlockElemTop = targetBlockElem.getBoundingClientRect().top
      if (pageY / calculateZoomLevel(target) >= targetBlockElemTop) {
        for (const node of nodesToInsert.reverse()) {
          dropTarget.insertAfter(node)
        }
      } else {
        for (const node of nodesToInsert) {
          dropTarget.insertBefore(node)
        }
      }

      draggedNode.selectStart()

      setDraggableBlockElem(null)

      return true
    }

    return mergeRegister(
      editor.registerCommand(
        DRAGOVER_COMMAND,
        (event) => {
          return onDragover(event)
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        DROP_COMMAND,
        (event) => {
          return $onDrop(event)
        },
        COMMAND_PRIORITY_HIGH
      )
    )
  }, [anchorElem, debugVisualizer, editor, lastEditorUpdateRef, targetLineRef])

  function onDragStart(event: ReactDragEvent<HTMLDivElement>): void {
    const dataTransfer = event.dataTransfer
    if (!dataTransfer || !draggableBlockElem) {
      return
    }
    const nodeKeys: string[] = []

    editor.update(() => {
      const node = $getNearestNodeFromDOMNode(draggableBlockElem)
      if (node) {
        const nodesToDrag = $getNodesToDrag(node)

        for (const node of nodesToDrag) {
          nodeKeys.push(node.getKey())
        }
      }
    })

    setDragImage(dataTransfer, draggableBlockElem)

    draggedNodesRef.current = nodeKeys
  }

  function onDragEnd(): void {
    draggedNodesRef.current = null
    hideTargetLine(targetLineRef.current)
    // Clear target debug highlight on drag end
    debugVisualizer.clearTargetHighlight()
  }

  return createPortal(
    <>
      {isEditable &&
        renderMenuComponent({
          blockElemKey: draggableBlockElemKey,
          onDragStart,
          onDragEnd,
        })}
      {targetLineComponent}
    </>,
    anchorElem
  )
}

export function LexicalDraggableBlockPlugin({
  anchorElem = document.body,
  menuRef,
  targetLineRef,
  renderMenuComponent,
  targetLineComponent,
  isOnMenu,
  debugVisualizerEnabled = false,
}: {
  anchorElem?: HTMLElement
  menuRef: React.RefObject<HTMLElement | null>
  targetLineRef: React.RefObject<HTMLElement | null>
  renderMenuComponent: (params: RenderMenuComponentParams) => ReactNode
  targetLineComponent: ReactNode
  isOnMenu: (element?: HTMLElement) => boolean
  debugVisualizerEnabled?: boolean
}): JSX.Element {
  const [editor] = useLexicalComposerContext()
  return useDraggableBlockMenu(
    editor,
    anchorElem,
    menuRef,
    targetLineRef,
    editor._editable,
    renderMenuComponent,
    targetLineComponent,
    isOnMenu,
    debugVisualizerEnabled
  )
}
