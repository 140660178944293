import { DOMExportOutput, LexicalEditor } from 'lexical'

import { ParsingContext } from './parsing-context'

type PatchTarget = {
  prototype: {
    exportDOM(editor: LexicalEditor): DOMExportOutput
  }
}

export function monkeyPatchClearInlineStyles(nodeClass: PatchTarget) {
  if ('__patched' in nodeClass) return nodeClass
  const originalExportDom = nodeClass.prototype.exportDOM
  nodeClass.prototype.exportDOM = function (editor) {
    const baseValue = originalExportDom.call(this, editor)

    if (ParsingContext.current.mode === 'publish') {
      // @ts-expect-error - Will be there
      baseValue.element.style = {}
    }
    return baseValue
  }
  // @ts-expect-error - hidden property
  nodeClass.__patched = true
}
