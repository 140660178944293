import { useLayoutEffect } from 'react'

import { useClosure } from './use-closure'

export function useResizeObserver<T extends HTMLElement>(
  ref: React.RefObject<T | null>,
  callback: ResizeObserverCallback
) {
  const stable = useClosure(callback)

  useLayoutEffect(() => {
    if (!ref.current) return

    const observer = new ResizeObserver(stable)
    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref, stable])
}
