import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { markSelection } from '@lexical/utils'
import { useEffect, useRef } from 'react'

export type SelectionAlwaysOnDisplayPluginProps = {
  enabled?: boolean
}

export const SelectionAlwaysOnDisplayPlugin = (
  props: SelectionAlwaysOnDisplayPluginProps
) => {
  const { enabled = true } = props
  const [editor] = useLexicalComposerContext()
  const hasAppliedStyles = useRef<boolean>(false)

  function applyStylesToElements(elements: HTMLElement[]) {
    // Don't re-apply styles if we've already styled these elements
    // This is to prevent moving the selection while the modal is open
    if (hasAppliedStyles.current) return

    for (const element of elements) {
      element.className =
        'bg-semantic-gradient-purple opacity-20 z-1 pointer-events-none box-content -mt-0.5 pt-1'
    }

    hasAppliedStyles.current = true
  }

  useEffect(() => {
    let removeSelectionMark: (() => void) | null = null

    const onMarkCreated = (elements: HTMLElement[]) => {
      applyStylesToElements(elements)
    }

    // Handle enabled flag changes
    if (enabled) {
      if (removeSelectionMark === null) {
        // Set up the mark once
        removeSelectionMark = markSelection(editor, onMarkCreated)
      }
    }

    if (!enabled && removeSelectionMark !== null) {
      removeSelectionMark()
      removeSelectionMark = null
      hasAppliedStyles.current = false
    }

    return () => {
      if (removeSelectionMark !== null) {
        removeSelectionMark()
        removeSelectionMark = null
      }
      hasAppliedStyles.current = false
    }
  }, [editor, enabled])

  return null
}
