import { FilledChevronRightSolid } from '@motion/icons'

import { type Row } from '@tanstack/react-table'
import { type PropsWithChildren, type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { TreeNodeShell } from './tree-node-shell'

import { Checkbox } from '../../../forms'
import { Button } from '../../button'
import { useTreeKeyboardContext } from '../tree-keyboard-context'
import { type VirtualizedTreeNode } from '../types'
import { useTreeContext } from '../virtualized-tree-context'

type ExpandableCheckboxTreeNodeProps = PropsWithChildren<{
  row: Row<VirtualizedTreeNode>
  renderIcon: (node: VirtualizedTreeNode) => ReactNode
  showExplicitSelectionOnly?: boolean
}>

export const ExpandableCheckboxTreeNode = (
  props: ExpandableCheckboxTreeNodeProps
) => {
  const { row, renderIcon, children } = props
  const { onSelect } = useTreeContext()
  const { activeRow, setActiveRow } = useTreeKeyboardContext()

  const node = row.original

  const isActive = !!activeRow && activeRow.id === row.id
  const showArrow = !!row.originalSubRows

  return (
    <TreeNodeShell
      disabled={node.disabled}
      className='h-8'
      onClick={() => {
        row.toggleSelected()
        onSelect(row)
      }}
      focused={isActive}
      style={{
        marginLeft: (row.depth ?? 0) * 12,
      }}
      onPointerMove={() => (!isActive ? setActiveRow(row) : undefined)}
    >
      <div className={twMerge('opacity-0 mr-1', showArrow && 'opacity-100')}>
        <Button
          onClick={(e) => {
            e.stopPropagation()
            row.toggleExpanded(!row.getIsExpanded())
          }}
          iconOnly
          size='small'
          variant='muted'
          sentiment='neutral'
          disabled={node.disabled || !row.getCanExpand()}
        >
          <FilledChevronRightSolid
            className={twMerge(
              'text-semantic-neutral-text-disabled transition-all',
              row.getIsExpanded() && 'rotate-90'
            )}
          />
        </Button>
      </div>
      <Checkbox
        disabled={node.disabled || row.originalSubRows?.length === 0}
        checked={
          showArrow ? calculateVisiblySelected(row, props) : row.getIsSelected()
        }
        onChange={() => {
          row.toggleSelected()
          onSelect(row)
        }}
      />
      {renderIcon(node)}

      <div className='flex-grow flex truncate min-w-0'>{children}</div>
    </TreeNodeShell>
  )
}

function calculateVisiblySelected(
  row: Row<VirtualizedTreeNode>,
  opts: { showExplicitSelectionOnly?: boolean }
) {
  if (row.getIsSelected()) return true
  if (row.getIsSomeSelected()) return 'indeterminate'

  if (opts.showExplicitSelectionOnly) {
    if (row.getIsAllSubRowsSelected()) return 'indeterminate'
    return false
  }

  return row.getIsAllSubRowsSelected()
}
