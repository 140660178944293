import {
  $getNodeByMotionId,
  $isCollapsibleHeadingNode,
  $isCollapsibleListItemNode,
  LIST_TOGGLE_COLLAPSE_COMMAND,
  LIST_UNCOLLAPSE_COMMAND,
  type MotionId,
} from '@motion/notes-shared'

import { $getEditor } from 'lexical'

import { BROADCAST_UPDATE_COMMAND } from '../../broadcast-plugin'

export const $handleToggleCollapseCommand = (
  motionId: MotionId,
  setToggleState: (motionId: MotionId, open: boolean) => void
): boolean => {
  const editor = $getEditor()
  const node = $getNodeByMotionId(motionId)

  if (
    node == null ||
    !($isCollapsibleListItemNode(node) || $isCollapsibleHeadingNode(node))
  ) {
    return false
  }

  const oldOpen = node.getOpen()
  const newOpen = !oldOpen

  // Persist the open state
  setToggleState(motionId, newOpen)

  // Update the node open state
  node.setOpen(newOpen)

  // Move selection to the start of the node
  node.selectStart()

  // Broadcast the update to other editors
  editor.dispatchCommand(BROADCAST_UPDATE_COMMAND, {
    command: newOpen ? LIST_UNCOLLAPSE_COMMAND : LIST_TOGGLE_COLLAPSE_COMMAND,
    payload: motionId,
  })

  return true
}
