import { useDependantState } from '@motion/react-core/hooks'

import React from 'react'

import {
  CheckboxList,
  SearchableListContent,
  SearchableListInput,
  SectionedCheckboxList,
  SectionedSelectList,
  SectionedSimpleList,
  SimpleList,
} from './components'
import { useItemSelectors } from './hooks'
import { type SearchableListProps } from './types'

export const SearchableList = <T,>(props: SearchableListProps<T>) => {
  const {
    inputProps = { placeholder: 'Filter...' },
    searchable = true,
    search: searchProp,
    onSearchChange,
    keyboardControlRef,
    ...listProps
  } = props

  const [search, setSearch] = useDependantState<string>(
    () => searchProp ?? '',
    [searchProp]
  )
  const [activeValue, setActiveValue] = React.useState<string | null>(null)
  const isDirty = React.useRef<boolean>(false)

  const containerRef = React.useRef<HTMLDivElement>(null)

  const { getFirstActiveItemValue } = useItemSelectors(containerRef)

  const clearSearch = () => {
    setSearch('')
    onSearchChange?.('')
  }

  const handleInputValueChange = React.useCallback(
    (search: string) => {
      setSearch(search)
      onSearchChange?.(search)
    },
    [onSearchChange, setSearch]
  )

  React.useLayoutEffect(() => {
    // selected the first item whenever the filtered items change, but only when the input is dirty
    if (!isDirty.current && !search) {
      return
    }
    isDirty.current = true
    const newValue = getFirstActiveItemValue()
    setActiveValue(newValue || null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <SearchableListContent
      search={search}
      activeValue={activeValue}
      setActiveValue={setActiveValue}
      keyboardControlRef={keyboardControlRef}
    >
      {searchable && (
        <SearchableListInput
          {...inputProps}
          onValueChange={handleInputValueChange}
          search={search}
        />
      )}
      {listProps.itemType === 'checkbox' ? (
        <CheckboxList
          activeValue={activeValue}
          setActiveValue={setActiveValue}
          search={search}
          clearSearch={clearSearch}
          containerRef={containerRef}
          {...listProps}
        />
      ) : listProps.itemType === 'sectioned-checkbox' ? (
        <SectionedCheckboxList
          activeValue={activeValue}
          setActiveValue={setActiveValue}
          search={search}
          clearSearch={clearSearch}
          containerRef={containerRef}
          {...listProps}
        />
      ) : listProps.itemType === 'sectioned-select' ? (
        <SectionedSelectList
          activeValue={activeValue}
          setActiveValue={setActiveValue}
          search={search}
          clearSearch={clearSearch}
          containerRef={containerRef}
          {...listProps}
        />
      ) : listProps.itemType === 'sectioned' ? (
        <SectionedSimpleList
          activeValue={activeValue}
          setActiveValue={setActiveValue}
          search={search}
          clearSearch={clearSearch}
          containerRef={containerRef}
          {...listProps}
        />
      ) : (
        <SimpleList
          activeValue={activeValue}
          setActiveValue={setActiveValue}
          search={search}
          clearSearch={clearSearch}
          containerRef={containerRef}
          {...listProps}
        />
      )}
    </SearchableListContent>
  )
}
