import { useClosure, useDependantState } from '@motion/react-core/hooks'
import { templateStr } from '@motion/react-core/strings'
import { type VariantProps } from '@motion/theme'
import { byProperty, isArrayEqual, ordered } from '@motion/utils/array'

import { type DragEndEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { type ReactNode, useCallback, useMemo, useState } from 'react'

import {
  SortableContainer,
  SortableTab,
  StyledTabList,
  Tab,
  TabButton,
  TabListCreateNewItem,
  TabListItem,
} from './components'
import { type TabItem } from './types'
import { getVisibleAndHiddenIndices } from './utils'

import {
  ConditionalWrapper,
  ItemsMeasurer,
  type ItemsMeasurerProps,
} from '../../utils'
import { SearchableDropdownContent } from '../dropdowns'
import { PopoverTrigger } from '../popover'

type RenderDisclosureProps = {
  items: TabItem[]
  visibleIndices: TablistState['visibleIndices']
  hiddenIndices: TablistState['hiddenIndices']
}

type SortableTabListProps =
  | { sortable?: never; onSortOrderChange?: never }
  | {
      sortable: true
      onSortOrderChange: (ids: string[]) => void
    }

export type ResponsiveTabListProps = {
  items: TabItem[]
  activeValue: string | null | undefined
  renderDisclosureButtonContent?: (p: RenderDisclosureProps) => ReactNode
  renderDisclosurePanelContent?: (
    p: RenderDisclosureProps & { close: () => void }
  ) => ReactNode
  renderCreateNewElement?: () => ReactNode
  variant?: VariantProps<typeof StyledTabList>['variant']
} & SortableTabListProps

type TablistState = {
  visibleIndices: number[]
  hiddenIndices: number[]
}

export const ResponsiveTabList = ({
  items,
  activeValue,
  renderDisclosureButtonContent,
  renderDisclosurePanelContent,
  renderCreateNewElement,
  variant,
  sortable,
  onSortOrderChange,
}: ResponsiveTabListProps) => {
  const [sortableItemIds, setSortableItemIds] = useDependantState(
    () => (sortable ? items.map(({ value: id }) => id) : []),
    // When the activeValue changes, we need to recompute the sortable items
    // because we may have made one of the hidden tab visible because of it being selected
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, sortable, activeValue]
  )

  const tabs = useMemo(() => {
    return sortable
      ? items.toSorted(byProperty('value', ordered(sortableItemIds)))
      : items
  }, [sortable, items, sortableItemIds])

  const selectedIndex = useMemo(
    () =>
      activeValue != null ? tabs.findIndex((t) => t.value === activeValue) : -1,
    [tabs, activeValue]
  )

  const [{ visibleIndices, hiddenIndices }, setState] = useState<TablistState>(
    () => ({
      visibleIndices: [],
      hiddenIndices: [],
    })
  )

  // When deleting an item, our `visibleIndices` and `hiddenIndices` will be slightly out of sync for a few frame,
  // so we cannot guarantee that we'll always have a valid tab until the indices get fixed next time the measurements run
  function getTabByIndex(index: number) {
    return tabs[index] ?? null
  }

  const hiddenTabs = hiddenIndices
    .map((index) => {
      const tabInfo = getTabByIndex(index)
      if (tabInfo == null) return null
      return {
        id: tabInfo.value,
        ...tabInfo,
      }
    })
    .filter(Boolean)

  const disclosureActivatorVisible = hiddenIndices.length > 0
  const renderDisclosurePanelContentClosure = useClosure<
    NonNullable<ResponsiveTabListProps['renderDisclosurePanelContent']>
  >(
    renderDisclosurePanelContent ??
      (({ close }) => (
        <SearchableDropdownContent
          close={close}
          items={hiddenTabs}
          onChange={(item) => item.onAction?.()}
          renderItem={(item) => item.content}
          searchable
          searchPlaceholder='Search...'
          computeSearchValue={(item) => item.name}
        />
      ))
  )
  const renderDisclosureButtonContentClosure = useClosure(
    renderDisclosureButtonContent ??
      (() => templateStr('{{count}} more', { count: hiddenIndices.length }))
  )

  const disclosureActivator = useMemo(() => {
    const disclosureProps = {
      items: tabs,
      visibleIndices,
      hiddenIndices,
    }

    return (
      <PopoverTrigger
        placement='bottom-end'
        renderPopover={({ close }) =>
          renderDisclosurePanelContentClosure({ ...disclosureProps, close })
        }
      >
        <TabListItem>
          <TabButton variant={variant}>
            {renderDisclosureButtonContentClosure(disclosureProps)}
          </TabButton>
        </TabListItem>
      </PopoverTrigger>
    )
  }, [
    hiddenIndices,
    renderDisclosureButtonContentClosure,
    renderDisclosurePanelContentClosure,
    tabs,
    variant,
    visibleIndices,
  ])

  const createNewActivatorVisible = renderCreateNewElement != null

  const createNewActivator = useMemo(() => {
    return createNewActivatorVisible ? (
      <TabListCreateNewItem>{renderCreateNewElement()}</TabListCreateNewItem>
    ) : null
  }, [createNewActivatorVisible, renderCreateNewElement])

  const handleMeasurement: ItemsMeasurerProps<void>['handleMeasurement'] =
    useCallback(
      (measurements) => {
        const {
          hiddenTabWidths: itemWidths,
          containerWidth,
          activatorWidth,
          suffixWidth,
        } = measurements

        const { visibleIndices, hiddenIndices } = getVisibleAndHiddenIndices({
          containerWidth: containerWidth - suffixWidth,
          itemWidths,
          selectedIndex,
          activatorWidth,
          keepOrder: true,
        })

        setState((prev) => {
          if (
            isArrayEqual(prev.hiddenIndices, hiddenIndices) &&
            isArrayEqual(prev.visibleIndices, visibleIndices)
          ) {
            return prev
          }

          return {
            visibleIndices,
            hiddenIndices,
          }
        })

        setSortableItemIds((prev) => {
          const newSortedIds = [...visibleIndices, ...hiddenIndices].map(
            (index) => prev[index]
          )

          if (isArrayEqual(prev, newSortedIds)) {
            return prev
          }

          return newSortedIds
        })
      },
      [selectedIndex, setSortableItemIds]
    )

  const renderTab = useCallback(
    (item: TabItem) => {
      const { name, content, value, ...rest } = item

      const Component = sortable ? SortableTab : Tab

      return (
        <Component
          key={item.value}
          id={item.value}
          variant={variant}
          active={activeValue === item.value}
          {...rest}
        >
          {item.content}
        </Component>
      )
    },
    [activeValue, sortable, variant]
  )

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (over == null) return
    if (active.id === over.id) return

    const oldIndex = sortableItemIds.findIndex((id) => id === active.id)
    const newIndex = sortableItemIds.findIndex((id) => id === over.id)
    const newItemIds = arrayMove(sortableItemIds, oldIndex, newIndex)

    setSortableItemIds(newItemIds)
    onSortOrderChange?.(newItemIds)
  }

  return (
    <>
      <ItemsMeasurer
        items={tabs}
        renderItem={renderTab as any}
        activator={disclosureActivator}
        suffix={createNewActivator}
        selectedIndex={selectedIndex}
        handleMeasurement={handleMeasurement}
      />
      <StyledTabList role='tablist' variant={variant} sortable={sortable}>
        <ConditionalWrapper
          condition={sortable ?? false}
          wrapper={(children) => (
            <SortableContainer
              items={sortableItemIds}
              onDragEnd={handleDragEnd}
            >
              {children}
            </SortableContainer>
          )}
        >
          {visibleIndices.map((index) => {
            const tabInfo = getTabByIndex(index)
            return tabInfo && renderTab(tabInfo)
          })}
        </ConditionalWrapper>
        {disclosureActivatorVisible && disclosureActivator}
        {createNewActivatorVisible && createNewActivator}
      </StyledTabList>
    </>
  )
}
