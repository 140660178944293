import { classed } from '@motion/theme'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { calculateZoomLevel } from '@lexical/utils'
import type { LexicalNode, NodeKey } from 'lexical'
import { $getNodeByKey } from 'lexical'
import { type ReactNode, useEffect, useRef, useState } from 'react'

function $isResizableNode(node: LexicalNode | null): node is LexicalNode & {
  getWidth: () => number
  setWidth: (width: number) => void
  getHeight: () => number
  setHeight: (height: number) => void
} {
  return (
    node != null &&
    'getWidth' in node &&
    typeof node.getWidth === 'function' &&
    'setWidth' in node &&
    typeof node.setWidth === 'function' &&
    node != null &&
    'getHeight' in node &&
    typeof node.getHeight === 'function' &&
    'setHeight' in node &&
    typeof node.setHeight === 'function'
  )
}

type Props = Readonly<{
  children: ReactNode
  resizable?: boolean
  nodeKey: NodeKey
  initialWidth: number
  initialHeight?: number
  aspectRatio?: string
}>

const Direction = {
  east: 1 << 0,
  west: 1 << 2,
}

const innerWidth = (node: HTMLElement) => {
  var computedStyle = getComputedStyle(node)

  let width = node.clientWidth // width with padding
  width -=
    parseFloat(computedStyle.paddingLeft) +
    parseFloat(computedStyle.paddingRight)
  return width
}

function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max)
}

const onResizeStart = () => null

export function ResizableBlock({
  children,
  resizable = true,
  nodeKey,
  initialWidth,
  initialHeight,
  aspectRatio,
}: Props): JSX.Element {
  const [editor] = useLexicalComposerContext()
  const editorRootElement = editor.getRootElement()
  const [hovering, setHovering] = useState(false)
  const [resizing, setResizing] = useState(false)

  const positioningRef = useRef<{
    currentWidth: number
    direction: number
    isResizing: boolean
    startWidth: number
    startX: number
  }>({
    currentWidth: initialWidth,
    direction: 0,
    isResizing: false,
    startWidth: 0,
    startX: 0,
  })

  const containerRef = useRef<HTMLDivElement | null>(null)

  const observerRef = useRef<ResizeObserver | null>(null)

  useEffect(() => {
    const container = containerRef.current

    if (container !== null) {
      const resizeObserver = new ResizeObserver(() => {
        editor.update(() => {
          const node = $getNodeByKey(nodeKey)

          if (!$isResizableNode(node)) {
            return null
          }

          const { width, height } = container.getBoundingClientRect()

          node.setWidth(width)
          node.setHeight(height)
        })
      })

      resizeObserver.observe(container)

      observerRef.current = resizeObserver

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [editor, nodeKey])

  const onResizeEnd = (nextWidth: number, nextHeight: number) => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if ($isResizableNode(node)) {
        node.setWidth(nextWidth)
        node.setHeight(nextHeight)
      }
    })
  }

  // Find max width, accounting for editor padding.
  const maxWidthContainer =
    editorRootElement !== null ? innerWidth(editorRootElement) : 200

  const minWidth = 100

  const handlePointerDown = (
    event: React.PointerEvent<HTMLDivElement>,
    direction: number
  ) => {
    if (!editor.isEditable()) {
      return
    }

    observerRef.current?.disconnect()

    const container = containerRef.current

    if (container !== null) {
      event.preventDefault()
      const { width } = container.getBoundingClientRect()
      const zoom = calculateZoomLevel(container)
      const positioning = positioningRef.current
      positioning.startWidth = width
      positioning.currentWidth = width
      positioning.startX = event.clientX / zoom
      positioning.isResizing = true
      setResizing(true)
      positioning.direction = direction

      onResizeStart()

      container.style.width = `${width}px`
      container.style.height = 'inherit'

      document.addEventListener('pointermove', handlePointerMove)
      document.addEventListener('pointerup', handlePointerUp)
    }
  }
  const handlePointerMove = (event: PointerEvent) => {
    const container = containerRef.current
    const positioning = positioningRef.current

    if (container !== null && positioning.isResizing) {
      const zoom = calculateZoomLevel(container)

      let diff = Math.floor(positioning.startX - event.clientX / zoom)
      diff = positioning.direction & Direction.east ? -diff : diff

      const width = clamp(
        positioning.startWidth + diff,
        minWidth,
        maxWidthContainer
      )

      positioning.currentWidth = width
      container.style.width = `${width}px`
    }
  }
  const handlePointerUp = () => {
    const container = containerRef.current
    const positioning = positioningRef.current
    if (container !== null && positioning.isResizing) {
      const width = positioning.currentWidth
      const height = container.clientHeight
      positioning.startWidth = 0
      positioning.startX = 0
      positioning.currentWidth = 0
      positioning.isResizing = false
      setResizing(false)

      onResizeEnd(width, height)

      document.removeEventListener('pointermove', handlePointerMove)
      document.removeEventListener('pointerup', handlePointerUp)
    }
  }

  return (
    <div
      className='relative mx-auto max-w-full rounded-md overflow-hidden'
      style={{
        aspectRatio: aspectRatio,
        width: initialWidth || '100%',
        height: initialHeight || 'inherit',
      }}
      onPointerEnter={() => setHovering(true)}
      onPointerLeave={() => setHovering(false)}
      ref={containerRef}
    >
      <div className='h-full'>{children}</div>

      {resizable && (hovering || resizing) && (
        <>
          {resizing && <ResizeBackdrop />}
          <ResizeHandle
            side='left'
            onPointerDown={(event) => {
              handlePointerDown(event, Direction.west)
            }}
          />
          <ResizeHandle
            side='right'
            onPointerDown={(event) => {
              handlePointerDown(event, Direction.east)
            }}
          />
        </>
      )}
    </div>
  )
}

const ResizeHandle = classed('div', {
  base: `
    absolute top-1/2 -translate-y-1/2 right-1 
    bg-black/80 rounded-full w-[5px] h-10 
    cursor-col-resize 
    border-[0.5px] border-white
    before:absolute before:top-0 before:bottom-0
  `,
  variants: {
    side: {
      left: 'left-1 before:-left-1 before:-right-1.5',
      right: 'right-1 before:-left-1.5 before:-right-1',
    },
  },
})

const ResizeBackdrop = classed('div', {
  base: `
    absolute inset-0 w-full h-full
  `,
})
