import { cloneDeep } from '@motion/utils/core'
import { entries, keys } from '@motion/utils/object'
import { type AllModelsSchema } from '@motion/zod/client'

import { type QueryFilters, type QueryKey } from '@tanstack/react-query'

import { DELETE_MODEL } from './constants'
import { applyPartialToTargetStore } from './shared'
import {
  type ApplyModelReturn,
  type ModelStore,
  type QueryCacheMatches,
  type QueryCacheUpsert,
} from './types'
import {
  getResponseModelType,
  matchQueries,
  shouldAppendNew,
  shouldRemoveOldEntity,
  updateQueryData,
} from './utils'

import { isModelCacheKey, type Model, type ModelId } from '../model-cache'
import { type MotionCacheContext, type V2ResponseStoreShape } from '../types'
import { log } from '../utils'

/**
 * Upsert the cache with a **full** model response.
 * Appends new items by default
 *
 * @param ctx - The MotionCacheContext with the client and userId.
 * @param filter - The QueryFilters to determine which queries to update.
 * @param response - The full response data to upsert into the cache.
 * @param appendNewFilter - Optional function to determine whether to append new data.
 *
 * @returns An array of QueryCacheUpsert objects representing the changes made.
 */
export function updateQueryCaches(
  ctx: MotionCacheContext,
  filter: QueryFilters<QueryCacheMatches>,
  response: V2ResponseStoreShape
): QueryCacheUpsert<keyof AllModelsSchema>[] {
  try {
    const queries = matchQueries(ctx, filter)

    const cacheUpdates: QueryCacheUpsert<keyof AllModelsSchema>[] = []

    log.time('update.all', () => {
      queries.forEach(([key, cacheStore]) => {
        // Not all queries have an operable cache store
        // can be any arbitrary shape, but only do updates on V2ResponseStoreShape
        if (cacheStore == null || cacheStore.models == null) return
        log.time(`update`, (): void => {
          const baseEntry = buildMinimalStore(cacheStore, response)
          const updates = processQueryCacheUpsert(ctx, baseEntry, response, key)
          cacheUpdates.push(...updates)
        })
      })
    })

    // Handles optimistic updates
    updateQueryData(ctx, cacheUpdates)

    return cacheUpdates
  } catch (error) {
    log.error('Error updating query caches:', error)
    return []
  }
}

type FlexibleStore = {
  ids?: string[]
  id?: string
  meta?: {
    model: keyof AllModelsSchema
  }
  models: Partial<AllModelsSchema>
}

function buildMinimalStore<
  TTarget extends QueryCacheMatches,
  TResponse extends QueryCacheMatches,
>(cacheStore: TTarget, response: TResponse) {
  const baseEntry: FlexibleStore = {
    models: {},
  }
  if ('ids' in cacheStore && cacheStore.ids != null) {
    baseEntry.ids = [...cacheStore.ids]
  }
  if ('meta' in cacheStore) {
    baseEntry.meta = cloneDeep(cacheStore.meta)
  }
  keys(response.models).forEach((type) => {
    baseEntry.models[type] = {}

    if (response.models[type] == null) return

    Object.keys(response.models[type]).forEach((id) => {
      if (cacheStore.models[type]?.[id] == null) return
      // @ts-expect-error - initialized above
      baseEntry.models[type][id] = cacheStore.models[type][id]
    })
  })

  return baseEntry
}

function processQueryCacheUpsert(
  ctx: MotionCacheContext,
  cacheStore: QueryCacheMatches,
  response: V2ResponseStoreShape,
  key: QueryKey
): QueryCacheUpsert<keyof AllModelsSchema>[] {
  const cacheUpdates: QueryCacheUpsert<keyof AllModelsSchema>[] = []

  const types = keys(response.models)
  types.forEach((type) => {
    const source = response.models[type]
    if (source == null) return

    const updates = applyMatching(ctx, cacheStore, type, source, key)

    cacheUpdates.push(...updates)
  })

  return cacheUpdates
}

/**
 * Apply the source models to the target store.
 *
 * Either an upsert or an update depending on `appendNew` and if the model already exists.
 */
function applyMatching<TType extends keyof AllModelsSchema>(
  ctx: MotionCacheContext,
  cacheStore: QueryCacheMatches,
  type: TType,
  source: Record<ModelId, Partial<Model<TType>>>,
  key: QueryKey
): QueryCacheUpsert<TType>[] {
  const cacheUpdates: QueryCacheUpsert<TType>[] = []

  if (cacheStore.models[type] == null) return cacheUpdates

  const targetStore = cacheStore.models[type] as ModelStore<TType>

  entries(source).forEach(([id, model]) => {
    if (id == null || model == null) return

    const appendNew = shouldAppendNew({
      ctx,
      key,
      type,
      model: model as Model<TType>,
    })
    const isNew = targetStore[id] == null

    if (!isNew) {
      const shouldRemoveOld = shouldRemoveOldEntity({
        ctx,
        key,
        type,
        model: model as Model<TType>,
      })
      if (shouldRemoveOld) {
        handleRemoveEntity(targetStore, cacheStore, id)

        /**
         * Used in updateQueryData to remove the model from the cache
         */
        cacheUpdates.push({
          operation: 'delete',
          key,
          data: DELETE_MODEL,
          id,
          type,
          updates: model,
          inverse: model,
        })
      }
    }

    if (isNew && !appendNew) return

    handleModelUpsert(
      isNew,
      targetStore,
      id,
      model,
      type,
      key,
      cacheStore,
      cacheUpdates,
      appendNew
    )
  })

  return cacheUpdates as QueryCacheUpsert<TType>[]
}

function handleRemoveEntity(
  targetStore: ModelStore<any>,
  target: QueryCacheMatches,
  id: ModelId
) {
  delete targetStore[id]
  if ('ids' in target && target.ids != null) {
    target.ids = target.ids.filter((x) => x !== id)
  }
}

function handleModelUpsert<TType extends keyof AllModelsSchema>(
  isNew: boolean,
  targetStore: ModelStore<TType>,
  id: ModelId,
  model: Partial<Model<TType>>,
  type: TType,
  key: QueryKey,
  cacheStore: QueryCacheMatches,
  cacheUpdates: QueryCacheUpsert<TType>[],
  appendNew: boolean
) {
  if (isNew) {
    // Create new model
    const { applied } = appendModelToTargetStore(
      key,
      targetStore,
      id,
      model as Model<TType>
    )

    if (!applied.changed) return

    cacheUpdates.push({
      operation: 'create',
      key,
      data: cacheStore,
      id,
      type,
      updates: model,
      inverse: applied.inverse,
    })
  } else {
    // Update existing model
    const applied = applyPartialToTargetStore(targetStore, id, model)
    if (!applied.changed) return

    cacheUpdates.push({
      operation: 'upsert',
      key,
      data: cacheStore,
      id,
      type,
      updates: model,
      inverse: applied.inverse,
    })
  }

  if (appendNew) {
    const targetModelType = getResponseModelType(cacheStore)

    // If the model matches the target then update the ids
    if (
      'ids' in cacheStore &&
      cacheStore.ids != null &&
      targetModelType === type &&
      model.id != null
    ) {
      cacheStore.ids = Array.from(new Set([...cacheStore.ids, model.id]))
    }
  }
}

function appendModelToTargetStore<TType extends keyof AllModelsSchema>(
  key: QueryKey,
  targetStore: ModelStore<TType>,
  id: ModelId,
  model: Model<TType>
): { applied: ApplyModelReturn<TType> } {
  const now = Date.now()

  if (isModelCacheKey(key)) {
    targetStore[id] = { updatedAt: now, value: model }
  } else {
    targetStore[id] = model
  }

  return {
    applied: {
      changed: true,
      inverse: DELETE_MODEL,
      data: model,
    },
  }
}
