import {
  $getNodeByMotionId,
  $isCollapsibleHeadingNode,
  $isCollapsibleListItemNode,
  $isCollapsibleListNode,
  CollapsibleHeadingNode,
  CollapsibleListItemNode,
  CollapsibleListNode,
  LIST_TOGGLE_COLLAPSE_COMMAND,
  LIST_UNCOLLAPSE_COMMAND,
  type MotionId,
} from '@motion/notes-shared'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $findMatchingParent, mergeRegister } from '@lexical/utils'
import {
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  INSERT_PARAGRAPH_COMMAND,
  SELECTION_INSERT_CLIPBOARD_NODES_COMMAND,
} from 'lexical'
import { useEffect } from 'react'

import { $collapsibleNodeMutationListener } from './handlers/collapsible-node-mutation.listener'
import { $handleCollapsibleListItemNodeTransform } from './handlers/handle-collapsible-list-transform'
import { $handleListInsertParagraph } from './handlers/handle-list-insert-paragraph'
import { $handleSelectionUpdate } from './handlers/handle-selection-update'
import { $handleToggleCollapseCommand } from './handlers/handle-toggle-collapse-command'

export type CreateCollapsibleListPluginProps = {
  getToggleState: (motionId: MotionId) => Promise<boolean>
  setToggleState: (motionId: MotionId, open: boolean) => void
}

export function CollapsibleListPlugin({
  getToggleState,
  setToggleState,
}: CreateCollapsibleListPluginProps): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener($handleSelectionUpdate(editor)),
      // When the editor first loads the content all nodes will be created,
      // so we should set the open state for each collapsible node
      // based on the persisted state
      editor.registerMutationListener(
        CollapsibleHeadingNode,
        $collapsibleNodeMutationListener(
          editor,
          $isCollapsibleHeadingNode,
          getToggleState
        )
      ),
      editor.registerMutationListener(
        CollapsibleListItemNode,
        $collapsibleNodeMutationListener(
          editor,
          $isCollapsibleListItemNode,
          getToggleState
        )
      ),

      // Register the command to toggle the open state of a collapsible list item node
      editor.registerCommand(
        LIST_TOGGLE_COLLAPSE_COMMAND,
        (motionId) => {
          return $handleToggleCollapseCommand(motionId, setToggleState)
        },
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        LIST_UNCOLLAPSE_COMMAND,
        (motionId) => {
          const node = $getNodeByMotionId(motionId)

          if (
            node == null ||
            !(
              $isCollapsibleListItemNode(node) ||
              $isCollapsibleHeadingNode(node)
            )
          ) {
            return false
          }

          // Update the node open state
          node.setOpen(true)

          return true
        },
        COMMAND_PRIORITY_NORMAL
      ),
      // Node cleanup transforms
      editor.registerNodeTransform(CollapsibleListNode, (node) => {
        // Remove empty collapsible containers (happens on drag and drop sometimes)
        if (node.isEmpty()) {
          node.remove()
        }
      }),
      editor.registerNodeTransform(
        CollapsibleListItemNode,
        $handleCollapsibleListItemNodeTransform
      ),
      editor.registerCommand(
        INSERT_PARAGRAPH_COMMAND,
        () => $handleListInsertParagraph(),
        COMMAND_PRIORITY_NORMAL
      ),
      editor.registerCommand(
        SELECTION_INSERT_CLIPBOARD_NODES_COMMAND,
        ({ nodes, selection }) => {
          if (!$isRangeSelection(selection)) {
            return false
          }
          const listItemParent = $findMatchingParent(
            selection.anchor.getNode(),
            (node) => $isCollapsibleListItemNode(node)
          )

          if (
            listItemParent !== null &&
            (nodes.some($isCollapsibleListItemNode) ||
              nodes.some($isCollapsibleListNode))
          ) {
            for (const node of nodes) {
              listItemParent.insertAfter(node)
            }

            return true
          }
          return false
        },
        COMMAND_PRIORITY_CRITICAL
      )
    )
  }, [editor, getToggleState, setToggleState])

  return null
}
