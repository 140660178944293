import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementNode,
  LexicalNode,
  type SerializedElementNode,
} from 'lexical'

const DATA_COLLAPSIBLE_CONTENT_ATTRIBUTE = 'data-lexical-collapsible-content'

export class CollapsibleContentNode extends ElementNode {
  static getType(): string {
    return 'collapsible-content'
  }

  static clone(node: CollapsibleContentNode): CollapsibleContentNode {
    return new CollapsibleContentNode(node.__key)
  }

  constructor(key?: string) {
    super(key)
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = document.createElement('div')
    element.className = config.theme.collapsible.content
    return element
  }

  updateDOM(): false {
    return false
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (domNode) => {
        if (!domNode.hasAttribute(DATA_COLLAPSIBLE_CONTENT_ATTRIBUTE)) {
          return null
        }

        return {
          conversion: $convertCollapsibleContentElement,
          priority: 2,
        }
      },
    }
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('div')
    element.setAttribute(DATA_COLLAPSIBLE_CONTENT_ATTRIBUTE, '')
    return {
      element,
    }
  }

  static importJSON(
    serializedNode: SerializedElementNode
  ): CollapsibleContentNode {
    return new CollapsibleContentNode().updateFromJSON(serializedNode)
  }

  isShadowRoot(): boolean {
    return true
  }
}

export function $convertCollapsibleContentElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const node = $createCollapsibleContentNode()
  return {
    node,
  }
}

export function $createCollapsibleContentNode(): CollapsibleContentNode {
  return new CollapsibleContentNode()
}

export function $isCollapsibleContentNode(
  node: LexicalNode | null | undefined
): node is CollapsibleContentNode {
  return node instanceof CollapsibleContentNode
}
