import { PopoverContainer, PopoverOverlay } from '@motion/ui/base'

import {
  autoPlacement,
  autoUpdate,
  FloatingPortal,
  offset as floatingOffset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  type MenuOption,
  type MenuResolution,
  type MenuTextMatch,
  type TriggerFn,
} from '@lexical/react/LexicalTypeaheadMenuPlugin'
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  type CommandListenerPriority,
  type TextNode,
} from 'lexical'
import { type ReactPortal, useCallback, useEffect, useState } from 'react'

import { TypeaheadMenuContainer } from './typeahead-menu-container'
import {
  getQueryTextForSearch,
  isSelectionOnEntityBoundary,
  tryToPositionRange,
} from './utils'

export type TypeaheadMenuPluginProps<TOption extends MenuOption> = {
  onQueryChange: (matchingString: string | null) => void
  onSelectOption: (
    option: TOption,
    textNodeContainingQuery: TextNode | null,
    closeMenu: () => void,
    matchingString: string
  ) => void
  options: Array<TOption>
  menuRenderFn: (args: {
    selectedIndex: number | null
    selectOptionAndCleanUp: (option: TOption) => void
    setHighlightedIndex: (index: number) => void
  }) => ReactPortal | JSX.Element | null
  triggerFn: TriggerFn
  onOpen?: (resolution: MenuResolution) => void
  onClose?: () => void
  commandPriority?: CommandListenerPriority
}

export const TypeaheadMenuPlugin = <TOption extends MenuOption>({
  options,
  onQueryChange,
  onSelectOption,
  onOpen,
  onClose,
  menuRenderFn,
  triggerFn,
  commandPriority = COMMAND_PRIORITY_LOW,
}: TypeaheadMenuPluginProps<TOption>) => {
  const [isOpen, setIsOpen] = useState(false)
  const [match, setMatch] = useState<MenuTextMatch | null>(null)
  const [editor] = useLexicalComposerContext()

  const { refs, floatingStyles, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      floatingOffset(5),
      shift({ padding: 5 }),
      autoPlacement({
        crossAxis: false,
        allowedPlacements: ['bottom-start', 'top-start'],
      }),
    ],
    whileElementsMounted: autoUpdate,
  })
  const dismiss = useDismiss(context)
  const { getFloatingProps } = useInteractions([dismiss])

  const closeTypeahead = useCallback(() => {
    setIsOpen(false)
    setMatch(null)
    if (onClose != null) {
      onClose()
    }
  }, [onClose])

  useEffect(() => {
    const updateListener = () => {
      editor.getEditorState().read(() => {
        // Check if editor is in read-only mode
        if (!editor.isEditable()) {
          closeTypeahead()
          return
        }

        const editorWindow = editor._window || window
        const range = editorWindow.document.createRange()
        const selection = $getSelection()
        const text = getQueryTextForSearch(editor)

        if (
          !$isRangeSelection(selection) ||
          !selection.isCollapsed() ||
          text === null ||
          range === null
        ) {
          closeTypeahead()
          return
        }

        const match = triggerFn(text, editor)
        onQueryChange(match ? match.matchingString : null)

        if (
          match !== null &&
          !isSelectionOnEntityBoundary(editor, match.leadOffset)
        ) {
          const isRangePositioned = tryToPositionRange(
            match.leadOffset,
            range,
            editorWindow
          )
          if (isRangePositioned) {
            refs.setReference({
              getBoundingClientRect: () => range.getBoundingClientRect(),
              getClientRects: () => range.getClientRects(),
            })
            setMatch(match)
            setIsOpen(true)
            return
          }
        }
        closeTypeahead()
      })
    }

    const removeUpdateListener = editor.registerUpdateListener(updateListener)

    return () => {
      removeUpdateListener()
    }
  }, [editor, triggerFn, onQueryChange, closeTypeahead, refs])

  useEffect(
    () =>
      editor.registerEditableListener((isEditable) => {
        if (!isEditable) {
          closeTypeahead()
        }
      }),
    [editor, closeTypeahead]
  )

  if (!isOpen || match === null || editor === null) {
    return null
  }

  return (
    <FloatingPortal>
      <PopoverOverlay enableOutsideInteractions={false}>
        <PopoverContainer
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            width: 280,
            maxHeight: '45vh',
            overflowX: 'hidden',
            overflowY: 'auto',
            scrollPadding: '4px 0',
          }}
          {...getFloatingProps()}
        >
          <TypeaheadMenuContainer
            close={closeTypeahead}
            editor={editor}
            options={options}
            onSelectOption={onSelectOption}
            match={match}
            menuRenderFn={menuRenderFn}
            commandPriority={commandPriority}
          />
        </PopoverContainer>
      </PopoverOverlay>
    </FloatingPortal>
  )
}
