import {
  DATA_ATTRIBUTE_ENTITY_ID,
  DATA_ATTRIBUTE_ENTITY_LABEL,
  DATA_ATTRIBUTE_ENTITY_TYPE,
} from './constants'

export function getMentionDomNodeAttributes(domNode: HTMLElement) {
  return {
    entityId: domNode.getAttribute(DATA_ATTRIBUTE_ENTITY_ID),
    entityLabel: domNode.getAttribute(DATA_ATTRIBUTE_ENTITY_LABEL),
    entityType: domNode.getAttribute(DATA_ATTRIBUTE_ENTITY_TYPE),
  }
}
