import { type LooseApiDefinition } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'
import { WorkspaceQueryIncludeSchema } from '@motion/zod/client'

export type QueryReference = {
  query: LooseApiDefinition
  args?: unknown
  staleTime?: number
  gcTime?: number
}

const TEN_MINUTES = 10 * 60 * 1000

const ONE_HOUR = 60 * 60 * 1000

export const prefetchQueries = {
  duringAuth: [],
  postAuth: [
    {
      query: API.workspacesV2.getWorkspaces,
      args: {
        include: WorkspaceQueryIncludeSchema,
      },
      gcTime: Infinity,
      staleTime: ONE_HOUR,
    },
    { query: API.views.getAll, staleTime: TEN_MINUTES },
    { query: API.usersV2.getMySettings, staleTime: ONE_HOUR, gcTime: Infinity },
    { query: API.usersV2.getCurrentUser, staleTime: TEN_MINUTES },
    { query: API.calendars.fetchUncachedCalendarList, staleTime: Infinity },
    {
      query: API.usersV2.getFeaturePermissions,
      staleTime: TEN_MINUTES * 3,
      gcTime: Infinity,
    },
  ],
} satisfies { duringAuth: QueryReference[]; postAuth: QueryReference[] }
