import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import {
  $getRoot,
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isRootNode,
  BLUR_COMMAND,
  COMMAND_PRIORITY_HIGH,
  FOCUS_COMMAND,
} from 'lexical'
import { useCallback, useEffect } from 'react'

const PLACEHOLDER_ATTRIBUTE = 'data-placeholder'

export const ParagraphPlaceholderPlugin = ({
  placeholder,
}: {
  placeholder: string
}) => {
  const [editor] = useLexicalComposerContext()
  const updatePlaceholder = useCallback(
    (event: Event) => {
      editor.getEditorState().read(() => {
        if (editor.isComposing()) {
          return
        }

        const domSelection = getSelection()
        const domAnchorNode = domSelection && domSelection.anchorNode
        const editorRootElement = editor.getRootElement()

        const isSelectionInsideEditor =
          event.type !== 'blur' &&
          domAnchorNode !== null &&
          editorRootElement !== null &&
          editorRootElement.contains(domAnchorNode)

        const topLevelNodes = $getRoot().getChildren()
        const selection = $getSelection()

        for (const node of topLevelNodes) {
          if ($isParagraphNode(node)) {
            const el = editor.getElementByKey(node.getKey())
            el?.removeAttribute(PLACEHOLDER_ATTRIBUTE)
          }
        }

        if ($isRangeSelection(selection) && selection.isCollapsed()) {
          const selectedNodes = selection.getNodes()
          for (const node of selectedNodes) {
            if (!isSelectionInsideEditor || !$isRootNode(node.getParent())) {
              continue
            }
            const el = editor.getElementByKey(node.getKey())
            el?.setAttribute(PLACEHOLDER_ATTRIBUTE, placeholder)
          }
        }
      })
      return false
    },
    [editor, placeholder]
  )

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        BLUR_COMMAND,
        updatePlaceholder,
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        FOCUS_COMMAND,
        updatePlaceholder,
        COMMAND_PRIORITY_HIGH
      )
    )
  }, [editor, updatePlaceholder])

  useEffect(() => {
    document.addEventListener('selectionchange', updatePlaceholder)
    return () => {
      document.removeEventListener('selectionchange', updatePlaceholder)
    }
  }, [updatePlaceholder])

  return null
}
