import { OnChangePlugin as LexicalOnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { $getRoot, type EditorState, type LexicalEditor } from 'lexical'
import { type ComponentProps } from 'react'

export type LexicalOnChangePluginProps = ComponentProps<
  typeof LexicalOnChangePlugin
>

export type OnChangePluginProps = Omit<
  LexicalOnChangePluginProps,
  'onChange'
> & {
  onChange?: LexicalOnChangePluginProps['onChange']
  onChangeValue?: (value: { text: string }) => void
}

export function OnChangePlugin({
  onChange,
  onChangeValue,
  ignoreSelectionChange = false,
  ignoreHistoryMergeTagChange = false,
}: OnChangePluginProps) {
  const handleChange = (
    editorState: EditorState,
    editor: LexicalEditor,
    tags: Set<string>
  ) => {
    if (onChange) {
      onChange(editorState, editor, tags)
    }
    if (onChangeValue) {
      const text = editorState.read(() => {
        const rootNode = $getRoot()
        return rootNode.getTextContent()
      })
      onChangeValue({ text })
    }
  }

  return (
    <LexicalOnChangePlugin
      onChange={handleChange}
      ignoreSelectionChange={ignoreSelectionChange}
      ignoreHistoryMergeTagChange={ignoreHistoryMergeTagChange}
    />
  )
}
