import { type ClientFirebaseSettingsDto } from '@motion/rpc-types'

export const settingsSyncPullKeys = [
  'calendarStartDay',
  'schedules',
  'showCompletedTasks',
  'showAgendaEvents',
  'cta',
  'desktop',
  'theme',
] as const satisfies readonly (keyof ClientFirebaseSettingsDto & string)[]

// Finished KFS - I think these can probably be removed in general,
// but Chris expressed caution and won't be back until next year
// so I'll keep them for now to be safe
//  - Peter
const retiredKfsKeys = [
  'conferenceSettings',
  'reminderSettings',
  'zoomAccessToken',
  'zoomExpiration',
  'zoomNeedsRefresh',
  'onboardingProgress',
  'lastOnboardTimestamp',
  'isOnboardingComplete',
  'didSkipOnboarding',
  'onboardingLatestScreen',
  'onboardingType',
  'onboardingVersion',
  'onboardingExtraData',
  'onboardingCompletedSurvey',
] as const
type RetiredKfsKey = (typeof retiredKfsKeys)[number]

export const settingsSyncDontPushKeys = [
  'showCompletedTasks',
  'showAgendaEvents',
  'cta',
  'desktop',
  'theme',

  ...retiredKfsKeys,
] as const satisfies (keyof ClientFirebaseSettingsDto | RetiredKfsKey)[]

export const storageListenerDontPushKeys = [
  'googleAuth', // [kfs]
  'calendarList', // [kfs]
  'cta',
  'desktop',
  'theme',

  ...retiredKfsKeys,
] as const satisfies (keyof ClientFirebaseSettingsDto | RetiredKfsKey)[]

export const KEYS_TO_PUSH_TO_FIREBASE: (keyof ClientFirebaseSettingsDto)[] =
  settingsSyncPullKeys.filter(
    (x) =>
      !settingsSyncDontPushKeys.includes(x as any) &&
      !storageListenerDontPushKeys.includes(x as any)
  )

export const firstSyncIgnoreKeys =
  [] as const satisfies readonly (keyof ClientFirebaseSettingsDto & string)[]
