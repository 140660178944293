import { type ReactNode } from 'react'

import { ActionList, type ActionListProps } from '../action-list'
import { PopoverTrigger, type PopoverTriggerProps } from '../popover'

export type ActionDropdownProps = Omit<ActionListProps, 'onActionAnyItem'> & {
  placement?: PopoverTriggerProps['placement']
  offset?: PopoverTriggerProps['offset']
  enableOutsideInteractions?: PopoverTriggerProps['enableOutsideInteractions']
  onClose?: PopoverTriggerProps['onClose']
  onActionAnyItem?: ActionListProps['onActionAnyItem']
  children?: ReactNode
}

export function ActionDropdown(props: ActionDropdownProps) {
  const {
    placement = 'bottom-start',
    offset,
    enableOutsideInteractions,
    onClose,
    onActionAnyItem,
    children,
    ...actionListProps
  } = props

  return (
    <PopoverTrigger
      placement={placement}
      offset={offset}
      enableOutsideInteractions={enableOutsideInteractions}
      onClose={onClose}
      renderPopover={({ close }) => (
        <div className='scrollbar-none w-full scroll-py-1 overflow-y-auto overflow-x-hidden max-h-[400px]'>
          <ActionList
            {...actionListProps}
            onActionAnyItem={onActionAnyItem ?? (() => close())}
          />
        </div>
      )}
    >
      {children}
    </PopoverTrigger>
  )
}
