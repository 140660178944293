import { type HocuspocusProviderWebsocket } from '@hocuspocus/provider'
import { createContext, type MutableRefObject } from 'react'

export type EditorContextType = {
  containerRef: MutableRefObject<HTMLDivElement | null>
  floatingAnchorElem: HTMLDivElement | null
  setFloatingAnchorRef: (ref: HTMLDivElement | null) => void
  websocketProvider: HocuspocusProviderWebsocket | null
}

export const EditorContext = createContext<EditorContextType | null>(null)
