import { classed } from '@motion/theme'

export const EditorContainer = classed('div', {
  base: 'relative flex flex-col h-full flex-grow w-full min-h-0 [contain:content] overflow-x-hidden',
})

export const EditorInnerContainer = classed('div', {
  base: 'flex flex-col flex-grow text-[15px] overflow-y-scroll scroll-p-20',
  variants: {
    loading: {
      true: 'opacity-0 pointer-events-none',
      false: 'opacity-100',
    },

    isMobile: {
      true: 'px-6 overflow-x-hidden max-w-full',
      false: '',
    },
  },
})

export const EditorSizeContainer = classed('div', {
  base: 'relative h-full w-full max-w-[min(84ch,100%)] sm:max-w-[min(84ch,100%-136px)]',
})

export const Skeleton = classed('div', {
  base: `absolute text-[15px] animate-pulse after:rounded-md after:bg-semantic-neutral-surface-raised-bg-subtlest w-full after:max-w-[min(84ch,100%-136px)] after:w-full after:h-10 after:mx-auto after:block after:mt-14`,
})
