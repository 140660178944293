import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { type NodeKey } from 'lexical'
import { type ComponentType } from 'react'

import { DELETE_ATTACHMENT_NODE, SET_ATTACHMENT_PREVIEW_NODE } from './commands'
import { type AttachmentComponentProps } from './types'

import { ResizableBlock } from '../embed-plugin/components/resizable-block'

const DEFAULT_WIDTH = 400
const DEFAULT_HEIGHT = 0

interface AttachmentWrapperProps
  extends Omit<AttachmentComponentProps, 'onDelete' | 'onTogglePreview'> {
  nodeKey: NodeKey
  Component: ComponentType<AttachmentComponentProps>
}

export const AttachmentLexicalWrapper = ({
  nodeKey,
  Component,
  ...props
}: AttachmentWrapperProps) => {
  const [editor] = useLexicalComposerContext()

  const handleTogglePreview = () => {
    editor.dispatchCommand(SET_ATTACHMENT_PREVIEW_NODE, {
      nodeKey,
      isPreview: !props.isPreview,
    })
  }

  const handleDelete = () => {
    editor.dispatchCommand(DELETE_ATTACHMENT_NODE, nodeKey)
  }

  if (props.isPreview && !props.isUploading) {
    return (
      <ResizableBlock
        nodeKey={nodeKey}
        initialWidth={props.width || DEFAULT_WIDTH}
        initialHeight={props.height || DEFAULT_HEIGHT}
      >
        <Component
          {...props}
          onDelete={handleDelete}
          onTogglePreview={handleTogglePreview}
        />
      </ResizableBlock>
    )
  }

  return (
    <Component
      {...props}
      onDelete={handleDelete}
      onTogglePreview={handleTogglePreview}
    />
  )
}
