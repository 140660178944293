import { ListNode, type ListType, type SerializedListNode } from '@lexical/list'
import { EditorConfig, LexicalNode, type NodeKey } from 'lexical'

export type SerializedCollapsibleListNode = SerializedListNode

export const TAG_TO_LIST_TYPE: Record<string, ListType> = {
  ol: 'number',
  ul: 'bullet',
}

export class CollapsibleListNode extends ListNode {
  static getType() {
    return 'collapsible-list'
  }

  constructor(listType: ListType, start: number, key?: NodeKey) {
    super(listType, start, key)
  }

  static clone(node: CollapsibleListNode): CollapsibleListNode {
    const listType = node.__listType || TAG_TO_LIST_TYPE[node.__tag]
    return new CollapsibleListNode(listType, node.__start, node.__key)
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config)
    return dom
  }

  updateDOM(prevNode: this, dom: HTMLElement, config: EditorConfig): boolean {
    const update = super.updateDOM(prevNode, dom, config)
    return update
  }

  static importJSON(
    serializedNode: SerializedCollapsibleListNode
  ): CollapsibleListNode {
    return new CollapsibleListNode(
      serializedNode.listType,
      serializedNode.start
    ).updateFromJSON(serializedNode)
  }

  isShadowRoot(): boolean {
    return false
  }

  exportJSON(): SerializedCollapsibleListNode {
    return {
      ...super.exportJSON(),
      type: this.getType(),
      version: 1,
    }
  }
}

export function $createCollapsibleListNode(
  listType: ListType,
  start: number
): CollapsibleListNode {
  return new CollapsibleListNode(listType, start)
}

export function $isCollapsibleListNode(
  node: LexicalNode | null | undefined
): node is CollapsibleListNode {
  return node instanceof CollapsibleListNode
}
