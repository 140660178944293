import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $getNearestBlockElementAncestorOrThrow,
  mergeRegister,
} from '@lexical/utils'
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  $isRootNode,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_EDITOR,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_LEFT_COMMAND,
  KEY_ARROW_RIGHT_COMMAND,
  KEY_ARROW_UP_COMMAND,
  KEY_ENTER_COMMAND,
  type LexicalEditor,
} from 'lexical'
import { useCallback, useEffect } from 'react'

import { $focusEditor } from './utils'

export const ChangeFocusPlugin = (props: { parentEditor: LexicalEditor }) => {
  const { parentEditor } = props
  const [titleEditor] = useLexicalComposerContext()

  const $focusTitleEditor = useCallback(
    (event: KeyboardEvent | null) => {
      const selection = $getSelection()
      if (!$isRangeSelection(selection)) {
        return false
      }
      if (!selection.isCollapsed() || selection.anchor.offset !== 0) {
        return false
      }

      const root = $getRoot()
      const anchorNode = selection.anchor.getNode()

      const elementNode = $isRootNode(anchorNode)
        ? anchorNode
        : $getNearestBlockElementAncestorOrThrow(anchorNode)
      const firstChild = root.getFirstChild()

      if (elementNode === root || elementNode === firstChild) {
        event?.preventDefault()
        $focusEditor(titleEditor, 'end')
        return true
      }

      return false
    },
    [titleEditor]
  )

  const $focusMainEditor = useCallback(
    (event: KeyboardEvent | null) => {
      const selection = $getSelection()
      if (!$isRangeSelection(selection)) {
        return false
      }
      if (
        selection.anchor.offset ===
        selection.anchor.getNode().getTextContentSize()
      ) {
        event?.preventDefault()

        $focusEditor(parentEditor, 'start')

        return true
      }

      return false
    },
    [parentEditor]
  )

  useEffect(() => {
    return mergeRegister(
      parentEditor.registerCommand(
        KEY_ARROW_UP_COMMAND,
        $focusTitleEditor,
        COMMAND_PRIORITY_EDITOR
      ),
      parentEditor.registerCommand(
        KEY_ARROW_LEFT_COMMAND,
        $focusTitleEditor,
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [$focusTitleEditor, parentEditor])

  useEffect(() => {
    return mergeRegister(
      titleEditor.registerCommand(
        KEY_ENTER_COMMAND,
        (event) => {
          event?.preventDefault()
          $focusEditor(parentEditor, 'start')
          return true
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      titleEditor.registerCommand(
        KEY_ARROW_RIGHT_COMMAND,
        $focusMainEditor,
        COMMAND_PRIORITY_EDITOR
      ),
      titleEditor.registerCommand(
        KEY_ARROW_DOWN_COMMAND,
        $focusMainEditor,
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [titleEditor, parentEditor, $focusMainEditor])

  return null
}
