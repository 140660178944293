import {
  type CalendarProviderType,
  type Contact,
  type EmailAccount,
} from '@motion/rpc/types'
import { type EventConferenceType } from '@motion/rpc-types'

import { type EventFormFields } from './form-fields'

import { getConferenceDataForType } from '../../conference'

type HandleGuestsChangeNewValue = {
  contact: Contact | Contact[]
  hostEmailAccount: EmailAccount
  conferenceTypeInSettings?: EventConferenceType
}

export function handleGuestsChange(
  {
    contact,
    hostEmailAccount,
    conferenceTypeInSettings = 'none',
  }: HandleGuestsChangeNewValue,
  {
    attendees,
    conferenceType,
  }: Pick<EventFormFields, 'attendees' | 'conferenceType'>
) {
  const changes = {
    attendees: [...attendees],
    conferenceType,
  }

  let contactList = Array.isArray(contact) ? contact : [contact]

  // Adding the host + contact when the list of attendees was empty initially
  if (attendees.length === 0) {
    // Remove the host from the contact list
    contactList = contactList.filter(
      (contact) => contact.email !== hostEmailAccount.email
    )
    changes.attendees.push({
      email: hostEmailAccount.email,
      displayName: hostEmailAccount.name ?? hostEmailAccount.email,
      isOptional: false,
      isOrganizer: true,
      status: 'accepted',
    })

    if (conferenceType === 'none') {
      changes.conferenceType = getValidConferenceTypeForProvider(
        conferenceTypeInSettings,
        hostEmailAccount.providerType
      )
    }
  }

  contactList.forEach((contact) => {
    const wasAlreadyGuest = changes.attendees.some(
      (attendee) => attendee.email === contact.email
    )

    // Removing the attendee
    if (wasAlreadyGuest) {
      changes.attendees = changes.attendees.filter(
        (attendee) => attendee.email !== contact.email
      )

      return changes
    }

    changes.attendees.push(createContactAttendee(contact))
  })

  return changes
}

function createContactAttendee(contact: Contact, isHost = false) {
  return {
    email: contact.email,
    displayName: contact.displayName ?? contact.email,
    isOptional: false,
    isOrganizer: isHost,
    status: 'needsAction' as const,
  }
}

export function getValidConferenceTypeForProvider(
  conferenceType: EventConferenceType,
  providerType: CalendarProviderType
): EventConferenceType {
  const conferenceData = getConferenceDataForType(conferenceType)

  if (conferenceData.category === 'meet' && providerType !== 'GOOGLE') {
    return 'zoom'
  }

  if (conferenceData.category === 'teams' && providerType !== 'MICROSOFT') {
    return 'zoom'
  }

  return conferenceType
}

export function getValidConferenceTypesForProvider(
  conferenceTypes: EventConferenceType[],
  providerType: CalendarProviderType
): EventConferenceType[] {
  const set = new Set<EventConferenceType>(
    conferenceTypes.map((conferenceType) =>
      getValidConferenceTypeForProvider(conferenceType, providerType)
    )
  )
  return Array.from(set)
}
