import { MotionLogo } from '@motion/icons'

import { type ReactNode } from 'react'

export type LoginScreenProps = {
  children: ReactNode
  iframeUrl?: string
  subtitle: ReactNode
  title: string
}

export const LoginScreen = ({
  children,
  iframeUrl,
  subtitle,
  title,
}: LoginScreenProps) => {
  return (
    <div className='flex h-full w-full relative' data-theme='light'>
      <div className='absolute w-full h-8 electron-drag top-0 left-0' />
      <div
        className={`flex px-6 pt-8 pb-16 items-center justify-center bg-semantic-neutral-bg-default ${
          iframeUrl ? 'w-full lg:w-1/2' : 'w-full'
        }`}
      >
        <div className='flex h-full max-w-[410px] flex-col gap-y-14'>
          <div className='flex items-center gap-2 mb-auto'>
            <MotionLogo className='w-[120px] h-9' />
          </div>
          <div className='flex flex-col w-full'>
            <p className='text-semantic-neutral-text-default text-[32px] mb-4 font-semibold'>
              {title}
            </p>
            <p className='text-semantic-neutral-text-subtle mb-7'>{subtitle}</p>
            <div className='w-full flex flex-col gap-y-3'>{children}</div>
          </div>
          <div className='mt-auto'>
            <p className='text-xs text-semantic-neutral-text-subtle'>
              Having trouble with your account or subscription? Don&apos;t
              worry, just head over to{' '}
              <a
                target='_blank'
                rel='noreferrer'
                href='https://help.usemotion.com'
                className='underline'
              >
                help.usemotion.com
              </a>{' '}
              and our support team will be happy to assist you!
            </p>
          </div>
        </div>
      </div>
      {iframeUrl && (
        <iframe
          src={iframeUrl}
          className='h-full w-1/2 hidden lg:block'
        ></iframe>
      )}
    </div>
  )
}
