import { ExternalLinkOutline, PencilSolid } from '@motion/icons'
import { $createEmbedNode } from '@motion/notes-shared'
import { useOnValueChange } from '@motion/react-core/hooks'
import { Button, IconButton, showToast } from '@motion/ui/base'
import { TextField } from '@motion/ui/forms'

import { $createLinkNode, $isAutoLinkNode, $isLinkNode } from '@lexical/link'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  $findMatchingParent,
  $insertNodeToNearestRoot,
  mergeRegister,
} from '@lexical/utils'
import {
  $createTextNode,
  $getNodeByKey,
  $getSelection,
  $isLineBreakNode,
  $isRangeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  getDOMSelection,
  KEY_ESCAPE_COMMAND,
  type LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'
import {
  type Dispatch,
  type MouseEvent,
  type ReactPortal,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'

import { setFloatingElemPositionForLinkEditor } from './utils'

import { useEditorContext } from '../../context'
import { getSelectedNode } from '../../utils'

const YoutubeUrlRegex =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/

function LinkEditor({
  editor,
  isLink,
  setIsLink,
  anchorElem,
}: {
  editor: LexicalEditor
  isLink: boolean
  setIsLink: Dispatch<boolean>
  anchorElem: HTMLElement
}) {
  const editorRef = useRef<HTMLDivElement | null>(null)

  const [isEditing, setIsEditing] = useState(false)

  const [editedLinkUrl, setEditedLinkUrl] = useState('https://')
  const [editedLinkTitle, setEditedLinkTitle] = useState('')

  const [linkNodeKey, setLinkNodeKey] = useState<string | null>()

  // When clicking on a different link, reset the link editor
  useOnValueChange(linkNodeKey, () => {
    setIsEditing(false)
  })

  useOnValueChange(isLink, (isLink) => {
    if (isLink) {
      editor.getEditorState().read(() => {
        $updateLinkEditorState()
        $updateLinkEditorPosition()
      })
    } else {
      setEditedLinkUrl('')
      setEditedLinkTitle('')
    }
  })

  const $updateLinkEditorState = useCallback(() => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection)
      const linkParent = $findMatchingParent(node, $isLinkNode)

      if (linkParent) {
        setLinkNodeKey(linkParent.getKey())
        setEditedLinkUrl(linkParent.getURL())
        setEditedLinkTitle(linkParent.getTextContent())
      } else if ($isLinkNode(node)) {
        setLinkNodeKey(node.getKey())
        setEditedLinkUrl(node.getURL())
        setEditedLinkTitle(node.getTextContent())
      } else {
        setLinkNodeKey(null)
        setEditedLinkUrl('')
        setEditedLinkTitle('')
      }
    }
  }, [])

  const $updateLinkEditorPosition = useCallback(() => {
    const selection = $getSelection()

    const editorElem = editorRef.current
    const nativeSelection = getDOMSelection(editor._window)
    const activeElement = document.activeElement

    if (editorElem === null) {
      return
    }

    const rootElement = editor.getRootElement()

    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode) &&
      editor.isEditable()
    ) {
      const domRect: DOMRect | undefined =
        nativeSelection.focusNode?.parentElement?.getBoundingClientRect()
      if (domRect) {
        domRect.y += 40
        setTimeout(
          () =>
            setFloatingElemPositionForLinkEditor(
              domRect,
              editorElem,
              anchorElem
            ),
          50
        )
      }
    } else if (
      !activeElement ||
      (activeElement.getAttribute('name') !== 'link-title' &&
        activeElement.getAttribute('name') !== 'link-url')
    ) {
      if (rootElement !== null) {
        setTimeout(
          () =>
            setFloatingElemPositionForLinkEditor(null, editorElem, anchorElem),
          50
        )
      }
    }

    return true
  }, [anchorElem, editor])

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement

    const update = () => {
      editor.getEditorState().read(() => {
        $updateLinkEditorPosition()
      })
    }

    window.addEventListener('resize', update)

    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update)
    }

    return () => {
      window.removeEventListener('resize', update)

      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update)
      }
    }
  }, [anchorElem.parentElement, editor, $updateLinkEditorPosition])

  useEffect(() => {
    return mergeRegister(
      // Update the link editor state when the editor state changes
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateLinkEditorState()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          $updateLinkEditorState()
          $updateLinkEditorPosition()
          return true
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (isLink) {
            setIsLink(false)
            return true
          }
          return false
        },
        COMMAND_PRIORITY_HIGH
      )
    )
  }, [
    editor,
    $updateLinkEditorState,
    $updateLinkEditorPosition,
    setIsLink,
    isLink,
  ])

  const handleSubmit = useCallback(
    (event: MouseEvent<HTMLElement> | KeyboardEvent) => {
      event.preventDefault()
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          const parent = getSelectedNode(selection).getParent()

          if ($isAutoLinkNode(parent) || $isLinkNode(parent)) {
            const newLinkNode = $createLinkNode(editedLinkUrl, {
              rel: parent.getRel(),
              target: parent.getTarget(),
              title: editedLinkTitle,
            })

            newLinkNode.append($createTextNode(editedLinkTitle))

            parent.replace(newLinkNode, false)

            newLinkNode.selectEnd()
          }
        }
      })
    },
    [editor, editedLinkUrl, editedLinkTitle]
  )

  const handleContainerKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (isEditing) {
          handleSubmit(event)
        }
      } else if (event.key === 'Escape') {
        if (isEditing) {
          event.preventDefault()
          setIsEditing(false)
        } else {
          setIsLink(false)
        }
      }
    },
    [handleSubmit, isEditing, setIsEditing, setIsLink]
  )

  const handleTurnIntoEmbed = useCallback(() => {
    editor.update(() => {
      if (!linkNodeKey) {
        return showToast('error', 'Failed to turn link into embed')
      }

      const linkNode = $getNodeByKey(linkNodeKey)

      if ($isLinkNode(linkNode)) {
        const embedNode = $createEmbedNode(editedLinkUrl, 'youtube')
        $insertNodeToNearestRoot(embedNode)
        linkNode.remove()
      }
    })
  }, [editor, linkNodeKey, editedLinkUrl])

  useEffect(() => {
    const editorElem = editorRef.current

    if (editorElem == null) {
      return
    }

    editorElem.addEventListener('keydown', handleContainerKeyDown)

    return () => {
      editorElem.removeEventListener('keydown', handleContainerKeyDown)
    }
  }, [handleContainerKeyDown])

  if (!isLink) {
    return null
  }

  const isInternalLink = editedLinkUrl.startsWith(window.location.origin)
  const isYoutubeLink = YoutubeUrlRegex.test(editedLinkUrl)

  return (
    <div
      ref={editorRef}
      className='absolute top-0 left-0 flex flex-col p-3 gap-3 w-96 border border-dropdown-border rounded bg-dropdown-bg shadow-md opacity-0'
    >
      {!isEditing ? (
        <div className='flex items-center gap-1'>
          <a
            href={editedLinkUrl}
            target={isInternalLink ? undefined : '_blank'}
            rel='noreferrer'
            className='flex-1 truncate text-semantic-neutral-text-subtle'
          >
            {editedLinkUrl}
          </a>
          <IconButton
            icon={PencilSolid}
            size='small'
            variant='muted'
            sentiment='neutral'
            onClick={() => setIsEditing(true)}
          />
          <IconButton
            icon={ExternalLinkOutline}
            size='small'
            variant='muted'
            sentiment='neutral'
            url={editedLinkUrl}
            external={!isInternalLink}
          />
          {isYoutubeLink && (
            <Button
              size='small'
              variant='muted'
              sentiment='neutral'
              onClick={handleTurnIntoEmbed}
            >
              Turn into embed
            </Button>
          )}
        </div>
      ) : (
        <>
          <TextField
            name='link-title'
            value={editedLinkTitle}
            label='Title'
            onChange={(value) => {
              setEditedLinkTitle(value)
            }}
            autoFocus
          />
          <TextField
            name='link-url'
            value={editedLinkUrl}
            label='URL'
            onChange={(value) => {
              setEditedLinkUrl(value)
            }}
          />
          <div className='flex justify-end gap-2'>
            <Button
              size='small'
              onClick={() => setIsEditing(false)}
              variant='muted'
              sentiment='neutral'
            >
              Cancel
            </Button>
            <Button size='small' onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export function LinkEditorPlugin(): ReactPortal | null {
  const { floatingAnchorElem } = useEditorContext()

  const [editor] = useLexicalComposerContext()

  const [isLink, setIsLink] = useState(false)

  useEffect(() => {
    function $updateToolbar() {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const focusNode = getSelectedNode(selection)
        const focusLinkNode = $findMatchingParent(focusNode, $isLinkNode)
        const focusAutoLinkNode = $findMatchingParent(
          focusNode,
          $isAutoLinkNode
        )
        if (!(focusLinkNode || focusAutoLinkNode)) {
          setIsLink(false)
          return
        }
        const badNode = selection
          .getNodes()
          .filter((node) => !$isLineBreakNode(node))
          .find((node) => {
            const linkNode = $findMatchingParent(node, $isLinkNode)
            const autoLinkNode = $findMatchingParent(node, $isAutoLinkNode)
            return (
              (focusLinkNode && !focusLinkNode.is(linkNode)) ||
              (linkNode && !linkNode.is(focusLinkNode)) ||
              (focusAutoLinkNode && !focusAutoLinkNode.is(autoLinkNode)) ||
              (autoLinkNode &&
                (!autoLinkNode.is(focusAutoLinkNode) ||
                  autoLinkNode.getIsUnlinked()))
            )
          })
        if (!badNode) {
          setIsLink(true)
        } else {
          setIsLink(false)
        }
      }
    }
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload) => {
          $updateToolbar()
          return false
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerCommand(
        CLICK_COMMAND,
        (payload) => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection)
            const linkNode = $findMatchingParent(node, $isLinkNode)
            if ($isLinkNode(linkNode) && (payload.metaKey || payload.ctrlKey)) {
              window.open(linkNode.getURL(), '_blank')
              return true
            }
          }
          return false
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        () => {
          if (isLink) {
            setIsLink(false)
            return true
          }
          return false
        },
        COMMAND_PRIORITY_HIGH
      )
    )
  }, [editor, isLink])

  if (floatingAnchorElem == null) return null

  return createPortal(
    <LinkEditor
      editor={editor}
      isLink={isLink}
      anchorElem={floatingAnchorElem}
      setIsLink={setIsLink}
    />,
    floatingAnchorElem
  ) as React.ReactPortal
}
