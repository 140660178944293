import { LexicalTheme } from '@motion/notes-shared'
import { useMemoDeep } from '@motion/react-core/hooks'
import { isArrayEqual } from '@motion/utils/array'

import { AutoLinkNode, LinkNode } from '@lexical/link'
import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import {
  type InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import {
  type EditorState,
  type EditorThemeClasses,
  type LexicalEditor,
} from 'lexical'
import { type ReactNode, type RefObject, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'

import { LexicalAutoLinkPlugin } from './plugins/auto-link-plugin'
import { ReadOnlyPlugin } from './plugins/read-only-plugin'
import { MaxLengthPlugin } from './title-editor/plugins'
import { populateFromHtml } from './utils'

export type BaseEditorProps = {
  name: string
  editorRef?: RefObject<LexicalEditor | null>

  initialValue?: {
    format: 'html' | 'markdown' | 'lexical-state'
    value: string
  }
  placeholder?: ReactNode
  readOnly?: boolean
  autoFocus?: boolean
  maxLength?: number
  label?: string

  onChange?: (
    editorState: EditorState,
    editor: LexicalEditor,
    tags: Set<string>
  ) => void

  theme?: EditorThemeClasses
  nodes?: InitialConfigType['nodes']
  plugins?: ReactNode

  className?: string
  editableClassName?: string
}

export const BaseEditor = ({
  name,
  editorRef,

  initialValue,
  placeholder,
  readOnly,
  autoFocus,
  maxLength,
  label,

  onChange,

  theme,
  nodes: nodesProp = [],
  plugins,

  className,
  editableClassName,
}: BaseEditorProps) => {
  const nodes = useMemoDeep(
    [AutoLinkNode, LinkNode, ...nodesProp],
    isArrayEqual
  )

  const initialConfig = useMemo<InitialConfigType>(
    () => ({
      namespace: name,
      theme: { ...LexicalTheme, ...theme },
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.error(err)
      },
      editorState: (editor) => {
        if (!initialValue?.value) return

        switch (initialValue.format) {
          case 'lexical-state':
            editor.setEditorState(editor.parseEditorState(initialValue.value))
            return
          case 'html':
            populateFromHtml(editor, initialValue.value)
            return
          case 'markdown':
            $convertFromMarkdownString(initialValue.value, TRANSFORMERS)
            return
        }
      },
      editable: !readOnly,
      nodes,
    }),
    [name, theme, readOnly, nodes, initialValue?.format, initialValue?.value]
  )

  return (
    <div className={twMerge('relative', className)}>
      <LexicalComposer initialConfig={initialConfig}>
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              className={twMerge(
                'relative outline-none py-2 text-field-text-default',
                editableClassName
              )}
            />
          }
          placeholder={
            <div className='text-field-text-placeholder absolute top-2 left-0 user-select-none inline-block pointer-events-none'>
              {placeholder}
            </div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        {autoFocus && <AutoFocusPlugin />}
        {onChange != null && <OnChangePlugin onChange={onChange} />}
        {editorRef != null && <EditorRefPlugin editorRef={editorRef} />}
        <ReadOnlyPlugin readOnly={readOnly ?? false} />
        {maxLength != null && (
          <MaxLengthPlugin maxLength={maxLength} label={label} />
        )}
        <LexicalAutoLinkPlugin />
        {plugins}
      </LexicalComposer>
    </div>
  )
}
