import {
  $isCollapsibleListItemNode,
  $isCollapsibleListNode,
} from '@motion/notes-shared'

import {
  $getNearestRootOrShadowRoot,
  $getSelection,
  $isRangeSelection,
  $isRootNode,
  $isRootOrShadowRoot,
  createCommand,
} from 'lexical'

export const REMOVE_LIST_ITEM_COMMAND = createCommand(
  'REMOVE_LIST_ITEM_COMMAND'
)

export function $handleOutdentContentCommand() {
  const selection = $getSelection()

  if (!$isRangeSelection(selection)) {
    return false
  }

  const anchorNode = selection.anchor.getNode()

  if ($isRootNode(anchorNode)) {
    return false
  }

  const listItemNode = $getNearestRootOrShadowRoot(anchorNode)

  if (!$isCollapsibleListItemNode(listItemNode)) {
    return false
  }

  const listNode = listItemNode.getParent()

  if (!$isCollapsibleListNode(listNode)) {
    return false
  }

  const lastListItemNode = listNode.getLastChild()

  if (!$isCollapsibleListItemNode(lastListItemNode)) {
    return false
  }

  const listParentNode = listNode.getParent()

  if (!$isRootOrShadowRoot(listParentNode)) {
    return false
  }

  const newNodes = [...listItemNode.getChildren()]
  const childrenLength = newNodes.length

  // Loop in reverse to maintain order
  for (let j = childrenLength - 1; j >= 0; j--) {
    listItemNode.insertAfter(newNodes[j])
  }

  listItemNode.remove()

  return true
}
