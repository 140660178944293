import fuzzysort from 'fuzzysort'

export type ScoreResult<T> = {
  /**
   * fuzzy search score
   */
  readonly score: number

  /**
   * original object
   */
  readonly obj: T
}

export type FuzzySearchProps<T> = {
  /**
   * Search string
   */
  query: string | undefined

  /**
   * List of objects to search
   */
  items: T[]

  /**
   * List of properties on each object to be used in the search
   */
  keys: string[]

  /**
   * Limit the number of results; defaults to 0 meaning return all results
   */
  limit?: number

  /**
   * Score threshold 1.0 is perfect, 0.0 is no match.
   *
   * Default is 0.1 (higher number increases speed and relevance)
   */
  threshold?: number

  /**
   * Function called with initial score result and related object.
   * You can use this to add additional score (boosting) as needed. defaults to undefined
   */
  scoreFn?: (keysResult: ScoreResult<T>) => number
}

/**
 * Fuzzy search
 * @returns List of objects filtered by match and sorted by relevance score
 */
export function fuzzySearch<T>({
  query,
  items,
  keys,
  limit = 0,
  threshold = 0.1,
  scoreFn = undefined,
}: FuzzySearchProps<T>): T[] {
  if (!query?.trim()) {
    return items
  }

  const results = fuzzysort.go(query, items, {
    threshold,
    limit,
    keys,
    scoreFn: scoreFn,
  })

  return results.map((r) => r.obj)
}

// Fuzzy search matching web's original api for migration
export function filterAndRankMatches<T>(
  query: string | undefined,
  items: T[],
  computeValue: (item: T) => string
): T[] {
  if (!query) return items

  // since we have to apply the computeValue for what to search
  // we apply it before passing to fuzzy search
  const searchItems = items.map((i) => ({
    term: computeValue(i),
    originalObject: i,
  }))

  const results = fuzzysort.go(query, searchItems, {
    threshold: 0,
    keys: ['term'],
  })

  return results.map((r) => r.obj.originalObject)
}

/**
 * Compute search score
 * @returns score 0-1
 *
 * Returns 0 if no value or query,
 * make sure if handle the empty cases in caller
 */
export function computeSearchScore(
  value?: string | null,
  query?: string
): number {
  if (!value || !query) return 0

  return fuzzysort.single(query, value)?.score ?? 0
}
