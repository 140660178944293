import { createEvent } from '@motion/react-core/hooks'
import { createStateKey, useSharedState } from '@motion/react-core/shared-state'

import { type AuthState, type UserLoginState } from './types'

export const UNSET_SIGNOUT = () => Promise.resolve()

export const AuthStateKey = createStateKey<AuthState>('auth', {
  defaultValue: {
    auth: { state: 'authenticating' },
    event: createEvent(),
    signOut: UNSET_SIGNOUT,
  },
})
export const AuthTokenKey = createStateKey<string>('auth-token', {
  defaultValue: '',
})

export const UserLoginStateKey = createStateKey<UserLoginState>('user-login', {
  defaultValue: {
    hasOldAutoScheduledTasks: false,
  },
})

export const useUserLoginState = () => useSharedState(UserLoginStateKey)
