import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'

import { type ReactNode, useState } from 'react'

import { FormModal, type FormModalProps } from './form-modal'

import { TextField } from '../../forms'

export interface ConfirmDeleteModalProps {
  alignment?: 'center' | 'left' | 'right'
  title: ReactNode
  description: ReactNode
  deleteMessage: string
  deleteMessageLabel?: string
  deleteButtonLabel: ReactNode
  onClose: FormModalProps['onClose']
  onConfirm: () => void
}

export const ConfirmDeleteModal = (props: ConfirmDeleteModalProps) => {
  const {
    title,
    description,
    deleteMessage,
    deleteMessageLabel,
    deleteButtonLabel,
    onConfirm,
    onClose,
    alignment = 'center',
  } = props

  const [inputValue, setInputValue] = useState('')

  const canSubmit = deleteMessage.trim() === inputValue.trim()

  return (
    <FormModal
      onClose={onClose}
      submitAction={{
        destructive: true,
        disabled: !canSubmit,
        onAction: onConfirm,
        text: deleteButtonLabel,
      }}
      title={title}
      visible
    >
      <BodyText alignment={alignment}>
        <p className='text-sm'>{description}</p>
        <p className='font-semibold'>
          {templateStr('Confirm by typing {{ value }} below.', {
            value: (
              <span className='text-semantic-error-text-default'>
                {deleteMessageLabel ?? deleteMessage}
              </span>
            ),
          })}
        </p>
        <TextField
          label='Confirmation value'
          labelHidden
          placeholder={deleteMessage}
          onChange={(value) => {
            setInputValue(value)
          }}
          value={inputValue}
        />
      </BodyText>
    </FormModal>
  )
}

const BodyText = classed('div', {
  base: 'flex h-full w-[450px] flex-col gap-4 text-semantic-neutral-text-default',
  variants: {
    alignment: {
      center: 'text-center',
      left: 'text-left',
      right: 'text-right',
    },
  },
})
