import {
  $createEmbedNode,
  $isEmbedNode,
  VIDEO_PLAYER_SEEK_COMMAND,
} from '@motion/notes-shared'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import {
  $createNodeSelection,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  $setSelection,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
} from 'lexical'
import { useEffect } from 'react'

import {
  type EmbedCommandPayload,
  INSERT_CUSTOM_EMBED_COMMAND,
} from '../commands'

export function EmbedPlugin(): null {
  const [editor] = useLexicalComposerContext()
  useEffect(() => {
    function $onDelete() {
      const selection = $getSelection()
      if (!selection) {
        return false
      }

      // Delete the embed node
      selection.getNodes().forEach((node) => {
        if ($isEmbedNode(node)) {
          node.remove()
        }
      })

      return false
    }

    return mergeRegister(
      editor.registerCommand<EmbedCommandPayload>(
        INSERT_CUSTOM_EMBED_COMMAND,
        (payload) => {
          const selection = $getSelection()

          if (!selection || !$isRangeSelection(selection)) {
            return false
          }

          const embedNode = $createEmbedNode(payload.src, payload.srcType)

          selection.insertNodes([embedNode])

          const nodeSelection = $createNodeSelection()
          // Add a node key to the selection.
          nodeSelection.add(embedNode.getKey())
          $setSelection(nodeSelection)

          return true
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        VIDEO_PLAYER_SEEK_COMMAND,
        (payload) => {
          // Seek the video player to the given time.
          const nodes = $getRoot().getChildren()

          // Iterate through the nodes to find the video element
          for (const node of nodes) {
            if ($isEmbedNode(node) && node.getSrcType() === 'video-player') {
              // Replace 'video' with your node type
              // Get the DOM element for the video node
              const element = editor.getElementByKey(node.getKey())

              const videoElement = element?.querySelector('video')

              if (videoElement instanceof HTMLVideoElement) {
                // Scrub the video to the desired time
                videoElement.currentTime = payload
                videoElement.scrollIntoView({
                  behavior: 'instant',
                  block: 'start',
                })
                return true
              }
            }
          }

          return false
        },
        COMMAND_PRIORITY_HIGH
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        $onDelete,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_DELETE_COMMAND,
        $onDelete,
        COMMAND_PRIORITY_LOW
      )
    )
  }, [editor])
  return null
}
