import { $createMentionNode } from '@motion/notes-shared'
import { capitalize } from '@motion/utils/string'
import { Sentry } from '@motion/web-base/sentry'

const NOTE_BASE_PATH = '/web/pm/docs/'

export function parseLink(link: string): [string, string] | null {
  try {
    const parsedUrl = new URL(link)
    const parsedUrlParams = new URLSearchParams(parsedUrl.search)

    // Check its a task link
    if (parsedUrlParams.has('task')) {
      return [parsedUrlParams.get('task') as string, 'task']
    }

    // Check its a project link
    if (parsedUrlParams.has('project')) {
      return [parsedUrlParams.get('project') as string, 'project']
    }

    // Check its a note link
    if (parsedUrl.pathname.startsWith(NOTE_BASE_PATH)) {
      return [parsedUrl.pathname.replace(NOTE_BASE_PATH, ''), 'note']
    }
  } catch (error) {
    Sentry.captureException(new Error(`Docs: Failed to parse link: ${link}`))
  }

  return null
}

export function shouldTransformMentionLink(link: string) {
  return parseLink(link) != null
}

export function transformMentionLink(link: string, label: string) {
  const [entityId, entityType] = parseLink(link) ?? []

  if (entityId == null || entityType == null) {
    Sentry.captureException(
      new Error(`Docs: Failed to transform link: ${link}`)
    )
    return null
  }

  return $createMentionNode(
    entityId,
    label || `${capitalize(entityType)}: ${entityId}`,
    entityType
  )
}
