import { $createCodeNode } from '@lexical/code'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createQuoteNode, $isQuoteNode } from '@lexical/rich-text'
import { $setBlocksType } from '@lexical/selection'
import { mergeRegister } from '@lexical/utils'
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  ParagraphNode,
  type RangeSelection,
} from 'lexical'
import { useEffect } from 'react'

import { FORMAT_QUOTE_COMMAND, INSERT_CODE_BLOCK_COMMAND } from '../commands'

export const CustomFormattingPlugin = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return mergeRegister(
      // Remove formatting of new line
      editor.registerNodeTransform(ParagraphNode, (paragraphNode) => {
        const selection = $getSelection()
        if ($isRangeSelection(selection) && paragraphNode.isEmpty()) {
          $resetFormatting(selection)
        }
      }),
      editor.registerCommand(
        FORMAT_QUOTE_COMMAND,
        () => {
          const selection = $getSelection()
          if (!$isRangeSelection(selection)) {
            return false
          }

          let hasQuoteNode = false
          for (const node of selection.getNodes()) {
            const parent = node.getParent()
            if ($isQuoteNode(parent)) {
              hasQuoteNode = true
              parent.replace($createParagraphNode(), true)
            }
          }
          if (!hasQuoteNode) {
            $setBlocksType(selection, () => $createQuoteNode())
          }
          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        INSERT_CODE_BLOCK_COMMAND,
        () => {
          const selection = $getSelection()

          if ($isRangeSelection(selection)) {
            if (selection.isCollapsed()) {
              $setBlocksType(selection, () => $createCodeNode('javascript'))
            } else {
              const textContent = selection.getTextContent()
              const codeNode = $createCodeNode('javascript')
              selection.insertNodes([codeNode])
              selection.insertRawText(textContent)
            }
          }

          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [editor])

  return null
}

function $resetFormatting(selection: RangeSelection): void {
  for (const format of [
    'bold',
    'underline',
    'strikethrough',
    'italic',
    'highlight',
    'code',
  ] as const) {
    if (selection.hasFormat(format)) {
      selection.toggleFormat(format)
    }
  }
}
