export function getDOMRangeRect(
  nativeSelection: Selection,
  rootElement: HTMLElement
): DOMRect {
  if (nativeSelection.rangeCount === 0) return new DOMRect()
  const domRange = nativeSelection.getRangeAt(0)

  let rect

  if (nativeSelection.anchorNode === rootElement) {
    let inner = rootElement
    let depth = 0
    const MAX_DEPTH = 1000
    while (inner.firstElementChild != null && depth < MAX_DEPTH) {
      inner = inner.firstElementChild as HTMLElement
      depth++
    }
    rect = inner.getBoundingClientRect()
  } else {
    rect = domRange.getBoundingClientRect()
  }

  return rect
}
