import { type MotionId } from '@motion/notes-shared'

import { createCommand } from 'lexical'

export type ListCollapsePayload = MotionId

export const LIST_TOGGLE_COLLAPSE_COMMAND = createCommand<ListCollapsePayload>(
  'LIST_TOGGLE_COLLAPSE_COMMAND'
)

export const LIST_COLLAPSE_COMMAND = createCommand<ListCollapsePayload>(
  'LIST_COLLAPSE_COMMAND'
)

export const LIST_UNCOLLAPSE_COMMAND = createCommand<ListCollapsePayload>(
  'LIST_UNCOLLAPSE_COMMAND'
)
