/**
 * Auto Generated
 */
import { type ClientZod } from '../../../utils/client-transform'
import type * as Server from '../../../server/v2/workspaces/dtos'

// Generated Types

export type WorkspaceQueryIncludeSchema = ClientZod<
  typeof Server.WorkspaceQueryIncludeSchema
>
export const WorkspaceQueryIncludeSchema = [
  'labels',
  'projects',
  'statuses',
  'users',
  'customFields',
  'projectDefinitions',
  'legacyProjectTemplates',
  'stageDefinitions',
  'folders',
  'attachments',
] as const
export type WorkspacesV2GetRequestSchema = ClientZod<
  typeof Server.WorkspacesV2GetRequestSchema
>
export type WorkspacesV2GetRequest = ClientZod<
  typeof Server.WorkspacesV2GetRequest.schema
>
export type WorkspacesV2GetAll = ClientZod<typeof Server.WorkspacesV2GetAll>
export type WorkspacesV2Create = ClientZod<typeof Server.WorkspacesV2Create>
export type WorkspacesV2GetSingle = ClientZod<
  typeof Server.WorkspacesV2GetSingle
>
export type WorkspacesV2GetSingleWithUsers = ClientZod<
  typeof Server.WorkspacesV2GetSingleWithUsers
>
export type WorkspaceV2QueryResponse = ClientZod<
  typeof Server.WorkspaceV2QueryResponse.schema
>
export type WorkspaceV2CreateResponse = ClientZod<
  typeof Server.WorkspaceV2CreateResponse.schema
>
export type WorkspaceV2SingleResponse = ClientZod<
  typeof Server.WorkspaceV2SingleResponse.schema
>
export type WorkspaceV2SingleWithUsersResponse = ClientZod<
  typeof Server.WorkspaceV2SingleWithUsersResponse.schema
>
