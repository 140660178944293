import { ArrowDownSolid, ArrowUpSolid, XSolid } from '@motion/icons'
import { useDelayedUnmount } from '@motion/react-core/hooks'
import { IconButton } from '@motion/ui/base'

import {
  type KeyboardEvent as ReactKeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'

import {
  findMatchedRanges,
  normalizeString,
  selectCurrentMatch,
  updateHighlights,
} from './utils'

import { useEditorContext } from '../../context'

export const SearchBarPlugin = () => {
  const { containerRef } = useEditorContext()

  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const mounted = useDelayedUnmount(isSearchOpen, 150)

  const [matches, setMatches] = useState<Range[]>([])
  const [currentMatchIndex, setCurrentMatchIndex] = useState(-1)
  const [search, setSearch] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)
  const inputContainerRef = useRef<HTMLDivElement>(null)

  const handleClose = useCallback(() => {
    selectCurrentMatch(matches, currentMatchIndex)
    CSS.highlights.clear()
    setIsSearchOpen(false)
    setCurrentMatchIndex(-1)
  }, [currentMatchIndex, matches])

  const highlightMatch = (matchedRanges: Range[], index: number) => {
    const currentRange = matchedRanges[index]
    updateHighlights(matchedRanges, index)
    if (currentRange) {
      currentRange.startContainer.parentElement?.scrollIntoView({
        block: 'center',
      })
    }
    setCurrentMatchIndex(index)
  }

  const navigateMatches = (direction: -1 | 1) => {
    if (matches.length > 0) {
      const nextIndex =
        (currentMatchIndex + direction + matches.length) % matches.length

      highlightMatch(matches, nextIndex)
    }
  }

  const handleSearchInputKeyDown = (e: ReactKeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const direction = e.getModifierState('Shift') ? -1 : 1
      navigateMatches(direction)
    }
  }

  const findMatches = useCallback(
    (query: string, highlightIndex: number) => {
      if (!containerRef.current || !inputContainerRef.current) {
        return
      }

      // Reset current match index and clear all highlights
      CSS.highlights.clear()

      const str = normalizeString(query)

      if (!str) {
        setMatches([])
        return
      }

      const allRanges = findMatchedRanges(str, containerRef.current, [
        inputContainerRef.current,
      ])

      setMatches(allRanges)

      highlightMatch(allRanges, highlightIndex)
    },
    [containerRef]
  )

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'f') {
        if (inputRef.current === document.activeElement) {
          // Allow native search if calling the command twice
          return
        }

        e.preventDefault()

        setIsSearchOpen(true)

        setTimeout(() => {
          findMatches(search, -1)
          if (inputRef.current) {
            inputRef.current.focus()
            inputRef.current.select()
          }
        }, 10)
      }

      if (e.key === 'Escape') {
        handleClose()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [findMatches, handleClose, search])

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    findMatches(query, 0)
    setSearch(query)
  }

  if (!containerRef.current) return null

  return (
    <div
      className='absolute top-4 right-4'
      ref={inputContainerRef}
      style={{
        pointerEvents: isSearchOpen ? 'auto' : 'none',
        opacity: isSearchOpen ? 1 : 0,
        transition: 'opacity 0.15s, transform 0.15s',
        transform: isSearchOpen
          ? 'translateY(0)'
          : 'translateY(calc(-100% - 16px))',
      }}
    >
      {mounted && (
        <div className='border flex items-center w-72 rounded-full bg-semantic-neutral-bg-default shadow focus-within:border-field-border-focus p-0.5'>
          <input
            ref={inputRef}
            type='text'
            value={search}
            onChange={handleSearchInputChange}
            placeholder='Type to search...'
            className='py-1.5 pl-3 pr-1 outline-none rounded-full text-[13px] flex-1 w-full bg-transparent'
            onKeyDown={handleSearchInputKeyDown}
            onBlur={handleClose}
          />

          <div className='text-xs text-semantic-neutral-text-subtle whitespace-nowrap pr-1'>
            {matches.length > 0
              ? `${currentMatchIndex + 1} of ${matches.length}`
              : search.length > 0 && 'Not found'}
          </div>
          <div className='flex items-center shrink-0 pr-2'>
            <IconButton
              icon={ArrowUpSolid}
              sentiment='neutral'
              variant='muted'
              size='small'
              onMouseDown={(e) => {
                e.preventDefault()
              }}
              onClick={(e) => {
                navigateMatches(-1)
              }}
            />
            <IconButton
              icon={ArrowDownSolid}
              sentiment='neutral'
              variant='muted'
              size='small'
              onMouseDown={(e) => {
                e.preventDefault()
              }}
              onClick={(e) => {
                navigateMatches(1)
              }}
            />
            <IconButton
              icon={XSolid}
              sentiment='neutral'
              variant='muted'
              size='small'
              onMouseDown={(e) => {
                e.preventDefault()
              }}
              onClick={handleClose}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export const SearchPlugin = () => {
  const { containerRef } = useEditorContext()

  // Check if Custom Highlight API is supported
  if (!CSS.highlights || !containerRef.current) return null

  return <>{createPortal(<SearchBarPlugin />, containerRef.current)}</>
}
