import { hash } from '../string'

export const USER_COLORS = [
  '#0B9C82',
  '#CF4519',
  '#CD1583',
  '#7957FD',
  '#1EA2CC',
  '#6A943F',
  '#3369BB',
  '#8F33BB',
  '#8C2828',
  '#AB7A30',
]

/**
 * Returns a predefined user color for a given identifier
 * @param identifier - user identifier to generate the color from
 * @returns
 */
// Ref: pmSliceUtils.ts -> pickTeamMemeberColor
export function getUserColor(identifier: string) {
  const h = hash(identifier)
  return USER_COLORS[h % USER_COLORS.length]
}
