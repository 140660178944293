import {
  type Calendar,
  type CalendarEvent,
  CalendarProviderType,
} from '@motion/rpc/types'
import { type CalendarEventSchemaV2 } from '@motion/zod/client'

import { DateTime } from 'luxon'

import { formatTime } from '../utils'

export type CalendarView = 'weekly' | 'daily'
export type CalendarStartDay = 'monday' | 'sunday'

export const calendarStartDayLabels: Record<CalendarStartDay, string> = {
  monday: 'Monday',
  sunday: 'Sunday',
}
export const calendarStartDayOptions = Object.keys(
  calendarStartDayLabels
) as CalendarStartDay[]

/**
 * ColorId 12 is not supported for setting on the calendar event instance for google.
 */
export const disabledGoogleEventColorIds: ColorId[] = ['12']

export const colorIds = [
  '0', // Default
  '1', // Google's Lavender
  '2', // Google's Sage
  '3', // Google's Grape
  '4', // Google's Flamingo
  '5', // Google's Banana
  '6', // Google's Tangerine
  '7', // Google's Peacock
  '8', // Google's Graphite
  '9', // Google's Blueberry
  '10', // Google's Basil
  '11', // Google's Tomato
  '12', // Not supported for Google
] as const

export const orderedColorIds: ColorId[] = [
  '9',
  '1',
  '3',
  '4',
  '11',
  '6',
  '7',
  '10',
  '0',
  '2',
  '5',
  '8',
]

export type ColorId = (typeof colorIds)[number]

export function isColorId(arg: unknown): arg is ColorId {
  return (
    !!arg &&
    typeof arg === 'string' &&
    (colorIds as readonly string[]).includes(arg)
  )
}

export function parseColorId(
  color: string | undefined | null,
  defaultValue: ColorId = '9'
): ColorId {
  return isColorId(color) ? color : defaultValue
}

export const formatTimeSlot = (date: DateTime, timezone?: string | null) => {
  const timeDate = timezone
    ? DateTime.fromMillis(date.toMillis(), { zone: timezone })
    : date

  return formatTime(timeDate, { optionalMinutes: true })
}

function eventIsForCalendar(
  event: Pick<CalendarEvent, 'calendarId'>,
  calendar: Calendar
): boolean {
  return (
    event.calendarId === calendar.id || calendar.providerId === event.calendarId
  )
}

export function calendarHasEditableRole(calendar: Calendar): boolean {
  return calendar.accessRole === 'OWNER' || calendar.accessRole === 'EDITOR'
}

export function isCalendarEventEditable(
  event: Pick<
    CalendarEvent,
    | 'id'
    | 'providerId'
    | 'attendees'
    | 'email'
    | 'calendarId'
    | 'type'
    | 'organizer'
  >,
  calendar: Calendar,
  hostEmail?: string
): boolean {
  const curUserAttendee = event.attendees?.find((a) => a.email === event.email)
  const curUserIsOrganizer = curUserAttendee?.isOrganizer ?? false

  return (
    eventIsForCalendar(event, calendar) &&
    calendarHasEditableRole(calendar) &&
    ((!!hostEmail && event.organizer?.email === hostEmail) ||
      calendar.providerType === CalendarProviderType.APPLE ||
      !event.attendees ||
      !event.attendees.length ||
      curUserIsOrganizer) &&
    event.type !== 'RECURRING_EVENT'
  )
}

export function isHostOfEvent(
  event?: Pick<CalendarEventSchemaV2, 'attendees' | 'organizer' | 'email'>
) {
  if (!event) return false

  return Boolean(
    (event.organizer && event.organizer.email === event.email) ||
      (event.attendees &&
        event.attendees.find(
          (attendee) => attendee.isOrganizer && attendee.email === event.email
        ))
  )
}

export function allOtherAttendeesDeclined(
  attendees: CalendarEventSchemaV2['attendees'] | undefined,
  eventOwnerEmail: string
) {
  const eventOwnerAttendee =
    attendees?.find((attendee) => attendee.email === eventOwnerEmail) || null
  const eventOwnerDeclined = eventOwnerAttendee?.status === 'declined'
  const otherAttendees =
    attendees?.filter((attendee) => attendee.email !== eventOwnerEmail) ?? []

  return eventOwnerDeclined
    ? false
    : otherAttendees.length > 0 &&
        otherAttendees.every((attendee) => attendee.status === 'declined')
}
