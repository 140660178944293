import {
  $applyNodeReplacement,
  DecoratorNode,
  type DOMConversionMap,
  DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  LexicalNode,
  type NodeKey,
  type SerializedLexicalNode,
  type Spread,
} from 'lexical'

import { ParsingContext } from '../../../utils'
import { createElement, pxSize } from '../../../utils/dom'

const DATA_ATTACHMENT_ID_ATTRIBUTE = 'data-lexical-attachment-id'
const DATA_ATTACHMENT_FILENAME_ATTRIBUTE = 'data-lexical-attachment-filename'
const DATA_ATTACHMENT_MIME_TYPE_ATTRIBUTE = 'data-lexical-attachment-mime-type'
const DATA_ATTACHMENT_SIZE_ATTRIBUTE = 'data-lexical-attachment-size'
const DATA_ATTACHMENT_PREVIEW_ATTRIBUTE = 'data-lexical-attachment-preview'
const DATA_ATTACHMENT_WIDTH_ATTRIBUTE = 'data-lexical-attachment-width'
const DATA_ATTACHMENT_HEIGHT_ATTRIBUTE = 'data-lexical-attachment-height'

export type SerializedAttachmentNode = Spread<
  {
    attachmentId: string
    filename: string
    mimeType: string
    size: number
    isPreview: boolean
    width: number | null
    height: number | null
  },
  SerializedLexicalNode
>

export class AttachmentNode<T = void> extends DecoratorNode<T> {
  __attachmentId: string

  __filename: string
  __mimeType: string
  __size: number

  __isUploading: boolean
  __isPreview: boolean

  __width: number | null
  __height: number | null

  render(
    nodeKey: NodeKey,
    attachmentId: string,
    isUploading: boolean,
    isPreview: boolean,
    width: number | null,
    height: number | null
  ): T {
    throw new Error('AttachmentNode.render method not implemented')
  }

  getAttachmentUrl(attachmentId: string): string {
    return `./attachments/${attachmentId}`
  }

  static getType(): string {
    return 'attachment-id'
  }

  static clone(node: AttachmentNode): AttachmentNode {
    return new AttachmentNode(
      node.__attachmentId,
      node.__filename,
      node.__mimeType,
      node.__size,
      node.__isUploading,
      node.__isPreview,
      node.__width,
      node.__height,
      node.__key
    )
  }

  isInline(): boolean {
    return false
  }

  constructor(
    attachmentId: string,
    filename: string,
    mimeType: string,
    size: number,
    isUploading: boolean,
    isPreview: boolean,
    width: number | null = null,
    height: number | null = null,
    key?: NodeKey
  ) {
    super(key)
    this.__attachmentId = attachmentId
    this.__filename = filename
    this.__mimeType = mimeType
    this.__size = size
    this.__isUploading = isUploading
    this.__isPreview = isPreview
    this.__width = width
    this.__height = height
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = document.createElement('div')

    element.classList.add('mb-2')

    return element
  }

  updateDOM(): false {
    return false
  }

  static importDOM(): DOMConversionMap | null {
    return {
      a: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute(DATA_ATTACHMENT_ID_ATTRIBUTE)) {
          return null
        }
        return {
          conversion: $convertAttachmentElement,
          priority: 2,
        }
      },
    }
  }

  private getCommonAttributes(): Record<string, string | undefined | null> {
    return {
      [DATA_ATTACHMENT_ID_ATTRIBUTE]: this.__attachmentId,
      [DATA_ATTACHMENT_FILENAME_ATTRIBUTE]: this.__filename,
      [DATA_ATTACHMENT_MIME_TYPE_ATTRIBUTE]: this.__mimeType,
      [DATA_ATTACHMENT_SIZE_ATTRIBUTE]: String(this.__size),
      [DATA_ATTACHMENT_PREVIEW_ATTRIBUTE]: String(this.__isPreview),
      [DATA_ATTACHMENT_WIDTH_ATTRIBUTE]: pxSize(this.__width),
      [DATA_ATTACHMENT_HEIGHT_ATTRIBUTE]: pxSize(this.__height),
    }
  }

  exportDOM(): DOMExportOutput {
    if (this.__isPreview) {
      const tag = this.__mimeType?.startsWith('video/') ? 'video' : 'img'

      const el = createElement(tag, {
        ...this.getCommonAttributes(),
        width: pxSize(this.__width),
        height: pxSize(this.__height),
        src: this.getAttachmentUrl(this.__attachmentId),
        controls: tag === 'video',
      })

      return { element: el }
    }
    const url = this.getAttachmentUrl(this.__attachmentId) || '#'

    const content =
      ParsingContext.current.mode === 'publish'
        ? createElement('motion-attachment-link', {
            filename: this.__filename,
            size: String(this.__size),
            'mime-type': this.__mimeType,
          })
        : this.__filename

    const element = createElement(
      'a',
      {
        ...this.getCommonAttributes(),
        href: url,
        target: '_blank',
      },
      content
    )

    return { element }
  }

  static importJSON(serializedNode: SerializedAttachmentNode): AttachmentNode {
    return new AttachmentNode(
      serializedNode.attachmentId,
      serializedNode.filename,
      serializedNode.mimeType,
      serializedNode.size,
      false,
      serializedNode.isPreview,
      serializedNode.width,
      serializedNode.height
    ).updateFromJSON(serializedNode)
  }

  exportJSON(): SerializedAttachmentNode {
    return {
      ...super.exportJSON(),
      attachmentId: this.__attachmentId,
      filename: this.__filename,
      mimeType: this.__mimeType,
      size: this.__size,
      isPreview: this.__isPreview,
      width: this.__width,
      height: this.__height,
      type: this.getType(),
      version: 1,
    }
  }

  getIsPreview(): boolean {
    return this.getLatest().__isPreview
  }

  setIsPreview(isPreview: boolean): void {
    const writable = this.getWritable()
    writable.__isPreview = isPreview
  }

  getIsUploading(): boolean {
    return this.getLatest().__isUploading
  }

  setIsUploading(isUploading: boolean): void {
    const writable = this.getWritable()
    writable.__isUploading = isUploading
  }

  setWidth(width: number): void {
    const writable = this.getWritable()
    writable.__width = width
  }

  getWidth(): number | null {
    return this.getLatest().__width
  }

  setHeight(height: number): void {
    const writable = this.getWritable()
    writable.__height = height
  }

  getHeight(): number | null {
    return this.getLatest().__height
  }

  getFilename(): string {
    return this.getLatest().__filename
  }

  getMimeType(): string {
    return this.getLatest().__mimeType
  }

  getSize(): number {
    return this.getLatest().__size
  }

  decorate(): T {
    return this.render(
      this.__key,
      this.__attachmentId,
      this.__isUploading,
      this.__isPreview,
      this.__width,
      this.__height
    )
  }
}

export function $createAttachmentNode(
  attachmentId: string,
  filename: string,
  mimeType: string,
  size: number,
  isUploading: boolean = false,
  isPreview: boolean = false,
  width: number | null = null,
  height: number | null = null
): AttachmentNode {
  return $applyNodeReplacement(
    new AttachmentNode(
      attachmentId,
      filename,
      mimeType,
      size,
      isUploading,
      isPreview,
      width,
      height
    )
  )
}

export function $convertAttachmentElement(
  domNode: HTMLElement
): DOMConversionOutput | null {
  const attachmentId = domNode.getAttribute(DATA_ATTACHMENT_ID_ATTRIBUTE)
  const filename = domNode.getAttribute(DATA_ATTACHMENT_FILENAME_ATTRIBUTE)
  const mimeType = domNode.getAttribute(DATA_ATTACHMENT_MIME_TYPE_ATTRIBUTE)
  const size = JSON.parse(
    domNode.getAttribute(DATA_ATTACHMENT_SIZE_ATTRIBUTE) || '0'
  ) as number
  const isPreview = JSON.parse(
    domNode.getAttribute(DATA_ATTACHMENT_PREVIEW_ATTRIBUTE) || 'false'
  ) as boolean
  const width = JSON.parse(
    domNode.getAttribute(DATA_ATTACHMENT_WIDTH_ATTRIBUTE) || 'null'
  ) as number
  const height = JSON.parse(
    domNode.getAttribute(DATA_ATTACHMENT_HEIGHT_ATTRIBUTE) || 'null'
  ) as number
  if (attachmentId !== null) {
    const node = $createAttachmentNode(
      attachmentId,
      filename || '',
      mimeType || '',
      size,
      false,
      isPreview,
      width,
      height
    )
    return { node }
  }
  return null
}

export function $isAttachmentNode(
  node: LexicalNode | null
): node is AttachmentNode {
  return node instanceof AttachmentNode
}
