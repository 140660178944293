/* c8 ignore start */

import type { QueryFilters, QueryKey } from '@tanstack/react-query'

export function createQueryFilter<TData = any>(
  keys: QueryKey[]
): QueryFilters<TData> {
  return {
    predicate(query) {
      return keys.some((key) => partialMatchKey(query.queryKey, key))
    },
  }
}

/* COPIED FROM https://github.com/TanStack/query/blob/main/packages/query-core/src/utils.ts#L193

/**
 * Checks if key `b` partially matches with key `a`.
 */
export function partialMatchKey(
  fullKey: QueryKey,
  partialKey: QueryKey
): boolean
export function partialMatchKey(fullKey: any, partialKey: any): boolean {
  if (fullKey === partialKey) {
    return true
  }

  if (typeof fullKey !== typeof partialKey) {
    return false
  }

  if (
    fullKey &&
    partialKey &&
    typeof fullKey === 'object' &&
    typeof partialKey === 'object'
  ) {
    return !Object.keys(partialKey).some(
      (key) => !partialMatchKey(fullKey[key], partialKey[key])
    )
  }

  return false
}
