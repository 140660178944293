import { defineMutation } from '@motion/rpc'

import { type RouteTypes } from '../types'

type UpdateThread = RouteTypes<'BetaThreadsController_updateThread'>

export const updateThread = defineMutation<
  UpdateThread['request'],
  UpdateThread['response']
>().using({
  method: 'PATCH',
  uri: (args) => `/v2/beta/threads/${args.id}`,
  body: (args) => args,
})
