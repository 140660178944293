import { NoteSolid, NotesSolid, type SvgIconProps } from '@motion/icons'
import { type COLOR } from '@motion/shared/common'

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { getColorHueValue, PaletteProvider } from '../../../palette'

type NotesColorIconProps = SvgIconProps & {
  color?: COLOR
  multiple?: boolean
}

export const NotesColorIcon = forwardRef<SVGSVGElement, NotesColorIconProps>(
  function NotesIcon(
    { color = 'gray', multiple = false, className, ...etc },
    ref
  ) {
    const classNames = twMerge(
      '!text-palette-highlight-default shrink-0',
      className
    )

    const colorHue = getColorHueValue(color)
    const Icon = multiple ? NotesSolid : NoteSolid

    return (
      <PaletteProvider colorHue={colorHue}>
        <Icon ref={ref} {...etc} className={classNames} />
      </PaletteProvider>
    )
  }
)
