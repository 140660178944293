import { useOnValueChange } from '@motion/react-core/hooks'
import { IconButton } from '@motion/ui/base'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getNearestNodeFromDOMNode } from 'lexical'
import { type SVGProps, useEffect, useState } from 'react'

const TargetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width={14}
    height={14}
    viewBox='0 0 485 485'
    fill='currentColor'
    {...props}
  >
    <path d='M257.5 169.203h-30V227.5h-58.297v30H227.5v58.297h30V257.5h58.297v-30H257.5zM319.317 0v30H455v136.667h30V0zM455 455H319.317v30H485V318.333h-30zM30 318.333H0V485h167.65v-30H30zM0 166.667h30V30h137.65V0H0z' />
  </svg>
)

export const NodeInspector = () => {
  const [editor] = useLexicalComposerContext()

  const [selectedElement, setSelectedElement] = useState<HTMLElement>()
  const [nodeData, setNodeData] = useState<Record<string, any> | null>(null)
  const [inspecting, setInspecting] = useState(false)

  useOnValueChange(inspecting, () => {
    if (!inspecting) return

    if (selectedElement) {
      selectedElement.style.outline = ''
      setSelectedElement(undefined)
    }

    const handleMouseOver = (event: any) => {
      event.target.style.outline = '2px solid red'
    }

    const handleMouseOut = (event: any) => {
      event.target.style.outline = ''
    }

    const handleClick = (event: any) => {
      event.preventDefault()
      event.stopPropagation()
      event.target.style.outline = ''
      setSelectedElement(event.target)
      setInspecting(false)
    }

    const domNode = document.querySelector('.motion-docs-editor')

    if (domNode == null) {
      return
    }

    domNode.addEventListener('mouseover', handleMouseOver)
    domNode.addEventListener('mouseout', handleMouseOut)
    domNode.addEventListener('click', handleClick, true)

    return () => {
      domNode.removeEventListener('mouseover', handleMouseOver)
      domNode.removeEventListener('mouseout', handleMouseOut)
      domNode.removeEventListener('click', handleClick, true)
    }
  })

  useEffect(() => {
    if (selectedElement) {
      // eslint-disable-next-line no-restricted-syntax
      const data = editor.read(() => {
        const node = $getNearestNodeFromDOMNode(selectedElement)

        if (node == null) {
          return null
        }

        const result: Record<string, any> = {}

        Object.keys(node).forEach((key) => {
          if (typeof node[key as keyof typeof node] === 'function') {
            return
          }

          result[key] = node[key as keyof typeof node]
        })

        return result
      })

      setNodeData(data)
    }
  }, [editor, selectedElement])

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex justify-end'>
        <IconButton icon={TargetIcon} onClick={() => setInspecting(true)} />
      </div>
      <table className='text-sm'>
        <thead>
          <tr className='font-semibold border-b border-semantic-neutral-border-default'>
            <td>Property</td>
            <td>Value</td>
          </tr>
        </thead>
        <tbody>
          {Object.entries(nodeData || {}).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
