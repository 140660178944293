import { defineApi } from '@motion/rpc'
import {
  AgentWorkflowV2SingleResponse,
  GetAgentWorkflowsResponse,
} from '@motion/zod/client'

import { queryKeys } from './keys'

// To remove when these endpoints moved from the AI host to the normal backend
declare global {
  const __AI_API_HOST__: string
}

export const getAgentWorkflows = defineApi<
  { workspaceId: string }, // TODO tleunen - missing schema type?
  GetAgentWorkflowsResponse
>().using({
  key: queryKeys.list,
  uri: (args) =>
    `${__AI_API_HOST__}/beta/workspaces/${args.workspaceId}/agents`,
})

export const getAgentWorkflowById = defineApi<
  { workspaceId: string; workflowId: string }, // TODO tleunen - missing schema type?
  AgentWorkflowV2SingleResponse
>().using({
  key: (args) => queryKeys.byId(args.workflowId),
  uri: (args) =>
    `${__AI_API_HOST__}/beta/workspaces/${args.workspaceId}/agents/${args.workflowId}`,
})
