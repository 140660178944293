import { templateStr } from '@motion/react-core/strings'
import {
  ActionList,
  PopoverButton,
  PopoverTrigger,
  SearchableList,
  Tag,
  Tooltip,
} from '@motion/ui/base'
import {
  MAX_PRO_TEAM_INITIAL_SIZE,
  MAX_TIERED_PRICING_SELF_SERVE_SIZE,
  TEAM_BILLING_BUCKETS,
} from '@motion/ui-logic/billing'
import { useHasTreatment } from '@motion/web-common/flags'

import { useEnterpriseLink } from '../hooks'

type BucketSeatsDropdownProps = {
  selectedBucket: number
  onChange: (bucket: number) => void
  minSeats?: number
  overrideMaxSeats?: number
  allowUpsell?: boolean
  disableUnderMemberCount?: number
}

export const BucketSeatsDropdown = ({
  selectedBucket,
  onChange,
  minSeats = Math.min(...TEAM_BILLING_BUCKETS),
  overrideMaxSeats,
  disableUnderMemberCount,
  allowUpsell = true,
}: BucketSeatsDropdownProps) => {
  const shouldUseTieredPricingV1 = useHasTreatment('tiered-pricing-v1')
  const hasTieredPricingExp3 = useHasTreatment('tiered-pricing-exp', {
    treatment: 'exp-3',
  })

  const maxSeats =
    overrideMaxSeats ??
    (shouldUseTieredPricingV1
      ? MAX_TIERED_PRICING_SELF_SERVE_SIZE
      : MAX_PRO_TEAM_INITIAL_SIZE)

  let possibleSeats = [1, ...TEAM_BILLING_BUCKETS].filter(
    (x) => x >= minSeats && x <= maxSeats
  )
  const disableThreshold = shouldUseTieredPricingV1
    ? (disableUnderMemberCount ?? 0)
    : 0

  const showEnterpriseUpsell = allowUpsell && minSeats <= maxSeats

  const openEnterpriseLink = useEnterpriseLink()

  return (
    <PopoverTrigger
      placement='bottom'
      renderPopover={({ close }) => (
        <>
          <SearchableList
            searchable={false}
            items={possibleSeats}
            computeKey={(item) => item.toString()}
            computeSelected={(item) => item === selectedBucket}
            computeDisabled={(item) => disableThreshold > item}
            onSelect={(item) => {
              onChange(item)
              close()
            }}
            renderItem={(item) => {
              return (
                <Tooltip
                  content={
                    disableThreshold > item
                      ? templateStr(
                          'You can’t change to {{item}} {{seats}} because you have more than {{item}} team {{members}}. Remove team members first to reduce seats.',
                          {
                            item,
                            seats: item === 1 ? 'seat' : 'seats',
                            members: item === 1 ? 'member' : 'members',
                          }
                        )
                      : ''
                  }
                  asChild
                >
                  <div className='flex flex-row w-full justify-between'>
                    <span className='text-sm'>
                      {item} {item === 1 ? 'seat' : 'seats'}
                    </span>
                    {shouldUseTieredPricingV1 &&
                      !hasTieredPricingExp3 &&
                      selectedBucket === 1 &&
                      item !== 1 && (
                        <Tag size='small' color='green' variant='subtle'>
                          40% cheaper
                        </Tag>
                      )}
                  </div>
                </Tooltip>
              )
            }}
          />

          {showEnterpriseUpsell &&
            (shouldUseTieredPricingV1 ? (
              <div className='flex flex-col'>
                <ActionList
                  onActionAnyItem={close}
                  items={[
                    {
                      content: (
                        <div className='flex flex-row w-full gap-8 justify-between'>
                          <p className='text-dropdown-item-text-default text-sm'>
                            {templateStr('{{maxSize}}+ seats', {
                              maxSize: maxSeats,
                            })}
                          </p>
                          <Tag size='small' variant='subtle'>
                            Custom pricing
                          </Tag>
                        </div>
                      ),
                      onAction: () => openEnterpriseLink('bucketSeatsDropdown'),
                    },
                  ]}
                />
              </div>
            ) : (
              <div className='flex flex-col border-t border-dropdown-border mt-1'>
                <ActionList
                  onActionAnyItem={close}
                  items={[
                    {
                      content: (
                        <div className='flex flex-col gap-1.5'>
                          <p className='text-dropdown-item-text-default text-sm'>
                            {templateStr(
                              '{{maxSize}}+ seats (Enterprise plan)',
                              {
                                maxSize: maxSeats,
                              }
                            )}
                          </p>
                          <p className='text-semantic-primary-text-default text-xs'>
                            Book a call with our sales team
                          </p>
                        </div>
                      ),
                      onAction: () => openEnterpriseLink('bucketSeatsDropdown'),
                    },
                  ]}
                />
              </div>
            ))}
        </>
      )}
    >
      <PopoverButton className='w-[95px] h-7 text-xs'>
        {selectedBucket} {selectedBucket === 1 ? 'seat' : 'seats'}
      </PopoverButton>
    </PopoverTrigger>
  )
}
