import { type TemplateProjectType } from '@motion/rpc/types'
import {
  type ProjectDefinitionSchema,
  type ProjectSchema,
} from '@motion/zod/client'

export type LegacyFlowProject = ProjectSchema & {
  activeStageDefinitionId: NonNullable<ProjectSchema['activeStageDefinitionId']>
  projectDefinitionId: NonNullable<ProjectSchema['projectDefinitionId']>
}

export type FlowProject = ProjectSchema & {
  activeStageDefinitionId: NonNullable<ProjectSchema['activeStageDefinitionId']>
}

export function isFlowProject(
  project: ProjectSchema | null | undefined
): project is FlowProject {
  return project != null && project.activeStageDefinitionId != null
}

export function isCustomTemplateProject(
  project:
    | Pick<ProjectSchema, 'projectDefinitionId' | 'activeStageDefinitionId'>
    | null
    | undefined
): project is FlowProject {
  return (
    project != null &&
    project.projectDefinitionId == null &&
    project.activeStageDefinitionId != null
  )
}

export function isLegacyFlowProject(
  project: ProjectSchema | null | undefined
): project is LegacyFlowProject {
  return (
    project?.projectDefinitionId != null &&
    project.activeStageDefinitionId != null
  )
}

export function isFlowTemplate(
  template: TemplateProjectType | ProjectDefinitionSchema | null | undefined
): template is ProjectDefinitionSchema {
  return (
    template != null &&
    typeof template === 'object' &&
    'stages' in template &&
    Array.isArray(template.stages)
  )
}
