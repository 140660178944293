import {
  useSharedState,
  useSharedStateSendOnly,
} from '@motion/react-core/shared-state'
import { createUseQueryFn } from '@motion/rpc'
import { API } from '@motion/rpc-definitions'

import { useMutation } from '@tanstack/react-query'

import { SubscriptionStateKey } from './state'
import { buildSubscriptionState } from './utils'

export const useSubscriptionState = () =>
  useSharedState(SubscriptionStateKey)[0]

const useRefreshSubscriptionFn = createUseQueryFn(API.subscriptions.refresh)
export const useGetSubscriptionFn = createUseQueryFn(
  API.subscriptions.getIndividualAndTeamSubscription
)

export const useRefreshSubscription = () => {
  const setSubscription = useSharedStateSendOnly(SubscriptionStateKey)
  const refreshSubscriptionFn = useRefreshSubscriptionFn()
  const getSubscription = useGetSubscriptionFn()

  return useMutation({
    mutationFn: async () => {
      const core = await refreshSubscriptionFn(undefined, {
        staleTime: 0,
        networkMode: 'always',
      })
      const valueResponse = await getSubscription(undefined, {
        staleTime: 0,
        networkMode: 'always',
      })
      return {
        core,
        individualAndTeam: valueResponse,
      }
    },
    onMutate() {
      setSubscription({
        state: 'unset',
      })
    },
    onSuccess(data) {
      setSubscription(buildSubscriptionState(data.individualAndTeam))
    },
    onError(ex) {
      setSubscription({
        state: 'error',
        message: ex instanceof Error ? ex.message : String(ex),
      })
    },
  })
}
