import type { QueryClient, QueryKey } from '@tanstack/react-query'

// TODO: Should only call this on initialization
export function setCacheInfinite(client: QueryClient, key: QueryKey) {
  const queryCache = client.getQueryCache()
  const cacheQuery = queryCache.find({ queryKey: key })

  // Update gcTime to infinite
  if (cacheQuery && cacheQuery.gcTime !== Infinity) {
    cacheQuery.setOptions({ gcTime: Infinity })
  }
}
