import {
  appendModelsToCache,
  isModelCacheKey,
  isResponseV2Shape,
} from '@motion/rpc-cache'
import { type ModelCacheDirective } from '@motion/shared/common'

import {
  type Mutation,
  type Query,
  type QueryClient,
} from '@tanstack/react-query'

type UnknownQuery = Query<unknown, unknown, unknown, readonly unknown[]>
type UnknownMutation = Mutation<unknown, unknown, unknown, unknown>

function hasCacheDirective(
  data: unknown
): data is { meta: { cache?: ModelCacheDirective } } {
  if (data == null) return false
  if (typeof data !== 'object') return false
  if (!('meta' in data)) return false
  if (typeof data.meta !== 'object') return false
  if (data.meta == null) return false

  return 'cache' in data.meta
}

function getCacheDirective(data: unknown): ModelCacheDirective {
  if (!hasCacheDirective(data)) return 'default'
  return data.meta.cache ?? 'default'
}

export function onQuerySuccess(client: QueryClient) {
  return (data: unknown, query: UnknownQuery) => {
    if (isModelCacheKey(query.queryKey)) return
    if (getCacheDirective(data) === 'disable') return
    cacheModels(client, data)
  }
}

export function onMutationSuccess(client: QueryClient) {
  return (
    data: unknown,
    variables: unknown,
    context: unknown,
    mutation: UnknownMutation
  ) => {
    if (getCacheDirective(data) === 'disable') return
    cacheModels(client, data)
  }
}
function cacheModels(client: QueryClient, data: unknown): void {
  if (!isResponseV2Shape(data)) return

  appendModelsToCache(client, data.models)
}
