import { Button } from '@motion/ui/base'

import { type PropsWithChildren } from 'react'

export const ToolbarButton = (
  props: PropsWithChildren<{
    onClick: () => void
    active?: boolean
    title: string
    ariaLabel: string
  }>
) => {
  const { onClick, active, title, ariaLabel } = props
  return (
    <Button
      onClick={onClick}
      iconOnly
      sentiment='neutral'
      variant={active ? 'mutedBg' : 'muted'}
      title={title}
      aria-label={ariaLabel}
      size='small'
    >
      {props.children}
    </Button>
  )
}
