import {
  CollapsibleHeadingNode,
  CollapsibleListItemNode,
} from '@motion/notes-shared'

import { type HocuspocusProviderWebsocket } from '@hocuspocus/provider'
import { type Provider } from '@lexical/yjs'
import { useCallback } from 'react'
import { type Doc } from 'yjs'

import { CollaborationPlugin } from './collaboration-plugin'
import { createWebsocketProvider } from './utils'

import { useEditorContext } from '../../context'

const EXCLUDED_PROPERTIES = new Map()

EXCLUDED_PROPERTIES.set(CollapsibleHeadingNode, new Set(['__open']))
EXCLUDED_PROPERTIES.set(CollapsibleListItemNode, new Set(['__open']))

type WebsocketCollaborationPluginProps = {
  id: string
  userColor?: string
  userDisplayName?: string
  getToken?: () => Promise<string>
  onStatus?: (message: { status: string }) => void
  onSynced?: (message: { state: boolean }) => void
  onVersionError?: () => void
}

type WebsocketCollaborationProps = WebsocketCollaborationPluginProps & {
  websocketProvider: HocuspocusProviderWebsocket
}

export const WebsocketCollaboration = (props: WebsocketCollaborationProps) => {
  const {
    id,
    userColor,
    userDisplayName,
    getToken,
    onStatus,
    onSynced,
    websocketProvider,
    onVersionError,
  } = props

  const providerFactory = useCallback(
    (id: string, yjsDocMap: Map<string, Doc>) => {
      const provider = createWebsocketProvider(
        id,
        yjsDocMap,
        websocketProvider,
        getToken,
        onStatus,
        onSynced,
        onVersionError
      )

      return provider as unknown as Provider
    },
    [getToken, onStatus, onSynced, websocketProvider, onVersionError]
  )

  return (
    <CollaborationPlugin
      id={id}
      providerFactory={providerFactory}
      shouldBootstrap={false}
      cursorColor={userColor}
      username={userDisplayName}
      excludedProperties={EXCLUDED_PROPERTIES}
    />
  )
}

export const WebsocketCollaborationPlugin = (
  props: WebsocketCollaborationPluginProps
) => {
  const { websocketProvider } = useEditorContext()
  return websocketProvider ? (
    <WebsocketCollaboration {...props} websocketProvider={websocketProvider} />
  ) : null
}
