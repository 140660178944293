import {
  ArrowLeftOutline,
  CheckSolid,
  DesktopComputerSolid,
  ShieldCheckSolid,
  StripeLogo,
} from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Button, LoadingSpinner } from '@motion/ui/base'
import {
  type BillingPrices,
  generateBillingFormula,
  INDIVIDUAL_PRICES,
  LOW_COST_TRIAL_PRICE,
  Term,
} from '@motion/ui-logic/billing'
import { getTierTitle, type Tier } from '@motion/ui-logic/tiered-pricing'
import { useHasTreatment } from '@motion/web-common/flags'

import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { BillingPlanSelector } from '../billing-plan-selector'
import { useShouldShowSalesTaxMessage } from '../hooks'
import {
  getTierIconForBilling,
  useGetTierPricesUnauthorized,
} from '../tiered-pricing'

export type CheckoutType =
  | 'add-payment-method'
  | 'resubscribe'
  | 'initial-purchase'

type BillingPaymentInformationProps = {
  userEmail: string
  onChangeEmail?: () => void
}

export const BillingPaymentInformation = ({
  userEmail,
  onChangeEmail,
}: BillingPaymentInformationProps) => {
  return (
    <>
      <p className='my-3 text-semantic-neutral-text-default text-xl font-semibold'>
        Payment information
      </p>
      {onChangeEmail && (
        <div className='bg-semantic-neutral-bg-default rounded-md flex flex-row justify-between items-center px-3 py-2 mb-3 border border-semantic-neutral-border-subtle'>
          <p className='text-semantic-neutral-text-default flex-grow truncate'>
            {userEmail}
          </p>
          <div className='flex-shrink-0'>
            <Button
              sentiment='primary'
              variant='muted'
              onClick={onChangeEmail}
              size='small'
            >
              Change
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

type BillingHeaderProps = {
  term: Term
  trialLength?: number
  isMobile: boolean
}

export const BillingHeader = ({
  term,
  trialLength,
  isMobile,
}: BillingHeaderProps) => {
  return (
    <>
      <p className='text-semantic-neutral-text-default text-2xl font-semibold mb-4'>
        {term === Term.LowCostTrial
          ? `Get 1 month of Motion for $${LOW_COST_TRIAL_PRICE}`
          : trialLength
            ? `Start your ${trialLength}-day free trial${isMobile ? '' : ' of Motion'}`
            : `Start your ${term === Term.Monthly ? 'monthly' : 'annual'} subscription of Motion`}
      </p>
      <p className='text-semantic-neutral-text-default text-sm font-normal mb-6'>
        Start saving 30.3 days every single year.
      </p>
    </>
  )
}

type BillingFooterProps = {
  term: Term
  trialLength?: number
  price: number
  isTeam: boolean
  bucket?: number
}

export const BillingFooter = ({
  term,
  trialLength,
  isTeam,
  bucket,
  price,
}: BillingFooterProps) => {
  const shouldShowSalesTaxMessage = useShouldShowSalesTaxMessage()
  const isLowCostTrial = term === Term.LowCostTrial
  const isAnnual = term !== Term.Monthly

  return (
    <p className='max-w-screen-sm mt-4 mb-0 text-semantic-neutral-text-disabled self-center text-xs font-medium text-center'>
      {templateStr(
        'Cancel anytime on your account page or by emailing/chatting support. Your subscription is {{lctOrBasis}} {{formula}}{{applicableTax}}{{trialDays}}. Your subscription will automatically renew unless cancelled.',
        {
          lctOrBasis: isLowCostTrial
            ? `$${LOW_COST_TRIAL_PRICE} for the first month, and then`
            : `on ${isAnnual ? 'an annual' : 'a monthly'} basis of`,
          formula: generateBillingFormula({
            isTeam,
            isAnnual,
            seats: isTeam ? bucket : undefined,
            monthlyPrice: price,
          }),
          applicableTax: shouldShowSalesTaxMessage
            ? ', plus applicable taxes'
            : '',
          trialDays: trialLength
            ? ` after a ${trialLength}-day free trial`
            : '',
        }
      )}
    </p>
  )
}

type MobileSignupWarningProps = {
  isMobile: boolean
}

export const MobileSignupWarning = ({ isMobile }: MobileSignupWarningProps) => {
  return (
    isMobile && (
      <div className='mt-4 bg-semantic-neutral-bg-active-default rounded p-2'>
        <DesktopComputerSolid
          width={18}
          height={18}
          className='text-semantic-neutral-icon-default'
        />
        <div className='mt-2'>
          <h3 className='text-xs font-medium'>
            Motion is best when used on a computer
          </h3>

          <p className='mt-2 text-xs text-semantic-neutral-text-subtle'>
            If you&apos;re planning to use Motion only on mobile, it may not be
            for you.
          </p>
        </div>
      </div>
    )
  )
}

type BillingCardHoldWarningProps = {
  visible: boolean
}

export const BillingCardHoldWarning = ({
  visible,
}: BillingCardHoldWarningProps) => {
  return (
    visible && (
      <p className='mt-2 mb-0 text-semantic-neutral-text-default text-sm'>
        To verify that your card is valid, we will put a temporary $1 hold on
        your card. The hold will be returned automatically after 7 days.
      </p>
    )
  )
}

export const SafeCheckoutGuarantee = () => {
  return (
    <div className='mt-4 flex flex-row items-center justify-start'>
      <ShieldCheckSolid className='text-semantic-success-bg-strong-default h-5 w-5' />
      <p className='text-semantic-neutral-text-default mb-0 ml-2.5 text-sm font-medium'>
        Guaranteed safe checkout
      </p>
      <div className='bg-semantic-neutral-bg-strong-active ml-4 rounded py-1 px-1.5'>
        <StripeLogo className='text-white' width='70' height='14' />
      </div>
    </div>
  )
}

type SubmitButtonProps = {
  disabled: boolean
  loading: boolean
  onClick: () => void
  trialLength?: number
  isAddPaymentMethod?: boolean
  isLowCostTrial?: boolean
  tierTitle?: string
  isDowngrade?: boolean
  bucket?: number
}

export const SubmitButton = ({
  disabled,
  loading,
  onClick,
  trialLength,
  isAddPaymentMethod,
  isLowCostTrial,
  tierTitle,
  isDowngrade,
  bucket,
}: SubmitButtonProps) => {
  const getButtonText = () => {
    if (isDowngrade) {
      return `Downgrade to ${tierTitle}`
    }
    if (tierTitle && isAddPaymentMethod) {
      return `Upgrade to ${tierTitle}`
    }
    if (isLowCostTrial) {
      return `Get 1 month for $${(LOW_COST_TRIAL_PRICE * (bucket ?? 1)).toFixed(2)}`
    }
    if (isAddPaymentMethod) {
      return 'Add payment information'
    }
    return trialLength ? `Start ${trialLength} day trial` : 'Start subscription'
  }

  return (
    <Button
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      id='submit-subscribe-button'
    >
      {getButtonText()}
    </Button>
  )
}

type BillingPaymentPageProps = {
  paymentElement: React.ReactNode
  annualTrialLength: number
  monthlyTrialLength: number
  chosenTrialLength: number
  term: Term
  setTerm: (term: Term) => void
  lowCostTrialPriceId?: string
  isMobile: boolean
  userEmail: string
  onChangeEmail?: () => void
  hasSelectedCard: boolean
  error: string | null
  isPaymentFilledOut: boolean
  isSubmitting: boolean
  handleSubmit: () => void
  onBack?: () => void
  checkoutType: CheckoutType
  isDowngrade?: boolean
  canHidePaymentMethodElements?: boolean
  minSeats: number
  tier?: Tier
  teamPrices?: BillingPrices
} & (
  | {
      isTeam: false
      bucket?: undefined
      setBucket?: undefined
      teamPrices?: undefined
    }
  | {
      isTeam: true
      teamPrices: BillingPrices
      bucket: number
      setBucket: (bucket: number) => void
    }
)

export const BillingPaymentPage = ({
  paymentElement,
  annualTrialLength,
  monthlyTrialLength,
  chosenTrialLength,
  term,
  setTerm,
  lowCostTrialPriceId,
  isMobile,
  userEmail,
  onChangeEmail,
  hasSelectedCard,
  error,
  isPaymentFilledOut,
  isSubmitting,
  handleSubmit,
  onBack,
  tier,
  checkoutType,
  isDowngrade = false,
  canHidePaymentMethodElements = false,
  minSeats,
  ...teamProps
}: BillingPaymentPageProps) => {
  const [shouldHidePaymentElements, setShouldHidePaymentElements] = useState(
    canHidePaymentMethodElements
  )
  const tieredPricingProAIEnabled = useHasTreatment('tiered-pricing-pro-ai')

  const isAddPaymentMethod = checkoutType === 'add-payment-method'
  const isResubscribe = checkoutType === 'resubscribe'

  const isTeam = teamProps.isTeam
  const prices = isTeam ? teamProps.teamPrices : INDIVIDUAL_PRICES
  const isMonthly = term === Term.Monthly

  const tierTitle = tier ? getTierTitle(tier) : undefined
  const TierIcon = tier
    ? getTierIconForBilling(tier, tieredPricingProAIEnabled)
    : undefined
  const tierIcon = TierIcon != null && <TierIcon />

  const { price: tierPrice, isLoading } = useGetTierPricesUnauthorized(
    isTeam ? teamProps.bucket : 1,
    term,
    tier,
    userEmail
  )

  const price =
    tierPrice ?? (isMonthly ? prices.monthlyPrice : prices.annualPricePerMonth)

  const planName = tier
    ? templateStr('{{tier}} {{icon}}', { tier: tierTitle, icon: tierIcon })
    : isTeam
      ? 'Team Plan'
      : 'Individual Plan'

  const isBucketValid = teamProps.bucket && teamProps.bucket >= minSeats

  const canSubmit = () => {
    if (!isPaymentFilledOut && !shouldHidePaymentElements) {
      return false
    }

    if (isTeam) {
      return isBucketValid
    }

    return true
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <div
      className={twMerge(
        'max-w-screen-md grow border-semantic-neutral-border-default flex flex-col rounded-t-lg lg:rounded-r-none lg:rounded-l-lg',
        onBack ? '' : 'lg:py-8',
        tierTitle
          ? 'p-6 bg-modal-bg'
          : 'p-4 lg:px-12 bg-semantic-neutral-bg-subtle'
      )}
    >
      {onBack && (
        <div className='w-full flex mb-3'>
          <Button
            sentiment='neutral'
            variant='muted'
            size='small'
            onClick={onBack}
          >
            <ArrowLeftOutline className='w-4 h-4 text-semantic-neutral-icon-default' />
            Select plan
          </Button>
        </div>
      )}
      {!isAddPaymentMethod && !tierTitle && (
        <BillingHeader
          term={term}
          trialLength={chosenTrialLength}
          isMobile={isMobile}
        />
      )}
      <BillingPlanSelector
        allowLowCostTrial={!!lowCostTrialPriceId}
        term={term}
        setTerm={setTerm}
        tier={tier}
        savingsPercent={prices.annualSavingsPercentInteger}
        text={planName}
        subText={term === Term.Monthly ? 'Billed monthly' : 'Billed yearly'}
        price={price}
        annualTrialLength={annualTrialLength}
        monthlyTrialLength={monthlyTrialLength}
        chosenTrialLength={chosenTrialLength}
        minSeats={minSeats}
        tierTitle={tierTitle}
        tierIcon={tierIcon}
        {...teamProps}
      />
      <BillingPaymentInformation
        userEmail={userEmail}
        onChangeEmail={onChangeEmail}
      />
      {canHidePaymentMethodElements ? (
        <div
          className={twMerge(
            'flex py-2',
            shouldHidePaymentElements
              ? 'flex-row justify-between'
              : 'flex flex-col-reverse items-end gap-4'
          )}
        >
          {shouldHidePaymentElements ? (
            <p className='text-semantic-neutral-text-default'>
              The card on file will be charged.
            </p>
          ) : (
            <div className='w-full'>{paymentElement}</div>
          )}
          <div>
            <Button
              sentiment='neutral'
              variant='outlined'
              size='small'
              onClick={() => {
                setShouldHidePaymentElements(!shouldHidePaymentElements)
              }}
            >
              {shouldHidePaymentElements
                ? 'Change payment method'
                : 'Use existing payment method'}
            </Button>
          </div>
        </div>
      ) : (
        paymentElement
      )}
      <BillingCardHoldWarning visible={!hasSelectedCard} />
      {!shouldHidePaymentElements && <SafeCheckoutGuarantee />}
      {error && (
        <p className='text-semantic-error-text-default mb-4'>{error}</p>
      )}
      <div className='mt-6 self-center'>
        <SubmitButton
          disabled={!canSubmit()}
          loading={isSubmitting}
          onClick={handleSubmit}
          trialLength={isResubscribe ? 0 : chosenTrialLength}
          isAddPaymentMethod={isAddPaymentMethod}
          isLowCostTrial={term === Term.LowCostTrial}
          tierTitle={tierTitle}
          isDowngrade={isDowngrade}
          bucket={teamProps.bucket}
        />
      </div>
      <BillingFooter
        term={term}
        trialLength={chosenTrialLength}
        price={price}
        bucket={isTeam ? teamProps.bucket : undefined}
        isTeam={isTeam}
      />
      <MobileSignupWarning isMobile={isMobile} />
    </div>
  )
}

const motionFeatures = [
  'Task & Project management',
  'Intelligent schedule builder',
  'Calendar syncing & management',
  'Meeting booking pages',
  '1-click email assistant',
  'Mobile, Web & Desktop apps',
  'API and Integrations',
  'Free upgrades and new features',
  'Customer support',
]

type MotionFeatureProps = {
  text: string
}

const MotionFeature = ({ text }: MotionFeatureProps) => {
  return (
    <div className='mb-3 flex flex-row items-center justify-start gap-x-2'>
      <CheckSolid className='h-5 w-5 text-semantic-primary-bg-strong-default bg-semantic-primary-bg-active-default rounded-full p-0.5' />
      <p className='text-semantic-neutral-text-default mb-0 text-base font-normal'>
        {text}
      </p>
    </div>
  )
}

type BillingFeaturesProps = {
  term: Term
  price?: number
  trialLength?: number
  allowLowCostTrial?: boolean
  hideTimeline?: boolean
  tierTitle?: string
  tierBulletHeader?: string
  tierBullets?: string[]
  bucket?: number
}

export const BillingFeatures = ({
  term,
  price,
  trialLength,
  allowLowCostTrial,
  hideTimeline,
  tierTitle,
  tierBulletHeader,
  tierBullets,
  bucket,
}: BillingFeaturesProps) => {
  const showTimeline =
    !hideTimeline &&
    !!(term === Term.LowCostTrial || trialLength || allowLowCostTrial)
  return (
    <div className='max-w-screen-md flex flex-col rounded-b-lg bg-semantic-neutral-bg-default overflow-hidden lg:rounded-l-none lg:rounded-r-lg lg:w-[390px] lg:border-l'>
      {showTimeline && (
        <BillingTimeline
          term={term}
          trialLength={trialLength}
          allowLowCostTrial={allowLowCostTrial}
          fullPrice={price}
          bucket={bucket}
        />
      )}
      <div className='p-6 overflow-y-auto'>
        {tierTitle ? (
          <p className='text-semantic-neutral-text-default mb-5 text-base font-semibold'>
            Motion {tierTitle} includes:
          </p>
        ) : (
          <p className='text-semantic-neutral-text-default mb-5 text-base font-semibold'>
            What&apos;s included with Motion:
          </p>
        )}
        {tierBulletHeader && (
          <p className='text-sm text-semantic-neutral-text-subtle pb-3'>
            {tierBulletHeader}
          </p>
        )}
        {tierBullets ? (
          <ul className='flex flex-col gap-1.5 pb-2'>
            {tierBullets.map((item) => (
              <li key={item} className='flex pb-1 gap-1.5 place-content-start'>
                <CheckSolid className='text-semantic-primary-icon-default h-4 w-4 shrink-0 mt-[2px]' />
                <span className='text-semantic-neutral-text-subtle mb-0 text-sm font-normal'>
                  {item}
                </span>
              </li>
            ))}
          </ul>
        ) : (
          motionFeatures.map((feature) => (
            <MotionFeature key={feature} text={feature} />
          ))
        )}
      </div>
    </div>
  )
}

type BillingTimelineProps = {
  term: Term
  trialLength?: number
  allowLowCostTrial?: boolean
  fullPrice?: number
  bucket?: number
}

type TimelineStep = { text: string; day: number }

export const BillingTimeline = ({
  term,
  trialLength,
  allowLowCostTrial,
  fullPrice,
  bucket,
}: BillingTimelineProps) => {
  const isLowCostTrial = term === Term.LowCostTrial

  let step1Height: number
  let step1Text: string
  let step2: TimelineStep | undefined
  let step3: TimelineStep | undefined

  if (isLowCostTrial) {
    step1Height = 113
    step1Text = `Your first month of $${(LOW_COST_TRIAL_PRICE * (bucket ?? 1)).toFixed(2)} begins. You can cancel your subscription anytime on your account page, or by emailing or chatting with support.`
    step2 = {
      text: 'We will send you an email reminder that your first month of subscription ends in two weeks.',
      day: 16,
    }
    step3 = {
      text: 'Your subscription at regular pricing starts. You can cancel your subscription anytime on your account page, or by emailing or chatting with support.',
      day: 30,
    }
  } else if (trialLength && allowLowCostTrial) {
    step1Height = 134
    step1Text = `Your trial begins free for ${trialLength} days. You can cancel your trial anytime on your account page, or by emailing or chatting with support, and you’ll not be charged.`
    step2 =
      trialLength - 2 > 0
        ? {
            text: 'We will send you an email reminder that your trial is about to expire.',
            day: trialLength - 2,
          }
        : undefined
    step3 = {
      text: 'Your subscription starts when your trial ends. You can cancel your subscription anytime on your account page, or by emailing or chatting with support.',
      day: trialLength,
    }
  } else if (trialLength) {
    step1Height = 68
    step1Text = `Your trial begins free for ${trialLength} days`
    step2 =
      trialLength - 2 > 0
        ? {
            text: 'We will send you an email reminder that your trial is about to expire',
            day: trialLength - 2,
          }
        : undefined
    step3 = {
      text: 'Your subscription starts when your trial ends',
      day: trialLength,
    }
  } else if (term === Term.Monthly && fullPrice) {
    step1Height = 88
    step1Text = `Your subscription ($${fullPrice * (bucket ?? 1)}/mo) starts. You can cancel your subscription anytime on your account page, or by emailing or chatting with support.`
  } else {
    return null
  }

  return (
    <div className='flex bg-semantic-blue-bg-default py-6 px-4'>
      <div className='flex flex-col items-center mr-3'>
        <CheckSolid className='h-5 w-5 bg-semantic-primary-bg-strong-default text-semantic-neutral-bg-default rounded-full p-0.5' />
        {step2 && (
          <>
            <div
              className='w-0.5 bg-semantic-primary-border-active'
              style={{ height: step1Height - 20 }}
            />
            <div className='h-5 w-5 rounded-full border-2 border-semantic-primary-border-active bg-semantic-neutral-bg-default' />
          </>
        )}
        {step3 && (
          <>
            <div className='h-[72px] w-0.5 bg-semantic-primary-border-active' />
            <div className='h-5 w-5 rounded-full border-2 border-semantic-primary-border-active bg-semantic-neutral-bg-default' />
          </>
        )}
      </div>
      <div>
        <div style={{ height: step1Height }}>
          <p className='font-bold mb-1'>Today:</p>
          <p>{step1Text}</p>
        </div>
        {step2 && (
          <div className='h-[92px]'>
            <p className='font-bold mb-1'>Day {step2.day}:</p>
            <p>{step2.text}</p>
          </div>
        )}
        {step3 && (
          <div>
            <p className='font-bold mb-1'>Day {step3.day}:</p>
            <p>{step3.text}</p>
          </div>
        )}
      </div>
    </div>
  )
}
