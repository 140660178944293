import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $createParagraphNode, $getRoot, $isParagraphNode } from 'lexical'
import { useEffect } from 'react'

export function TrailingEmptyLinePlugin() {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const root = $getRoot()
        const lastChild = root.getLastChild()
        const isEmpty = root.isEmpty()

        if (isEmpty) {
          return
        }
        // Ensure the last node is a paragraph node
        if (!lastChild || !$isParagraphNode(lastChild)) {
          editor.update(() => {
            const newParagraph = $createParagraphNode()
            root.append(newParagraph)
          })
        }
      })
    })
  }, [editor])

  return null
}
