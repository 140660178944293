import { makeLog } from '@motion/web-base/logging'

import { type QueryCacheNotifyEvent } from '@tanstack/react-query'
import {
  type AsyncStorage,
  experimental_createPersister,
  type PersistedQuery,
} from '@tanstack/react-query-persist-client'

import { DB } from '../storage/db'

const log = makeLog('query-persister')

const queryStorage = createIndexedDBStorage()
const BUSTER = ''

export function createPerQueryPersister(prefix: string) {
  return experimental_createPersister({
    buster: BUSTER,
    prefix,
    storage: queryStorage,

    serialize: (data) => data,
    deserialize: (data) => data,
  })
}

export function createIndexedDBStorage(): AsyncStorage<PersistedQuery> {
  return {
    async getItem(key) {
      return log.time(
        'get',
        async () => {
          await DB.open()
          return DB.queryCache.get(key)
        },
        (value) => {
          return `[${value === null ? 'cache-miss' : 'cache-hit'}] ${key}`
        }
      )
    },
    async setItem(key, value) {
      return log.time(
        'set',
        async () => {
          await DB.open()
          return DB.queryCache.set(key, value)
        },
        () => key
      )
    },
    async removeItem(key) {
      return log.time(
        'remove',
        async () => {
          await DB.open()
          return DB.queryCache.delete(key)
        },
        () => key
      )
    },
  }
}

export function handleManualCacheEvents(e: QueryCacheNotifyEvent) {
  if (e.query.options.persister == null) return
  if (e.type === 'updated' && e.action.type === 'success' && e.action.manual) {
    log('manual', e.query.queryKey)

    // should probably be debounced
    queryStorage.setItem(`query-${e.query.queryHash}`, {
      state: e.query.state,
      queryKey: e.query.queryKey,
      queryHash: e.query.queryHash,
      buster: BUSTER,
    })
  }
}
