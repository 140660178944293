import { type IDBPDatabase } from 'idb'

import { recordToSentry } from './error-reporting'
import { log } from './log'
import { openDatabaseWithTimeout } from './open-db'
import { type MotionDBConfig } from './types'

import { DB_VERSION } from '../db/constants'

type MotionIndexedDB = IDBPDatabase<MotionDBConfig>

type DbConnectionOptions = {
  closeOnIdle: number | false
}

export class DbConnection {
  readonly name: string
  readonly options: DbConnectionOptions

  private _timeoutHandle: NodeJS.Timeout | undefined
  private _openPromise: Promise<MotionIndexedDB> | undefined

  private _db: MotionIndexedDB | undefined

  constructor(
    name: string,
    opts: DbConnectionOptions = { closeOnIdle: 5_000 }
  ) {
    this.name = name
    this.options = opts
  }

  public async exec<T>(
    executor: (db: MotionIndexedDB) => Promise<T>
  ): Promise<T> {
    const db = this._db ?? (await this.open())
    return executor(db)
  }

  get version(): number {
    return DB_VERSION
  }

  get state(): 'open' | 'closed' {
    return this._db == null ? 'closed' : 'open'
  }

  public async open() {
    this.resetIdleTimeout()
    if (this._db != null) return this._db
    if (this._openPromise) return this._openPromise
    this._openPromise = log.time('opened', () => {
      return openDatabaseWithTimeout(this.name)
        .catch(recordToSentry('open'))
        .then((local) => {
          local.addEventListener('close', () => this.onClose())

          local.onversionchange = (ev) => {
            log('versionchange', ev.oldVersion, ev.newVersion)
          }
          local.onabort = (ev) => {
            log.warn('abort', ev)
            recordToSentry('abort')(new Error('IndexedDB connection aborted. '))
            this.close()
          }
          return local
        })
    })

    try {
      this._db = await this._openPromise
    } finally {
      this._openPromise = undefined
    }

    return this._db
  }

  public close() {
    if (this._db == null) return
    this._db.close()
    this.onClose()
  }

  private onClose() {
    if (this._db == null) return
    log('closing')
    if (this._timeoutHandle) {
      clearTimeout(this._timeoutHandle)
      this._timeoutHandle = undefined
    }
    this._db = undefined
  }

  private resetIdleTimeout() {
    if (this._timeoutHandle) {
      clearTimeout(this._timeoutHandle)
    }
    if (this.options.closeOnIdle === false) return

    this._timeoutHandle = setTimeout(() => {
      if (this._db == null) return
      this.close()
    }, this.options.closeOnIdle)
  }
}
