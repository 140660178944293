declare global {
  interface Window {
    $parsingContext?: ParsingContext
  }
}

export type ParsingContext = {
  mode: 'default' | 'publish'
}

export const ParsingContext = {
  get current(): ParsingContext {
    return window.$parsingContext ?? { mode: 'default' }
  },
}
