import {
  BASE_ORDERED_TIERS,
  BASE_RECOMMENDED_TIER,
  basicTier,
  businessAITier,
  businessPlusTier,
  businessTier,
  enterpriseTier,
  proAITier,
  proPlusTier,
  proTier,
} from '@motion/ui-logic/tiered-pricing'
import { useExperiment, useHasTreatment } from '@motion/web-common/flags'

export const useGetOrderedTiers = () => {
  const tieredPricingExp2Enabled = useHasTreatment('tiered-pricing-exp', {
    treatment: 'exp-2',
  })
  const tieredPricingExp3Enabled = useHasTreatment('tiered-pricing-exp', {
    treatment: 'exp-3',
  })
  const tieredPricingProAIEnabled = useHasTreatment('tiered-pricing-pro-ai')

  // For the AI launch, we'll just have the two AI tiers
  if (tieredPricingProAIEnabled) {
    return [proAITier, businessAITier, enterpriseTier]
  }

  if (tieredPricingExp2Enabled) {
    return [basicTier, proTier, proPlusTier, enterpriseTier]
  }

  if (tieredPricingExp3Enabled) {
    return [basicTier, proTier, businessTier, businessPlusTier, enterpriseTier]
  }

  return BASE_ORDERED_TIERS
}

export const useGetRecommendedTier = (bucketSeats: number) => {
  const tieredPricingExp = useExperiment('tiered-pricing-exp').value
  const tieredPricingProAIEnabled = useHasTreatment('tiered-pricing-pro-ai')

  if (tieredPricingProAIEnabled) {
    return bucketSeats > 1 ? businessAITier : proAITier
  }

  if (tieredPricingExp === 'exp-1') {
    return bucketSeats === 1 ? basicTier : BASE_RECOMMENDED_TIER
  }

  if (tieredPricingExp === 'exp-3') {
    return businessTier
  }

  return BASE_RECOMMENDED_TIER
}
