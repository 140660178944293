import { createDOMRange, createRectsFromDOMRange } from '@lexical/selection'
import type { LexicalEditor } from 'lexical'
import { $getSelection, $isRangeSelection } from 'lexical'
import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

type InlineCreateHoverCardProps = PropsWithChildren<{
  editor: LexicalEditor
  anchorElement: HTMLDivElement
}>

export const InlineCreateHoverCard = ({
  editor,
  anchorElement,
  children,
}: InlineCreateHoverCardProps) => {
  const boxRef = useRef<HTMLDivElement>(null)

  const selectionState = useMemo(
    () => ({
      container: document.createElement('div'),
      elements: [],
    }),
    []
  )

  const [open, setOpen] = useState(false)

  useEffect(() => {
    requestAnimationFrame(() => {
      setOpen(true)
    })
  }, [])

  const updateLocation = useCallback(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()

      if ($isRangeSelection(selection)) {
        const anchor = selection.anchor
        const focus = selection.focus
        const range = createDOMRange(
          editor,
          anchor.getNode(),
          anchor.offset,
          focus.getNode(),
          focus.offset
        )
        const boxElem = boxRef.current
        const anchorRect = anchorElement.getBoundingClientRect()

        if (range !== null && boxElem !== null) {
          const { left, bottom } = range.getBoundingClientRect()
          const selectionRects = createRectsFromDOMRange(editor, range)

          boxElem.style.left = `${left - anchorRect.left}px`
          boxElem.style.top = `${bottom - anchorRect.top - 20}px`

          const selectionRectsLength = selectionRects.length
          const { container } = selectionState
          const elements: Array<HTMLSpanElement> = selectionState.elements
          const elementsLength = elements.length

          for (let i = 0; i < selectionRectsLength; i++) {
            const selectionRect = selectionRects[i]
            let elem: HTMLSpanElement = elements[i]
            if (elem === undefined) {
              elem = document.createElement('span')
              // eslint-disable-next-line react-compiler/react-compiler
              elements[i] = elem
              container.appendChild(elem)
            }

            elem.classList.add('note-comment-selection')

            const style = `
              top:${selectionRect.top - anchorRect.top}px;
              left:${selectionRect.left - anchorRect.left}px;
              height:${selectionRect.height}px;
              width:${selectionRect.width}px;
            `
            elem.style.cssText = style
          }
          for (let i = elementsLength - 1; i >= selectionRectsLength; i--) {
            const elem = elements[i]
            container.removeChild(elem)
            elements.pop()
          }
        }
      }
    })
  }, [anchorElement, editor, selectionState])

  useLayoutEffect(() => {
    updateLocation()
    const container = selectionState.container

    anchorElement.appendChild(container)
    return () => {
      anchorElement.removeChild(container)
    }
  }, [anchorElement, open, selectionState.container, updateLocation])

  useEffect(() => {
    window.addEventListener('resize', updateLocation)

    return () => {
      window.removeEventListener('resize', updateLocation)
    }
  }, [updateLocation])

  return (
    <div className='absolute inset-0'>
      <div className='flex absolute shadow-md rounded' ref={boxRef}>
        {open && children}
      </div>
    </div>
  )
}
