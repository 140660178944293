import { useState } from 'react'

export function useStatefulRef<T>(initialValue: T): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(initialValue)

  const setRef = (_value: T) => {
    if (_value !== null) {
      setValue(_value)
    }
  }

  return [value, setRef]
}
