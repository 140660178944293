import { CheckSolid, TrashSolid, XSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { classed, type VariantProps } from '@motion/theme'
import { addComponentName } from '@motion/ui/helpers'
import { formatTimeRange } from '@motion/ui-logic'

import { DateTime } from 'luxon'
import { forwardRef } from 'react'

import { IconButton } from '../../base'

export interface AvailabilityEventProps extends VariantProps<typeof Event> {
  startTime: Date
  endTime: Date
  onRemove?: () => void
  onClickPreferred?: () => void
}

export const AvailabilityEvent = forwardRef<
  HTMLDivElement,
  AvailabilityEventProps
>(function CalendarEvent(props, ref) {
  const {
    startTime,
    endTime,
    onRemove,
    onClickPreferred,
    preferred = false,
    ...rest
  } = props

  const startTimeISO = startTime.toISOString()
  const endTimeISO = endTime.toISOString()

  const height = '100%'

  return (
    <Event
      ref={ref}
      role='button'
      {...addComponentName('AvailabilityEvent')}
      {...rest}
      style={{ height, minHeight: onClickPreferred ? 55 : 20 }}
      preferred={preferred}
    >
      <Content>
        <TextContainer>
          <Time>
            {formatTimeRange(
              DateTime.fromISO(startTimeISO),
              DateTime.fromISO(endTimeISO)
            )}
          </Time>
          {onClickPreferred != null && (
            <a
              className='px-1 [&>[data-icon]]:w-3 [&>[data-icon]]:h-3 [&>[data-icon]]:inline'
              onClick={() => {
                onClickPreferred()
              }}
            >
              {templateStr('{{iconAction}} Preferred time', {
                iconAction: preferred ? <XSolid /> : <CheckSolid />,
              })}
            </a>
          )}
        </TextContainer>
        {onRemove != null && (
          <div className='hidden group-hover:block'>
            <IconButton
              sentiment='neutral'
              variant='muted'
              size='xsmall'
              icon={TrashSolid}
              onClick={() => onRemove()}
            />
          </div>
        )}
      </Content>
    </Event>
  )
})

const Event = classed('div', {
  base: `
  group
  min-w-full
  rounded
  relative
  border
  `,
  variants: {
    preferred: {
      true: `
        bg-calendar-event-semantic-blue-bg-default
        border-calendar-event-semantic-blue-border-free
        text-calendar-event-semantic-blue-text-default
        [&>[data-icon]]:text-calendar-event-semantic-blue-text-default
      `,
      false: `
        bg-calendar-event-semantic-gray-bg-default
        border-calendar-event-semantic-gray-border-free
        text-calendar-event-semantic-gray-text-default
        [&>[data-icon]]:text-calendar-event-semantic-gray-text-default
      `,
    },
  },
  defaultVariants: {
    preferred: false,
  },
})

const Content = classed('div', 'grid grid-cols-[1fr_auto] gap-1 px-1 text-xs')
const TextContainer = classed('div', 'flex flex-col gap-1 min-w-0')
const Time = classed('div', `font-bold truncate`)
