import { CheckSolid, ChevronDownOutline, ChevronUpOutline } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { Button, GradientButton, LoadingSpinner } from '@motion/ui/base'
import { isEnterpriseEmail } from '@motion/ui-logic'
import { BASIC_TIER_SEAT_LIMIT, Term } from '@motion/ui-logic/billing'
import {
  basicTier,
  enterpriseTier,
  getBucketDelta,
  getExperimentalTierBullets,
  getExperimentalTierDescription,
  getTermDelta,
  getTierBulletHeader,
  getTierDelta,
  getTierTitle,
  type Tier,
  type TierDisplayMode,
} from '@motion/ui-logic/tiered-pricing'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { useAuthenticatedUser } from '@motion/web-common/auth'
import { useExperiment, useHasTreatment } from '@motion/web-common/flags'

import { useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { getTierIconForBilling } from './icons'

import { useEnterpriseLink } from '../../hooks'
import { WorkEmailModal } from '../../work-email-modal'
import {
  useGetOrderedTiers,
  useGetRecommendedTier,
  useGetTierPrice,
  useGetTierPricesUnauthorized,
} from '../hooks'

export interface FeatureTierCardsProps {
  mode: TierDisplayMode
  bucket: number
  term: Term
  currentBucket?: number
  currentTerm?: Term
  lowCostAmount?: number
  annualTrialLength?: number
  monthlyTrialLength?: number
  onSelectFeatureTier: (tier: Tier, term: Term, bucket: number) => void
  currentTier?: Tier
  hiddenTiers?: Tier[]
}

export const FeatureTierCards = ({
  mode,
  bucket,
  term,
  currentBucket,
  currentTerm,
  lowCostAmount,
  annualTrialLength = 0,
  monthlyTrialLength = 0,
  onSelectFeatureTier,
  currentTier,
  hiddenTiers,
}: FeatureTierCardsProps) => {
  const [showWorkEmailModal, setShowWorkEmailModal] = useState(false)
  const user = useAuthenticatedUser()
  const openEnterpriseLink = useEnterpriseLink()

  const tieredPricingExpValue = useExperiment('tiered-pricing-exp').value

  const filteredOrderedTiers = useGetOrderedTiers()
  const recommendedTier = useGetRecommendedTier(bucket)

  const shownTiers = filteredOrderedTiers.filter(
    (tier) => !hiddenTiers?.includes(tier)
  )

  const shouldGroupCards = shownTiers.length > 4

  function onSelectEnterpriseTier() {
    if (isEnterpriseEmail(user.email)) {
      openEnterpriseLink('tier-card', user.email, user.email, user.displayName)
      return
    }
    setShowWorkEmailModal(true)
  }

  function onSubmitWorkEmail(newEmail: string, onError: () => void) {
    if (isEnterpriseEmail(newEmail)) {
      openEnterpriseLink('tier-card', newEmail, newEmail, user.displayName)
      setShowWorkEmailModal(false)
    } else {
      onError()
    }
  }

  return (
    <div
      className={twMerge(
        'flex sm:flex-row w-full mx-auto p-6 justify-center items-stretch',
        shouldGroupCards ? '' : 'gap-4'
      )}
    >
      {shownTiers.map((tier) => {
        const isEnterpriseTier = tier === enterpriseTier

        return (
          <BaseTierCard
            key={tier}
            tier={tier}
            currentTier={currentTier}
            currentBucket={currentBucket}
            currentTerm={currentTerm}
            mode={mode}
            description={getExperimentalTierDescription(
              tier,
              tieredPricingExpValue
            )}
            term={term}
            numSeats={bucket}
            lowCostAmount={lowCostAmount}
            annualTrialLength={annualTrialLength}
            monthlyTrialLength={monthlyTrialLength}
            bulletHeader={getTierBulletHeader(tier, filteredOrderedTiers)}
            bulletItems={getExperimentalTierBullets(
              tier,
              tieredPricingExpValue
            )}
            onClick={() => {
              if (isEnterpriseTier) {
                onSelectEnterpriseTier()
                return
              }
              onSelectFeatureTier(tier, term, bucket)
            }}
            disabled={tier === basicTier && bucket > BASIC_TIER_SEAT_LIMIT}
            recommended={tier === recommendedTier}
            rounded={!shouldGroupCards}
          />
        )
      })}
      <WorkEmailModal
        isOpen={showWorkEmailModal}
        onClose={() => setShowWorkEmailModal(false)}
        onAction={onSubmitWorkEmail}
      />
    </div>
  )
}

export type BaseTierCardProps = {
  tier: Tier
  currentTier?: Tier
  currentBucket?: number
  currentTerm?: Term
  mode: TierDisplayMode
  description: string
  bulletHeader: string
  bulletItems: string[]
  onClick: () => void
  numSeats?: number
  term: Term
  lowCostAmount?: number
  annualTrialLength?: number
  monthlyTrialLength?: number
  termText?: string
  recommended?: boolean
  disabled?: boolean
  collapsible?: boolean
  mobileEmail?: string
  rounded?: boolean
}

export const BaseTierCard = ({
  tier,
  currentTier,
  currentBucket,
  currentTerm,
  mode,
  description,
  bulletHeader,
  bulletItems,
  onClick,
  numSeats,
  term,
  lowCostAmount,
  annualTrialLength,
  monthlyTrialLength,
  recommended = false,
  disabled = false,
  collapsible = false,
  mobileEmail,
  rounded = true,
}: BaseTierCardProps) => {
  const tieredPricingProAIEnabled = useHasTreatment('tiered-pricing-pro-ai')

  const tierTitle = getTierTitle(tier)
  const TierIconComponent = getTierIconForBilling(
    tier,
    tieredPricingProAIEnabled
  )
  const tierIcon = TierIconComponent != null && <TierIconComponent />
  const tierDelta = currentTier && getTierDelta(currentTier, tier)
  const termDelta = currentTerm && getTermDelta(currentTerm, term)
  const bucketDelta =
    currentBucket && numSeats && getBucketDelta(currentBucket, numSeats)
  const [isCollapsed, setIsCollapsed] = useState(collapsible)
  const { price: perSeatPriceAuthorized } = useGetTierPrice(
    numSeats ?? 0,
    term,
    tier,
    !!mobileEmail
  )
  const { price: perSeatPriceUnauthorized } = useGetTierPricesUnauthorized(
    numSeats ?? 0,
    term,
    tier,
    mobileEmail
  )
  const perSeatPrice = perSeatPriceAuthorized ?? perSeatPriceUnauthorized

  const termText = term === Term.Monthly ? 'monthly' : 'annually'
  const isButtonDisabled =
    disabled ||
    (tierDelta === 'no_change' &&
      termDelta === 'no_change' &&
      bucketDelta === 'no_change')

  const getButtonText = () => {
    if (disabled) {
      return `Not available for ${numSeats}+ seats`
    }
    if (tier === enterpriseTier) {
      return 'Contact us'
    }
    if (tierDelta === 'no_change' && mode === 'resubscribe') {
      return `Re-subscribe to ${tierTitle}`
    }
    if (tierDelta === 'no_change') {
      if (termDelta === 'upgrade') {
        return `Switch to ${termText} (40% less)`
      }
      if (termDelta === 'downgrade') {
        return `Switch to ${termText} (67% more)`
      }
      if (bucketDelta === 'upgrade') {
        return `Upgrade to ${numSeats} seats`
      }
      if (bucketDelta === 'downgrade') {
        return `Downgrade to ${numSeats} seats`
      }
      return 'Continue with Current Plan'
    }
    if (tierDelta === 'upgrade') {
      return `Upgrade to ${tierTitle}`
    }
    if (tierDelta === 'downgrade') {
      return `Downgrade to ${tierTitle}`
    }
    if (
      (term === Term.Annual && !annualTrialLength) ||
      (term === Term.Monthly && !monthlyTrialLength)
    ) {
      return 'Continue'
    }
    return templateStr(`Try Motion {{tierTitle}} for {{amount}} {{perSeat}}`, {
      tierTitle,
      amount:
        lowCostAmount && term === Term.LowCostTrial
          ? `$${lowCostAmount}`
          : 'free',
      perSeat:
        term === Term.LowCostTrial && numSeats && numSeats > 1
          ? 'per seat'
          : '',
    })
  }

  const onClickWithAnalytics = () => {
    onClick()
    recordAnalyticsEvent('BILLING_TIER_CARD_CLICKED', {
      tier: tier,
      term: term,
      numSeats: numSeats,
    })
  }

  const getResubscribeMessage = () => {
    if (mode !== 'resubscribe' && mode !== 'teamUpgrade') {
      return null
    }

    if (tier === enterpriseTier) {
      return (
        <p className='px-2 text-center text-sm text-semantic-success-text-default'>
          Best fit for 10+ teams with advanced needs
        </p>
      )
    }

    if (tierDelta === 'no_change') {
      return (
        <p className='px-2 text-center text-sm text-semantic-success-text-default'>
          You’ll have access to all features you’ve been using
        </p>
      )
    }

    if (tierDelta === 'downgrade') {
      return (
        <p className='px-2 text-center text-sm text-semantic-error-text-default'>
          You’ll lose access to some of the features you’ve been using
        </p>
      )
    }
    return null
  }

  return (
    <div
      className={twMerge(
        'relative flex flex-col h-auto gap-3 overflow-visible p-3 bg-semantic-neutral-bg-default flex-1',
        mobileEmail ? 'w-full' : 'max-w-[384px]',
        recommended
          ? 'rounded-b-lg border-x-2 border-b-2 border-semantic-gradient-purple'
          : rounded
            ? 'border border-semantic-neutral-border-subtle'
            : 'border-y first:border-l border-r border-semantic-neutral-border-subtle',
        rounded ? 'rounded-lg' : 'first:rounded-l-lg last:rounded-r-lg'
      )}
      data-testid={`tier-card-${tier.toLowerCase()}`}
    >
      {disabled && (
        <div className='absolute inset-0 bg-semantic-neutral-bg-default opacity-50 pointer-events-none' />
      )}
      {recommended ? (
        <div>
          <div className='absolute rounded-t-lg -top-6 -inset-x-[2px] h-8 bg-purple-gradient flex pt-1 justify-center'>
            <p className='text-sm font-semibold text-primitives-global-white'>
              Recommended
            </p>
          </div>
          <div
            className={twMerge(
              'absolute top-0 mx-[2px] -inset-x-[2px] h-3 bg-semantic-neutral-bg-default',
              rounded && 'rounded-t-lg'
            )}
          />
        </div>
      ) : (
        <div />
      )}

      <div className='flex flex-row gap-2'>
        <p className='font-bold text-lg text-semantic-neutral-text-default'>
          {tierTitle}
        </p>
        {tierIcon}
      </div>

      <p className='text-sm text-semantic-neutral-text-subtle min-h-6 '>
        {description}
      </p>

      {perSeatPrice ? (
        <div>
          <span className='text-semantic-neutral-text-subtle text-sm font-normal'>
            {disabled ? (
              <span className='text-semantic-neutral-text-default text-xl font-bold pr-1'>
                N/A
              </span>
            ) : (
              templateStr('{{price}} per seat per month', {
                price: (
                  <span
                    className='text-semantic-neutral-text-default text-2xl font-medium pr-1'
                    data-testid='tier-card-price'
                  >
                    ${perSeatPrice}
                  </span>
                ),
              })
            )}
          </span>
        </div>
      ) : (
        <div>
          <span className='text-semantic-neutral-text-default text-2xl font-medium pr-1'>
            {tier === enterpriseTier ? (
              'Custom Pricing'
            ) : (
              <LoadingSpinner size={24} />
            )}
          </span>
        </div>
      )}

      {disabled && (
        <p className='text-sm text-semantic-neutral-text-subtle'>
          {getButtonText()}
        </p>
      )}

      {recommended ? (
        <GradientButton
          fullWidth
          sentiment='purple'
          onClick={onClickWithAnalytics}
          disabled={isButtonDisabled}
        >
          {getButtonText()}
        </GradientButton>
      ) : (
        <Button
          fullWidth
          onClick={onClickWithAnalytics}
          sentiment='neutral'
          disabled={isButtonDisabled}
        >
          {getButtonText()}
        </Button>
      )}

      {getResubscribeMessage()}

      {(!collapsible || !isCollapsed) && (
        <div className='flex mb-3 flex-col gap-2'>
          <p className='text-sm text-semantic-neutral-text-subtle font-bold'>
            {bulletHeader}
          </p>
          <ul className='flex flex-col gap-1'>
            {bulletItems.map((item) => (
              <li key={item} className='flex gap-2'>
                <CheckSolid className='text-semantic-primary-icon-default h-4 w-4 shrink-0 mt-[2px]' />
                <span className='text-semantic-neutral-text-subtle mb-0 text-sm font-normal'>
                  {item}
                </span>
              </li>
            ))}
          </ul>
          {collapsible && (
            <div
              onClick={() => setIsCollapsed(true)}
              onKeyDown={(e) => e.key === 'Enter' && setIsCollapsed(true)}
              role='button'
              tabIndex={0}
              className='w-full px-2 py-1 flex flex-row gap-1.5 justify-center items-center text-button-neutral-muted-text-default'
            >
              Hide features <ChevronUpOutline className='size-4' />
            </div>
          )}
        </div>
      )}
      {isCollapsed && (
        <div
          onClick={() => setIsCollapsed(false)}
          onKeyDown={(e) => e.key === 'Enter' && setIsCollapsed(false)}
          role='button'
          tabIndex={0}
          className='w-full px-4 pb-2 flex flex-row gap-1.5 justify-center items-center text-button-neutral-muted-text-default'
        >
          See what's included <ChevronDownOutline className='size-4' />
        </div>
      )}
    </div>
  )
}
