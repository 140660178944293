import {
  $isCollapsibleListNode,
  type CollapsibleListItemNode,
} from '@motion/notes-shared'

import {
  $createParagraphNode,
  $isDecoratorNode,
  $isElementNode,
  type LexicalNode,
} from 'lexical'

export function $handleCollapsibleListItemNodeTransform(
  node: CollapsibleListItemNode
) {
  const nodes = node.getChildren()

  const notInline = (node: LexicalNode) =>
    ($isElementNode(node) || $isDecoratorNode(node)) && !node.isInline()

  if (!$isCollapsibleListNode(node.getParent())) {
    node.remove()
  } else if (node.isEmpty()) {
    const paragraph = $createParagraphNode()
    node.append(paragraph)
    paragraph.selectStart()
  } else if (!nodes.some(notInline)) {
    const paragraph = $createParagraphNode()

    paragraph.append(...nodes)

    node.append(paragraph)

    if (nodes.some((node) => node.isSelected())) {
      paragraph.selectStart()
    }
  }
}
