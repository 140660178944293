import {
  $isCollapsibleHeadingContentNode,
  $isCollapsibleHeadingNode,
  $isCollapsibleListItemNode,
  $isCollapsibleListNode,
  type CollapsibleHeadingNode,
  type CollapsibleListItemNode,
  LIST_UNCOLLAPSE_COMMAND,
} from '@motion/notes-shared'

import { $findMatchingParent } from '@lexical/utils'
import { $getSelection, $isRangeSelection, type LexicalEditor } from 'lexical'

export function $handleSelectionUpdate(editor: LexicalEditor) {
  return function () {
    const toggleMotionId = editor.getEditorState().read(() => {
      const selection = $getSelection()

      if (!$isRangeSelection(selection)) {
        return
      }

      const node = selection.anchor.getNode()

      const parentCollapsibleListNode = $findMatchingParent(
        node,
        $isCollapsibleListNode
      )

      const parentCollapsibleHeadingContent = $findMatchingParent(
        node,
        $isCollapsibleHeadingContentNode
      )

      let toggleNode: CollapsibleHeadingNode | CollapsibleListItemNode

      if (parentCollapsibleListNode != null) {
        const parentCollapsibleListItemNode =
          parentCollapsibleListNode.getParent()

        if (!$isCollapsibleListItemNode(parentCollapsibleListItemNode)) {
          return
        }

        const triggerCollapsibleListItemNode =
          parentCollapsibleListItemNode.getPreviousSibling()

        if (!$isCollapsibleListItemNode(triggerCollapsibleListItemNode)) {
          return
        }

        toggleNode = triggerCollapsibleListItemNode
      } else if (parentCollapsibleHeadingContent != null) {
        const parentCollapsibleHeadingNode =
          parentCollapsibleHeadingContent.getPreviousSibling()

        if (!$isCollapsibleHeadingNode(parentCollapsibleHeadingNode)) {
          return
        }

        toggleNode = parentCollapsibleHeadingNode
      } else {
        return
      }

      return toggleNode != null && !toggleNode.getOpen()
        ? toggleNode.getMotionId()
        : null
    })

    if (toggleMotionId != null) {
      editor.dispatchCommand(LIST_UNCOLLAPSE_COMMAND, toggleMotionId)
    }
  }
}
