import {
  $createMarkNode,
  $getMarkIDs,
  $isMarkNode,
  $unwrapMarkNode,
  $wrapSelectionInMarkNode,
  MarkNode,
} from '@lexical/mark'
import { type InitialConfigType } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister, registerNestedElementResolver } from '@lexical/utils'
import type { NodeKey } from 'lexical'
import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_EDITOR,
  getDOMSelection,
} from 'lexical'
import {
  forwardRef,
  type ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { createPortal } from 'react-dom'

import { INSERT_INLINE_COMMENT_COMMAND } from './commands'
import { CommentHoverCard } from './comment-hover-card'

import { useEditorContext } from '../../context'

export type CommentsPluginProps = {
  activeThreadId?: string
  setActiveThreadId?: (activeID: string | undefined) => void
  setDocumentThreadMap?: (threadMap: Map<string, Set<NodeKey>>) => void
  createComment: (args: {
    bodyHtml: string
    mentions: Array<string>
    threadId?: string
  }) => Promise<
    | {
        threadId?: string | null
      }
    | undefined
  >
  editorNodes?: InitialConfigType['nodes']
  editorPlugins?: ReactNode
}

export type CommentsPluginHandle = {
  deleteThread: (threadId: string) => void
}

export const CommentsPlugin = forwardRef<
  CommentsPluginHandle,
  CommentsPluginProps
>((props, ref) => {
  const {
    activeThreadId,
    setActiveThreadId,
    createComment,
    setDocumentThreadMap,
    editorNodes,
    editorPlugins,
  } = props
  const { floatingAnchorElem } = useEditorContext()

  const [anchorRect, setAnchorRect] = useState<DOMRect>()
  const [editor] = useLexicalComposerContext()

  const markNodeMap = useMemo<Map<string, Set<NodeKey>>>(() => {
    return new Map()
  }, [])
  const [, setActiveAnchorKey] = useState<NodeKey | null>()
  const [showCommentInput, setShowCommentInput] = useState(false)

  const submitAddComment = useCallback(
    async (bodyHtml: string, mentions: Array<string>) => {
      const thread = await createComment({ bodyHtml, mentions })
      const id = thread?.threadId

      if (!id) return

      const selection = editor.getEditorState().read(() => {
        const selection = $getSelection()
        return selection !== null ? selection.clone() : null
      })

      editor.update(() => {
        if ($isRangeSelection(selection)) {
          const isBackward = selection.isBackward()

          // Wrap content in a MarkNode
          $wrapSelectionInMarkNode(selection, isBackward, id)
        }
      })
      setShowCommentInput(false)
    },
    [createComment, editor]
  )

  const deleteThread = useCallback(
    (threadId: string) => {
      // Remove ids from associated marks
      const markNodeKeys = markNodeMap.get(threadId)
      if (markNodeKeys !== undefined) {
        // Do async to avoid causing a React infinite loop
        setTimeout(() => {
          editor.update(() => {
            for (const key of markNodeKeys) {
              const node: null | MarkNode = $getNodeByKey(key)
              if ($isMarkNode(node)) {
                node.deleteID(threadId)
                if (node.getIDs().length === 0) {
                  $unwrapMarkNode(node)
                }
              }
            }
          })
        })
      }
    },
    [editor, markNodeMap]
  )

  useImperativeHandle(ref, () => {
    return {
      deleteThread,
    }
  }, [deleteThread])

  useEffect(() => {
    const changedElems: Array<HTMLElement> = []

    if (activeThreadId) {
      const keys = markNodeMap.get(activeThreadId)
      if (keys !== undefined) {
        for (const key of keys) {
          const elem = editor.getElementByKey(key)
          if (elem !== null) {
            elem.classList.add('note-comment-mark-active')
            elem.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
            })
            changedElems.push(elem)
          }
        }
      }
    }
    return () => {
      for (let i = 0; i < changedElems.length; i++) {
        const changedElem = changedElems[i]
        changedElem.classList.remove('note-comment-mark-active')
      }
    }
  }, [editor, markNodeMap, activeThreadId])

  useEffect(() => {
    const markNodeKeysToIDs: Map<NodeKey, Array<string>> = new Map()

    return mergeRegister(
      registerNestedElementResolver<MarkNode>(
        editor,
        MarkNode,
        (from: MarkNode) => {
          return $createMarkNode(from.getIDs())
        },
        (from: MarkNode, to: MarkNode) => {
          // Merge the IDs
          const ids = from.getIDs()
          ids.forEach((id) => {
            to.addID(id)
          })
        }
      ),
      editor.registerMutationListener(
        MarkNode,
        (mutations) => {
          editor.getEditorState().read(() => {
            for (const [key, mutation] of mutations) {
              const node: null | MarkNode = $getNodeByKey(key)
              let ids: NodeKey[] = []

              if (mutation === 'destroyed') {
                ids = markNodeKeysToIDs.get(key) || []
              } else if ($isMarkNode(node)) {
                ids = node.getIDs()
              }

              for (let i = 0; i < ids.length; i++) {
                const id = ids[i]
                let markNodeKeys = markNodeMap.get(id)
                markNodeKeysToIDs.set(key, ids)

                if (mutation === 'destroyed') {
                  if (markNodeKeys !== undefined) {
                    markNodeKeys.delete(key)
                    if (markNodeKeys.size === 0) {
                      markNodeMap.delete(id)
                    }
                  }
                } else {
                  if (markNodeKeys === undefined) {
                    markNodeKeys = new Set()
                    markNodeMap.set(id, markNodeKeys)
                  }
                  if (!markNodeKeys.has(key)) {
                    markNodeKeys.add(key)
                  }
                }
              }
            }
          })
          setDocumentThreadMap?.(new Map(markNodeMap))
        },
        { skipInitialization: false }
      ),
      editor.registerUpdateListener(({ editorState, tags }) => {
        editorState.read(() => {
          const selection = $getSelection()
          let hasActiveIds = false
          let hasAnchorKey = false

          if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode()

            if ($isTextNode(anchorNode)) {
              const commentIDs = $getMarkIDs(
                anchorNode,
                selection.anchor.offset
              )
              if (commentIDs !== null && commentIDs.length > 0) {
                setActiveThreadId?.(commentIDs[0])
                hasActiveIds = true
              }
              if (!selection.isCollapsed()) {
                setActiveAnchorKey(anchorNode.getKey())
                hasAnchorKey = true
              }
            }
          }
          if (!hasActiveIds) {
            setActiveThreadId?.(undefined)
          }
          if (!hasAnchorKey) {
            setActiveAnchorKey(null)
          }
          if (!tags.has('collaboration') && $isRangeSelection(selection)) {
            setShowCommentInput(false)
          }
        })
      }),
      editor.registerCommand(
        INSERT_INLINE_COMMENT_COMMAND,
        () => {
          const domSelection = getDOMSelection(editor._window)
          if (domSelection !== null) {
            domSelection.removeAllRanges()
          }
          setShowCommentInput(true)
          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [editor, markNodeMap, setActiveThreadId, setDocumentThreadMap])

  useEffect(() => {
    if (!floatingAnchorElem) return

    const observer = new ResizeObserver(() => {
      setAnchorRect(floatingAnchorElem.getBoundingClientRect())
    })

    observer.observe(floatingAnchorElem)

    return () => {
      observer.disconnect()
    }
  }, [floatingAnchorElem, editor])

  if (!floatingAnchorElem || !anchorRect || !showCommentInput) return null

  return (
    <>
      {createPortal(
        <CommentHoverCard
          editor={editor}
          onSubmitComment={submitAddComment}
          anchorElement={floatingAnchorElem}
          editorNodes={editorNodes}
          editorPlugins={editorPlugins}
        />,
        floatingAnchorElem
      )}
    </>
  )
})

CommentsPlugin.displayName = 'CommentsPlugin'

// Show comments on the side, keeping code here since we might want this soon

// type CommentsPanelProps = {
//   activeIDs: string[]
//   markNodeMap: Map<string, Set<string>>
//   anchorElementRect: DOMRect
// }

// const CommentsPanel = (props: CommentsPanelProps) => {
//   const { activeIDs, markNodeMap, anchorElementRect } = props
//   return Array.from(markNodeMap.entries()).map(([id, keys]) => (
//     <ThreadPannel
//       key={id}
//       id={id}
//       keys={keys}
//       anchorElementRect={anchorElementRect}
//     />
//   ))
// }

// type ThreadPannelProps = {
//   id: string
//   keys: Set<string>
//   anchorElementRect: DOMRect
// }
// const ThreadPannel = (props: ThreadPannelProps) => {
//   const { id, keys, anchorElementRect } = props
//   const [editor] = useLexicalComposerContext()
//   const [top, setTop] = useState(0)
//   useEffect(() => {
//     let pos = Infinity
//     for (const key of keys) {
//       const elem = editor.getElementByKey(key)
//       if (!elem) continue
//       const { top } = elem.getBoundingClientRect()
//       if (top <= pos) {
//         pos = top
//       }
//       setTop(pos - anchorElementRect.top)
//     }
//   }, [editor, keys, anchorElementRect])

//   return (
//     <div
//       className='absolute bg-red-500 -right-2 translate-x-full'
//       style={{ top }}
//     >
//       {id}
//     </div>
//   )
// }
