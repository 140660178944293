import {
  $isCollapsibleHeadingContentNode,
  $isCollapsibleListItemNode,
  $isCollapsibleListNode,
  $isInsideCollapsedListItem,
} from '@motion/notes-shared'

import { $findTableNode, $isTableNode } from '@lexical/table'
import { $dfs, calculateZoomLevel } from '@lexical/utils'
import {
  $getNodeByKey,
  $isDecoratorNode,
  $isElementNode,
  $isRootNode,
  type LexicalEditor,
  type LexicalNode,
  type NodeKey,
} from 'lexical'

import { Point } from './point'
import { Rectangle } from './rect'

const DRAG_HANDLE_LEFT_POS = -30
const DRAG_HANDLE_GUTTER = 68
const TARGET_LINE_HALF_HEIGHT = 2

const Downward = 1
const Upward = -1
const Indeterminate = 0

let prevIndex = Infinity

function getCurrentIndex(keysLength: number): number {
  if (keysLength === 0) {
    return Infinity
  }
  if (prevIndex >= 0 && prevIndex < keysLength) {
    return prevIndex
  }

  return Math.floor(keysLength / 2)
}

function isNodeDraggable(node: LexicalNode): boolean {
  return (
    ($isElementNode(node) || $isDecoratorNode(node)) &&
    !node.isInline() &&
    !$isRootNode(node) &&
    !$isCollapsibleHeadingContentNode(node) &&
    !$isCollapsibleListItemNode(node) &&
    !$isInsideCollapsedListItem(node) &&
    !$isCollapsibleListNode(node) &&
    !($findTableNode(node) && !$isTableNode(node))
  )
}

function getDraggableNodeKeys(editor: LexicalEditor): string[] {
  return editor.getEditorState().read(() => {
    const listItemNodes = $dfs().flatMap(({ node }) => {
      return isNodeDraggable(node) ? [node] : []
    })

    return listItemNodes.map((listItemNode) => listItemNode.getKey())
  })
}

export function getCollapsedMargins(elem: HTMLElement): {
  marginTop: number
  marginBottom: number
} {
  const getMargin = (
    element: Element | null,
    margin: 'marginTop' | 'marginBottom'
  ): number =>
    element ? parseFloat(window.getComputedStyle(element)[margin]) : 0

  const { marginTop, marginBottom } = window.getComputedStyle(elem)
  const prevElemSiblingMarginBottom = getMargin(
    elem.previousElementSibling,
    'marginBottom'
  )
  const nextElemSiblingMarginTop = getMargin(
    elem.nextElementSibling,
    'marginTop'
  )
  const collapsedTopMargin = Math.max(
    parseFloat(marginTop),
    prevElemSiblingMarginBottom
  )
  const collapsedBottomMargin = Math.max(
    parseFloat(marginBottom),
    nextElemSiblingMarginTop
  )

  return { marginBottom: collapsedBottomMargin, marginTop: collapsedTopMargin }
}

let draggableNodeKeys: string[] = []
let lastCacheUpdateTimestamp = 0

export function getBlockElement({
  anchorElem,
  editor,
  event,
  getDroppable = false,
  lastUpdateTimestamp,
}: {
  anchorElem: HTMLElement
  editor: LexicalEditor
  event: MouseEvent
  getDroppable: boolean
  lastUpdateTimestamp: number
}): [HTMLElement | null, NodeKey | null] {
  if (lastCacheUpdateTimestamp < lastUpdateTimestamp) {
    lastCacheUpdateTimestamp = lastUpdateTimestamp
    draggableNodeKeys = getDraggableNodeKeys(editor)
  }

  const anchorElementRect = anchorElem.getBoundingClientRect()

  let blockElem: HTMLElement | null = null
  let blockElemKey: NodeKey | null = null

  editor.getEditorState().read(() => {
    // fallback behavior when the cursor is outside the bounds of existing blocks
    if (getDroppable) {
      const [firstNode, lastNode] = [
        editor.getElementByKey(draggableNodeKeys[0]),
        editor.getElementByKey(draggableNodeKeys[draggableNodeKeys.length - 1]),
      ]

      const [firstNodeRect, lastNodeRect] = [
        firstNode != null ? firstNode.getBoundingClientRect() : undefined,
        lastNode != null ? lastNode.getBoundingClientRect() : undefined,
      ]

      if (firstNodeRect && lastNodeRect) {
        const firstNodeZoom = calculateZoomLevel(firstNode)
        const lastNodeZoom = calculateZoomLevel(lastNode)
        if (event.y / firstNodeZoom < firstNodeRect.top) {
          blockElem = firstNode
        } else if (event.y / lastNodeZoom > lastNodeRect.bottom) {
          blockElem = lastNode
        }

        if (blockElem) {
          return
        }
      }
    }

    let index = getCurrentIndex(draggableNodeKeys.length)
    let direction = Indeterminate

    while (index >= 0 && index < draggableNodeKeys.length) {
      const key = draggableNodeKeys[index]
      let elem = editor.getElementByKey(key)

      const node = $getNodeByKey(key)

      if (elem === null || node == null) {
        break
      }

      const zoom = calculateZoomLevel(elem)
      const point = new Point(event.x / zoom, event.y / zoom)
      const domRect = Rectangle.fromDOM(elem)
      const { marginTop, marginBottom } = getCollapsedMargins(elem)
      const rect = domRect.generateNewRect({
        bottom: domRect.bottom + marginBottom,
        left: anchorElementRect.left - DRAG_HANDLE_GUTTER,
        right: anchorElementRect.right,
        top: domRect.top - marginTop,
      })

      const {
        result,
        reason: { isOnTopSide, isOnBottomSide },
      } = rect.contains(point)

      if (result) {
        blockElem = elem
        prevIndex = index
        blockElemKey = key
        break
      }

      if (direction === Indeterminate) {
        if (isOnTopSide) {
          direction = Upward
        } else if (isOnBottomSide) {
          direction = Downward
        } else {
          // stop search block element
          direction = Infinity
        }
      }

      index += direction
    }
  })

  return [blockElem, blockElemKey]
}

export function setMenuPosition(
  targetElem: HTMLElement | null,
  floatingElem: HTMLElement,
  anchorElem: HTMLElement
) {
  if (!targetElem) {
    floatingElem.style.opacity = '0'
    floatingElem.style.transform = 'translate(-10000px, -10000px)'
    return
  }

  const targetRect = targetElem.getBoundingClientRect()
  const targetStyle = window.getComputedStyle(targetElem)
  const floatingElemRect = floatingElem.getBoundingClientRect()
  const anchorElementRect = anchorElem.getBoundingClientRect()

  // top left
  let targetCalculateHeight: number = parseInt(targetStyle.lineHeight, 10)
  if (isNaN(targetCalculateHeight)) {
    // middle
    targetCalculateHeight = targetRect.bottom - targetRect.top
  }
  const top =
    targetRect.top +
    (targetCalculateHeight - floatingElemRect.height) / 2 -
    anchorElementRect.top

  floatingElem.style.opacity = '1'
  floatingElem.style.transform = `translate(${Math.max(0, targetRect.left - anchorElementRect.left) + DRAG_HANDLE_LEFT_POS}px, ${top}px)`
}

export function setDragImage(
  dataTransfer: DataTransfer,
  draggableBlockElem: HTMLElement
) {
  const { transform, overflow, paddingLeft, paddingRight } =
    draggableBlockElem.style

  dataTransfer.setDragImage(draggableBlockElem, 0, 0)
  // Remove dragImage borders
  draggableBlockElem.style.transform = 'translateZ(0)'
  draggableBlockElem.style.overflow = 'hidden'
  draggableBlockElem.style.paddingLeft = '0'
  draggableBlockElem.style.paddingRight = '0'

  // 1 frame later set back the original styles, this prevents the original element from having the modified styles.
  requestAnimationFrame(() => {
    draggableBlockElem.style.transform = transform
    draggableBlockElem.style.overflow = overflow
    draggableBlockElem.style.paddingLeft = paddingLeft
    draggableBlockElem.style.paddingRight = paddingRight
  })
}

export function setTargetLine(
  targetLineElem: HTMLElement,
  targetBlockElem: HTMLElement,
  mouseY: number,
  anchorElem: HTMLElement
) {
  const {
    top: targetBlockElemTop,
    height: targetBlockElemHeight,
    left: targetBlockElemLeft,
  } = targetBlockElem.getBoundingClientRect()
  const {
    top: anchorTop,
    width: anchorWidth,
    left: annchorLeft,
  } = anchorElem.getBoundingClientRect()
  const { marginTop, marginBottom } = getCollapsedMargins(targetBlockElem)
  let lineTop = targetBlockElemTop
  if (mouseY >= targetBlockElemTop) {
    lineTop += targetBlockElemHeight + marginBottom / 2
  } else {
    lineTop -= marginTop / 2
  }

  const top = lineTop - anchorTop - TARGET_LINE_HALF_HEIGHT
  const left = Math.max(0, targetBlockElemLeft - annchorLeft)

  targetLineElem.style.transform = `translate(${left}px, ${top}px)`
  targetLineElem.style.width = `${anchorWidth}px`
  targetLineElem.style.opacity = '.4'
}

export function hideTargetLine(targetLineElem: HTMLElement | null) {
  if (targetLineElem) {
    targetLineElem.style.opacity = '0'
    targetLineElem.style.transform = 'translate(-10000px, -10000px)'
  }
}
