import {
  AINotetakerOutline,
  MagicWandGradientSolid,
  MenuAlt2Outline,
  TaskSolid,
} from '@motion/icons'
import { $createMentionNode } from '@motion/notes-shared'
import { type TasksV2SingleResponse } from '@motion/rpc-types'
import { ActionDropdown, Button } from '@motion/ui/base'

import { $generateHtmlFromNodes } from '@lexical/html'
import { $isListItemNode, type ListItemNode } from '@lexical/list'
import {
  $getNodeByKey,
  $getSelection,
  $insertNodes,
  $isRangeSelection,
  type LexicalEditor,
  type NodeKey,
} from 'lexical'
import { useCallback, useState } from 'react'

type TextSelectionAskAIDropdownProps = {
  editor: LexicalEditor
  onOpenTaskModal?: (selectedText: string, createMultiple: boolean) => void
  onCreateTaskWithAi?: (
    selectedText: string
  ) => Promise<TasksV2SingleResponse | void>
  isSingularTextSelection?: boolean
  selectedListItemKeys?: NodeKey[]
  onOpenSummarizeModal?: (selectedText: string) => void
  onOpenRewriteModal?: (selectedText: string) => void
}

export function TextSelectionAskAIDropdown({
  editor,
  onOpenTaskModal,
  onCreateTaskWithAi,
  isSingularTextSelection,
  selectedListItemKeys,
  onOpenSummarizeModal,
  onOpenRewriteModal,
}: TextSelectionAskAIDropdownProps) {
  const getSelectedAsHtml = useCallback(() => {
    let selectedText: string | undefined
    editor.update(() => {
      const selection = $getSelection()
      if (selection) {
        selectedText = $generateHtmlFromNodes(editor, selection)
      }
    })
    return selectedText
  }, [editor])

  const [isCreatingInlineTask, setIsCreatingInlineTask] = useState(false)

  const createTaskFromSelection = useCallback(async () => {
    const res = await onCreateTaskWithAi?.(getSelectedAsHtml() ?? '')
    if (!res?.id) {
      return
    }

    const task = res.models.tasks[res.id]

    editor.update(() => {
      const selection = $getSelection()
      if (
        selection &&
        $isRangeSelection(selection) &&
        isSingularTextSelection
      ) {
        selection.removeText()
        $insertNodes([$createMentionNode(task.id, task.name, 'task')])
      }
    })
  }, [onCreateTaskWithAi, editor, getSelectedAsHtml, isSingularTextSelection])

  /**
   * Given a list item node by NodeKey, take the contents of the entire list
   * item, pass it to the AI to create a task, and then insert the task into
   * the list item, replacing the original contents.
   */
  const createTaskFromListItem = useCallback(
    async (nodeKey: NodeKey) => {
      const nodeTextContent = editor.getEditorState().read(() => {
        return $getNodeByKey(nodeKey)?.getTextContent()
      })

      if (!nodeTextContent) {
        return
      }

      const res = await onCreateTaskWithAi?.(nodeTextContent)
      if (!res?.id) {
        return
      }

      const task = res.models.tasks[res.id]

      editor.update(() => {
        const node = $getNodeByKey(nodeKey) as ListItemNode | null
        if (!node || !$isListItemNode(node)) return

        node.clear()
        node.append($createMentionNode(task.id, task.name, 'task'))
      })
    },
    [editor, onCreateTaskWithAi]
  )

  /**
   * Take a given text selection and replace it with an AI generated task
   */
  const handleCreateInlineTask = useCallback(async () => {
    setIsCreatingInlineTask(true)

    try {
      if (selectedListItemKeys?.length) {
        await Promise.all(
          selectedListItemKeys.map((nodeKey) => createTaskFromListItem(nodeKey))
        )
      } else {
        await createTaskFromSelection()
      }
    } finally {
      setIsCreatingInlineTask(false)
    }
  }, [createTaskFromListItem, createTaskFromSelection, selectedListItemKeys])

  return (
    <>
      {(isSingularTextSelection || !!selectedListItemKeys?.length) && (
        <Button
          sentiment='neutral'
          variant='muted'
          size='small'
          onClick={handleCreateInlineTask}
          disabled={isCreatingInlineTask}
          loading={isCreatingInlineTask}
        >
          <MagicWandGradientSolid />
          <span className='text-semantic-gradient-purple'>
            Create task{selectedListItemKeys?.length ? 's' : ''}
          </span>
        </Button>
      )}
      <ActionDropdown
        enableOutsideInteractions
        placement='bottom-start'
        offset={{
          crossAxis: -8,
          mainAxis: 12,
        }}
        items={[
          {
            content: 'Create a task',
            prefix: <TaskSolid />,
            onAction: () => {
              const selectedText = getSelectedAsHtml()
              if (selectedText?.length) {
                onOpenTaskModal?.(selectedText, false)
              }
            },
          },
          {
            content: 'Create multiple tasks',
            prefix: <TaskSolid />,
            onAction: () => {
              const selectedText = getSelectedAsHtml()
              if (selectedText?.length) {
                onOpenTaskModal?.(selectedText, true)
              }
            },
          },
          {
            content: 'Summarize',
            prefix: <MenuAlt2Outline />,
            onAction: () => {
              const selectedText = getSelectedAsHtml()
              if (selectedText?.length) {
                onOpenSummarizeModal?.(selectedText)
              }
            },
          },
          {
            content: 'Rewrite or edit text',
            prefix: <AINotetakerOutline />,
            onAction: () => {
              const selectedText = getSelectedAsHtml()
              if (selectedText?.length) {
                onOpenRewriteModal?.(selectedText)
              }
            },
          },
        ]}
      >
        <Button sentiment='neutral' variant='muted' size='small'>
          <MagicWandGradientSolid />
          <span className='text-semantic-gradient-purple'>Ask AI (Beta)</span>
        </Button>
      </ActionDropdown>
    </>
  )
}
