import { durationToTimestamp } from '@motion/utils/dates'

import { EditorConfig, LexicalEditor } from 'lexical'

import { VIDEO_PLAYER_SEEK_COMMAND } from '../../commands'

export function createNoteInfoElement(
  timestamp: number | undefined,
  editor: LexicalEditor,
  config: EditorConfig
): HTMLElement {
  const info = document.createElement('button')

  info.className = config.theme.listItemNoteInfo

  info.innerHTML = AI_EDIT_ICON_SVG

  if (timestamp) {
    const timestampText = durationToTimestamp(timestamp)

    const span = document.createElement('span')
    span.textContent = timestampText
    info.append(span)

    info.addEventListener('click', (e) => {
      e.stopPropagation()
      editor.dispatchCommand(VIDEO_PLAYER_SEEK_COMMAND, timestamp)
    })
  }

  info.tabIndex = -1
  info.contentEditable = 'false'

  return info
}

const AI_EDIT_ICON_SVG = `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0179 1.97922C12.3801 1.33415 11.3375 1.33627 10.7023 1.98393L2.20828 10.6442C1.91032 10.9479 1.74341 11.3565 1.74341 11.782V12.0802L1.6792 12.1444L1.74341 12.2086V13.627C1.74341 13.9722 2.02323 14.252 2.36841 14.252H3.78689L3.85327 14.3184L3.91965 14.252H4.2341C4.66507 14.252 5.0784 14.0808 5.38314 13.776L13.9446 5.21453C14.5767 4.58245 14.5796 3.55853 13.9511 2.92291L13.0179 1.97922ZM4.29898 12.9963C4.37434 12.9831 4.44442 12.947 4.49926 12.8921L11.1041 6.2873L9.6543 4.83759L3.10069 11.5194C3.05142 11.5697 3.01767 11.6323 3.0025 11.6999L4.29898 12.9963ZM11.5947 2.85921C11.7413 2.70975 11.9819 2.70926 12.1291 2.85812L13.0622 3.80181C13.2073 3.9485 13.2066 4.18479 13.0608 4.33065L11.988 5.40342L10.5296 3.94515L11.5947 2.85921Z" fill="currentColor"/>
  <path d="M4.1978 1.85954C4.12984 1.80651 4.04609 1.77772 3.95989 1.77777C3.87369 1.77772 3.78995 1.80651 3.72199 1.85954C3.65402 1.91258 3.60574 1.98682 3.58483 2.07046L3.4519 2.60431C3.39246 2.84215 3.2695 3.05937 3.09617 3.23272C2.92285 3.40608 2.70567 3.52906 2.46787 3.58852L1.93412 3.72147C1.85034 3.74223 1.77591 3.79044 1.72272 3.85844C1.66953 3.92643 1.64062 4.01027 1.64062 4.09661C1.64062 4.18294 1.66953 4.26679 1.72272 4.33478C1.77591 4.40277 1.85034 4.45098 1.93412 4.47174L2.46787 4.60469C2.70567 4.66415 2.92285 4.78713 3.09617 4.96049C3.2695 5.13385 3.39246 5.35106 3.4519 5.5889L3.58483 6.12275C3.60558 6.20655 3.65379 6.28099 3.72177 6.33419C3.78975 6.3874 3.87358 6.41631 3.95989 6.41631C4.04621 6.41631 4.13004 6.3874 4.19802 6.33419C4.266 6.28099 4.31421 6.20655 4.33496 6.12275L4.46788 5.5889C4.58947 5.10453 4.96763 4.7263 5.45191 4.60469L5.98566 4.47174C6.06945 4.45098 6.14387 4.40277 6.19706 4.33478C6.25026 4.26679 6.27916 4.18294 6.27916 4.09661C6.27916 4.01027 6.25026 3.92643 6.19706 3.85844C6.14387 3.79044 6.06945 3.74223 5.98566 3.72147L5.45191 3.58852C4.96763 3.46691 4.58947 3.08869 4.46788 2.60431L4.33496 2.07046C4.31404 1.98682 4.26576 1.91258 4.1978 1.85954Z" fill="currentColor"/>
</svg>
`
