import { useOnceWhen } from '@motion/react-core/hooks'
import { Sentry } from '@motion/web-base/sentry'

import { useEffect, useState } from 'react'

import { ResizableBlock } from './resizable-block'

type VideoPlayerEmbedComponentProps = {
  nodeKey: string
  width: number
  /**
   * Takes either a regular URL or a GET url that can be used to fetch the resource url.
   */
  src: string
}

export function VideoPlayerEmbedComponent(
  props: VideoPlayerEmbedComponentProps
) {
  // This is temporary to check if the src is a meeting recording signed url
  const isSignedUrl = props.src.endsWith('/signed')
  const [src, setSrc] = useState(() => (isSignedUrl ? undefined : props.src))

  useOnceWhen(isSignedUrl && props.src != null, () => {
    if (!isSignedUrl) return

    fetch(props.src, {
      credentials: 'include',
    })
      .then((res) => res.text())
      .then((data) => {
        setSrc(data)
      })
      .catch((e) => {
        Sentry.captureException(e, {
          tags: { position: 'VideoPlayerEmbedComponent' },
        })
      })
  })

  return (
    <ResizableBlock
      nodeKey={props.nodeKey}
      initialWidth={props.width}
      aspectRatio='16/9'
    >
      <VideoPlayer src={src} />
    </ResizableBlock>
  )
}

/**
 * DUPLICATE OF /packages/motion-extension/src/areas/ai-hackerhouse/meeting-insight/video-player.tsx.
 * The following code should eventually move to /ui or its own package.
 */

type VideoPlayerProps = {
  src: string | undefined
}

export function VideoPlayer({ src }: VideoPlayerProps) {
  const posterUrl = useClientSideThumbnailGeneration(src)

  return (
    <div className='overflow-hidden rounded-md pb-[56.25%] relative'>
      <video
        src={src}
        controls
        className='size-full absolute'
        poster={posterUrl}
      />
    </div>
  )
}

function useClientSideThumbnailGeneration(videoUrl: string | undefined) {
  const [posterUrl, setPosterUrl] = useState('')

  const thumbnailDurationPercent = 16

  useEffect(() => {
    if (videoUrl == null || videoUrl === '') return

    // Create an offscreen <video> element (not appended to DOM)
    const offscreenVideo = document.createElement('video')
    offscreenVideo.src = videoUrl
    offscreenVideo.crossOrigin = 'anonymous' // needed bc of bucket CORS
    offscreenVideo.load()

    // Define handler to seek once metadata is loaded (so we know duration, size, etc.)
    function handleLoadedMetadata() {
      const duration = offscreenVideo.duration
      const thumbnailTime = duration * (thumbnailDurationPercent / 100)
      const timeToSeek = Math.min(thumbnailTime, duration)
      offscreenVideo.currentTime = timeToSeek
    }

    // Define handler to create a canvas image once we have the correct frame
    function handleSeeked() {
      const canvas = document.createElement('canvas')
      canvas.width = offscreenVideo.videoWidth
      canvas.height = offscreenVideo.videoHeight

      const ctx = canvas.getContext('2d')
      if (ctx) {
        try {
          ctx.drawImage(offscreenVideo, 0, 0, canvas.width, canvas.height)
          const dataURL = canvas.toDataURL('image/png')
          setPosterUrl(dataURL)
        } catch (e) {
          Sentry.captureException(e, {
            tags: { position: 'useClientSideThumbnailGeneration' },
          })
        }
      }
    }

    // Attach event listeners
    offscreenVideo.addEventListener('loadedmetadata', handleLoadedMetadata)
    offscreenVideo.addEventListener('seeked', handleSeeked)

    // Cleanup: remove event listeners if component unmounts or url changes
    return () => {
      offscreenVideo.removeEventListener('loadedmetadata', handleLoadedMetadata)
      offscreenVideo.removeEventListener('seeked', handleSeeked)
      // No need to do anything else with offscreenVideo; GC should handle it
    }
  }, [videoUrl, thumbnailDurationPercent])

  return posterUrl
}
