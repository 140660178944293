import { type MotionId } from '@motion/notes-shared'

import {
  $getNodeByKey,
  type LexicalEditor,
  type LexicalNode,
  type NodeKey,
  type NodeMutation,
} from 'lexical'

export const $collapsibleNodeMutationListener =
  (
    editor: LexicalEditor,
    $isCollapsibleNode: (node: LexicalNode) => node is LexicalNode & {
      getMotionId: () => MotionId
      setOpen: (open: boolean) => void
    },
    getToggleState: (motionId: MotionId) => Promise<boolean>
  ) =>
  async (mutations: Map<NodeKey, NodeMutation>) => {
    const toggleStates: { nodeKey: NodeKey; open: boolean }[] = []

    for (const [nodeKey, mutation] of mutations) {
      if (mutation === 'created') {
        let motionId: MotionId | null = null

        editor.getEditorState().read(() => {
          const node = $getNodeByKey(nodeKey)
          if (node && $isCollapsibleNode(node)) {
            motionId = node.getMotionId()
          }
        })

        if (motionId) {
          toggleStates.push({
            nodeKey,
            // eslint-disable-next-line no-await-in-loop
            open: await getToggleState(motionId),
          })
        }
      }
    }

    editor.update(() => {
      for (const { nodeKey, open } of toggleStates) {
        const node = $getNodeByKey(nodeKey) // Fetch fresh node reference
        if (node && $isCollapsibleNode(node)) {
          node.setOpen(open)
        }
      }
    })
  }
