/**
 * Auto Generated
 */
import { type ClientZod } from '../../utils/client-transform'
import type * as Server from '../../server/models/meeting-insights'

// Generated Types

export type MeetingBotStatusSchema = ClientZod<
  typeof Server.MeetingBotStatusSchema
>
export const MeetingBotStatusSchema = [
  'DONT_SCHEDULE',
  'NEEDS_SCHEDULING',
  'SCHEDULED',
  'SCHEDULING',
  // TODO: Deprecate after moving to Temporal
  'FAILED_TO_SCHEDULE',
  'IN_CALL',
  'CALL_ENDED',
  'COMPLETED',
  'PROCESSING',
  'FATAL_BOT_ERROR',
  'FAILED_TO_PROCESS',
  'BOT_NEVER_ADMITTED',
] as const
export type MeetingBotStatusDetailsSchema = ClientZod<
  typeof Server.MeetingBotStatusDetailsSchema
>
export const MeetingBotStatusDetailsSchema = [
  // when `FAILED_TO_SCHEDULE`
  'RECALL_TEMP_LIMIT_EXCEEDED',
  'MEETING_URL_UNSUPPORTED',
  // when `SCHEDULED`
  'BOT_JOINING_CALL',
  'BOT_IN_WAITING_ROOM',
  // when `IN_CALL`
  'BOT_IN_CALL_NOT_RECORDING',
  'BOT_IN_CALL_RECORDING',
  // when `COMPLETED`
  'MEETING_PARTIALLY_RECORDED',
  'MEETING_HAD_NO_SPEECH',
  // when `BOT_NEVER_ADMITTED`
  'BOT_KICKED_FROM_WAITING_ROOM',
  'BOT_KICKED_FROM_CALL',
  // possible for `COMPLETED` as well
  'RECORDING_PERMISSIONS_DENIED',
] as const
export type ProcessedDateSchema = ClientZod<typeof Server.ProcessedDateSchema>
export type MeetingActionItemTaskDataSchema = ClientZod<
  typeof Server.MeetingActionItemTaskDataSchema
>
export type MeetingInsightsEventSchema = ClientZod<
  typeof Server.MeetingInsightsEventSchema
>
export type RecurringMeetingInsightsSchema = ClientZod<
  typeof Server.RecurringMeetingInsightsSchema
>
export type MeetingInsightsSchema = ClientZod<
  typeof Server.MeetingInsightsSchema
>
export type MeetingActionItemSchema = ClientZod<
  typeof Server.MeetingActionItemSchema
>
