import { Duration } from 'luxon'

export function durationToTimestamp(durationInSeconds: number) {
  const duration = Duration.fromObject({ seconds: durationInSeconds })

  if (durationInSeconds >= 3600) {
    return duration.toFormat('hh:mm:ss')
  }

  return duration.toFormat('mm:ss')
}
