import { $getNodeByMotionId, type MotionId } from '@motion/notes-shared'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $isElementNode } from 'lexical'
import { forwardRef, useCallback, useImperativeHandle } from 'react'

import { type EditorPublicAPI } from '../types'

export const EditorPublicAPIProvider = forwardRef<EditorPublicAPI>(
  function EditorExposedAPIProvider(_, ref) {
    const [editor] = useLexicalComposerContext()

    const navigateToNodeByMotionId = useCallback(
      (motionId: MotionId) => {
        // eslint-disable-next-line no-restricted-syntax
        const targetNode = editor.read(() => $getNodeByMotionId(motionId))

        if (targetNode == null) {
          throw new Error(`Node with motionId ${motionId} not found`)
        }

        // eslint-disable-next-line no-restricted-syntax
        const elementNode = editor.read(() =>
          $isElementNode(targetNode)
            ? targetNode
            : targetNode.getTopLevelElement()
        )

        if (elementNode == null) {
          throw new Error(
            `Node with motionId ${motionId} does not have a top level element`
          )
        }

        editor.update(() => {
          const elementHTMLNode = editor.getElementByKey(elementNode.getKey())

          if (elementHTMLNode == null) {
            elementNode.selectStart()
          } else {
            elementHTMLNode.scrollIntoView({ behavior: 'smooth' })

            // Wait for the scroll to finish
            setTimeout(
              () => editor.update(() => elementNode.selectStart()),
              500
            )
          }
        })
      },
      [editor]
    )

    useImperativeHandle(ref, () => ({ navigateToNodeByMotionId }), [
      navigateToNodeByMotionId,
    ])

    return null
  }
)
