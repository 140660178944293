import { HeadingNode } from '@lexical/rich-text'

import { MotionHeadingNode } from './heading-node'

export const HeadingNodeReplacement = {
  replace: HeadingNode,
  with: (node: HeadingNode) => {
    return new MotionHeadingNode(node.getTag())
  },
  withKlass: MotionHeadingNode,
}
