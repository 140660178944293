import { type PropsWithChildren, useCallback, useEffect, useRef } from 'react'

import { EditorSizeContainer } from './styled'

export const EditorContent = (props: PropsWithChildren) => {
  const outerRef = useRef<HTMLDivElement | null>(null)
  const innerRef = useRef<HTMLDivElement | null>(null)

  const calculatePadding = useCallback(() => {
    const outerElement = outerRef.current
    if (!outerElement) return
    const outerWidth = outerElement.offsetWidth || 0
    const innerWidth = innerRef.current?.offsetWidth || 0
    const calculatedPadding = Math.max(0, (outerWidth - innerWidth) / 2)

    outerElement.style.setProperty(
      '--editor-overflow-padding',
      `${calculatedPadding}px`
    )
    outerElement.style.setProperty('--editor-overflow-width', `${outerWidth}px`)
  }, [])

  useEffect(() => {
    const outerElement = outerRef.current

    if (!outerElement) return

    const resizeObserver = new ResizeObserver(() => {
      calculatePadding()
    })

    resizeObserver.observe(outerElement)

    return () => {
      resizeObserver.disconnect()
    }
  }, [calculatePadding])

  return (
    <div className='flex w-full' ref={outerRef}>
      <div className='relative w-full'>
        <div className='flex flex-row justify-center'>
          <EditorSizeContainer ref={innerRef}>
            {props.children}
          </EditorSizeContainer>
        </div>
      </div>
    </div>
  )
}
