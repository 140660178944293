import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { type NodeKey } from 'lexical'

import { TURN_INTO_COMMAND } from '../commands'
import { type TurnIntoOption, type TurnIntoOptionKey } from '../types'

export type UseTurnIntoActionListParams = {
  blockNodeKey: NodeKey | null
  turnIntoOptions: TurnIntoOption[]
}

export function useTurnIntoActions({
  blockNodeKey,
  turnIntoOptions,
}: UseTurnIntoActionListParams) {
  const [editor] = useLexicalComposerContext()

  const handleOptionSelected = (type: TurnIntoOptionKey) => () => {
    if (blockNodeKey == null) {
      return
    }

    editor.dispatchCommand(TURN_INTO_COMMAND, {
      nodeKey: blockNodeKey,
      type,
    })
  }

  return turnIntoOptions.map(({ key, label, icon: Icon }) => ({
    prefix: <Icon />,
    content: label,
    onAction: handleOptionSelected(key),
  }))
}
