import { CheckSolid, FilledChevronDownSolid } from '@motion/icons'
import { templateStr } from '@motion/react-core/strings'
import { classed } from '@motion/theme'
import {
  ActionList,
  PopoverButton,
  PopoverTrigger,
  Tag,
  Toggle,
} from '@motion/ui/base'
import {
  BASIC_TIER_SEAT_LIMIT,
  generateBillingFormula,
  type GenerateBillingFormulaProps,
  INDIVIDUAL_ANNUAL_PLAN_SAVINGS_ABSOLUTE,
  INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY,
  INDIVIDUAL_MONTHLY_PRICE,
  LOW_COST_TRIAL_PRICE,
  Term,
} from '@motion/ui-logic/billing'
import { type Tier } from '@motion/ui-logic/tiered-pricing'
import { useHasTreatment } from '@motion/web-common/flags'

import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { BucketSeatsDropdown } from '../bucket-seats-dropdown'
import { useGetTierAnnualDollarSavings } from '../tiered-pricing/hooks'

type SharedBillingPlanProps = {
  text: ReactNode
  subText?: string
  price: number
  chosenTrialLength: number
  term: Term
  allowLowCostTrial?: boolean
  isTeam: boolean
  minSeats?: number
  tierTitle?: string
  tierIcon?: ReactNode
  tier?: Tier
}

type IndividualBillingPlanProps = SharedBillingPlanProps & {
  setBucket: undefined
  bucket: undefined
}

type TeamBillingPlanProps = SharedBillingPlanProps & {
  setBucket: (bucket: number) => void
  bucket: number
}

export type BillingPlanProps = IndividualBillingPlanProps | TeamBillingPlanProps

const BillingPlan = ({
  text,
  subText,
  price,
  chosenTrialLength,
  term,
  allowLowCostTrial,
  setBucket,
  isTeam,
  bucket,
  minSeats,
  tier,
}: BillingPlanProps) => {
  return (
    <div
      className={twMerge(
        'bg-semantic-neutral-bg-default rounded-lg flex flex-col lg:flex-row justify-between items-center py-3 px-4 text-semantic-neutral-text-default}',
        isTeam && 'mb-3'
      )}
    >
      <div className='flex w-full justify-between flex-row lg:flex-col lg:items-start lg:gap-1 flex-wrap gap-y-3'>
        {text}
        {isTeam && bucket && setBucket ? (
          <div className='flex flex-row gap-1.5 items-center'>
            <BucketSeatsDropdown
              selectedBucket={bucket}
              onChange={setBucket}
              minSeats={minSeats}
              overrideMaxSeats={
                tier === 'BASIC' ? BASIC_TIER_SEAT_LIMIT : undefined
              }
            />
            <BillingSubtext>
              {allowLowCostTrial && subText ? subText : `$${price}/mo per seat`}
            </BillingSubtext>
          </div>
        ) : (
          subText && <BillingSubtext>{subText}</BillingSubtext>
        )}
      </div>
      <div className='flex flex-col w-full mt-[18px] gap-2 lg:mt-0 lg:items-end lg:gap-1'>
        {term === Term.LowCostTrial ? (
          <HighlightedBillingPlan
            line1Big={`$${(LOW_COST_TRIAL_PRICE * (bucket ?? 1)).toFixed(2)}`}
            line1Small='for first month'
            formulaInputs={{
              isTeam: !!isTeam,
              isAnnual: true,
              seats: isTeam ? bucket : undefined,
              monthlyPrice: price,
            }}
          />
        ) : chosenTrialLength ? (
          <HighlightedBillingPlan
            line1Big='$0'
            line1Small={`for ${chosenTrialLength} day free trial`}
            formulaInputs={{
              isTeam: !!isTeam,
              isAnnual: term === Term.Annual,
              seats: isTeam ? bucket : undefined,
              monthlyPrice: price,
            }}
          />
        ) : allowLowCostTrial && term === Term.Monthly ? (
          <>
            <div
              className='text-semantic-neutral-text-subtle'
              data-testid='billing-plan-price-text'
            >
              <span className='text-lg leading-5 mr-1 font-semibold'>
                ${price * (bucket ?? 1)}
              </span>
              <span className='font-normal'>per month starting now</span>
            </div>
            <BillingSubtext>
              {isTeam
                ? `then ${generateBillingFormula({
                    isTeam: true,
                    isAnnual: false,
                    seats: bucket ?? 1,
                    monthlyPrice: price,
                  })}`
                : `then $${price * (bucket ?? 1)} every month`}
            </BillingSubtext>
          </>
        ) : (
          <>
            <div className='flex flex-col items-end'>
              <div
                className='font-semibold text-semantic-neutral-text-subtle'
                data-testid='billing-plan-price-text'
              >
                <span className='text-lg'>
                  ${price * (bucket ?? 1) * (term === Term.Annual ? 12 : 1)}
                </span>
                <span className='text-xs'>
                  /{term === Term.Annual ? 'year' : 'month'}
                </span>
              </div>
              <p className='text-xs text-semantic-neutral-text-subtle'>
                billed {term === Term.Annual ? 'annually' : 'monthly'}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

type HighlightedBillingPlanProps = {
  line1Big: string
  line1Small: string
} & ({ formulaInputs: GenerateBillingFormulaProps } | { line2: string })
const HighlightedBillingPlan = (props: HighlightedBillingPlanProps) => {
  const { line1Big, line1Small } = props
  let line2: string
  if ('formulaInputs' in props) {
    line2 = templateStr('then {{formula}}', {
      formula: generateBillingFormula(props.formulaInputs),
    })
  } else {
    line2 = props.line2
  }
  return (
    <>
      <div
        className='text-semantic-primary-text-default'
        data-testid='billing-plan-price-text'
      >
        <span className='text-lg leading-5 mr-1 font-semibold'>{line1Big}</span>
        <span className='font-normal'>{line1Small}</span>
      </div>
      <BillingSubtext>{line2}</BillingSubtext>
    </>
  )
}

const BillingSubtext = classed('span', {
  base: 'text-semantic-neutral-text-subtle text-xs font-normal',
})

type BillingPlanSelectorProps = {
  term: Term
  setTerm: (term: Term) => void
  tier?: Tier
  annualTrialLength: number
  monthlyTrialLength: number
  allowLowCostTrial: boolean
  savingsPercent: number
  text: ReactNode
  tierTitle?: string
  tierIcon?: ReactNode
  title?: string
}

export const BillingPlanSelector = ({
  term,
  setTerm,
  tier,
  annualTrialLength,
  monthlyTrialLength,
  chosenTrialLength,
  allowLowCostTrial,
  savingsPercent,
  title = 'Your plan',
  tierTitle,
  tierIcon,
  text,
  price,
  isTeam,
  ...billingPlanExclusiveProps
}: BillingPlanSelectorProps & BillingPlanProps) => {
  const tierAnnualDollarSavings = useGetTierAnnualDollarSavings(tier, isTeam)
  const annualDollarSavings = tier
    ? tierAnnualDollarSavings
    : INDIVIDUAL_ANNUAL_PLAN_SAVINGS_ABSOLUTE

  const titleElement = (
    <BoldedBillingText>
      {tierTitle ? tierTitle : text}
      {allowLowCostTrial && isTeam && ` ($${price}/mo per seat)`}
      {tierIcon}
      {!allowLowCostTrial && !isTeam && !tierTitle && ` ($${price}/mo)`}
    </BoldedBillingText>
  )

  const mainElement = allowLowCostTrial ? (
    <BoldedBillingText>
      <BillingPlanDropdown
        isTeam={isTeam}
        annualTrialLength={annualTrialLength}
        monthlyTrialLength={monthlyTrialLength}
        term={term}
        setTerm={setTerm}
        annualDollarSavings={annualDollarSavings}
        popoverButtonStyle='custom'
      />
    </BoldedBillingText>
  ) : (
    titleElement
  )

  const topRightElement = allowLowCostTrial ? (
    tierTitle ? (
      titleElement
    ) : null
  ) : (
    <BillingPlanToggle
      term={term}
      setTerm={setTerm}
      savingsPercent={savingsPercent}
    />
  )
  return (
    <div>
      <div className='flex flex-row justify-between items-center mb-3'>
        <p className='text-semantic-neutral-text-default text-xl font-semibold'>
          {title}
        </p>
        <div className='flex flex-row gap-2 items-center'>
          {topRightElement}
        </div>
      </div>
      <div className='border border-semantic-neutral-border-subtle rounded bg-semantic-neutral-bg-default'>
        <BillingPlan
          text={mainElement}
          term={term}
          chosenTrialLength={chosenTrialLength}
          allowLowCostTrial={allowLowCostTrial}
          tierTitle={tierTitle}
          tierIcon={tierIcon}
          price={price}
          isTeam={isTeam}
          tier={tier}
          {...billingPlanExclusiveProps}
        />
      </div>
    </div>
  )
}

const BoldedBillingText = ({ children }: { children: ReactNode }) => (
  <div data-testid='billing-plan-text' className='flex flex-row'>
    <span className='flex flex-row gap-2 font-semibold text-base text-semantic-neutral-text-default'>
      {children}
    </span>
  </div>
)

type BillingPlanDropdownProps = {
  annualTrialLength: number
  monthlyTrialLength: number
  term: Term
  setTerm: (term: Term) => void
  isTeam: boolean
  annualDollarSavings?: number
  isAnnualSavingsUpTo?: boolean
  mostExpensiveTier?: Tier
  popoverButtonStyle?: 'standard' | 'custom'
}

export const BillingPlanDropdown = ({
  annualTrialLength,
  monthlyTrialLength,
  term,
  setTerm,
  isTeam,
  annualDollarSavings = INDIVIDUAL_ANNUAL_PLAN_SAVINGS_ABSOLUTE,
  isAnnualSavingsUpTo,
  popoverButtonStyle = 'standard',
}: BillingPlanDropdownProps) => {
  const tieredPricingEnabled = useHasTreatment('tiered-pricing-v1')

  const termToButtonText = {
    [Term.Annual]: annualTrialLength
      ? `${annualTrialLength}-day free trial`
      : 'Annual plan',
    [Term.LowCostTrial]: `$${LOW_COST_TRIAL_PRICE} ${
      isTeam ? 'per seat' : ''
    } for 1 month`,
    [Term.Monthly]: monthlyTrialLength
      ? `${monthlyTrialLength}-day free trial`
      : 'Monthly plan',
  }

  const termToItemText = {
    [Term.Annual]: annualTrialLength
      ? getAnnualFreeTrialDropdownText(
          annualTrialLength,
          annualDollarSavings,
          isTeam,
          isAnnualSavingsUpTo
        )
      : 'ANNUAL starting now',
    [Term.LowCostTrial]: `ANNUAL with $${LOW_COST_TRIAL_PRICE} for first month`,
    [Term.Monthly]: monthlyTrialLength
      ? `MONTHLY with ${monthlyTrialLength} day free trial`
      : 'MONTHLY starting now',
  }
  const annualItemSubtext = `then $${INDIVIDUAL_ANNUAL_PRICE_AMORTIZED_MONTHLY} USD/month`
  const monthlyItemSubtext = `then $${INDIVIDUAL_MONTHLY_PRICE} USD/month`

  const makeDropdownItem = (itemTerm: Term) => {
    return {
      content: (
        <div className='my-1 flex'>
          <div className='flex-column w-[256px]'>
            <div className='mb-1'>{termToItemText[itemTerm]}</div>
            {!tieredPricingEnabled && (
              <div className='text-sm text-semantic-neutral-text-subtle'>
                {itemTerm === Term.Monthly
                  ? monthlyItemSubtext
                  : annualItemSubtext}
              </div>
            )}
          </div>
          {term === itemTerm && (
            <CheckSolid className='text-semantic-blue-text-default size-[18px] ml-[3px] mr-[3px]' />
          )}
        </div>
      ),
      onAction: () => setTerm(itemTerm),
    }
  }

  const buttonText = termToButtonText[term]
  const customChevron = (
    <FilledChevronDownSolid className='text-semantic-neutral-icon-default size-[18px] mt-[3px] ml-[3px]' />
  )

  const button =
    popoverButtonStyle === 'standard' ? (
      <PopoverButton className='w-[150px] h-7 text-xs'>
        {buttonText}
      </PopoverButton>
    ) : (
      <div className='flex text-semantic-neutral-text-default hover:text-semantic-blue-text-default cursor-pointer'>
        {templateStr('{{buttonText}} {{customChevron}}', {
          buttonText,
          customChevron,
        })}
      </div>
    )

  return (
    <PopoverTrigger
      placement='bottom-start'
      renderPopover={({ close }) => (
        <ActionList
          onActionAnyItem={close}
          items={[
            makeDropdownItem(Term.Annual),
            makeDropdownItem(Term.LowCostTrial),
            makeDropdownItem(Term.Monthly),
          ]}
        />
      )}
    >
      {button}
    </PopoverTrigger>
  )
}

const getAnnualFreeTrialDropdownText = (
  trialLength: number,
  dollarSavings: number,
  isTeam: boolean,
  isAnnualSavingsUpTo?: boolean
) => {
  const savingsText = dollarSavings
    ? templateStr('(SAVE {{upTo}} ${{savings}} USD {{perSeat}})', {
        upTo: isAnnualSavingsUpTo && 'UP TO',
        savings: dollarSavings,
        perSeat: isTeam ? 'per seat' : '',
      })
    : ''
  return templateStr(`ANNUAL with {{trialLength}} day free trial {{savings}}`, {
    trialLength,
    savings: savingsText,
  })
}

type BillingPlanToggleProps = {
  term: Term
  setTerm: (term: Term) => void
  savingsPercent: number
}

export const BillingPlanToggle = ({
  term,
  setTerm,
  savingsPercent,
}: BillingPlanToggleProps) => {
  return (
    <Toggle
      checked={term === Term.Annual}
      onChange={(e) =>
        setTerm(e.currentTarget.checked ? Term.Annual : Term.Monthly)
      }
    >
      <span>Billed annually </span>
      <Tag size='small' color='green' variant='subtle'>
        Save {savingsPercent}%
      </Tag>
    </Toggle>
  )
}
