import {
  $createCollapsibleListItemNode,
  $isCollapsibleListItemNode,
} from '@motion/notes-shared'

import {
  $getEditor,
  $getNearestRootOrShadowRoot,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootNode,
  OUTDENT_CONTENT_COMMAND,
} from 'lexical'

export const $handleListInsertParagraph = (): boolean => {
  const editor = $getEditor()
  const selection = $getSelection()

  if (!$isRangeSelection(selection)) {
    return false
  }

  const anchor = selection.anchor.getNode()

  if ($isRootNode(anchor) || $isRootNode(anchor.getParent())) {
    return false
  }

  const nearestRoot = $isCollapsibleListItemNode(anchor)
    ? anchor
    : $getNearestRootOrShadowRoot(anchor)
  if ($isCollapsibleListItemNode(nearestRoot)) {
    const firstChild = nearestRoot.getFirstChild()

    if (
      nearestRoot.getChildren().length === 1 &&
      $isElementNode(firstChild) &&
      firstChild.isEmpty()
    ) {
      editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
      return true
    }

    const newNode = $createCollapsibleListItemNode()
    const createdBlock = selection.insertParagraph()
    const nextSibling = nearestRoot.getNextSibling()

    if (createdBlock) {
      newNode.append(createdBlock, ...createdBlock.getNextSiblings())
    }

    if (nearestRoot.getOpen()) {
      nearestRoot.insertAfter(newNode)
    } else if (nextSibling) {
      nextSibling.insertAfter(newNode)
    } else {
      nearestRoot.insertAfter(newNode)
    }

    return true
  }

  return false
}
