export function createElement<K extends keyof HTMLElementTagNameMap>(
  tagName: K,
  attributes: Record<string, string | boolean | number | undefined | null>,
  content?: string | HTMLElement
): HTMLElementTagNameMap[K]
export function createElement(
  tagName: string,
  attributes: Record<string, string | boolean | number | undefined | null>,
  content?: string | HTMLElement
): HTMLElement
export function createElement(
  tagName: string,
  attributes: Record<string, string | boolean | number | undefined | null>,
  content?: string | HTMLElement
): HTMLElement {
  const el = document.createElement(tagName)
  for (const [name, value] of Object.entries(attributes)) {
    if (value == null) continue
    if (value === false) continue
    if (typeof value === 'boolean') {
      el.setAttribute(name, '')
      continue
    }
    if (typeof value === 'number') {
      el.setAttribute(name, String(value))
      continue
    }
    el.setAttribute(name, value)
  }

  if (!content) return el
  if (typeof content === 'string') {
    el.innerHTML = content
    return el
  }

  el.appendChild(content)

  return el
}

export function pxSize(value: number | undefined | null) {
  if (value == null) return undefined
  if (value === 0) return undefined
  return `${value}px`
}
