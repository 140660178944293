import {
  $isCollapsibleListItemNode,
  $isCollapsibleListNode,
  type CollapsibleListItemNode,
  type CollapsibleListNode,
} from '@motion/notes-shared'

import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
} from '@lexical/utils'
import {
  $getSelection,
  $isRangeSelection,
  $isRootNode,
  type ElementNode,
} from 'lexical'

export function $handleIndentFirstBlock(): boolean {
  const selection = $getSelection()
  if (!$isRangeSelection(selection)) {
    return false
  }

  const anchor = selection.anchor
  const focus = selection.focus
  const first = focus.isBefore(anchor) ? focus : anchor
  const firstNode = first.getNode()

  const block = $findMatchingParent(
    firstNode,
    (parentNode): parentNode is ElementNode =>
      $isCollapsibleListItemNode(parentNode) && !parentNode.isInline()
  )

  if (!block) {
    return false
  }

  const prevSibling = block.getPreviousSibling()

  if (
    prevSibling &&
    $isCollapsibleListItemNode(prevSibling) &&
    prevSibling.getIndent() >= block.getIndent()
  ) {
    const collapsibleSibling = prevSibling.getPreviousSibling()
    if (collapsibleSibling && $isCollapsibleListItemNode(collapsibleSibling)) {
      collapsibleSibling.setOpen(true)
    }
    return true
  }
  return false
}

export function $getCanIndent(): boolean {
  const selection = $getSelection()
  if (!$isRangeSelection(selection)) {
    return false
  }

  const anchor = selection.anchor
  const focus = selection.focus
  const first = focus.isBefore(anchor) ? focus : anchor
  const firstNode = first.getNode()

  try {
    const block = $getNearestBlockElementAncestorOrThrow(firstNode)
    const prevSibling = block.getPreviousSibling()
    return Boolean(
      prevSibling &&
        $isCollapsibleListItemNode(prevSibling) &&
        prevSibling.getIndent() >= block.getIndent()
    )
  } catch (e) {
    return false
  }
}

export function $handleIndentAndOutdent(
  indentOrOutdent: (block: ElementNode) => void,
  indentChildren = false
): boolean {
  const selection = $getSelection()

  if (!$isRangeSelection(selection)) {
    return false
  }
  const alreadyHandled = new Set()
  const nodes = selection.getNodes()

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    const key = node.getKey()
    if (alreadyHandled.has(key)) {
      continue
    }
    const parentBlock = $findMatchingParent(
      node,
      (parentNode): parentNode is ElementNode =>
        $isCollapsibleListItemNode(parentNode) && !parentNode.isInline()
    )

    if (parentBlock === null) {
      continue
    }
    const parentKey = parentBlock.getKey()
    if (parentBlock.canIndent() && !alreadyHandled.has(parentKey)) {
      const nextSibling = parentBlock.getNextSibling()
      if (
        indentChildren &&
        nextSibling &&
        $isCollapsibleListItemNode(nextSibling) &&
        $isCollapsibleListNode(nextSibling.getFirstChild())
      ) {
        const nextChild = nextSibling.getFirstChild()
        if ($isCollapsibleListNode(nextChild)) {
          const children = $getAllListItems(nextChild)

          for (const child of children) {
            alreadyHandled.add(child.getKey())
            indentOrOutdent(child)
          }
        }
      }

      alreadyHandled.add(parentKey)
      indentOrOutdent(parentBlock)
    }
  }
  return alreadyHandled.size > 0
}

export function $getCanOutdent(): boolean {
  const selection = $getSelection()

  if (!$isRangeSelection(selection)) {
    return false
  }

  const hasIndentedParent = selection.getNodes().some((node) => {
    const parentBlock = $findMatchingParent(
      node,
      (parentNode): parentNode is ElementNode =>
        $isCollapsibleListItemNode(parentNode) && !parentNode.isInline()
    )
    return parentBlock !== null && parentBlock.getIndent() > 0
  })

  if (hasIndentedParent) {
    return true
  }

  const anchorNode = selection.anchor.getNode()
  const listItemNode = anchorNode.getParent()

  if (!$isCollapsibleListItemNode(listItemNode)) {
    return false
  }

  const listNode = listItemNode.getParent()

  if (!$isCollapsibleListNode(listNode)) {
    return false
  }

  const lastListItemNode = listNode.getLastChild()

  if (!$isCollapsibleListItemNode(lastListItemNode)) {
    return false
  }

  const listParentNode = listNode.getParent()

  if (!$isRootNode(listParentNode)) {
    return false
  }

  return true
}

export function $getAllListItems(
  node: CollapsibleListNode
): Array<CollapsibleListItemNode> {
  let listItemNodes: Array<CollapsibleListItemNode> = []
  const listChildren: Array<CollapsibleListItemNode> = node
    .getChildren()
    .filter($isCollapsibleListItemNode)

  for (let i = 0; i < listChildren.length; i++) {
    const listItemNode = listChildren[i]
    const firstChild = listItemNode.getFirstChild()

    if ($isCollapsibleListNode(firstChild)) {
      listItemNodes = listItemNodes.concat($getAllListItems(firstChild))
    } else {
      listItemNodes.push(listItemNode)
    }
  }

  return listItemNodes
}

export function $getLastListItem(
  node: CollapsibleListNode
): CollapsibleListItemNode | null {
  const listItemNodes = $getAllListItems(node)
  if (listItemNodes.length === 0) {
    return null
  }

  return listItemNodes[listItemNodes.length - 1]
}

export function getFirstIndentedListItem(
  node: CollapsibleListItemNode
): CollapsibleListItemNode | null {
  const sibling = node.getNextSibling()
  if (!$isCollapsibleListItemNode(sibling)) {
    return null
  }

  const child = sibling.getFirstChild()
  if (!$isCollapsibleListNode(child)) {
    return null
  }

  const firstChild = child.getFirstChild()
  if (!$isCollapsibleListItemNode(firstChild)) {
    return null
  }

  return firstChild
}
