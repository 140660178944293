import { entries } from './iterate'

export type TypedEntry<T extends object> = NonNullable<
  {
    [K in keyof T]: {
      key: K
      value: NonNullable<T[K]>
    }
  }[keyof T]
>

/**
 * Returns an array of typed list of non-nullable key value pairs on the object
 * Since this keeps the type information, you are able to use the key as a predicate to the value
 * @param obj the object to get the entries of
 * @returns {TypedEntry<T>[]} array of typed entries
 */
export function getTypedEntries<T extends object>(obj: T): TypedEntry<T>[] {
  return entries(obj).reduce((acc, cur) => {
    if (cur[1] == null) return acc
    acc.push({ key: cur[0], value: cur[1] })
    return acc
  }, [] as TypedEntry<T>[])
}
