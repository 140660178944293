import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementNode,
  LexicalEditor,
  LexicalNode,
  type SerializedElementNode,
} from 'lexical'

export const DATA_ATTRIBUTE_HEADING_CONTENT = 'data-heading-content'
export class CollapsibleHeadingContentNode extends ElementNode {
  constructor(key?: string) {
    super(key)
  }

  createDOM(config: EditorConfig, editor: LexicalEditor): HTMLElement {
    const dom = document.createElement('div')

    dom.className = config.theme.collapsible.container

    return dom
  }

  updateDOM(prevNode: this, dom: HTMLDivElement): boolean {
    return false
  }

  static getType(): string {
    return 'collapsible-heading-content'
  }

  static clone(
    node: CollapsibleHeadingContentNode
  ): CollapsibleHeadingContentNode {
    return new CollapsibleHeadingContentNode(node.getKey())
  }

  static importJSON(
    serializedNode: SerializedElementNode
  ): CollapsibleHeadingContentNode {
    return new CollapsibleHeadingContentNode().updateFromJSON(serializedNode)
  }

  exportJSON(): SerializedElementNode {
    return {
      ...super.exportJSON(),
    }
  }

  isShadowRoot(): boolean {
    return true
  }

  static importDOM(): DOMConversionMap<HTMLDivElement> | null {
    return {
      div: (dom) => {
        return {
          conversion: $convertCollapsibleHeadingContentElement,
          priority: 1,
        }
      },
    }
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('div')
    element.setAttribute(DATA_ATTRIBUTE_HEADING_CONTENT, 'true')
    return {
      element,
    }
  }
}

export function $createCollapsibleHeadingContentNode(): CollapsibleHeadingContentNode {
  return new CollapsibleHeadingContentNode()
}

export function $isCollapsibleHeadingContentNode(
  node: LexicalNode | null | undefined
): node is CollapsibleHeadingContentNode {
  return node instanceof CollapsibleHeadingContentNode
}

function $convertCollapsibleHeadingContentElement(
  domNode: HTMLDivElement
): DOMConversionOutput | null {
  if (!domNode.hasAttribute(DATA_ATTRIBUTE_HEADING_CONTENT)) {
    return null
  }

  const node = $createCollapsibleHeadingContentNode()

  return {
    node,
  }
}
