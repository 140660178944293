import {
  type ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { SearchableListContent } from './common'
import { GrowOnlyDiv } from './grow-only-div'
import { SearchableListInput } from './search-input'

import { useItemSelectors } from '../hooks'
import { MAX_HEIGHT } from '../utils'

export type SearchableListContainerProps = {
  children: ReactNode
  placeholder?: string

  search: string
  setSearch(value: string): void
}

export const SearchableListContainer = (
  props: SearchableListContainerProps
) => {
  const { search, setSearch } = props

  const [activeValue, setActiveValue] = useState<string | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const selectors = useItemSelectors(containerRef)
  const isDirty = useRef<boolean>(false)

  const handleInputValueChange = useCallback(
    (search: string) => {
      setSearch(search)
    },
    [setSearch]
  )

  useLayoutEffect(() => {
    // selected the first item whenever the filtered items change, but only when the input is dirty
    if (!isDirty.current && !search) {
      return
    }
    isDirty.current = true
    const newValue = selectors.getFirstActiveItemValue()
    setActiveValue(newValue || null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  return (
    <SearchableListContent
      search={search}
      activeValue={activeValue}
      setActiveValue={setActiveValue}
      containerRef={containerRef}
    >
      <SearchableListInput
        placeholder={props.placeholder}
        onValueChange={handleInputValueChange}
        search={search}
      />
      <GrowOnlyDiv
        ref={containerRef}
        style={{
          maxHeight: MAX_HEIGHT,
          transitionProperty: 'height',
          transitionDuration: '75ms',
        }}
        className='scrollbar-none w-full scroll-py-1 overflow-y-auto overflow-x-hidden'
      >
        {props.children}
      </GrowOnlyDiv>
    </SearchableListContent>
  )
}
