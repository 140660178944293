import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  type Placement,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import { cloneElement, isValidElement, type ReactNode, useState } from 'react'

import { mergeRefs } from '../../utils'
import { PopoverContainer } from '../popover'

export interface HoverCardProps {
  children: ReactNode
  renderContent: (close?: () => void) => ReactNode
  placement?: Placement
  disableAutoClose?: boolean
}

export const HoverCard = (props: HoverCardProps) => {
  const {
    children,
    renderContent,
    placement = 'left-start',
    disableAutoClose = false,
  } = props
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    middleware: [
      offset(5),
      flip({ fallbackAxisSideDirection: 'end' }),
      shift({ padding: 5 }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const hover = useHover(context, {
    delay: {
      open: 300,
      close: 400,
    },
    handleClose: disableAutoClose
      ? null
      : safePolygon({
          requireIntent: false,
        }),
  })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
    role,
    focus,
  ])

  const trigger = isValidElement(children) ? (
    cloneElement(children, {
      ...getReferenceProps(),
      ref: mergeRefs(refs.setReference, (children as any).ref),
    } as React.HTMLAttributes<HTMLElement>)
  ) : (
    <div ref={refs.setReference} {...getReferenceProps()}>
      {children}
    </div>
  )

  const close = () => setIsOpen(false)

  return (
    <>
      {trigger}
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} visuallyHiddenDismiss>
            <PopoverContainer
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                maxHeight: '80vh',
                overflowY: 'auto',
              }}
              onClick={(e) => e.stopPropagation()}
              onContextMenu={(e) => e.stopPropagation()}
              {...getFloatingProps()}
            >
              {renderContent(close)}
            </PopoverContainer>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  )
}
