import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
import { LineBreakNode } from 'lexical'
import { useEffect } from 'react'

export const RemoveNewLinesPlugin = () => {
  const [editor] = useLexicalComposerContext()
  useEffect(() => {
    return mergeRegister(
      editor.registerNodeTransform(LineBreakNode, (node) => {
        node.remove()
      })
    )
  }, [editor])
  return null
}
