import { type LexicalEditor, type RootNode } from 'lexical'

function $getEditorRoot(editor: LexicalEditor): RootNode {
  const editorState = editor.getEditorState()
  if (editorState == null) {
    throw new Error('Unable to find an editor state.')
  }
  return editorState._nodeMap.get('root') as RootNode
}

export function $focusEditor(editor: LexicalEditor, position: 'start' | 'end') {
  editor.focus(
    () => {
      editor.update(() => {
        const root = $getEditorRoot(editor)
        if (position === 'start') {
          root.selectStart()
        } else {
          root.selectEnd()
        }
      })
    },
    { defaultSelection: position === 'start' ? 'rootStart' : 'rootEnd' }
  )
}
