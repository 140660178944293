import { ConditionalWrapper } from '../../../utils'
import { Tooltip } from '../../tooltip'
import { type VirtualizedTreeNode } from '../types'

export const PrettyPath = (props: { node: VirtualizedTreeNode }) => {
  const { node } = props

  if (!node.prettyPath || !node.prettyPath.length) return null

  const truncatePath = node.prettyPath.length > 3
  const fullPathString = node.prettyPath.join(' / ')

  return (
    <ConditionalWrapper
      condition={truncatePath}
      wrapper={(children) => (
        <Tooltip content={fullPathString}>{children}</Tooltip>
      )}
    >
      <div className='inline-block text-semantic-neutral-text-disabled text-xs px-1 mt-0.5'>
        {truncatePath
          ? `${node.prettyPath[0]} / ... / ${node.prettyPath.at(-1)}`
          : fullPathString}
      </div>
    </ConditionalWrapper>
  )
}
