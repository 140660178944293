import {
  ProjectCubeCanceledNotesSolid,
  ProjectCubeCanceledSolid,
  ProjectCubeCompletedNotesSolid,
  ProjectCubeCompletedSolid,
  ProjectCubeNotesSolid,
  ProjectCubeSolid,
  type SvgIcon,
  type SvgIconProps,
} from '@motion/icons'
import { type COLOR } from '@motion/shared/common'

import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { ProjectPalette } from '../palette'

export type ProjectColoredIconVariant = 'DEFAULT' | 'COMPLETED' | 'CANCELED'

const iconsWithoutNotes: Record<ProjectColoredIconVariant, SvgIcon> = {
  DEFAULT: ProjectCubeSolid,
  CANCELED: ProjectCubeCanceledSolid,
  COMPLETED: ProjectCubeCompletedSolid,
}

const iconsWithNotes: Record<ProjectColoredIconVariant, SvgIcon> = {
  DEFAULT: ProjectCubeNotesSolid,
  CANCELED: ProjectCubeCanceledNotesSolid,
  COMPLETED: ProjectCubeCompletedNotesSolid,
}

type ProjectColoredIconProps = SvgIconProps & {
  color?: COLOR
  noProject?: boolean
  variant?: ProjectColoredIconVariant
  withNotes?: boolean
}

export const ProjectColoredIcon = forwardRef<
  SVGSVGElement,
  ProjectColoredIconProps
>(function ProjectIcon(
  {
    className,
    color = 'gray',
    noProject = false,
    variant = 'DEFAULT',
    withNotes = false,
    ...etc
  },
  ref
) {
  const Icon = (withNotes ? iconsWithNotes : iconsWithoutNotes)[variant]

  return (
    <ProjectPalette color={color}>
      <Icon
        ref={ref}
        className={twMerge(
          '!text-palette-highlight-default shrink-0',
          noProject && 'opacity-50',
          className
        )}
        {...etc}
      />
    </ProjectPalette>
  )
})
