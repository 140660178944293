/**
 * When using a non-primitive value (functions are non-primitive) as a default
 * parameter in a hook, every time the hook is called, a brand-new function is
 * created. Even if it's "empty", it’s still a new reference in memory every
 * time. So from React’s perspective (or anything using referential equality
 * checks), that prop has changed, which can trigger unnecessary re-renders or
 * re-computations in downstream components/hooks/memoized functions.
 *
 * See: https://usemotion.slack.com/archives/C05L59APX53/p1743604995613549
 */

export const NOOP_FUNCTION = () => void 0
export const ASYNC_NOOP_FUNCTION = () => Promise.resolve()
