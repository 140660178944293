import {
  ArrowDownSolid,
  ArrowLeftSolid,
  ArrowRightSolid,
  ArrowUpSolid,
  DotsHorizontalSolid,
  DotsVerticalSolid,
  TrashSolid,
} from '@motion/icons'
import { classed } from '@motion/theme'
import { ActionList, PopoverTrigger } from '@motion/ui/base'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useLexicalEditable } from '@lexical/react/useLexicalEditable'
import {
  $deleteTableColumn__EXPERIMENTAL,
  $deleteTableRow__EXPERIMENTAL,
  $getTableCellNodeFromLexicalNode,
  $insertTableColumn__EXPERIMENTAL,
  $insertTableRow__EXPERIMENTAL,
  $isTableNode,
  getTableElement,
} from '@lexical/table'
import { $findMatchingParent, mergeRegister } from '@lexical/utils'
import { $getSelection, $isRangeSelection } from 'lexical'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { useEditorContext } from '../../context'

const TableHoverActionsContainer = ({
  anchorElem,
}: {
  anchorElem: HTMLElement
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const [editor] = useLexicalComposerContext()
  const [pos, setPos] = useState<{
    top: { x: number; y: number }
    left: { x: number; y: number; isHeader?: boolean }
  } | null>(null)

  const [scrollDOMNode, setScrollDOMNode] = useState<HTMLElement | null>(null)

  const updateContainerPosition = useCallback(
    (scrollNode: HTMLElement | null) => {
      if (!scrollNode || !containerRef.current) return
      containerRef.current.style.transform = `translate(${-scrollNode.scrollLeft}px, 0)`
    },
    []
  )

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        editor.getEditorState().read(() => {
          setPos(null)
          const selection = $getSelection()
          if (!anchorElem) return false
          if ($isRangeSelection(selection)) {
            const maybeTableCell = $getTableCellNodeFromLexicalNode(
              selection.anchor.getNode()
            )
            if (!maybeTableCell) return false
            const tableNode = $findMatchingParent(maybeTableCell, (node) =>
              $isTableNode(node)
            )
            if (!tableNode) return false
            const scrollDOMElement = editor.getElementByKey(tableNode.getKey())
            const tableDOMElement = getTableElement(
              tableNode,
              editor.getElementByKey(tableNode.getKey())
            )
            const cellDOMElement = editor.getElementByKey(
              maybeTableCell.getKey()
            )
            if (!tableDOMElement || !cellDOMElement) return false
            const containerRect = anchorElem.getBoundingClientRect()
            const tableRect = tableDOMElement.getBoundingClientRect()
            const cellRect = cellDOMElement.getBoundingClientRect()

            const scrollLeft = scrollDOMElement?.scrollLeft ?? 0
            setPos({
              top: {
                x:
                  cellRect.x -
                  containerRect.x +
                  cellRect.width * 0.5 +
                  // Unlike the table, the cell's position includes the scroll, so we have to subtract it
                  scrollLeft,
                y: tableRect.y - containerRect.y,
              },
              left: {
                x: tableRect.x + scrollLeft - containerRect.x,
                y: cellRect.y - containerRect.y + cellRect.height * 0.5,
                isHeader: maybeTableCell.hasHeader(),
              },
            })
            setScrollDOMNode(scrollDOMElement)
            // Just in case, make sure the scroll pos is the latest
            updateContainerPosition(scrollDOMElement)
          }
        })
      })
    )
  }, [anchorElem, editor, updateContainerPosition])

  useEffect(() => {
    if (scrollDOMNode) {
      const handleScroll = function (e: Event) {
        if (e.target !== scrollDOMNode) return
        updateContainerPosition(scrollDOMNode)
      }

      scrollDOMNode.addEventListener('scroll', handleScroll, {
        capture: true,
        passive: true,
      })
      return () => {
        scrollDOMNode.removeEventListener('scroll', handleScroll, true)
      }
    }
  }, [scrollDOMNode, updateContainerPosition])

  const insertTableColumnAtSelection = useCallback(
    (shouldInsertAfter: boolean) => {
      editor.update(() => {
        $insertTableColumn__EXPERIMENTAL(shouldInsertAfter)
      })
    },
    [editor]
  )

  const deleteTableColumnAtSelection = useCallback(() => {
    editor.update(() => {
      $deleteTableColumn__EXPERIMENTAL()
    })
  }, [editor])

  const insertTableRowAtSelection = useCallback(
    (shouldInsertAfter: boolean) => {
      editor.update(() => {
        $insertTableRow__EXPERIMENTAL(shouldInsertAfter)
      })
    },
    [editor]
  )

  const deleteRowAtSelection = useCallback(() => {
    editor.update(() => {
      $deleteTableRow__EXPERIMENTAL()
    })
  }, [editor])

  return (
    <div ref={containerRef} className='absolute top-0 left-0'>
      {pos?.top && (
        <PopoverTrigger
          renderPopover={({ close }) => {
            return (
              <div onClick={() => close()}>
                <ActionList
                  items={[
                    {
                      prefix: <ArrowLeftSolid />,
                      content: 'Insert left',
                      onAction: () => insertTableColumnAtSelection(false),
                    },
                    {
                      prefix: <ArrowRightSolid />,
                      content: 'Insert right',
                      onAction: () => insertTableColumnAtSelection(true),
                    },
                    {
                      prefix: <TrashSolid />,
                      content: 'Delete column',
                      onAction: () => deleteTableColumnAtSelection(),
                    },
                  ]}
                />
              </div>
            )
          }}
          placement='bottom'
        >
          <MenuButton
            className='w-7 h-4'
            style={{
              top: pos.top.y,
              left: pos.top.x,
              transform: 'translate(-50%, -50%)',
            }}
          >
            <DotsHorizontalSolid className='h-4 shrink-0' />
          </MenuButton>
        </PopoverTrigger>
      )}
      {pos?.left && (
        <PopoverTrigger
          renderPopover={({ close }) => {
            return (
              <div onClick={() => close()}>
                <ActionList
                  items={
                    pos.left.isHeader
                      ? [
                          {
                            prefix: <ArrowDownSolid />,
                            content: 'Insert below',
                            onAction: () => insertTableRowAtSelection(true),
                          },
                        ]
                      : [
                          {
                            prefix: <ArrowUpSolid />,
                            content: 'Insert above',
                            onAction: () => insertTableRowAtSelection(false),
                          },
                          {
                            prefix: <ArrowDownSolid />,
                            content: 'Insert below',
                            onAction: () => insertTableRowAtSelection(true),
                          },
                          {
                            prefix: <TrashSolid />,
                            content: 'Delete row',
                            onAction: () => deleteRowAtSelection(),
                          },
                        ]
                  }
                />
              </div>
            )
          }}
          placement='left'
        >
          <MenuButton
            className='h-7 w-4'
            style={{
              top: pos.left.y,
              left: pos.left.x,
              transform: 'translate(-50%, -50%)',
            }}
          >
            <DotsVerticalSolid className='w-4 shrink-0' />
          </MenuButton>
        </PopoverTrigger>
      )}
    </div>
  )
}

const MenuButton = classed('button', {
  base: 'absolute top-0 left-0 bg-slate-200 flex justify-center items-center rounded  bg-semantic-neutral-bg-active-default border border-dropdown-border hover:bg-semantic-neutral-bg-active-hover',
})

export function TableHoverActionsPlugin(): React.ReactPortal | null {
  const { floatingAnchorElem } = useEditorContext()

  const isEditable = useLexicalEditable()

  if (!floatingAnchorElem || !isEditable) return null

  return createPortal(
    <TableHoverActionsContainer anchorElem={floatingAnchorElem} />,
    floatingAnchorElem
  ) as React.ReactPortal
}
