import {
  $createParagraphNode,
  $isElementNode,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  ElementNode,
  LexicalEditor,
  type LexicalNode,
  RangeSelection,
  type SerializedElementNode,
} from 'lexical'

import { $isCollapsibleContainerNode } from './collapsible-container-node'
import { $isCollapsibleContentNode } from './collapsible-content-node'

const DATA_COLLAPSIBLE_TITLE_ATTRIBUTE = 'data-lexical-collapsible-title'

export class CollapsibleTitleNode extends ElementNode {
  static getType(): string {
    return 'collapsible-title'
  }

  constructor(key?: string) {
    super(key)
  }

  static clone(node: CollapsibleTitleNode): CollapsibleTitleNode {
    return new CollapsibleTitleNode(node.__key)
  }

  createDOM(config: EditorConfig, editor: LexicalEditor): HTMLElement {
    const element = document.createElement('summary')
    element.className = config.theme.collapsible.title

    element.addEventListener('click', (e) => {
      editor.update(() => {
        const rect = element.getBoundingClientRect()

        const containerNode = this.getParentOrThrow()
        if (!$isCollapsibleContainerNode(containerNode)) {
          throw new Error('Parent is not a CollapsibleContainerNode')
        }

        if (rect.left - e.clientX >= 0) {
          containerNode.$toggleOpen()
        }
      })
    })

    return element
  }

  updateDOM(): false {
    return false
  }

  static importJSON(
    serializedNode: SerializedElementNode
  ): CollapsibleTitleNode {
    return new CollapsibleTitleNode().updateFromJSON(serializedNode)
  }

  static importDOM(): DOMConversionMap | null {
    return {
      summary: (domNode) => {
        if (!domNode.hasAttribute(DATA_COLLAPSIBLE_TITLE_ATTRIBUTE)) {
          return null
        }

        return {
          conversion: $convertSummaryElement,
          priority: 2,
        }
      },
    }
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const element = document.createElement('summary')
    element.setAttribute(DATA_COLLAPSIBLE_TITLE_ATTRIBUTE, '')
    return {
      element,
    }
  }

  insertNewAfter(
    selection: RangeSelection,
    restoreSelection?: boolean
  ): ElementNode {
    const containerNode = this.getParentOrThrow()

    if (!$isCollapsibleContainerNode(containerNode)) {
      throw new Error('Parent is not a CollapsibleContainerNode')
    }

    if (containerNode.getOpen()) {
      const contentNode = this.getNextSibling()
      if (!$isCollapsibleContentNode(contentNode)) {
        throw new Error(
          'CollapsibleTitleNode expects to have CollapsibleContentNode sibling'
        )
      }

      const firstChild = contentNode.getFirstChild()
      if ($isElementNode(firstChild)) {
        return firstChild
      }
      const paragraph = $createParagraphNode()
      contentNode.append(paragraph)
      return paragraph
    }

    const paragraph = $createParagraphNode()
    containerNode.insertAfter(paragraph, restoreSelection)
    return paragraph
  }

  collapseAtStart(): boolean {
    this.getParentOrThrow().insertBefore(this)
    return true
  }

  static transform(): (node: LexicalNode) => void {
    return (node) => {
      if (!(node instanceof CollapsibleTitleNode)) {
        throw new Error('node is not a CollapsibleTitleNode')
      }

      if (node.isEmpty()) {
        node.remove()
      }
    }
  }
}

export function $createCollapsibleTitleNode(): CollapsibleTitleNode {
  return new CollapsibleTitleNode()
}

export function $isCollapsibleTitleNode(
  node: LexicalNode | null | undefined
): node is CollapsibleTitleNode {
  return node instanceof CollapsibleTitleNode
}

function $convertSummaryElement(domNode: HTMLElement): DOMConversionOutput {
  return {
    node: $createCollapsibleTitleNode(),
  }
}
