import { createCommand, type NodeKey } from 'lexical'

import { type TurnIntoOptionKey } from './types'

type TurnIntoCommandPayload = {
  nodeKey: NodeKey
  type: TurnIntoOptionKey
}

export const TURN_INTO_COMMAND =
  createCommand<TurnIntoCommandPayload>('TURN_INTO_COMMAND')
