import { createCommand, type LexicalCommand } from 'lexical'

export type EmbedSrcType = 'youtube' | 'bookmark' | 'video-player'

export type EmbedCommandPayload = {
  src: string
  srcType: EmbedSrcType
}

export const INSERT_CUSTOM_EMBED_COMMAND: LexicalCommand<EmbedCommandPayload> =
  createCommand('INSERT_CUSTOM_EMBED_COMMAND')

export const FORMAT_QUOTE_COMMAND: LexicalCommand<boolean> = createCommand(
  'FORMAT_QUOTE_COMMAND'
)

export const INSERT_CODE_BLOCK_COMMAND: LexicalCommand<void> = createCommand(
  'INSERT_CODE_BLOCK_COMMAND'
)

export { TOGGLE_INLINE_CREATE_COMMAND } from './inline-create-plugin/command'
