import { useCallback, useEffect, useMemo, useRef } from 'react'

const DEBUG_STYLES = {
  draggable: {
    outline: '2px solid red',
    outlineOffset: '2px',
  },
  target: {
    outline: '2px solid blue',
    outlineOffset: '2px',
  },
}

type UseDebugVisualizerReturn = {
  highlightDraggable: (element: HTMLElement | null) => void
  highlightTarget: (element: HTMLElement | null) => void
  clearDraggableHighlight: () => void
  clearTargetHighlight: () => void
  clearAll: () => void
}

/**
 * Visualize the draggable and droppable elements
 * This highlights the exact elements that will be used in the drop operation
 */
export function useDebugVisualizer(
  isEnabled: boolean = false
): UseDebugVisualizerReturn {
  const draggableRef = useRef<HTMLElement | null>(null)
  const targetRef = useRef<HTMLElement | null>(null)
  const enabledRef = useRef<boolean>(isEnabled)

  useEffect(() => {
    enabledRef.current = isEnabled
    if (!isEnabled) {
      clearDraggableHighlight()
      clearTargetHighlight()
    }
  }, [isEnabled])

  const highlightDraggable = useCallback((element: HTMLElement | null) => {
    clearDraggableHighlight()

    if (!enabledRef.current || !element) return

    element.style.outline = DEBUG_STYLES.draggable.outline
    element.style.outlineOffset = DEBUG_STYLES.draggable.outlineOffset
    draggableRef.current = element
  }, [])

  const highlightTarget = useCallback((element: HTMLElement | null) => {
    clearTargetHighlight()

    if (!enabledRef.current || !element) return

    element.style.outline = DEBUG_STYLES.target.outline
    element.style.outlineOffset = DEBUG_STYLES.target.outlineOffset
    targetRef.current = element
  }, [])

  function clearDraggableHighlight() {
    if (draggableRef.current) {
      draggableRef.current.style.outline = ''
      draggableRef.current.style.outlineOffset = ''
      draggableRef.current = null
    }
  }

  function clearTargetHighlight() {
    if (targetRef.current) {
      targetRef.current.style.outline = ''
      targetRef.current.style.outlineOffset = ''
      targetRef.current = null
    }
  }

  const clearAll = useCallback(() => {
    clearDraggableHighlight()
    clearTargetHighlight()
  }, [])

  useEffect(() => {
    return () => {
      clearAll()
    }
  }, [clearAll])

  return useMemo(
    () => ({
      highlightDraggable,
      highlightTarget,
      clearDraggableHighlight,
      clearTargetHighlight,
      clearAll,
    }),
    [clearAll, highlightDraggable, highlightTarget]
  )
}
